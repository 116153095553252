import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import { createUserWithEmailAndPassword, updateProfile, signOut, getAuth } from 'firebase/auth'

// Modals
import MainModal from '../../modals/common/MainModal'
import { ModalBackButton } from '../../modals/components/ModalBackButton'

// Components
import { IconError } from '../../icons/IconError'
import { InputUploadMemberPictureRow } from '../../inputs/InputUploadMemberPictureRow'
import { InputNewMemberRow, IOption } from '../../inputs/InputNewMemberRow'
import { InputCheckMemberInfo } from '../../inputs/InputCheckMemberInfo'

// Helpers
import { isValidEmail } from '../../../helpers/isValidEmail'

// Services
import FirestoreService from '../../../services/firestoreService'

// Helpers
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'
import { getConvertedData, getSelectedUserAsTeamMember } from '../../../models/interface.helper'
import { uploadPicture } from '../../../helpers/uploadPicture'

// Models
import { IRegistrationTeamInterface } from '../../../models/registeration-teams/registration-teams.interface'
import { UserModel } from '../../../models/users/user.model'
import { ITeamMember, IUserInterface } from '../../../models/users/user.interface'

// Store
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectTeamTabData, setTeamTabData } from '../../../store/registration/registrationSlice'
import { RootState } from '../../../store/store'
import { selectEventDetails } from '../../../store/events/eventsSlice'
import { selectAllUsers, setAllUsers } from '../../../store/users/usersSlice'
import {
  riderProfileRegistrationActions,
  selectNewTeamRiderRegistration,
  selectRiderProfileRegistration,
} from '../../../store/riderProfileRegistration/riderProfileRegistrationSlice'

// Const
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST, USER_DEF_PASS } from '../../../const/const'

// Types
import { IInputUserData, IInputUserKey } from '../types/inputData'

// Hooks
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import useUserAccountHook from '../../../hooks/users/competitor/profile/useAccountHook'

import { initialUserInputs, inputUserIcons } from './data/input-data'

const auth = getAuth()

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

const userIcon = '/assets/img/User2.png'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const AddNewTeamMemberModal = (props: Props) => {
  const { updateUserDetails } = useProfileHook({ dontFetch: true })
  const { handleOnLoginValid, checkValidity, onChangeHandler } = useUserAccountHook()
  const toastFunctions = useToasterHelper()
  const dispatch = useAppDispatch()

  const [inputData, setInputDate] = useState<IInputUserData>(cloneDeep(initialUserInputs))
  const [picture, setPicture] = useState('')
  const [pictureFile, setPictureFile] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const event = useAppSelector(selectEventDetails)
  const riderProfileRegistration = useAppSelector(selectRiderProfileRegistration)
  const newTeamRiderRegistration = useAppSelector(selectNewTeamRiderRegistration)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const teamMembers = useAppSelector(selectTeamTabData)
  const users = useAppSelector(selectAllUsers)

  useEffect(() => {
    if (newTeamRiderRegistration) {
      setInputDate(newTeamRiderRegistration.inputData)
      setIsConfirmed(true)

      if (newTeamRiderRegistration.picture) {
        const imageUrl = URL.createObjectURL(newTeamRiderRegistration.picture)
        setPicture(imageUrl)
      }
    }
  }, [newTeamRiderRegistration])

  useEffect(() => {
    setIsValid(isConfirmed && checkValidity(inputData))
  }, [inputData, isConfirmed])

  const submitOnlyMemberHandler = async ({
    isSetLoading,
    fromHorse,
    newCompetitor,
    newPicture,
  }: {
    isSetLoading?: boolean
    fromHorse?: boolean
    newCompetitor?: IUserInterface
    newPicture?: string
  }): Promise<{
    id: string | null
    email: string | null
  } | null> => {
    const { email, userName, userFullName, date, nationality, discipline } = inputData
    let user: IUserInterface | null = null
    isSetLoading && setLoading(true)

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        `${email.value}`.toLowerCase(),
        USER_DEF_PASS
      )
      if (props.dataToPassOn.isLogin) await signOut(auth)

      const newUser = userCredential.user

      await updateProfile(userCredential.user, {
        displayName: `${userFullName.value}`,
      })

      await FirestoreService.createItemWithCustomId(
        COLLECTIONS.USERS.NAME,
        newUser.uid,
        new UserModel({
          id: newUser.uid,
          userName: userName.value as string,
          userFullName: userFullName.value as string,
          userEmail: `${email.value}`.toLowerCase(),
          userType: 'competitor',
          userProfilePicture: newPicture || picture || '',
          userDOB: date.value.toString(),
          userNationality: (nationality?.value as IOption).label as string,
          userDiscipline: (discipline?.value as IOption).label as string,
          userCreated: new Date(),
          userModified: new Date(),
        }).toFirestore()
      )
      const userSnaps = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, newUser.uid)

      if (userSnaps.exists()) {
        user = getConvertedData(UserModel.fromFirestoreDoc(userSnaps).toObject())
      }

      if (!props.dataToPassOn.isLogin) {
        const userTeamMember: ITeamMember = {
          defaultRole: user?.userDefaultRole,
          memberAddress: user?.userAddress,
          memberAuthorized: '1',
          memberCountry: user?.userNationality,
          memberDob: user?.userDOB,
          memberEmail: user?.userEmail,
          memberId: user?.id ?? '',
          memberName: user ? getUserFullName(user) : null,
          memberPhoneNumber: user?.userPhoneNumber,
          memberProfilePicture: user?.userProfilePicture,
          memberRole: CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER,
          memberSafeSupportTraining: false,
          memberShipActive: false,
          memberStatus: '1',
          memberUserName: user?.userName,
          memberprofileSynced: false,
          selected: true,
          connectedDate: new Date(),
        }
        const updatedProfileDetails = cloneDeep(newCompetitor || profileDetails)

        if (Array.isArray(updatedProfileDetails.userTeamMembers)) {
          updatedProfileDetails.userTeamMembers.push({ ...userTeamMember })
        } else {
          updatedProfileDetails.userTeamMembers = [userTeamMember]
        }

        await updateUserDetails(updatedProfileDetails)

        const teamData: IRegistrationTeamInterface[] = []

        if (user) {
          teamData.push(
            getConvertedData(getSelectedUserAsTeamMember(user, newCompetitor || profileDetails))
          )
        }
        dispatch(setTeamTabData([...teamMembers, ...teamData]))
        const allUsers: IUserInterface[] = []

        const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

        usersSnaps.forEach((currSnap) => {
          allUsers.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
        })

        dispatch(setAllUsers(allUsers))
      }
      if (props.dataToPassOn.isLogin && !fromHorse) {
        await handleOnLoginValid({
          email: `${email.value}`,
          eventId: event.id,
          password: USER_DEF_PASS,
        })
      }

      isSetLoading && toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBER_CREATED })
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
      return {
        email: `${email.value}`,
        id: user?.id || null,
      }
    } catch (error: any) {
      console.error(error, 'error')
      toastFunctions.error({ message: error?.message ?? MESSAGES_CONST.TEAM_MEMBER_NOT_CREATED })
      return null
    } finally {
      isSetLoading && setLoading(false)
    }
  }

  const submitHandler = () => {
    const { email } = inputData
    if (!email || !email.value || !isValidEmail(`${email.value}`)) {
      const newInputData = { ...inputData }
      newInputData['email'].hasError = true
      setInputDate(newInputData)
      toastFunctions.error({
        message: 'Invalid email address',
      })
      return null
    }

    if (riderProfileRegistration.isTeam) {
      dispatch(
        riderProfileRegistrationActions.setNewTeamRider({
          picture: pictureFile,
          inputData,
        })
      )
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
      props.handleModal(true, MODAL_CONSTS.CONFIRM_PROFILE_DETAILS, {
        submitOnlyMemberHandler,
      })
    } else if (props.dataToPassOn.isLogin) {
      submitOnlyMemberHandler({ isSetLoading: true }).then()
    } else {
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
      props.handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
        userPicture: picture,
        submitOnlyMemberHandler,
        ...props.dataToPassOn,
      })
    }
  }

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      if (riderProfileRegistration.rider) {
        setPictureFile(file)
        const imageUrl = URL.createObjectURL(file)
        setPicture(`${imageUrl}`)
      } else {
        const downloadUrl = await uploadPicture(file)
        if (downloadUrl) {
          setPicture(`${downloadUrl}`)
        }

        toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
      }
    } catch (error) {
      console.error(error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setImgLoading(false)
    }
  }

  const backHandler = () => {
    props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
    props.handleModal(true, MODAL_CONSTS.REGISTER_TEAM, {
      userTeamMembers: riderProfileRegistration.team,
      users,
      competitor: riderProfileRegistration.competitor,
      authorizeUserHorses: [],
    })
  }

  return (
    <>
      <MainModal
        title="Add new team member"
        description={'Create account for a team member and invite them to Pegasus'}
        show={props.show}
        type="ADD_NEW_TEAM_MEMBER"
        size="md"
        goBackBtn={riderProfileRegistration.isTeam && <ModalBackButton onClick={backHandler} />}
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={[
          {
            loading,
            label: 'NEXT >',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: submitHandler,
            className: 'outline-none !w-full',
            textClass: 'text-white',
            disabled: !isValid,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none !w-full',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => {
              props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
              dispatch(riderProfileRegistrationActions.clear())
            },
          },
        ]}
      >
        <div className={'flex flex-col gap-4 mt-4'}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              title={'Profile picture'}
              isLoading={imgLoading}
              picture={picture}
              onchangeHandler={updateProfilePic}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key, i) => (
              <div key={key} className={`relative z-${i}`}>
                <InputNewMemberRow
                  value={inputData[key as IInputUserKey].value}
                  onChangeHandler={(key, value) => {
                    const newData = onChangeHandler(inputData, key, value)
                    setInputDate(newData)
                  }}
                  placeholder={inputData[key as IInputUserKey].placeholder}
                  inputName={inputData[key as IInputUserKey].name}
                  name={inputData[key as IInputUserKey].label}
                  isError={inputData[key as IInputUserKey].hasError}
                  icon={inputUserIcons[inputData[key as IInputUserKey].name as IInputUserKey]}
                  isRequired={inputData[key as IInputUserKey].required}
                  type={inputData[key as IInputUserKey].type}
                  selectData={inputData[key as IInputUserKey].selectData || []}
                />
              </div>
            ))}
          </div>

          <InputCheckMemberInfo
            isChecked={isConfirmed}
            onClick={() => setIsConfirmed(!isConfirmed)}
            text={
              'By ticking this box I confirm that I have been given permission to create a Pegasus\n' +
              'account on behalf of this person, and I am authorized to agree to Pegasus’ terms and\n' +
              'conditions on their behalf, as well as register them for events.'
            }
          />

          <div className={'flex mt-4 gap-4 bg-[#F6F7FB] py-[12px] px-[20px] rounded-[12px]'}>
            <div className={'mt-1'}>
              <IconError />
            </div>

            <p className={'flex-1 text-[#122B46] text-[14px] m-0'}>
              The email address listed above will be notified of this account creation and provided
              with login details to join Pegasus and complete their profile.
            </p>
          </div>
        </div>
      </MainModal>
    </>
  )
}
