import { getFilteredPaperwork } from './documents'
import { IPaperworkTeam } from '../models/user-documents/user-documents.interface'

export const paperworkPdfPageParams = {
  pageWidth: 550,
  pageHeight: 760,
  distanceBetweenLines: 20,
  pageVerticalStartPoint: 720,
  pageHorizontalStartPoint: 45,
  pageHorizontalEndPoint: 480,
  pageVerticalEndPoint: 80,
}

export const getAllPaperwork = (rider: IPaperworkTeam) => {
  let universalOrganizerWaver = false
  let USEFWaiverAndReleaseOfLiability = false
  let USEFEntryAgreement = false
  let USDFWaiverAndReleaseOfLiability = false
  let USEAWaiverAndReleaseOfLiability = false
  let USHJAWaiverAndReleaseOfLiability = false

  if (rider.documents && Array.isArray(rider.documents)) {
    universalOrganizerWaver = !!rider.documents.find(
      (document) => document.documentName === 'universalOrganizerWaver'
    )
    USEFEntryAgreement = !!rider.documents.find(
      (document) => document.documentName === 'USEFEntryAgreement'
    )
    USHJAWaiverAndReleaseOfLiability = !!rider.documents.find(
      (document) => document.documentName === 'USHJAWaiverAndReleaseOfLiability'
    )
    USDFWaiverAndReleaseOfLiability = !!rider.documents.find(
      (document) => document.documentName === 'USDFWaiverAndReleaseOfLiability'
    )
    USEAWaiverAndReleaseOfLiability = !!rider.documents.find(
      (document) => document.documentName === 'USEAWaiverAndReleaseOfLiability'
    )
    USEFWaiverAndReleaseOfLiability = !!rider.documents.find(
      (document) => document.documentName === 'USEFWaiverAndReleaseOfLiability'
    )
  }

  return getFilteredPaperwork({
    universalOrganizerWaver,
    USEFWaiverAndReleaseOfLiability,
    USEFEntryAgreement,
    USDFWaiverAndReleaseOfLiability,
    USEAWaiverAndReleaseOfLiability,
    USHJAWaiverAndReleaseOfLiability,
  })
}
