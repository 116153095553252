import React from 'react'

import { FieldErrors, UseFormRegister } from 'react-hook-form'

import { IconDelete } from '../../../../components/icons/IconDelete'
import SwitchElementUpdated from '../../../../components/events/forms/paperwork/SwitchElement'

import { IEventInterface, IQuestion } from '../../../../models/events/event.interface'
import MessageHelperComp from '../../../../helpers/MessageHelper'

interface EventQuestionInputProps {
  index: number
  question: IQuestion
  errors: FieldErrors<IEventInterface>
  register: UseFormRegister<IEventInterface>
  onRemove: () => void
  onUpdate: (value: IQuestion) => void
}

export const EventQuestionInput: React.FC<EventQuestionInputProps> = ({
  register,
  errors,
  index,
  onRemove,
  onUpdate,
  question,
}) => {
  const ref = React.useRef<HTMLInputElement | null>(null)

  return (
    <div
      ref={ref}
      className="relative w-full items-center gap-x-3 gap-y-7 flex flex-wrap md:flex-nowrap"
    >
      <input
        className="w-full px-[22px] border-solid rounded-lg h-[55px] !text-[16px] bg-SeabiscuitGrayThemeColor focus:bg-white border-white focus:border-SeabiscuitGray500ThemeColor"
        {...register(`questions.${index}.value`)}
        type="text"
        placeholder={'Enter question'}
      />
      <div className={'relative flex items-center'}>
        <div className={'text-[10px] absolute bottom-[100%] left-0 mb-0.5'}>Required</div>
        <SwitchElementUpdated
          onText={'Yes'}
          offText={'No'}
          onSwitch={() => onUpdate({ ...question, isRequired: !question?.isRequired })}
          on={question?.isRequired}
        />
      </div>
      <button onClick={onRemove}>
        <IconDelete />
      </button>
      {!!errors?.['questions']?.[index]?.value?.message ? (
        <MessageHelperComp
          className="absolute z-0 bottom-0 left-0 translate-y-full"
          isError={true}
          message={errors?.['questions']?.[index]?.value?.message}
        />
      ) : null}
    </div>
  )
}
