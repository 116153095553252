import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { cloneDeep } from 'lodash'

// Const
import { MODAL_CONSTS } from '../../../const/modal-const'
import { MESSAGES_CONST } from '../../../const/messages-const'

// Modals
import MainModal, { IMainModalButton } from '../../modals/common/MainModal'
import { ModalBackButton } from '../../modals/components/ModalBackButton'
import { createHorseData } from '../../modals/common/createHorseData'

// Store
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectHorses,
  setHorses,
  setIsMyHorse,
  setSelectedHorseStatus,
} from '../../../store/horses/horseSlice'
import { selectEventDetails } from '../../../store/events/eventsSlice'
import {
  riderProfileRegistrationActions,
  selectIsTeamRegistration,
  selectRiderProfileHorse,
  selectRiderProfileRegistration,
} from '../../../store/riderProfileRegistration/riderProfileRegistrationSlice'
import { selectAllUsers } from '../../../store/users/usersSlice'

// Components
import { InputNewMemberRow, IOption } from '../../inputs/InputNewMemberRow'
import { InputUploadMemberPictureRow } from '../../inputs/InputUploadMemberPictureRow'
import { InputCheckMemberInfo } from '../../inputs/InputCheckMemberInfo'

// Types
import { IInputHorseData, IInputHorseDataKey } from '../types/inputData'
import { ITEAMMEMBER_MODAL_TYPES } from './UserProfileTeamTabAddTeamMember'

// Helpers
import useToasterHelper from '../../../helpers/ToasterHelper'
import { uploadPicture } from '../../../helpers/uploadPicture'

import useUserAccountHook from '../../../hooks/users/competitor/profile/useAccountHook'

import { initialHorseInputs, inputHorseIcons } from './data/input-data'
import { USER_DEF_PASS } from '../../../const/const'
import { selectProfileData } from '../../../store/user/userSlice'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

const userIcon = '/assets/img/HorsesSign.png'

export const AddNewHorseModal = (props: Props) => {
  const { handleOnLoginValid } = useUserAccountHook()
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)
  const [horsePicture, setHorsePicture] = useState<string>('')
  const [horsePictureFile, setHorsePictureFile] = useState<string>('')
  const [isValid, setIsValid] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [inputData, setInputDate] = useState<IInputHorseData>(cloneDeep(initialHorseInputs))

  const event = useAppSelector(selectEventDetails)
  const users = useAppSelector(selectAllUsers)
  const myHorses = useAppSelector(selectHorses)
  const isTeamRegistration = useAppSelector(selectIsTeamRegistration)
  const riderProfileRegistration = useAppSelector(selectRiderProfileRegistration)
  const riderProfileHorse = useAppSelector(selectRiderProfileHorse)
  const profileData = useAppSelector(selectProfileData)

  React.useEffect(() => {
    const checkValidity = () => {
      for (const key in inputData) {
        const field = inputData[key as IInputHorseDataKey]
        if (field.required && !field.value) {
          return false
        }
      }
      return true
    }

    setIsValid(isConfirmed && checkValidity())
  }, [inputData, isConfirmed])

  React.useEffect(() => {
    if (riderProfileHorse) {
      setInputDate(riderProfileHorse.inputData)
      setIsConfirmed(true)
    }
    if (riderProfileHorse?.horsePicture) {
      const imageUrl = URL.createObjectURL(riderProfileHorse.horsePicture)
      setHorsePicture(imageUrl)
    }
  }, [riderProfileHorse])

  useEffect(() => {
    setLoading(false)
  }, [])

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })
    setHorsePictureFile(file)

    try {
      if (!riderProfileRegistration.rider) {
        setImgLoading(true)
        const downloadUrl = await uploadPicture(file)
        if (downloadUrl) {
          setHorsePicture(`${downloadUrl}`)
        }

        toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
      } else {
        const imageUrl = URL.createObjectURL(file)
        setHorsePicture(`${imageUrl}`)
      }
    } catch (error) {
      console.error(error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setImgLoading(false)
    }
  }

  const submitHandler = async () => {
    if (riderProfileRegistration.rider) {
      dispatch(
        riderProfileRegistrationActions.setHorse({
          horsePicture: horsePictureFile || riderProfileRegistration?.horse?.horsePicture,
          inputData,
        })
      )

      props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)

      props.handleModal(
        true,
        isTeamRegistration ? MODAL_CONSTS.REGISTER_TEAM : MODAL_CONSTS.CONFIRM_PROFILE_DETAILS,
        {
          modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
          userTeamMembers: riderProfileRegistration.team,
          users,
          competitor: riderProfileRegistration.competitor,
          authorizeUserHorses: [],
          createCb: () => {},
        }
      )
      return
    }
    let userData: { id: string | null; email: string | null } | null
    setLoading(true)
    if (!props.dataToPassOn.userId && props.dataToPassOn.submitOnlyMemberHandler) {
      userData = await props.dataToPassOn.submitOnlyMemberHandler({
        isSetLoading: false,
        fromHorse: true,
      })
    } else {
      userData = {
        id: props.dataToPassOn.userId,
        email: props.dataToPassOn.userEmail || profileData.userEmail,
      }
    }

    try {
      if (!userData || !userData.id || !userData.email) {
        return null
      }

      const { horseToCreate } = await createHorseData({
        userData: { id: userData.id, email: userData.email },
        horsePicture,
        inputData,
      })

      dispatch(setHorses([...myHorses, horseToCreate]))

      dispatch(setIsMyHorse(true))
      dispatch(setSelectedHorseStatus('fulfilled'))
      if (props.dataToPassOn.createCb) props.dataToPassOn.createCb(horseToCreate)
      if (props.dataToPassOn.isLogin && userData.email)
        await handleOnLoginValid({
          email: userData.email,
          eventId: event.id,
          password: USER_DEF_PASS,
        })
      if (props.dataToPassOn.onlyHorse) {
        toastFunctions.success({ message: MESSAGES_CONST.HORSE_CREATED })
        props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
      } else {
        toastFunctions.success({ message: MESSAGES_CONST.HORSE_TEAM_MEMBER_CREATED })
      }
    } catch (e) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  const onChangeHandler = (key: string, value: string | Date | IOption) => {
    const newInputData = { ...inputData }
    if (newInputData[key as IInputHorseDataKey]) {
      newInputData[key as IInputHorseDataKey].value = value
      newInputData[key as IInputHorseDataKey].hasError = false
      setInputDate(newInputData)
    }
    return {}
  }

  const saveBtn: IMainModalButton = {
    label: riderProfileRegistration.rider ? 'NEXT >' : 'SAVE',
    onClick: submitHandler,
    disabled: !isValid,
    fullWidth: true,
    loading,
  }

  const saveWithoutHorseBtn = {
    label: 'SAVE WITHOUT HORSE',
    onClick: async () => {
      if (loading) return null
      setLoading(true)
      if (props.dataToPassOn.submitOnlyMemberHandler) {
        await props.dataToPassOn.submitOnlyMemberHandler({ isSetLoading: true })
      }
      setLoading(false)
    },
    disabled: false,
    fullWidth: true,
    loading,
  }

  const cancelBtn: IMainModalButton = {
    label: 'CANCEL',
    bgClass: 'bg-SeabiscuitLightThemeColor',
    className: 'outline-none !w-full',
    borderClass: 'border border-transparent',
    textClass: 'text-SeabiscuitLightTextColor',
    onClick: () => props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER),
  }

  const btns: IMainModalButton[] = props.dataToPassOn.onlyHorse
    ? [saveBtn, cancelBtn]
    : [saveBtn, saveWithoutHorseBtn, cancelBtn]

  const backHandler = () => {
    props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
    props.handleModal(true, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_2)
  }

  return (
    <>
      <MainModal
        title="Add new horse"
        description={'Create a profile for your horse'}
        show={props.show}
        goBackBtn={
          props.dataToPassOn.backToCreateProfile && <ModalBackButton onClick={backHandler} />
        }
        type="ADD_NEW_HORSE_MEMBER"
        size="lg"
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={btns}
      >
        <div className={'flex flex-col gap-4 mt-4 '}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              onchangeHandler={updateProfilePic}
              picture={horsePicture}
              isLoading={imgLoading}
              title={'Profile picture'}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key) => (
              <InputNewMemberRow
                key={key}
                value={inputData[key as IInputHorseDataKey].value}
                onChangeHandler={onChangeHandler}
                placeholder={inputData[key as IInputHorseDataKey].placeholder}
                inputName={inputData[key as IInputHorseDataKey].name}
                name={inputData[key as IInputHorseDataKey].label}
                icon={
                  inputHorseIcons[inputData[key as IInputHorseDataKey].name as IInputHorseDataKey]
                }
                isRequired={inputData[key as IInputHorseDataKey].required}
                type={inputData[key as IInputHorseDataKey].type}
                selectData={inputData[key as IInputHorseDataKey].selectData || []}
              />
            ))}
          </div>
          <InputCheckMemberInfo
            onClick={() => setIsConfirmed(!isConfirmed)}
            isChecked={isConfirmed}
            text={
              'By ticking this box I confirm that I have been given permission to create a horse on behalf of this person and register the horse for events.'
            }
          />
        </div>
      </MainModal>
    </>
  )
}
