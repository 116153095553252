import { useEffect, useState } from 'react'

import { collection, doc } from 'firebase/firestore'
import { CONST } from '../const/const'
import { IGuestInterface } from '../models/guests/guest.interface'
import { GuestModel } from '../models/guests/guest.model'
import { getConvertedData } from '../models/interface.helper'
import FirebaseApp from '../services/firebaseApp'
import FirestoreService from '../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectIsLoggedIn, setGuestData } from '../store/user/userSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const GUEST_ID = 'GUEST_ID'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Checks if user is logged in or not,
 * if the user is logged in then this removes the guest id, from local storage and redux,
 * else adds it.
 * @returns guestId
 */
const useGuest = () => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const [guestId, setGuestId] = useState<string | null>(null)

  useEffect(() => {
    let guestId_: string | null

    const getGuestIdFn = () => {
      let guestId_ = localStorage.getItem(GUEST_ID)

      if (!guestId_) guestId_ = doc(collection(FirebaseApp.firestore, COLLECTIONS.GUESTS.NAME)).id

      return guestId_
    }

    guestId_ = getGuestIdFn()
    localStorage.setItem(GUEST_ID, guestId_)
    setGuestId(guestId_)
  }, [isLoggedIn])

  // Functions
  const getGuestFromDb = async (setInRedux?: boolean) => {
    let guest: GuestModel | null = new GuestModel()

    try {
      if (!guestId) {
        console.error('Guest id is empty')
        return null
      }

      const guestSnapshot = await FirestoreService.getItem(COLLECTIONS.GUESTS.NAME, guestId)

      if (guestSnapshot.exists()) {
        guest = GuestModel.fromFirestoreDoc(guestSnapshot)
      } else {
        guest = null
      }
    } catch (error) {
      guest = null
    } finally {
      if (guest && setInRedux) dispatch(setGuestData(getConvertedData(guest.toObject())))
      return guest?.toObject()
    }
  }

  const updateGuest = async (args: { [x in keyof IGuestInterface]?: IGuestInterface[x] }) => {
    let guest: GuestModel | null = new GuestModel()

    try {
      guest = (await getGuestFromDb()) as any

      if (!guest) {
        console.error("Guest doen't exists")
        return null
      }

      guest = new GuestModel({
        ...guest,
        ...args,
      })

      await FirestoreService.updateItem(COLLECTIONS.GUESTS.NAME, guestId, guest.toFirestore())
    } catch (error) {
      guest = null
    } finally {
      if (guest) dispatch(setGuestData(getConvertedData(guest.toObject())))
      return guest
    }
  }

  const createGuest = async (args: IGuestInterface) => {
    let guest: GuestModel | null = new GuestModel(args)

    try {
      if (!guestId) {
        console.error('Guest id is empty')
        return null
      }

      guest = (await getGuestFromDb()) as any

      if (guest) {
        guest = {
          ...(args as any),
          id: guest.id,
        }

        guest = new GuestModel(guest!)

        await FirestoreService.updateItem(COLLECTIONS.GUESTS.NAME, guestId, guest.toFirestore())
      } else {
        guest = args as any
        guest = new GuestModel(guest!)

        await FirestoreService.createItemWithCustomId(
          COLLECTIONS.GUESTS.NAME,
          guestId,
          guest.toFirestore()
        )
      }
    } catch (error) {
      console.error(error)
      guest = null
    } finally {
      if (guest) dispatch(setGuestData(getConvertedData(guest.toFirestore())))
      return guest?.toObject()
    }
  }

  const forceRemoveGuestId = () => {
    localStorage.removeItem(GUEST_ID)
  }

  return { guestId, forceRemoveGuestId, getGuestFromDb, createGuest, updateGuest }
}

export default useGuest
