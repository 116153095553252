import { AccountBalanceOutlined, AddCircleOutline, AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { VideoModal } from '../../modals/VideoModals'

import { useDispatch } from 'react-redux'
import helpers from '../../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { CountryList } from '../../../../../../fakeData/countryList'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import useProfileHook from '../../../../../../hooks/users/competitor/profile/useProfileHook'
import { IHandleModal } from '../../../../../../layout/mainlayout/MainLayout'
import { UserModel } from '../../../../../../models/users/user.model'
import FirebaseApp from '../../../../../../services/firebaseApp'
import { httpService } from '../../../../../../services/httpService'
import { useAppSelector } from '../../../../../../store/hooks'
import {
  selectProfileData,
  selectUserId,
  setProfileDetails,
} from '../../../../../../store/user/userSlice'
import ViewsLoader from '../../../../../loader/ViewsLoader'
import OrganizerStripeTransactions from '../../../../../modals/OrganizerStripeTransactions'
import formFunctions from '../../../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../../../ui/form/form-header/FormHeader'

// Types

type Props = {
  handleModal?: IHandleModal['handleModal']
}

const ProfileRootPageFinanceTab = (props: Props) => {
  // Hooks and vars
  const dispatch = useDispatch()
  const userId = useAppSelector(selectUserId)
  const toasterFunctions = useToasterHelper()
  const { updateUserDetails, updateStripeConnectDetails } = useProfileHook({ dontFetch: true })

  const [connectAccountDetail, setConnectAccountDetail] = useState<any>([])
  const [connectAvailableBalance, setConnectAvailableBalance] = useState<any>([])
  const [connectAllPayouts, setConnectAllPayouts] = useState<any[]>([])
  const [payoutsTotal, setPayoutsTotal] = useState<any>(0)

  const profileData = useAppSelector(selectProfileData)

  const [creating, setCreating] = useState(false)
  const [limit, setLimit] = useState<number>(25)
  const hasMore = useState<boolean>(false)[0]
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false)

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null

    if (userId) {
      unsubscribe = onSnapshot(
        doc(FirebaseApp.firestore, CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME, userId),
        (doc) => {
          if (doc.exists()) {
            let user = UserModel.fromFirestoreDoc(doc)
            dispatch(setProfileDetails(user))
          }
        }
      )
    }

    return () => {
      unsubscribe?.()
    }
  }, [dispatch, userId])

  const removeStripeAccountFromDb = async (showMessage?: boolean) => {
    const response = await updateStripeConnectDetails(null, null)
    if (response?.status) {
      if (showMessage)
        toasterFunctions.success({
          message: MESSAGES_CONST.STRIPE_ACCOUNT_DELETE,
        })
    }
  }

  const handleFetchData = async () => {
    let total = 0

    if (!!profileData?.userStripeAccountId) {
      try {
        setLoading(true)

        const response: any = await httpService({
          method: 'GET',
          url: `fetch_all_organizer_transactions/${profileData?.userStripeAccountId}`,
        })

        const data = response?.data

        setConnectAvailableBalance(data.balance.instant_available)
        setConnectAccountDetail(data.account.external_accounts?.data)

        if (data?.payouts) {
          let payouts = data.payouts.reduce((acc: any[], current: any) => {
            acc.push({
              ...current,
              accountNumber: data.account.external_accounts?.data[0]?.last4,
            })
            return acc
          }, [])

          data?.payouts.forEach((item: any) => {
            total += item.amount
          })

          setConnectAllPayouts(payouts)
          setPayoutsTotal((total / 100).toFixed(2))
        }
      } catch (err: any) {
        let responseFromServer = err?.response?.data
        let message = responseFromServer?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG

        if (responseFromServer?.data?.removeAccount) await removeStripeAccountFromDb()

        toasterFunctions.error({
          message,
        })

        setMessage(message)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    handleFetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, profileData?.userStripeAccountId])

  const fetMoreData = () => {
    if (hasMore) setLimit(limit + 25)
  }

  const onTransferButtonClick = () => {
    props?.handleModal?.(true, MODAL_CONSTS.NEW_TRANSFER, {
      stripeConnectAccountId: profileData?.userStripeAccountId,
      last4: connectAccountDetail[0]?.last4,
      amountAvail: connectAvailableBalance.length ? connectAvailableBalance : [],
    })
  }

  const goToLink = (link: string) => {
    window.open(link, '_blank')
  }

  const updateProfile = async (accountId: string) => {
    try {
      const res = await updateUserDetails({
        userStripeAccountId: accountId,
        userStripeAccountStatus: 'pending',
      })

      if (res.status) {
        toasterFunctions.success({
          message: 'Stripe account created successfully',
        })
      }
    } catch (error) {
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Checks whether all the details that are needed for account creation are valid or not
   * @return True if all details are valid else false
   */
  const validateDetailsForStripe = (): Boolean => {
    let valueToReturn = true
    let userNationality = ''

    if (profileData?.userNationality?.length === 2)
      userNationality =
        CountryList.find((curr) => curr.value === userNationality)?.label ?? userNationality

    if (!userNationality) {
      valueToReturn = false
      toasterFunctions.error({
        message: MESSAGES_CONST.COUNTRY_NOT_FILLED,
      })
    }

    return valueToReturn
  }

  const handleCreateConnectAccount = async (userStripeAccountId: string | null) => {
    let id: string | null = null
    let url: string | null = null

    const creatAccount = !userStripeAccountId
    const userEmail = profileData?.userEmail
    let userNationality = profileData?.userNationality

    const nationality = CountryList.filter((data) => {
      return data.value === userNationality
    })
    userNationality = nationality.length ? nationality[0].ccode : profileData?.userNationality

    if (!validateDetailsForStripe()) return false

    setCreating(true)

    try {
      // Create account
      if (creatAccount) {
        const result: any = await httpService({
          url: 'create_stripe_account',
          method: 'POST',
          data: {
            email: userEmail,
            country: userNationality,
          },
        })

        id = result?.data?.id ?? null
        url = (result as any).accountLink.url

        if (id) updateProfile(id)

        if (url) goToLink(url)
      }
      // Manage account
      else {
        props.handleModal?.(true, MODAL_CONSTS.MANAGE_STRIPE_CONNECT_ACCOUNT, {
          handleModal: props.handleModal,
        })
      }
    } catch (error: any) {
      let message = MESSAGES_CONST.SOMETHING_WENT_WRONG

      if (error?.response?.data?.message) message = error?.response?.data?.message

      toasterFunctions.error({ message })
    } finally {
      setCreating(false)
    }
  }

  const getConnectBankAccountHtml = () => {
    let isCreated = false
    let userStripeAccountId = profileData?.userStripeAccountId ?? null

    if (userStripeAccountId) isCreated = true

    return (
      <>
        <div className="flex flex-col md:flex-row gap-4">
          <button
            type="button"
            onClick={() => {
              handleCreateConnectAccount(userStripeAccountId)
            }}
            className="w-full md:w-[450px] border border-solid border-SeabiscuitGray500ThemeColor text-[#122B46] py-[15px] h-[51px] px-6 rounded-xl text-nr text-left flex items-center justify-between"
          >
            {creating ? (
              <>
                <span className="mr-2 flex items-center justify-between w-full">
                  {isCreated ? 'Redirecting you to stripe...' : 'Connecting to your account...'}
                  <AutorenewRounded fontSize="small" className="animate-spin ml-auto" />
                </span>
              </>
            ) : (
              <>
                {isCreated
                  ? `Connected account ending in: ***${connectAccountDetail[0]?.last4 ?? '-****'}`
                  : 'Connect your bank account to receive funds'}
                {isCreated ? (
                  <img src="/assets/og_icons/Edit.svg" alt="editIcon" />
                ) : (
                  <AddCircleOutline />
                )}
              </>
            )}
          </button>

          {isCreated ? (
            <>
              {profileData?.userStripeAccountStatus ? (
                <span
                  className={clsx(
                    'text-nr h-[51px] font-medium rounded-xl capitalize px-3 flex justify-center items-center',
                    profileData?.userStripeAccountStatus === 'connected'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  )}
                >
                  {profileData?.userStripeAccountStatus}
                </span>
              ) : null}
            </>
          ) : null}
        </div>

        {profileData?.userStripeAccountStatus === 'pending' &&
        !!profileData?.userConnectAccountStatusMessage ? (
          <label className="text-nr h-fit mt-[10px] text-[#122B46] w-full">
            {profileData?.userConnectAccountStatusMessage}
          </label>
        ) : null}
      </>
    )
  }

  return (
    <>
      <FormHeader
        title={'Finances'}
        description={'Transfer funds to your connected bank account'}
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="items-center w-[125px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              onClick={() => setOpenVideoModal(true)}
            >
              More info
            </button>
            <button
              type="button"
              disabled={loading}
              onClick={onTransferButtonClick}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn min-w-[150px]"
            >
              New Transfer
            </button>
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <ViewsLoader size="xl" color="red" />{' '}
          </div>
        ) : (
          <>
            {/* Account details */}
            <div className="AccountEmail flex flex-wrap mb-8">
              <label className="text-nr md:mr-8 w-full md:w-1/4 h-fit mt-[10px] text-[#122B46]">
                {' '}
                <AccountBalanceOutlined className="mr-2" />
                Bank account
              </label>
              <div className="flex flex-col gap-2 mt-3 md:mt-0">{getConnectBankAccountHtml()}</div>
            </div>

            <hr className="my-4"></hr>

            {/* Fund Details */}
            <div className="AccountEmail mt-8 mb-3 flex flex-wrap items-center">
              <label className="text-nr md:mr-8 mb-3 md:mb-0  w-full md:w-1/4 flex items-center text-[#122B46]">
                <img className="mr-2" src="/assets/og_icons/MoneyBag5.svg" alt="MoneyBagIcon" />
                Funds available for transfer
              </label>
              <div className="flex flex-col w-[450px]">
                <span
                  className={clsx(
                    'rounded-xl py-[15px] h-[51px] px-6',
                    connectAvailableBalance && connectAvailableBalance[0]?.amount
                      ? 'text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5  border border-SeabiscuitMainThemeColor/5 font-bold '
                      : 'bg-[white] border border-[#D3DAEE] text-[#122B46]'
                  )}
                >
                  $
                  {`${connectAvailableBalance.length ? (connectAvailableBalance[0].amount / 100).toFixed(2) : 0}`}
                </span>
              </div>
            </div>

            {/* Historic transfer Details */}
            {}
            <div className="AccountEmail mb-3 flex flex-wrap items-center">
              <label className="text-nr  md:mr-8  mb-3 md:mb-0  w-full md:w-1/4 flex items-center text-[#122B46]">
                <img className="mr-2" src="/assets/og_icons/MoneyBag5.svg" alt="MoneyBagIcon" />
                Historic transfer total
              </label>
              <div className="flex flex-col w-full md:w-[initial]">
                <span className="border border-[#D3DAEE] w-full md:w-[450px] text-[#122B46] py-[15px] h-[51px] px-6 rounded-xl">
                  ${payoutsTotal}
                </span>
              </div>
            </div>

            {/* Previous number of transfers Details */}
            <div className="AccountEmail mb-3 flex flex-wrap items-center">
              <label className="text-nr md:mr-8  mb-3 md:mb-0  w-full md:w-1/4 flex items-center text-[#122B46]">
                <img className="mr-2" src="/assets/og_icons/MoneyBag5.svg" alt="MoneyBagIcon" />
                Previous number of transfers
              </label>
              <div className="flex flex-col w-full md:w-[initial]">
                <span className="border border-[#D3DAEE] w-full md:w-[450px] text-[#122B46] py-[15px] h-[51px] px-6 rounded-xl">
                  {connectAllPayouts.length}
                </span>
              </div>
            </div>

            <hr className="my-8"></hr>

            {/* Transactions History */}

            <div className="">
              <h4 className="text-[#122B46] font-bold my-4">Transaction History</h4>
            </div>

            <OrganizerStripeTransactions
              handleModal={props.handleModal}
              hasMore={hasMore}
              fetMoreData={fetMoreData}
              loading={loading}
              connectAllPayouts={connectAllPayouts}
              message={message}
              stripeConnectAccountId={profileData?.userStripeAccountId ?? ''}
              connectAccountDetail={connectAccountDetail}
            />
          </>
        )}
      </div>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          url={'https://google.com'}
          onClose={() => setOpenVideoModal(false)}
        />
      )}
    </>
  )
}

export default ProfileRootPageFinanceTab
