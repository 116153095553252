import { IconCheck } from './IconCheck'
import { FC } from 'react'
import clsx from 'clsx'

interface IconCheckAccentProps {
  isActive: boolean
  className?: string
}

export const IconCheckAccent: FC<IconCheckAccentProps> = ({
  isActive,
  className = 'border-SeabiscuitGreenThemeColor bg-SeabiscuitGreenThemeColor',
}) => {
  if (!isActive) {
    return <div className={'min-w-5 w-5 h-5 rounded-full border-black/20 border'} />
  }
  return (
    <div
      className={clsx(
        'text-white min-w-5  w-5 h-5 flex items-center justify-center rounded-full',
        className
      )}
    >
      <IconCheck />
    </div>
  )
}
