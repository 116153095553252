import { RUN_MODE_CONST } from './run-mode-const'
import { STORAGE_CONST } from './storage-const'
import { ROUTES_CONST } from './routes-const'
import { DATA_FIRESTORE_CONST } from './data/firestore-const'
import { UI_SHOWS_CONST } from './ui/ui-shows-const'
import { USE_MODE_CONST } from './use-mode'
import { UI_EVENTS_CONST } from './ui/ui-events-const'
import { UI_HOME_CONST } from './ui/ui-home-const'
import { COMPONENTS_CONST } from './components/components-const'
import { DATA_STORAGE_CONST } from './data/storage-const'
import { UI_REGISTER_CONST } from './ui/ui-register.const'
import { UI_EXHIBITOR_CONST } from './ui/ui-exhibitor-const'
import { UI_USER_CONST } from './ui/ui-user-const'
import { SPECTATOR_TICKETS, UI_SPECTATOR_CONST } from './ui/ui-spectator-tickets'
import UI_STAFF_PROFILE_CONST from './ui/ui-staff-profile-const'
import { UI_ROLES_CONST } from './ui/ui-roles-const'
import MAP_CONSTS from './map-const'

export const CONST = {
  RUN_MODE: RUN_MODE_CONST,
  USE_MODE: USE_MODE_CONST,
  STORAGE: STORAGE_CONST,
  ROUTES: ROUTES_CONST,
  DATA: {
    STORAGE: DATA_STORAGE_CONST,
    FIRESTORE: DATA_FIRESTORE_CONST,
  },
  UI: {
    HOME: UI_HOME_CONST,
    USER: UI_USER_CONST,
    SHOWS: UI_SHOWS_CONST,
    EVENTS: UI_EVENTS_CONST,
    REGISTER: UI_REGISTER_CONST,
    EXHIBITOR: UI_EXHIBITOR_CONST,
    STAFF: UI_STAFF_PROFILE_CONST,
    SPECTATOR: SPECTATOR_TICKETS,
    TEAM_MEMBERS_ROLES: {
      RIDER: 'Rider',
      COACH: 'Coach',
      GROOM: 'Groom',
      OWNER: 'Owner',
      TRAINER: 'Trainer',
      TEAM_MEMBER: 'Team member',
      GUARDIAN: 'Guardian',
    },
    ROLES: UI_ROLES_CONST,
    SPECTATOR_TABS: UI_SPECTATOR_CONST,
    MAP: MAP_CONSTS,
  },
  FIREBASE: {
    INVALID_FIREBASE_ID: '00000000000000000000',
  },
  COMPONENTS: COMPONENTS_CONST,
}

export const USER_DEF_PASS = 'defaultPassword123!'
