import { cloneDeep } from 'lodash'

import FirestoreService from '../services/firestoreService'

import { calculateAge, getConvertedData } from '../models/interface.helper'
import { httpService } from '../services/httpService'

import { IDocument } from '../models/event-registered-users/event-registered-users.interface'
import { IRiderTeamMemberInterface } from '../models/rider-team-member/riderTeamMember.interface'
import { IManageInfo } from '../pages/competitor/event-registration-tabs/EventRegistrationTabs'
import { UserModel } from '../models/users/user.model'
import { IEventDetails } from '../models/event-details/event-details.interface'
import { IUserInterface } from '../models/users/user.interface'
import { RiderTeamMemberModel } from '../models/rider-team-member/riderTeamMember.model'

import { signedStatuses } from '../const/data/v01/rider-team-members/firestore-v01-rider-team-member-const'

import { CONST } from '../const/const'
import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'
import { getAllClauses } from './clauses'

interface EligibleToSignProps {
  paperworkDocuments: IDocument[]
  ridersTeamMembers: IRiderTeamMemberInterface[]
  userId: string
  manageInfo?: IManageInfo
  isManage?: boolean
}

export const eligibleToSign = ({
  paperworkDocuments,
  ridersTeamMembers,
  isManage,
  manageInfo,
  userId,
}: EligibleToSignProps) => {
  let notSignedOwner = false
  let isMinorRider = false
  let isMinorGuardian = false
  const ridersTeamMembersManage: IRiderTeamMemberInterface[] = []
  let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

  if (isManage) {
    manageInfo?.paperwork.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0)
        ridersTeamMembersManage.push(...paperwork.ridersTeamMembers)
    })

    ridersTeamMembers_ = ridersTeamMembersManage
  }

  // check if an owner to sign if added as rider
  for (const paperworkDocument of paperworkDocuments) {
    for (const currRiderTeamMember of ridersTeamMembers_) {
      if (currRiderTeamMember.teamMemberId === userId) {
        const isSigned = currRiderTeamMember.mailLog.find(
          (currMailLogItem) =>
            currMailLogItem.paperworkKey === paperworkDocument.key &&
            currMailLogItem.status === signedStatuses.SIGNED
        )
        let age = calculateAge(currRiderTeamMember.teamMemberDob)

        if (!isSigned?.paperworkKey && currRiderTeamMember.teamMemberDob && age > 18) {
          notSignedOwner = true
          break
        }
      }
    }
    if (notSignedOwner) break
  }

  // check if added guardian to minor rider
  for (const currRiderTeamMember of ridersTeamMembers_) {
    let age = calculateAge(currRiderTeamMember.teamMemberDob)
    let isRider = currRiderTeamMember.teamMemberRole === CONST.UI.TEAM_MEMBERS_ROLES.RIDER

    let guardian = ridersTeamMembers_.find(
      (teamMember) =>
        teamMember.teamMemberRole === CONST.UI.TEAM_MEMBERS_ROLES.GUARDIAN &&
        teamMember.riderId === currRiderTeamMember.riderId
    )
    let guardianAge = calculateAge(guardian?.teamMemberDob)
    if (currRiderTeamMember.teamMemberDob && age < 18 && isRider && !guardian) {
      isMinorRider = true
      break
    } else if (guardian?.teamMemberDob && guardianAge < 18) {
      isMinorGuardian = true
      break
    }
  }

  return { notSignedOwner, isMinorRider, isMinorGuardian }
}

export const generatePaperworkDocument = async ({
  paperwork,
  currRidersTeamMember,
  registrationByDay,
  userData,
  eventId,
  EventDetails,
  dateOfSignature,
}: {
  paperwork: IDocument
  currRidersTeamMember: IRiderTeamMemberInterface
  registrationByDay: IRegistrationByDayInterface
  userData: IUserInterface
  eventId: string
  EventDetails: IEventDetails
  dateOfSignature: Date
}) => {
  const doc = await FirestoreService.getItem(
    CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
    EventDetails.owner ?? ''
  )

  if (!doc) throw new Error('Error getting owner user doc')

  const organizerDetails = UserModel.fromFirestoreDoc(doc).toObject()

  const allClauses = await getAllClauses(EventDetails.owner ?? '')

  let age = calculateAge(currRidersTeamMember?.teamMemberDob)

  let riderAge = calculateAge(currRidersTeamMember?.riderDob)
  let isGuardian = currRidersTeamMember?.teamMemberRole === CONST.UI.TEAM_MEMBERS_ROLES.GUARDIAN

  const teamMemberRole = [currRidersTeamMember.teamMemberRole ?? '']
  const index = 0

  const payData = {
    eventId,
    userData,
    EventDetails,
    organizerDetails,
    registrationByDay: registrationByDay,
    ridersTeamMember: currRidersTeamMember,
    paperwork,
    isMature: isGuardian ? riderAge > 18 : age > 18,
    isSigned: true,
    teamMemberRole,
    dateOfSignature,
    clauses: paperwork.document_type === 'general' ? allClauses : [],
    isStaff: false,
    index,
  }

  await httpService({
    url: 'generate_document',
    method: 'POST',
    data: payData,
  })
}

export const createMailLog = async ({
  ridersTeamMembers,
  teamMemberId,
  paperworkDocument,
}: {
  ridersTeamMembers: IRiderTeamMemberInterface[]
  teamMemberId: string
  paperworkDocument: IDocument
}) => {
  let mailLog: IRiderTeamMemberInterface['mailLog'] = []
  let mailLogItemIndex = -1

  return ridersTeamMembers
    .filter((member) => member.teamMemberId === teamMemberId)
    .map((currRiderTeamMember) => {
      mailLog = cloneDeep(currRiderTeamMember.mailLog)

      mailLogItemIndex = mailLog.findIndex((curMailLog) => {
        return curMailLog.paperworkKey === paperworkDocument.key
      })

      if (mailLogItemIndex === -1) {
        mailLog.push({
          paperworkKey: paperworkDocument.key,
          status: signedStatuses.SIGNED,
        })
      } else {
        mailLog[mailLogItemIndex] = {
          ...mailLog[mailLogItemIndex],
          paperworkKey: paperworkDocument.key,
          status: signedStatuses.SIGNED,
        }
      }

      return getConvertedData({
        ...new RiderTeamMemberModel({
          ...currRiderTeamMember,
          mailLog,
        }).toObject(),
      })
    })
}
