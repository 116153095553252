import { FC, useEffect, useState } from 'react'

import DataNotAvailable from '../../../common/alerts/data-not-available/DataNotAvailable'
import MoreButton from './global/MoreButton'

import { getEventPolicyImage } from './helpers/getEventPolicy'

import {
  IEventPolicies,
  IExtraField,
} from '../../../../models/event-policies/event-policies.interface'

import { MODAL_CONSTS } from '../../../../const/modal-const'

const EventDetailViewComponentPolicies: FC<{
  handleModal?: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  policiesData?: IEventPolicies
}> = ({ handleModal, policiesData }) => {
  const [policies, setPolicies] = useState<IExtraField[]>([])

  useEffect(() => {
    const policies_ = policiesData?.policies?.filter((policy) => policy.policy) ?? []
    setPolicies(policies_)
  }, [policiesData])

  if (policies.length <= 0) {
    return (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
        text="Nothing to show"
      />
    )
  }

  return (
    <>
      <div className="flex flex-wrap gap-6 md:gap-10 text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor mb-6 md:mb-10">
        {policies
          .filter((policy) => policy.policy)
          .slice(0, 7)
          .map((policy, key) => {
            const icon = getEventPolicyImage(policy.ruleType || '')

            return (
              <div key={key} className="w-full flex flex-wrap gap-x-4 gap-y-1 justify-between">
                <div className="flex gap-2 items-center w-full md:w-[22%]">
                  <img
                    src={`assets/og_icons/${icon}`}
                    alt="event rules"
                    className="w-5 h-5 2xl:w-6 2xl:h-6"
                  />
                  <span className="font-normal">{policy.name}</span>
                </div>
                <div className="w-full md:w-[74%] text-opacity-[.85]">
                  {policy.policy.length > 320
                    ? `${policy.policy?.slice(0, 320)}${policy.policy.length > 320 ? '...' : null}`
                    : policy.policy}
                </div>
              </div>
            )
          })}
      </div>
      {policies.length > 0 && (
        <MoreButton
          className="transition-all hover:opacity-70"
          onClick={() => handleModal?.(true, MODAL_CONSTS.POLICIES, { policies })}
        />
      )}
    </>
  )
}

export default EventDetailViewComponentPolicies
