import React, { FC } from 'react'
import clsx from 'clsx'

// Common
import MainModal from './common/MainModal'

// Const
import { MODAL_CONSTS } from '../../const/modal-const'

// Types
import { ECompetitorTypes } from '../../types/competitor_types'

// FakeData
import { CountryList } from '../../fakeData/countryList'
import { disciplineData } from '../../fakeData/disciplineList'

// Hooks
import { useCreateRiderProfileStep2 } from './hooks/useCreateRiderProfileStep2'

// Components
import { InputUploadMemberPictureRow } from '../inputs/InputUploadMemberPictureRow'
import { IconError } from '../icons/IconError'
import { IconUser } from '../icons/IconUser'
import { IInputField, InputRow } from './components/InputRow'
import { IconUserName } from '../icons/IconUserName'
import { IconEmailImg } from '../icons/IconEmailImg'
import { IconPassword } from '../icons/IconPassword'
import { IconDate } from '../icons/IconDate'
import { NationalityIconImg } from '../icons/NationalityIconImg'
import { IconHorseImg } from '../icons/IconHorseImg'
import { IconPhone } from '../icons/IconPhone'
import { ModalBackButton } from './components/ModalBackButton'

interface CreateRiderProfileModalProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    isTeam: boolean
  }
}

export const CreateRiderProfileStepTwoModal: FC<CreateRiderProfileModalProps> = ({
  handleModal,
  dataToPassOn,
}) => {
  const {
    register,
    control,
    picture,
    userIcon,
    errors,
    nextHandler,
    handleSubmit,
    cancelHandler,
    openModalPoliciesHandler,
    loading,
    handlerImageChanges,
    isRegistered,
    profileType,
  } = useCreateRiderProfileStep2({ handleModal, isTeam: dataToPassOn.isTeam })

  const linkClasses = 'cursor-pointer transition-all underline hover:text-SeabiscuitMainThemeColor'

  const inputFields: Array<IInputField> = [
    { label: 'Full Name', required: true, icon: <IconUser />, name: 'fullName', type: 'text' },
    {
      label: 'User Name',
      required: true,
      isUnique: true,
      icon: <IconUserName />,
      name: 'userName',
      type: 'text',
    },
    {
      label: 'Email Address',
      required: true,
      isUnique: true,
      icon: <IconEmailImg />,
      name: 'email',
      type: 'email',
    },
    {
      label: 'Phone Number',
      required: true,
      icon: <IconPhone />,
      name: 'userPhoneNumber',
      type: 'phone',
    },
    {
      label: 'Password',
      required: true,
      isUnique: true,
      icon: <IconPassword />,
      name: 'password',
      type: 'password',
    },
    {
      label: 'Confirm Password',
      required: true,
      isUnique: true,
      icon: <IconPassword />,
      name: 'confirmPassword',
      type: 'password',
    },
    {
      label: 'Date of birth',
      required: true,
      icon: <IconDate />,
      name: 'userDOB',
      type: 'date',
      control: control,
    },
    {
      label: 'Nationality',
      icon: <NationalityIconImg />,
      name: 'userNationality',
      type: 'select',
      selectData: CountryList,
    },
    {
      label: 'Discipline',
      icon: <IconHorseImg />,
      name: 'userDiscipline',
      type: 'select',
      selectData: disciplineData,
    },
  ]

  const backHandler = () => {
    handleModal(false, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_2)
    handleModal(true, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_1, {
      isTeam: dataToPassOn.isTeam,
    })
  }

  return (
    <MainModal
      title={
        profileType === ECompetitorTypes.spectator ? 'Create your profile' : 'Create rider profile'
      }
      description={'Create a profile to streamline all future registrations'}
      show={true}
      goBackBtn={<ModalBackButton onClick={backHandler} />}
      type="CREATE_RIDER_PROFILE_STEP_2"
      size={'lg'}
      buttons={[
        {
          label: 'NEXT >',
          className: '!w-full',
          loading,
          onClick: (e) => handleSubmit(nextHandler)(e),
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          className: 'outline-none',
          loading,
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor !w-full',
          onClick: cancelHandler,
        },
      ]}
    >
      <div className={'-ml-2 -mr-2 flex flex-col gap-3 text-SeabiscuitDark200ThemeColor'}>
        <InputUploadMemberPictureRow
          title={'Profile picture'}
          isLoading={false}
          picture={picture}
          onchangeHandler={handlerImageChanges}
          userIcon={userIcon}
        />

        <form className={'flex flex-col w-full gap-4'}>
          {inputFields
            .filter((field) => !(isRegistered && field.isUnique))
            .map((field) => (
              <InputRow
                key={field.name}
                field={field}
                errors={errors[field.name]}
                register={register(field.name)}
                control={control}
                placeholder={`Enter ${field.label.toLowerCase()}...`}
              />
            ))}
          <button type={'submit'} className={'!hidden'}>
            Submit
          </button>
        </form>

        <div className={'flex mt-4 gap-4 bg-[#F6F7FB] py-[12px] px-[20px] rounded-[12px]'}>
          <div className={'mt-1'}>
            <IconError />
          </div>

          <p className={'flex-1 text-[14px] pr-8'}>
            By creating a profile you are agreeing to our{' '}
            <span className={clsx(linkClasses)} onClick={() => openModalPoliciesHandler(false)}>
              Terms and Conditions
            </span>{' '}
            and{' '}
            <span className={clsx(linkClasses)} onClick={() => openModalPoliciesHandler(true)}>
              Privacy Policy
            </span>
          </p>
        </div>
      </div>
    </MainModal>
  )
}
