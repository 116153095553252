import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CONST } from '../../../../const/const'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'
import { EventReviewPublishModel } from '../../../../models/event-review-publish/event-review-publish.model'
import { selectEventLoading, selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import EventFormContainerComponent from '../container/EventFormContainerComponent'

//components
import { useLocation } from 'react-router'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { IEventReviewPublish } from '../../../../models/event-review-publish/event-review-publish.interface'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import ViewsLoader from '../../../loader/ViewsLoader'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import EventDetailsReviewPublishForm from './Components/EventDetailsReviewPublishForm'
import EventFeesReviewPublishForm from './Components/EventFeesReviewPublishForm'
import EventPaperworkReviewPublishForm from './Components/EventPaperworkReviewPublishForm'
import EventPoliciesReviewPublishForm from './Components/EventPoliciesReviewPublishForm'
import EventScheduleReviewPublishForm from './Components/EventScheduleReviewPublishForm'
import EventStaffReviewPublishForm from './Components/EventStaffReviewPublishForm'
import EventTicketsReviewPublishForm from './Components/EventTicketsReviewPublishForm'
import EventPaymentsReviewPublishForm from './Components/EventPaymentsReviewPublishForm'
import EventRegistrationReviewPublishForm from './Components/EventRegistrationReviewPublishForm'
import DataNotAvailable from '../../../common/alerts/data-not-available/DataNotAvailable'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'

const eventReviewPublishFormDefaultValues = new EventReviewPublishModel().toObject()

const eventReviewPublishFormValidationSchema = EventReviewPublishModel.validationSchema()

interface EventReviewPublishFormComponentProps {
  onSetEventTab: (tab: string) => void
  onInvalidAndExit: any
  data: any
  onValid: any
  onValidAnExit: any
  onInvalid: any
  onViewEventButtonPressed?: any
  loading: boolean
  onDeleteEventButtonPressed?: any
  publishedEvent?: IEventReviewPublish | null
}

const EventReviewPublishFormComponent: React.FC<EventReviewPublishFormComponentProps> = (props) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  let isMounted = true
  const { getAllData } = useGetEventData()
  const location = useLocation()
  const [openedMenu, setOpenedMenu] = useState<string[]>([])
  const loading = useAppSelector(selectEventLoading)
  const selectEvent = useAppSelector(selectedEvent)
  const router = useIonRouter()
  const history = useHistory()

  const eventId =
    selectEvent.Event?.id ?? location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

  const {
    EventDetails,
    EventPaymentSettings,
    EventStaff,
    EventSchedule,
    EventFees,
    EventPolicies,
    EventPaperwork,
    EventTickets,
  } = selectEvent

  const { handleSubmit, trigger, reset } = useForm({
    defaultValues: { ...eventReviewPublishFormDefaultValues },
    resolver: yupResolver(eventReviewPublishFormValidationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (props.data) reset(props.data)
    else reset(eventReviewPublishFormDefaultValues)
    trigger().then()
    if (isMounted) setOpenedMenu([])
    return () => {
      isMounted = false
    }
  }, [props.data, reset, trigger])

  useEffect(() => {
    if (eventId) {
      getAllData(eventId, [
        'v01_events',
        'v01_event_details',
        'v01_event_scheduling',
        'v01_event_staff',
        'v01_event_fees',
        'v01_event_paperwork',
        'v01_event_policies',
        'v01_event_ticketing',
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const handleToggle = (value?: any) => {
    const exist = openedMenu.includes(value)

    if (isMounted) {
      if (exist) {
        setOpenedMenu(() => {
          return openedMenu.filter((current: any) => {
            return current !== value
          })
        })
      } else {
        setOpenedMenu((prevState: any) => [...prevState, value])
      }
    }
  }

  const onValid = () => {
    props.onValid(selectEvent)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      {loading ? (
        <>
          <div className="flex justify-center items-center min-h-[300px]">
            <ViewsLoader
              className="flex items-center w-full justify-center min-h-[30vh] mt-3"
              size="lg"
              color="red"
            />
          </div>
        </>
      ) : !loading ? (
        <div className="mr-4 mb-4 w-full mx-auto">
          <EventFormHeaderComponent
            title="Review & Publish"
            description="Confirm event details and publish event"
          >
            <EventFormFooterCommonComponent
              publishedEvent={props.publishedEvent}
              publishButton={true}
              loading={props.loading}
              onSaveAndExit={() => {
                router.push(`${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/draft`)
                history.push(`${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/draft`)
              }}
              onNext={(e: any) => {
                handleSubmit(onValid, props.onInvalid)(e)
              }}
            />
          </EventFormHeaderComponent>

          {/* Event Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('1') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('1') ? 'font-semibold' : ''
              }`}
            >
              Event Details
              <div className="flex items-center">
                <span
                  onClick={() =>
                    props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE)
                  }
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                  onClick={() => handleToggle('1')}
                >
                  {openedMenu.includes('1') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`faqs-content ${openedMenu.includes('1') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                <EventDetailsReviewPublishForm data={EventDetails} />
              </div>
            </div>
          </div>

          {/* Payment Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('2') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('2') ? 'font-semibold' : ''
              }`}
            >
              Payments
              <div className="flex items-center">
                <span
                  onClick={() =>
                    props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE)
                  }
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('2')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('2') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`faqs-content ${openedMenu.includes('2') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                {EventPaymentSettings ? (
                  <EventPaymentsReviewPublishForm
                    data={String(EventPaymentSettings?.paymentOption)}
                  />
                ) : (
                  <DataNotAvailable
                    mode="text"
                    containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
                    text={MESSAGES_CONST.TICKETS_DATA_NOT_AVAILABLE}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Registration Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('3') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('3') ? 'font-semibold' : ''
              }`}
            >
              Entries
              <div className="flex items-center">
                <span
                  onClick={() =>
                    props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE)
                  }
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('3')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('3') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`faqs-content ${openedMenu.includes('3') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                <EventRegistrationReviewPublishForm loading={loading} data={EventFees} />
              </div>
            </div>
          </div>

          {/* Fees Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('4') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('4') ? 'font-semibold' : ''
              }`}
            >
              Fees
              <div className="flex items-center">
                <span
                  onClick={() => props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE)}
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('4')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('4') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`faqs-content ${openedMenu.includes('4') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                <EventFeesReviewPublishForm loading={loading} data={EventFees} />
              </div>
            </div>
          </div>

          {/* Tickets Details */}

          {EventTickets && EventTickets?.tickets && EventTickets?.tickets.length ? (
            <div
              className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
                openedMenu.includes('5') ? 'activeAccordion' : ''
              }`}
            >
              <div
                className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                  openedMenu.includes('5') ? 'font-semibold' : ''
                }`}
              >
                Tickets
                <div className="flex items-center">
                  <span
                    onClick={() =>
                      props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE)
                    }
                    className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                  >
                    Edit
                  </span>
                  <div
                    onClick={() => handleToggle('5')}
                    className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                  >
                    {openedMenu.includes('5') ? (
                      <img
                        src="/assets/og_icons/CloseArrow.svg"
                        className="cursor-pointer"
                        alt="close-arrow"
                      />
                    ) : (
                      <img
                        src="/assets/og_icons/OpenArrow.svg"
                        className="cursor-pointer"
                        alt="open-arrow"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={`faqs-content ${openedMenu.includes('5') ? 'activeTab' : ''}`}>
                <div className="faqs-content-inside mt-4">
                  {EventTickets ? (
                    <EventTicketsReviewPublishForm loading={loading} data={EventTickets} />
                  ) : (
                    <DataNotAvailable
                      mode="text"
                      containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
                      text={MESSAGES_CONST.TICKETS_DATA_NOT_AVAILABLE}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {/* Staff Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('6') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('6') ? 'font-semibold' : ''
              }`}
            >
              Staff
              <div className="flex items-center">
                <span
                  onClick={() => props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE)}
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('6')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('6') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`faqs-content ${openedMenu.includes('6') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                <EventStaffReviewPublishForm data={EventStaff} />
              </div>
            </div>
          </div>

          {/* Schedule Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('7') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('7') ? 'font-semibold' : ''
              }`}
            >
              Schedule
              <div className="flex items-center">
                <span
                  onClick={() =>
                    props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE)
                  }
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('7')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('7') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`faqs-content ${openedMenu.includes('7') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                <EventScheduleReviewPublishForm data={EventSchedule} />
              </div>
            </div>
          </div>

          {/* Policies Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('8') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('8') ? 'font-semibold' : ''
              }`}
            >
              Policies
              <div className="flex items-center">
                <span
                  onClick={() =>
                    props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE)
                  }
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('8')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('8') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`faqs-content ${openedMenu.includes('8') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                <EventPoliciesReviewPublishForm
                  loading={loading}
                  policies={EventPolicies?.policies}
                />
              </div>
            </div>
          </div>

          {/* Paperwork Details */}

          <div
            className={`mt-2 border-[#E6EAF2] border px-4 py-2 rounded-lg ${
              openedMenu.includes('9') ? 'activeAccordion' : ''
            }`}
          >
            <div
              className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${
                openedMenu.includes('9') ? 'font-semibold' : ''
              }`}
            >
              Paperwork
              <div className="flex items-center">
                <span
                  onClick={() =>
                    props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE)
                  }
                  className="font-normal bg-opacity-[0.08] px-[14px] py-[5px] opacity-50 hover:opacity-100 rounded-2xl ml-6 cursor-pointer"
                >
                  Edit
                </span>
                <div
                  onClick={() => handleToggle('9')}
                  className="status text-SeabiscuitGreenThemeColor rounded-full p px-4"
                >
                  {openedMenu.includes('9') ? (
                    <img
                      src="/assets/og_icons/CloseArrow.svg"
                      className="cursor-pointer"
                      alt="close-arrow"
                    />
                  ) : (
                    <img
                      src="/assets/og_icons/OpenArrow.svg"
                      className="cursor-pointer"
                      alt="open-arrow"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`faqs-content ${openedMenu.includes('9') ? 'activeTab' : ''}`}>
              <div className="faqs-content-inside mt-4">
                {EventPaperwork ? (
                  <EventPaperworkReviewPublishForm loading={loading} data={EventPaperwork} />
                ) : (
                  <DataNotAvailable
                    mode="text"
                    containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
                    text={MESSAGES_CONST.PAPERWORK_DATA_NOT_AVAILABLE}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </EventFormContainerComponent>
  )
}

export default EventReviewPublishFormComponent
