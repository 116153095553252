import TopNavigationCommonComponent from '../components/common/navigation/top/TopNavigationCommonComponent'
import PegasusLogo from '../icons/logo/Logo.svg'
import { useAppSelector } from '../store/hooks'
import { selectProfileData } from '../store/user/userSlice'
import { IonContent, IonPage } from '@ionic/react'
import { getUserFullName } from '../helpers/helpers'

type ICompetitorLayoutProps = {
  children: any
  handleModal?: any
  isLoggedIn?: boolean
  isPublicPage?: boolean
}

const CompetitorLayout = (props: ICompetitorLayoutProps) => {
  const profileData = useAppSelector(selectProfileData)

  return (
    <IonPage>
      <TopNavigationCommonComponent
        logo={PegasusLogo}
        isLoggedIn={props.isLoggedIn}
        displayName={profileData ? getUserFullName(profileData) : ''}
        handleModal={props.handleModal}
      />
      <IonContent
        fullscreen
        style={{ '--ion-background-color': '#F6F7FB', '--offset-top': '56px' }}
      >
        <div className="pt-14 pb-4 min-h-full flex flex-col">{props.children}</div>
      </IonContent>
    </IonPage>
  )
}

export default CompetitorLayout
