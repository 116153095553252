export const IconPhone = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="none" viewBox="0 0 16 20">
      <path
        fill="#122B46"
        d="M5.52.007c-.328-.024-.666.01-.996.108L3.34.465A3.77 3.77 0 0 0 .76 3.102c-.74 2.809.107 6.134 2.31 9.876 2.201 3.737 4.71 6.112 7.542 6.888a3.84 3.84 0 0 0 3.617-.882l.9-.835a2.726 2.726 0 0 0 .344-3.6l-1.3-1.767a2.8 2.8 0 0 0-3.057-1.01l-2.036.608c-.085.025-.29-.09-.467-.245h-.001c-.317-.276-.756-.833-1.226-1.63-.504-.856-.718-1.415-.805-1.746-.086-.33-.065-.37-.052-.482a.24.24 0 0 1 .078-.148v-.001L8.126 6.74a2.72 2.72 0 0 0 .642-3.134L7.852 1.61v-.001A2.78 2.78 0 0 0 5.52.007m-.108 1.497c.46.034.882.31 1.076.731l.916 1.996c.221.483.11 1.036-.29 1.402L5.596 7.02a1.74 1.74 0 0 0-.558 1.088v.001a3 3 0 0 0 .092 1.03c.134.51.41 1.187.963 2.127h.001c.517.877 1.005 1.543 1.534 2.002h.001c.172.149.892.839 1.871.553l.003-.001 2.044-.612c.53-.16 1.098.03 1.417.463l1.3 1.767a1.206 1.206 0 0 1-.155 1.611l-.9.836a2.36 2.36 0 0 1-2.202.534c-2.29-.627-4.555-2.656-6.643-6.202C2.273 8.667 1.618 5.73 2.21 3.485a2.29 2.29 0 0 1 1.556-1.581l1.182-.35q.233-.069.464-.05"
      />
    </svg>
  )
}
