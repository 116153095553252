import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useHistory } from 'react-router'
import { cloneDeep } from 'lodash'

import { IOption } from '../../../../components/inputs/InputNewMemberRow'

import { authHelpers } from '../../../../helpers/helpers'
import useToasterHelper from '../../../../helpers/ToasterHelper'

// Redux
import {
  setProfileDetails,
  setIsLoggedIn,
  setUserId,
  storeUserId,
} from '../../../../store/user/userSlice'
import { storeUseMode } from '../../../../store/system/systemThunk'
import { useAppDispatch } from '../../../../store/hooks'

// Services
import FirestoreService from '../../../../services/firestoreService'

import { UserModel } from '../../../../models/users/user.model'
import { IInputUserData, IInputUserKey } from '../../../../components/home/types/inputData'

// Constants
import FIREBASE_CONST from '../../../../const/firebase-const'
import { CONST } from '../../../../const/const'
import { httpService } from '../../../../services/httpService'

const useUserAccountHook = () => {
  const auth = getAuth()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const toastFunctions = useToasterHelper()

  const handleFailLoginAttempts = async (email: string) => {
    const LOGIN_ATTEMPT = 'loginAttempts'
    let loginAttemptsDetailsArray: string | Record<any, any>

    if (LOGIN_ATTEMPT in localStorage) {
      loginAttemptsDetailsArray = localStorage.getItem(LOGIN_ATTEMPT) ?? '{}'
      loginAttemptsDetailsArray = JSON.parse(loginAttemptsDetailsArray)

      if (Array.isArray(loginAttemptsDetailsArray) && loginAttemptsDetailsArray.length >= 1) {
        const res = await httpService({
          url: 'send_failed_login_attempts',
          method: 'POST',
          data: {
            emailId: loginAttemptsDetailsArray[0].email,
          },
        })

        if (res) toastFunctions.info({ message: 'Maximum limits mail send' })
      }
    } else {
      loginAttemptsDetailsArray = [{ email, attempts: 1 }]
      localStorage.setItem(LOGIN_ATTEMPT, JSON.stringify(loginAttemptsDetailsArray))
    }
  }

  const handleOnLoginValid = async ({
    email,
    eventId,
    password,
    onSuccess,
  }: {
    email: string
    eventId?: string
    password: string
    onSuccess?: () => void
  }) => {
    try {
      const emailOrError = await authHelpers.getEmail(email)

      const user = await signInWithEmailAndPassword(auth, emailOrError as any, password)
      if (user) {
        const user_data_doc: any = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        dispatch(setProfileDetails(userData))

        if (userData.userType === CONST.USE_MODE.COMPETITOR) {
          dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
          if (eventId) {
            history.replace(`${CONST.ROUTES.REGISTER_EVENT.URL}/${eventId}/${userData.id}`, {
              direction: 'none',
            })
          } else {
            history.replace('/home', { direction: 'none' })
          }
        } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
          dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
          history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, {
            direction: 'none',
          })
        }

        dispatch(setIsLoggedIn(true))
        dispatch(storeUserId(user.user.uid))
        dispatch(setUserId(user.user.uid))
      }

      if (onSuccess) onSuccess()
    } catch (error: any) {
      console.error(error)
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND) {
        return toastFunctions.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      }
      if (error.code === FIREBASE_CONST.WRONG_PASSWORD) {
        await handleFailLoginAttempts(email)
        return toastFunctions.error({ message: 'Credentials are incorrect' })
      }
      if (error.code === FIREBASE_CONST.TOO_MANY_REQUESTS) {
        await handleFailLoginAttempts(email)
        return toastFunctions.error({ message: 'Firebase login Maximum attempts reached' })
      }
    }
  }

  const checkValidity = (inputData: IInputUserData) => {
    for (const key in inputData) {
      const field = inputData[key as IInputUserKey]
      if (field.required && !field.value) {
        return false
      }
    }
    return true
  }

  const onChangeHandler = (data: IInputUserData, key: string, value: string | Date | IOption) => {
    const newInputData = cloneDeep(data)
    if (newInputData[key as IInputUserKey]) {
      newInputData[key as IInputUserKey].value = value
      newInputData[key as IInputUserKey].hasError = false
    }

    return newInputData
  }

  return { handleOnLoginValid, checkValidity, onChangeHandler }
}

export default useUserAccountHook
