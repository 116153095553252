import { IHorseData } from '../../../models/horse/horse.interface'
import { IOption } from '../../inputs/InputNewMemberRow'
import { v4 as uuidv4 } from 'uuid'
import { HorseModel } from '../../../models/horse/horse.model'
import FirestoreService from '../../../services/firestoreService'
import { getConvertedData } from '../../../models/interface.helper'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { IInputHorseData } from '../../home/types/inputData'
import { CONST } from '../../../const/const'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'

interface ICreateHorseData {
  userData: { id: string; email: string }
  horsePicture: string
  inputData: IInputHorseData
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const createHorseData = async ({ userData, horsePicture, inputData }: ICreateHorseData) => {
  let mapping: IUserHorseMappingInterface | null = null

  const horseData: IHorseData = {
    horseOwnerId: userData.id,
    horseProfilePicture: horsePicture,
    horseName: `${inputData.name.value}` || '',
    horseBreed: `${(inputData.breed.value as IOption).value}` || '',
    horseGender: `${(inputData.gender.value as IOption).value}` || '',
    horseColor: `${(inputData.coloring.value as IOption).value}` || '',
    horseId: uuidv4(),
  }

  let horseToCreate = new HorseModel(horseData)

  const ref = await FirestoreService.createItem(
    COLLECTIONS.HORSES.NAME,
    new HorseModel(horseToCreate).toFirestore()
  )

  horseToCreate.id = ref.id
  horseToCreate = getConvertedData(horseToCreate)

  let docToSave = new UserHorseMappingModel({
    userId: userData.id,
    horseId: (horseToCreate ? horseToCreate.id : null) ?? null,
    isMyHorse: true,
    horseOwnerId: userData.id,
    horseName: horseToCreate.horseName,
    horseZone: horseToCreate.horseZone,
    horseSelectedForCompeletion: true,
    horseNameNGram: horseToCreate?.horseNameNGram,
    horseDiscipline: horseToCreate.horseDiscipline,
    horseProfilePicture: horseToCreate.horseProfilePicture,
  })

  mapping = docToSave.cloneDeep().toFirestore()

  mapping!.id = (
    await FirestoreService.createItem(COLLECTIONS.USER_HORSE_MAPPING.NAME, docToSave.toFirestore())
  )?.id
  return {
    horseToCreate,
  }
}
