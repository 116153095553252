import * as yup from 'yup'

// Types
import { IHorseData } from './horse.interface'
import { cloneDeep } from 'lodash'

// Models
import { ModelBaseModel } from '../model-base/model-base.model'

export class HorseModel extends ModelBaseModel<IHorseData> {
  public id?: IHorseData['id']
  public horseId?: IHorseData['horseId']
  public horseOwnerId: IHorseData['horseOwnerId']
  public horseName: IHorseData['horseName']
  public horseNameNGram: IHorseData['horseNameNGram']
  public horseBreed: IHorseData['horseBreed']
  public horseBreeder: IHorseData['horseBreeder']
  public horseProfilePicture: IHorseData['horseProfilePicture']
  public horseHeight: IHorseData['horseHeight']
  public horseDob: IHorseData['horseDob']
  public horseAgeVerified?: IHorseData['horseAgeVerified']
  public horseGender: IHorseData['horseGender']
  public horseDiscipline: IHorseData['horseDiscipline']
  public horseColor: IHorseData['horseColor']
  public horseZone: IHorseData['horseZone']
  public horseMarking: IHorseData['horseMarking']
  public horseCountry: IHorseData['horseCountry']
  public horseMicrochipNumber?: IHorseData['horseMicrochipNumber']
  public horseMicrochipExpiration?: IHorseData['horseMicrochipExpiration']

  public horsePassportNumber?: IHorseData['horsePassportNumber']
  public horsePassportExpiration?: IHorseData['horsePassportExpiration']

  public horseDNACaseNumber?: IHorseData['horseDNACaseNumber']
  public horseDNACaseExpiration?: IHorseData['horseDNACaseExpiration']

  public horseTeamMembers: IHorseData['horseTeamMembers']
  public horseTeamMembers_: IHorseData['horseTeamMembers_']
  public horseStatus: IHorseData['horseStatus']
  public horseFeiStatus: IHorseData['horseFeiStatus']

  public horseFeiNumber: IHorseData['horseFeiNumber']
  public horseFeiExpiration: IHorseData['horseFeiExpiration']

  public horseUsefNumber: IHorseData['horseUsefNumber']
  public horseUsefExpiration: IHorseData['horseUsefExpiration']

  public horseUsdfNumber?: IHorseData['horseUsdfNumber']
  public horseUsdfExpiration?: IHorseData['horseUsdfExpiration']
  public horseUseaNumber?: IHorseData['horseUseaNumber']
  public horseUseaExpiration?: IHorseData['horseUseaExpiration']
  public horseUshjaNumber?: IHorseData['horseUshjaNumber']
  public horseUshjaExpiration?: IHorseData['horseUshjaExpiration']
  public horseAhhsNumber?: IHorseData['horseAhhsNumber']
  public horseAhhsExpiration?: IHorseData['horseAhhsExpiration']
  public horseAmhaNumber?: IHorseData['horseAmhaNumber']
  public horseAmhaExpiration?: IHorseData['horseAmhaExpiration']
  public horseArhpaNumber?: IHorseData['horseArhpaNumber']
  public horseArhpaExpiration?: IHorseData['horseArhpaExpiration']
  public horseAshaNumber?: IHorseData['horseAshaNumber']
  public horseAshaExpiration?: IHorseData['horseAshaExpiration']
  public horseUphaNumber?: IHorseData['horseUphaNumber']
  public horseUphaExpiration?: IHorseData['horseUphaExpiration']
  public horseWdaaNumber?: IHorseData['horseWdaaNumber']
  public horseWdaaExpiration?: IHorseData['horseWdaaExpiration']

  public horseOtherNumber: IHorseData['horseOtherNumber']
  public horsesOther?: IHorseData['horsesOther']

  public created: IHorseData['created']
  public modified: IHorseData['modified']

  public constructor(obj?: IHorseData) {
    super()
    this.id = obj?.id || ''
    this.horseId = obj?.horseId || ''
    this.horseOwnerId = obj?.horseOwnerId || null
    this.horseStatus = obj?.horseStatus || ''
    this.horseProfilePicture = obj?.horseProfilePicture || ''

    this.horseName = obj?.horseName || ''
    this.horseTeamMembers_ = obj?.horseTeamMembers_ ?? []
    this.horseBreed = obj?.horseBreed || ''
    this.horseBreeder = obj?.horseBreeder || ''
    this.horseHeight = obj?.horseHeight || ''
    this.horseDob = obj?.horseDob
      ? this.utcTimestamp({
          key: 'horseDob',
          value: obj?.horseDob,
        })
      : ''
    this.horseAgeVerified = obj?.horseAgeVerified || ''
    this.horseGender = obj?.horseGender || ''
    this.horseDiscipline = obj?.horseDiscipline || ''
    this.horseZone = obj?.horseZone || ''
    this.horseColor = obj?.horseColor || ''
    this.horseMarking = obj?.horseMarking ?? null
    this.horseCountry = obj?.horseCountry || ''
    this.horseTeamMembers = obj?.horseTeamMembers || []
    this.horseNameNGram = obj?.horseNameNGram ?? []

    this.horseMicrochipNumber = obj?.horseMicrochipNumber || ''
    this.horseMicrochipExpiration = obj?.horseMicrochipExpiration
      ? this.utcTimestamp({
          key: 'horseMicrochipExpiration',
          value: obj?.horseMicrochipExpiration,
        })
      : ''
    this.horsePassportNumber = obj?.horsePassportNumber || ''
    this.horsePassportExpiration = obj?.horsePassportExpiration
      ? this.utcTimestamp({
          key: 'horsePassportExpiration',
          value: obj?.horsePassportExpiration,
        })
      : ''
    this.horseDNACaseNumber = obj?.horseDNACaseNumber || ''
    this.horseDNACaseExpiration = obj?.horseDNACaseExpiration
      ? this.utcTimestamp({
          key: 'horseDNACaseExpiration',
          value: obj?.horseDNACaseExpiration,
        })
      : ''

    this.horseFeiNumber = obj?.horseFeiNumber || ''
    this.horseFeiExpiration = obj?.horseFeiExpiration
      ? this.utcTimestamp({
          key: 'horseFeiExpiration',
          value: obj?.horseFeiExpiration,
        })
      : ''

    this.horseFeiStatus = obj?.horseFeiStatus ? obj?.horseFeiStatus : ''

    this.horseUsefNumber = obj?.horseUsefNumber || ''
    this.horseUsefExpiration = obj?.horseUsefExpiration
      ? this.utcTimestamp({
          key: 'horseUsefExpiration',
          value: obj?.horseUsefExpiration,
        })
      : ''
    this.horseUsdfNumber = obj?.horseUsdfNumber || ''
    this.horseUsdfExpiration = obj?.horseUsdfExpiration
      ? this.utcTimestamp({
          key: 'horseUsdfExpiration',
          value: obj?.horseUsdfExpiration,
        })
      : ''
    this.horseUseaNumber = obj?.horseUseaNumber || ''
    this.horseUseaExpiration = obj?.horseUseaExpiration
      ? this.utcTimestamp({
          key: 'horseUseaExpiration',
          value: obj?.horseUseaExpiration,
        })
      : ''
    this.horseUshjaNumber = obj?.horseUshjaNumber || ''
    this.horseUshjaExpiration = obj?.horseUshjaExpiration
      ? this.utcTimestamp({
          key: 'horseUshjaExpiration',
          value: obj?.horseUshjaExpiration,
        })
      : ''
    this.horseAhhsNumber = obj?.horseAhhsNumber || ''
    this.horseAhhsExpiration = obj?.horseAhhsExpiration
      ? this.utcTimestamp({
          key: 'horseAhhsExpiration',
          value: obj?.horseAhhsExpiration,
        })
      : ''
    this.horseAmhaNumber = obj?.horseAmhaNumber || ''
    this.horseAmhaExpiration = obj?.horseAmhaExpiration
      ? this.utcTimestamp({
          key: 'horseAmhaExpiration',
          value: obj?.horseAmhaExpiration,
        })
      : ''
    this.horseArhpaNumber = obj?.horseArhpaNumber || ''
    this.horseArhpaExpiration = obj?.horseArhpaExpiration
      ? this.utcTimestamp({
          key: 'horseArhpaExpiration',
          value: obj?.horseArhpaExpiration,
        })
      : ''
    this.horseAshaNumber = obj?.horseAshaNumber || ''
    this.horseAshaExpiration = obj?.horseAshaExpiration
      ? this.utcTimestamp({
          key: 'horseAshaExpiration',
          value: obj?.horseAshaExpiration,
        })
      : ''
    this.horseUphaNumber = obj?.horseUphaNumber || ''
    this.horseUphaExpiration = obj?.horseUphaExpiration
      ? this.utcTimestamp({
          key: 'horseUphaExpiration',
          value: obj?.horseUphaExpiration,
        })
      : ''
    this.horseWdaaNumber = obj?.horseWdaaNumber || ''
    this.horseWdaaExpiration = obj?.horseWdaaExpiration
      ? this.utcTimestamp({
          key: 'horseWdaaExpiration',
          value: obj?.horseWdaaExpiration,
        })
      : ''

    this.horseOtherNumber = obj?.horseOtherNumber || ''
    this.horsesOther = obj?.horsesOther || []

    this.created = obj?.created
      ? this.utcTimestamp({
          key: 'created',
          value: obj?.created,
        })
      : new Date().toString()

    this.modified = obj?.modified
      ? this.utcTimestamp({
          key: 'modified',
          value: obj?.modified,
        })
      : new Date().toString()

    this._calculateEventNameNGrams()
  }

  private _calculateEventNameNGrams() {
    let reg = new RegExp(/\s+/, 'g')
    let horsenospace = this.horseName?.replace(reg, '')
    const words = horsenospace?.split(' ')
    const lowercase_words = words?.map((word) => word.toLowerCase())

    let ngram_words = new Set<string>()

    lowercase_words?.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    if (this.horseNameNGram && this.horseNameNGram.length > 0) {
      this.horseNameNGram = []
    }

    ngram_words.forEach((value) => {
      if (this.horseNameNGram) this.horseNameNGram.push(value)
    })
  }

  static fromFirestoreDoc(doc: any) {
    return new HorseModel({
      ...doc.data(),
      id: doc?.id,
    })
  }

  static paperworkValidationSchema() {
    return yup.object().shape({
      measurementCard: yup.array().of(yup.object().shape({})),
    })
  }
}
