import { useEffect, useState } from 'react'

// Third party
import { AutorenewRounded } from '@mui/icons-material'
import moment from 'moment/moment'
import { Clear } from '@mui/icons-material'

// Redux
import { selectBillRecipient, setSelectedRecipient } from '../../../store/bills/billsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Types
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { RecipientModel } from '../../../models/recipients/recipients'

// Services
import FirestoreService from '../../../services/firestoreService'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST } from '../../../const/const'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IPaymentType, PAYMENT_STATUS, PAYMENT_TYPE } from '../../../types/payment'
import { getTotalPrices } from '../../../helpers/payment'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { ISelectedEvent } from '../../../store/events/eventsSlice'

type OrganizerMarkPaidModalProps = {
  show: boolean
  handleModal: any
  dataToPassOn: {
    bill: IRecipientInterface
    registrationsByDay: IRegistrationByDayInterface[]
    registrationsFees: IRegistrationFeesInterface[]
    registrationsTickets: IRegistrationTicketInterface[]
    selectedEvent: ISelectedEvent
  }
}

const OrganizerMarkPaidModal = ({
  handleModal,
  show,
  dataToPassOn,
}: OrganizerMarkPaidModalProps) => {
  const dispatch = useAppDispatch()

  const selectedRecipient = useAppSelector(selectBillRecipient)

  const [loading, setLoading] = useState<boolean>(false)
  const [paymentType, setPaymentType] = useState<IPaymentType>(PAYMENT_TYPE.CHECK)
  const [amountNotPaid, setAmountNotPaid] = useState(0)

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.ORGANIZER_MARK_PAID)
  }

  const onSavePayment = async () => {
    setLoading(true)
    try {
      // Build promises for updating registration entries
      const updateRegistrations = dataToPassOn.registrationsByDay
        .filter((reg) => reg.paymentStatus !== PAYMENT_STATUS.PAID)
        .map((reg) =>
          FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
            reg.id,
            { paymentStatus: PAYMENT_STATUS.PAID, paymentType }
          )
        )

      // Build promises for updating fees
      const updateFees = dataToPassOn.registrationsFees
        .filter((fee) => fee.paymentStatus !== PAYMENT_STATUS.PAID)
        .map((fee) =>
          FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_FEES.NAME,
            fee.id,
            { paymentStatus: PAYMENT_STATUS.PAID, paymentType }
          )
        )

      // Build promises for updating tickets
      const updateTickets = dataToPassOn.registrationsTickets
        .filter((ticket) => ticket.paymentStatus !== PAYMENT_STATUS.PAID)
        .map((ticket) =>
          FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_TICKET.NAME,
            ticket.id,
            { paymentStatus: PAYMENT_STATUS.PAID, paymentType }
          )
        )

      // Execute all update operations in parallel
      await Promise.all([...updateRegistrations, ...updateFees, ...updateTickets])

      // Calculate total paid amount
      const { paidTotal } = getTotalPrices({
        registrationsEntries: dataToPassOn.registrationsByDay,
        registrationsFees: dataToPassOn.registrationsFees,
        registrationsTickets: dataToPassOn.registrationsTickets,
        isChargeSalesTax: dataToPassOn.selectedEvent?.EventPaymentSettings?.chargeSalesTax ?? false,
      })

      // Update recipient with the paid amount
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
        selectedRecipient?.id ?? '',
        { paymentStatus: PAYMENT_STATUS.PAID, amountPaid: paidTotal }
      )

      // Retrieve the updated recipient document
      const recipientSnapshots = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.NAME,
        selectedRecipient?.id ?? ''
      )
      const recipient = RecipientModel.fromFirestoreDoc(recipientSnapshots).toObject()

      if (recipient) dispatch(setSelectedRecipient(recipient))
      handleModal(false, MODAL_CONSTS.ORGANIZER_MARK_PAID)
      handleModal(true, MODAL_CONSTS.BILL_BRIEFE, { bill: recipient })
    } catch (error) {
      console.error('Error in onSavePayment:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const { unpaidTotal } = getTotalPrices({
      registrationsEntries: dataToPassOn.registrationsByDay,
      registrationsFees: dataToPassOn.registrationsFees,
      registrationsTickets: dataToPassOn.registrationsTickets,
      isChargeSalesTax: dataToPassOn.selectedEvent?.EventPaymentSettings?.chargeSalesTax ?? false,
    })
    setAmountNotPaid(unpaidTotal)
  }, [
    dataToPassOn.registrationsByDay,
    dataToPassOn.registrationsFees,
    dataToPassOn.registrationsTickets,
    dataToPassOn.selectedEvent,
  ])

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8 max-h-[90vh]">
          <span
            onClick={() => {
              if (loading) return
              closeModal()
            }}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-bold pb-2"
              id="exampleModalScrollableLabel"
            >
              Mark Paid?
            </h5>
          </div>
          <div className="min-h-1/2 overflow-y-auto">
            <p className="text-sm text-SeabiscuitDark200ThemeColor mb-4">
              This will mark this bill as paid and will no longer be able to paid electronically
              through Pegasus.
            </p>
            <p className="text-sm text-SeabiscuitDark200ThemeColor mb-4">
              Select the payment method used for your records.
            </p>
            <div className="mt-3 w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">Payment details:</p>
              <div className="flex w-full items-center mt-2 h-6">
                <img
                  className="relative w-6 h-6 object-contain -left-[2px]"
                  src={'assets/img/dark/Dollarcoin.svg'}
                  alt="icons"
                />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-1">${amountNotPaid}</p>
              </div>
              <div className="flex w-full items-center mt-2 h-6">
                <img
                  className="w-5 h-5 object-contain"
                  src={'assets/img/dark/wallet.svg'}
                  alt="icons"
                />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                  Payment date: {moment(Date.now()).format('DD MMM, YYYY')}
                </p>
              </div>
              <div
                className="flex w-full justify-between items-center mt-2 h-6 cursor-pointer hover:opacity-50 transition-all"
                onClick={() => setPaymentType(PAYMENT_TYPE.CHECK)}
              >
                <img
                  className="w-5 h-5 object-contain"
                  src={'assets/img/dark/check.svg'}
                  alt="icons"
                />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2 mr-auto">
                  Paid by Check
                </p>
                <span className="pl-2">
                  {paymentType === 'check' ? (
                    <img
                      className="relative -right-[1px]"
                      alt="icons"
                      src={'assets/og_icons/Ok-1.svg'}
                    />
                  ) : (
                    <img alt="icons" src={'assets/og_icons/circle.svg'} />
                  )}
                </span>
              </div>
              <div
                className="flex w-full justify-between items-center mt-2 h-6 cursor-pointer hover:opacity-50 transition-all"
                onClick={() => setPaymentType(PAYMENT_TYPE.CASH)}
              >
                <img className="w-5" src={'assets/img/dark/cash-2.svg'} alt="icons" />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2 mr-auto">
                  Paid by Cash
                </p>
                <span className="pl-2">
                  {paymentType === 'cash' ? (
                    <img
                      className="relative -right-[1px]"
                      alt="icons"
                      src={'assets/og_icons/Ok-1.svg'}
                    />
                  ) : (
                    <img alt="icons" src={'assets/og_icons/circle.svg'} />
                  )}
                </span>
              </div>
            </div>
          </div>

          <button
            disabled={loading}
            type="submit"
            className="w-full h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor focus:ring-SeabiscuitMainThemeColor"
            onClick={onSavePayment}
          >
            {loading ? (
              <AutorenewRounded fontSize="small" className="animate-spin" />
            ) : (
              'SAVE PAYMENT'
            )}
          </button>

          <button
            disabled={loading}
            onClick={() => {
              if (loading) closeModal()
            }}
            type="button"
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:bg-[#0b15261a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrganizerMarkPaidModal
