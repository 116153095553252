import React, { cloneElement, ReactElement, useEffect, useRef, useState } from 'react'

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useForm } from 'react-hook-form'
import { AutorenewRounded } from '@mui/icons-material'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserStripeId, setStripeId } from '../../../store/user/userSlice'
import { RootState } from '../../../store/store'
import { setRegisteredUser } from '../../../store/registeredUser/registeredUserSlice'

import { getStripeId } from '../../../commonHelpers/helpers'

import useToasterHelper from '../../../helpers/ToasterHelper'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'

import { IUserCards, IUserInterfaceExtended } from '../../../models/users/user.interface'

import { httpService } from '../../../services/httpService'
import FirestoreService from '../../../services/firestoreService'
import MainModal from '../../modals/common/MainModal'

import { getUserFullName } from '../../../helpers/helpers'

import { UserModel } from '../../../models/users/user.model'
import { ICompetitorCard, Props } from '../../../types/competitor_types'

import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST } from '../../../const/const'

interface IAddCardProps extends Props {
  dataToPassOn?: IDataToPassOn
  footer?: ReactElement
  className?: string
  ref?: React.Ref<HTMLButtonElement>
  backToDetails?: () => void
}

interface IDataToPassOn {
  re_open_modal?: boolean
  modal_name?: string
  add_card?: boolean
  type?: string
  organizerId?: string
}

interface IUserCardWithIsNew extends IUserCards {
  customerId?: string
  cardHolderName?: string
  cardHolderEmail?: string
}

interface ICheckoutForm extends Props {
  dataToPassOn: IDataToPassOn
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export const AddCard = React.forwardRef<HTMLButtonElement, IAddCardProps>((props, ref) => {
  return (
    <Elements stripe={stripePromise}>{<AddUserCard {...props} ref={ref ? ref : null} />}</Elements>
  )
})

export const AddUserCard = React.forwardRef<HTMLButtonElement, IAddCardProps>((props, ref) => {
  // Hooks and vars
  const stripe = useStripe()
  const dispatch = useAppDispatch()
  const elements = useElements()
  const { updateUserDetails } = useProfileHook({ dontFetch: true })

  const [loading, setLoading] = useState(false)
  const toasterFunctions = useToasterHelper()
  const [manualError, setManualError] = useState<string>('')

  const storeState = useAppSelector((state: RootState) => state)
  const stripeId = useAppSelector(selectUserStripeId)
  const registeredUser = useAppSelector((state) => state.registeredUser.data)

  const { userId, guestId, profileDetails } = storeState.user
  const [organiserProfile, setOrganiserProfile] = useState<IUserInterfaceExtended | null>(null)

  const { handleSubmit, register } = useForm<ICompetitorCard>({
    mode: 'onChange',
  })
  const form = useRef<HTMLFormElement>(null)

  // Components
  const Footer = props?.footer ? cloneElement(props?.footer, { loading }) : null

  // Is fired on submitting
  const onSubmit = async (formData: any) => {
    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setLoading(false)
      return
    }

    try {
      let customerId: string | null = null
      if (organiserProfile?.userStripeAccountId) {
        const stripeCustomer = await httpService({
          url: `create_stripe_customer`,
          method: 'POST',
          data: {
            userEmail: profileDetails.userEmail ?? formData.cardHolderEmail,
            userName: getUserFullName(profileDetails) ?? formData.cardHolderName,
            userStripeAccountId: organiserProfile.userStripeAccountId,
          },
        })
        customerId = stripeCustomer?.customer?.id
        dispatch(setStripeId(stripeCustomer?.customer?.id))
      }

      // Use elements.getElement to get a reference to the CardElement.
      const cardElement = elements.getElement(CardElement) as StripeCardElement

      // Use Stripe.js to handle the payment.
      const { error, paymentMethod } = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (error) {
        setManualError(error?.message ?? '')

        setTimeout(() => {
          setManualError('')
        }, 3000)

        setLoading(false)
        return
      }
      if (paymentMethod) {
        let competitorCardData: IUserCardWithIsNew = {
          pmId: paymentMethod?.id ?? '',
          cardNumber: paymentMethod.card?.last4 ?? '',
          expiryMonth: paymentMethod.card?.exp_month?.toString() ?? '',
          expiryYear: paymentMethod.card?.exp_year?.toString() ?? '',
          cardType: paymentMethod.card?.brand ?? '',
          zipCode: paymentMethod.billing_details.address?.postal_code,
          customerId: customerId!,
          default: true,
          isNew: true,
        }

        if (props.dataToPassOn?.type === 'ticketPurchase') {
          competitorCardData = {
            ...competitorCardData,
            cardHolderEmail: formData.cardHolderEmail,
            cardHolderName: formData.cardHolderName,
            customerId: customerId!,
          }

          await addCardToProfile(competitorCardData)
        } else if (props?.dataToPassOn?.add_card) {
          let res = await addCardToProfile(competitorCardData)

          setLoading(false)

          // True means card is created
          if (res) {
            props?.handleModal?.()
            props?.backToDetails?.()
          }
        } else {
          if (typeof props?.handleModal === 'function') props?.handleModal?.()
        }

        setLoading(false)
      }

      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      console.log(error, 'error')
      toasterFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  // Add card to profile in db
  const addCardToProfile = async (card: IUserCardWithIsNew) => {
    let newUserStripeId: null | string = card?.customerId ?? null

    if (!newUserStripeId && !stripeId) {
      const StripeId = await getStripeId(userId || '', profileDetails)
      newUserStripeId = StripeId
      if (StripeId) {
        dispatch(setStripeId(StripeId))
      }
    }

    try {
      if (userId) {
        const res = await updateUserDetails({
          userStripeId: newUserStripeId ?? stripeId,
        })
        if (!res.status) throw new Error(res?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG)
      }

      await httpService({
        url: 'create_card',
        method: 'POST',
        data: {
          pmId: card.pmId,
          stripeId: newUserStripeId ?? stripeId,
          userStripeAccountId: organiserProfile?.userStripeAccountId,
        },
      })

      if (registeredUser)
        dispatch(
          setRegisteredUser({
            ...registeredUser,
          })
        )

      dispatch(setStripeId(newUserStripeId ?? stripeId))

      toasterFunctions.success({ message: MESSAGES_CONST.CARDADDED })
      props?.handleModal?.(false, MODAL_CONSTS.CARD)
      return true
    } catch (err) {
      console.log(err, 'error')
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      return false
    }
  }
  const getOrganiser = async () => {
    const organiserSnapshots = await FirestoreService.getItem(
      COLLECTIONS.USERS.NAME,
      props?.dataToPassOn?.organizerId ?? ''
    )
    const owner = UserModel.fromFirestoreDoc(organiserSnapshots).toObject()
    setOrganiserProfile(owner)
  }

  useEffect(() => {
    if (props?.dataToPassOn?.organizerId) getOrganiser().then()
  }, [props?.dataToPassOn?.organizerId])

  return (
    <form
      className={`w-full flex flex-col justify-evenly h-full ${props.className ?? ''}`}
      ref={form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="relative py-6 px-4 cardView border border-SeabiscuitGray500ThemeColor rounded-[12px] ml-[-8px]">
        {!userId && !guestId && (
          <>
            <div className="flex flex-col mb-3">
              <input
                type="text"
                required
                className="pt-0 px-0 pb-4 w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 text-black cursor-pointer border-b !border-b-[#D3DAEE]"
                placeholder="Name on Card"
                {...register(`cardHolderName`)}
              />
            </div>
            <div className="flex flex-col mb-6">
              <input
                type="email"
                required
                className="pb-4 px-0 w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 text-black cursor-pointer border-b !border-b-[#D3DAEE]"
                placeholder="Card holder email"
                {...register(`cardHolderEmail`)}
              />
            </div>
          </>
        )}
        <CardElement />
        {manualError && (
          <div className="text-SeabiscuitMainThemeColor text-[12px] mt-2 absolute bottom-0 left-0 translate-y-full">
            {manualError}
          </div>
        )}{' '}
      </div>
      <button className="hidden" type="submit" disabled={!stripe} ref={ref}></button>
      {Footer}
    </form>
  )
})

const CheckOutFormFooter = (props: any) => {
  return (
    <div className="ActionButton mt-7">
      <button
        disabled={props?.loading}
        type="submit"
        className="w-full mb-2 h-12 m-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor"
      >
        {props?.loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : 'SAVE'}
      </button>

      <button
        type="button"
        onClick={props?.handleModal}
        className="w-full h-12 m-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor"
      >
        CANCEL
      </button>
    </div>
  )
}

const CheckoutForm = (props: ICheckoutForm) => {
  return (
    <MainModal
      title="Add credit card"
      show={!!props.show}
      type="CARD"
      className="mt-4"
      setHeightAsPerContent={true}
      size="sm"
    >
      <AddCard {...props} footer={<CheckOutFormFooter {...props} />} />
    </MainModal>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

const UserProfileAccountTabAddCard = (props: ICheckoutForm) => {
  const closeModal = () => {
    if (props?.dataToPassOn?.re_open_modal && props?.dataToPassOn?.modal_name)
      props?.handleModal?.(true, props?.dataToPassOn?.modal_name, props?.dataToPassOn)

    props?.handleModal?.(false, MODAL_CONSTS.CARD, props?.dataToPassOn)
  }

  return (
    <CheckoutForm show={props.show} handleModal={closeModal} dataToPassOn={props.dataToPassOn} />
  )
}

export default UserProfileAccountTabAddCard
