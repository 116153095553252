import { IconBack } from '../../icons/IconBack'
import React, { FC } from 'react'

interface ModalBackButtonProps {
  onClick: () => void
}
export const ModalBackButton: FC<ModalBackButtonProps> = (props) => {
  const { onClick } = props

  return (
    <button
      className={'cursor-pointer text-SeabiscuitDark200ThemeColor hover:opacity-50 transition-all'}
      onClick={onClick}
    >
      <IconBack />
    </button>
  )
}
