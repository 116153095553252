import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IRegistrationTicketInterface } from './registrationTicket.interface'

// Constants
const REGISTRATION_TICKET_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_TICKET

export class RegistrationTicketModel extends ModelBaseModel<IRegistrationTicketInterface> {
  // Properties
  public v: IRegistrationTicketInterface['v']
  public id: IRegistrationTicketInterface['id']
  public userId: IRegistrationTicketInterface['userId']
  public eventId: IRegistrationTicketInterface['eventId']
  public refundId: IRegistrationTicketInterface['refundId']
  public eventName: IRegistrationTicketInterface['eventName']
  public refundDocId: IRegistrationTicketInterface['refundDocId']
  public isScratched: IRegistrationTicketInterface['isScratched']
  public recipientId: IRegistrationTicketInterface['recipientId']
  public userName: IRegistrationTicketInterface['userName']
  public ticketTitle: IRegistrationTicketInterface['ticketTitle']
  public ticketPrice: IRegistrationTicketInterface['ticketPrice']
  public refundStatus: IRegistrationTicketInterface['refundStatus']
  public ticketItemId: IRegistrationTicketInterface['ticketItemId']
  public paymentStatus: IRegistrationTicketInterface['paymentStatus']
  public paymentType: IRegistrationTicketInterface['paymentType']
  public amountScratched: IRegistrationTicketInterface['amountScratched']
  public scratchAmountAdded: IRegistrationTicketInterface['scratchAmountAdded']
  public refundAmountAdded: IRegistrationTicketInterface['refundAmountAdded']
  public invoiceId: IRegistrationTicketInterface['invoiceId']
  public invoiceUrl: IRegistrationTicketInterface['invoiceUrl']
  public amountRefunded: IRegistrationTicketInterface['amountRefunded']
  public registrationDocId: IRegistrationTicketInterface['registrationDocId']
  public recipientNameNGram: IRegistrationTicketInterface['recipientNameNGram']
  public selectedUnitsCount: IRegistrationTicketInterface['selectedUnitsCount']
  public sharedTicketsCount: IRegistrationTicketInterface['sharedTicketsCount']
  public remainingTicketsCount: IRegistrationTicketInterface['remainingTicketsCount']
  public recipientProfilePicture: IRegistrationTicketInterface['recipientProfilePicture']

  public created: IRegistrationTicketInterface['created']
  public modified: IRegistrationTicketInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IRegistrationTicketInterface`
   */
  public constructor(obj?: IRegistrationTicketInterface) {
    super()
    this.v = obj?.v ?? REGISTRATION_TICKET_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? REGISTRATION_TICKET_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.eventName =
      obj?.eventName ?? REGISTRATION_TICKET_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.userName = obj?.userName ?? REGISTRATION_TICKET_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.refundId = obj?.refundId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.REFUND_ID.VALUES.DEFAULT
    this.refundDocId =
      obj?.refundDocId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.REFUND_DOC_ID.VALUES.DEFAULT
    this.ticketPrice =
      obj?.ticketPrice ?? REGISTRATION_TICKET_COLLECTION.FIELDS.FEES_PRICE.VALUES.DEFAULT
    this.ticketTitle =
      obj?.ticketTitle ?? REGISTRATION_TICKET_COLLECTION.FIELDS.FEES_TITLE.VALUES.DEFAULT
    this.ticketItemId =
      obj?.ticketItemId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.FEES_ITEM_ID.VALUES.DEFAULT
    this.isScratched =
      obj?.isScratched ?? REGISTRATION_TICKET_COLLECTION.FIELDS.IS_SCRATCHED.VALUES.DEFAULT
    this.recipientId =
      obj?.recipientId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.RECIPIENT_ID.VALUES.DEFAULT
    this.refundStatus =
      obj?.refundStatus ?? REGISTRATION_TICKET_COLLECTION.FIELDS.REFUND_STATUS.VALUES.DEFAULT
    this.paymentStatus =
      obj?.paymentStatus ?? REGISTRATION_TICKET_COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.paymentType =
      obj?.paymentType ?? REGISTRATION_TICKET_COLLECTION.FIELDS.PAYMENT_TYPE.VALUES.DEFAULT
    this.amountRefunded =
      obj?.amountRefunded ?? REGISTRATION_TICKET_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.amountScratched =
      obj?.amountScratched ?? REGISTRATION_TICKET_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.scratchAmountAdded =
      obj?.scratchAmountAdded ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.SCRATCH_AMOUNT_ADDED.VALUES.DEFAULT
    this.invoiceId =
      obj?.invoiceId ?? REGISTRATION_TICKET_COLLECTION.FIELDS.INVOICE_ID.VALUES.DEFAULT
    this.invoiceUrl =
      obj?.invoiceUrl ?? REGISTRATION_TICKET_COLLECTION.FIELDS.INVOICE_URL.VALUES.DEFAULT
    this.refundAmountAdded =
      obj?.refundAmountAdded ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.REFUND_AMOUNT_ADDED.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.selectedUnitsCount =
      obj?.selectedUnitsCount ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.SELECTED_UNITS_COUNT.VALUES.DEFAULT
    this.remainingTicketsCount =
      obj?.remainingTicketsCount ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.REMAINING_TICKETS_COUNT.VALUES.DEFAULT
    this.sharedTicketsCount =
      obj?.sharedTicketsCount ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.SHARED_TICKETS_COUNT.VALUES.DEFAULT
    this.recipientNameNGram =
      obj?.recipientNameNGram ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.RECIPIENT_NAME_N_GRAM.VALUES.DEFAULT
    this.recipientProfilePicture =
      obj?.recipientProfilePicture ??
      REGISTRATION_TICKET_COLLECTION.FIELDS.RECIPIENT_PROFILE_PICTURE.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new RegistrationTicketModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
