import { useState } from 'react'
// Third party

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'
import AutorenewIcon from '@mui/icons-material/Autorenew'

import MessageHelperComp from '../../../helpers/MessageHelper'

import { useAppDispatch } from '../../../store/hooks'
import { riderProfileRegistrationActions } from '../../../store/riderProfileRegistration/riderProfileRegistrationSlice'

import useUserAccountHook from '../../../hooks/users/competitor/profile/useAccountHook'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { ROUTES_CONST } from '../../../const/routes-const'

const userLoginViewComponentDefaultValues = {
  email: '',
  password: '',
}

const userLoginViewComponentSchemaValidation = yup
  .object({
    email: yup
      .string()
      .required('email or username is required')
      .test('no-spaces', 'email or username should not contain spaces', (value) => {
        if (value) return !/\s/.test(value)
        return true
      }),
    password: yup.string().required(),
  })
  .required()

type Props = {
  closeAuthModal: () => void
  handleModal?: (open: boolean, type: string) => void
  redirectAfterLogin?: string
  registerHandler?: () => void
  eventId?: string
}

const AuthenticationModalLoginComponent = (props: Props) => {
  const { handleOnLoginValid } = useUserAccountHook()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [innerType, setInnerType] = useState('password')
  const [loading, setLoading] = useState<boolean>(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: userLoginViewComponentDefaultValues,
    resolver: yupResolver(userLoginViewComponentSchemaValidation),
    mode: 'onChange',
  })

  const onSubmit = async (formData: { email: string; password: string }) => {
    setLoading(true)
    await handleOnLoginValid({
      email: `${formData.email}`,
      eventId: props.eventId,
      password: formData.password,
      onSuccess: props.closeAuthModal,
    })
    setLoading(false)
  }
  const handleLoginInvalid = async () => {}

  const closeAndNavigate = () => {
    props.closeAuthModal()
    history.push(ROUTES_CONST.SIGNUP.URL)
  }

  const singInUpHandler = () => {
    dispatch(riderProfileRegistrationActions.clear())
    if (props.eventId) {
      dispatch(riderProfileRegistrationActions.setEventId(props.eventId))
      props.closeAuthModal()
      props.handleModal?.(true, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_1)
    } else {
      props.registerHandler ? props.registerHandler() : closeAndNavigate()
    }
  }

  return (
    <>
      <form className="space-y-8" onSubmit={(e) => handleSubmit(onSubmit, handleLoginInvalid)(e)}>
        <div className="my-6">
          <label className="mb-3 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-[#122B4633] border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/cp_icons/Mail-1.svg" alt="icon" />
            </span>
            <input
              {...register('email')}
              placeholder="Email or Username"
              className="flex-1 border-0 outline-0 text-SeabiscuitDark200ThemeColor bg-transparent placeholder:text-[#122B4680] focus:ring-transparent w-full focus:border-none py-[0.5rem] px-[0.75rem]"
            />
          </label>
          {errors.email && (
            <MessageHelperComp
              className="mb-3 !mt-0"
              isError={true}
              message={errors.email.message}
            />
          )}

          <label className="mb-3 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-[#122B4633] border-[1px] bg-transparent">
            <div>
              <img src="/assets/cp_icons/Lock-1.svg" alt="icon" />
            </div>
            <input
              type={innerType}
              {...register('password')}
              placeholder="Password"
              className="flex-1 border-0 outline-0 text-SeabiscuitDark200ThemeColor bg-transparent placeholder:text-[#122B4680] focus:ring-transparent w-full focus:border-none"
            />
            <div
              className="cursor-pointer hover:opacity-50 transition-all"
              onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}
            >
              {innerType === 'password' ? (
                <VisibilityOffOutlined className="text-[#122B4680]" />
              ) : (
                <Visibility className="text-[#122B4680]" />
              )}
            </div>
          </label>
          {errors.password && (
            <MessageHelperComp
              className="mb-3 !mt-0"
              isError={true}
              message={errors.password.message}
            />
          )}
        </div>

        <div className="w-[70%] mt-[80px] mx-auto">
          <button
            disabled={loading}
            type="submit"
            className="w-full h-12 flex items-center justify-center py-2 px-4 border border-solid border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium nextBtnShadow focus:outline-none focus:ring-0 bg-SeabiscuitMainThemeColor text-SeabiscuitGrayThemeColor hover:bg-[#d70443]"
          >
            {loading ? <AutorenewIcon fontSize="small" className="animate-spin" /> : 'LOG IN'}
          </button>
        </div>

        <div className="text-SeabiscuitDark200ThemeColor text-[14px] text-center">
          Don’t have an account?
          <span
            className="text-SeabiscuitMainThemeColor ml-1 cursor-pointer text-[16px] hover:text-[#d70443]"
            onClick={singInUpHandler}
          >
            {' '}
            Sign up
          </span>
        </div>

        <div className="flex justify-center mt-4">
          <div className="text-sm ">
            <div
              onClick={() => props.handleModal?.(true, MODAL_CONSTS.FORGOT_PASSWORD)}
              className="font-normal text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor cursor-pointer underline"
            >
              Forgot your password?
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AuthenticationModalLoginComponent
