import { ModelBaseModel } from '../model-base/model-base.model'
import { IUserDocument, TDocumentShareViaEmail } from './user-documents.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

export class UserDocumentModel extends ModelBaseModel<IUserDocument> {
  public id: string
  public signatoryId: IUserDocument['signatoryId']
  public riderId: IUserDocument['riderId']
  public signatoryName: IUserDocument['signatoryName']
  public signatoryProfilePicture: IUserDocument['signatoryProfilePicture']
  public status: IUserDocument['status']
  public eventId: IUserDocument['eventId']
  public documentName: IUserDocument['documentName']
  public documentUrl: IUserDocument['documentUrl']
  public eventName: IUserDocument['eventName']
  public eventLogo: IUserDocument['eventLogo']
  public documentOriginalName: IUserDocument['documentOriginalName']
  public documentNameAsPerPdf: IUserDocument['documentNameAsPerPdf']
  public pageNumberToSignOn: IUserDocument['pageNumberToSignOn']
  public coordinatesToSignOn: IUserDocument['coordinatesToSignOn']
  public documentShareViaEmail: TDocumentShareViaEmail[]
  public documentOwner: IUserDocument['documentOwner']
  public competitorId: IUserDocument['competitorId']
  public signatoryDefaultRole: IUserDocument['signatoryDefaultRole']
  public registrationDocId: IUserDocument['registrationDocId']
  public registrationByDayUuid: IUserDocument['registrationByDayUuid']
  public registrationByDayUniqId: IUserDocument['registrationByDayUniqId']
  public riderTeamMemberDocId: IUserDocument['riderTeamMemberDocId']
  public signatoryEmail: IUserDocument['signatoryEmail']
  public reminder: IUserDocument['reminder']
  public created: IUserDocument['created']
  public modified: IUserDocument['modified']
  public activityUser: IUserDocument['activityUser']
  public riderName: IUserDocument['riderName']

  public dateOfSignature: IUserDocument['dateOfSignature']

  public constructor(obj?: IUserDocument) {
    super()
    this.id = obj?.id ?? ''
    this.signatoryId = obj?.signatoryId ?? ''
    this.riderId = obj?.riderId ?? ''
    this.signatoryName = obj?.signatoryName ?? ''
    this.pageNumberToSignOn = obj?.pageNumberToSignOn ?? null
    this.coordinatesToSignOn = obj?.coordinatesToSignOn ?? {
      x: null,
      y: null,
    }
    this.signatoryProfilePicture = obj?.signatoryProfilePicture ?? ''
    this.signatoryProfilePicture = obj?.signatoryProfilePicture ?? ''
    this.status = obj?.status ?? 'Not Signed'
    this.eventId = obj?.eventId ?? ''
    this.documentName = obj?.documentName ?? ''
    this.documentUrl = obj?.documentUrl ?? ''
    this.eventName = obj?.eventName ?? ''
    this.eventLogo = obj?.eventLogo ?? ''
    this.registrationDocId = obj?.registrationDocId ?? ''
    this.registrationByDayUuid = obj?.registrationByDayUuid ?? ''
    this.registrationByDayUniqId = obj?.registrationByDayUniqId ?? ''
    this.riderTeamMemberDocId = obj?.riderTeamMemberDocId ?? ''
    this.documentOriginalName = obj?.documentOriginalName ?? ''
    this.documentNameAsPerPdf = obj?.documentNameAsPerPdf ?? ''
    this.documentOwner = obj?.documentOwner ?? ''
    this.documentShareViaEmail = obj?.documentShareViaEmail ?? []
    this.competitorId = obj?.competitorId ?? ''
    this.signatoryDefaultRole = obj?.signatoryDefaultRole ?? ''
    this.signatoryEmail = obj?.signatoryEmail ?? ''
    this.reminder = obj?.reminder ?? false
    this.activityUser = obj?.activityUser ?? ''
    this.riderName = obj?.riderName ?? ''

    this.dateOfSignature = obj?.dateOfSignature
      ? this.utcTimestamp({
          key: 'dateOfSignature',
          isTimestamp: true,
          value: obj?.dateOfSignature,
        })
      : null

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified ?? new Date().toString(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new UserDocumentModel({
      ...doc.data(),
      id: doc.id,
    })
  }
}
