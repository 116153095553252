import React, { useState } from 'react'
import { CONST } from '../../../../const/const'
import FirestoreService from '../../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { UserModel } from '../../../../models/users/user.model'
import { EventModel } from '../../../../models/events/event.model'
import { IFilters } from '../../../../components/home/controls/HomeCategoriesControlComponent'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectedfilters,
  selectedOrganizerFilter,
  setMapVisibilityAc,
  setOrganizerFilters,
} from '../../../../store/filters/filterSlice'
import { disciplineData } from '../../../../fakeData/disciplineList'
import { selectIsLoggedIn, selectProfileData } from '../../../../store/user/userSlice'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { FollowingModel } from '../../../../models/following/following.model'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { CustomError, getUserFullName } from '../../../../helpers/helpers'
import helpers from '../../../../commonHelpers/helpers'
import {
  IOrganizer,
  selectOrganizers,
  selectOriginalOrganizers,
  setOrganizers,
  updateOrganizers,
} from '../../../../store/organizers/organizersSlice'
import { useSelector } from 'react-redux'

const { USERS, EVENTS, FOLLOWING } = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const FILE_NAME = 'useOrganizers'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

interface Coordinates {
  lat: number
  long: number
}

export const useOrganizers = () => {
  const [loading, setLoading] = useState(true)
  const filters = useAppSelector(selectedOrganizerFilter)
  const organizers = useAppSelector(selectOrganizers)
  const originalOrganizers = useAppSelector(selectOriginalOrganizers)
  const dispatch = useAppDispatch()

  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const toastMethods = useToasterHelper()
  const profileData = useAppSelector(selectProfileData)
  const filterData = useSelector(selectedfilters)

  const mapIsOpened = filterData.map.status

  const loggedInUserId = profileData?.id
  const loggedInUserType = profileData?.userType

  const onMapButtonClick = () => {
    dispatch(setMapVisibilityAc(!mapIsOpened))
  }
  const validateAction = () => {
    if (!isLoggedIn) {
      toastMethods.info({
        message: 'This service is only available to registered users',
      })
      return false
    }
    return true
  }

  const followHandler = async (followingUserId: string, organizerName: string) => {
    let docId: null | string = null

    try {
      if (!validateAction()) {
        return 0
      }

      if (!loggedInUserId || !profileData?.id) throw new Error()

      let docToSave = new FollowingModel({
        usedFor: 'user',
        followingHorseId: null,
        followingUserId: followingUserId,
        followerUserId: loggedInUserId,
        followerUserType: loggedInUserType as any,
        followingUserType: USERS.FIELDS.USER_TYPE.VALUES.ORGANIZER as any,
      })

      const docRef = await FirestoreService.createItem(FOLLOWING.NAME, docToSave.toFirestore())

      docId = docRef.id
      docToSave = docToSave.cloneDeep()
      docToSave.id = docId

      toastMethods.success({
        message: MESSAGES_CONST.FOLLOWED.replace('[USER_NAME]', organizerName),
      })

      const newOriginalOrganizers = [...originalOrganizers]
      const newOrganizers = [...organizers]

      const newOriginalOrganizer = newOriginalOrganizers.find((org) => org.id === followingUserId)
      const newOrganizer = newOrganizers.find((org) => org.id === followingUserId)

      if (newOriginalOrganizer) {
        newOriginalOrganizer.followingDocId = docId
      }
      if (newOrganizer) {
        newOrganizer.followingDocId = docId
      }
      console.log('=>(useOrganizers.ts:113) newOrganizers', newOrganizers)
      dispatch(setOrganizers(newOrganizers))
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onFollowBtnClick',
          devMessage: error,
        }),
      })
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      // setFollowing(false)
    }
  }

  const unfollowHandler = async (
    followingDocId: string,
    followingUserId: string,
    organizerName: string
  ) => {
    await FirestoreService.deleteItem(FOLLOWING.NAME, followingDocId)

    const newOriginalOrganizers = [...originalOrganizers]
    const newOrganizers = [...organizers]

    const newOriginalOrganizer = newOriginalOrganizers.find((org) => org.id === followingUserId)
    const newOrganizer = newOrganizers.find((org) => org.id === followingUserId)

    if (newOriginalOrganizer) {
      newOriginalOrganizer.followingDocId = undefined
    }
    if (newOrganizer) {
      newOrganizer.followingDocId = undefined
    }
    dispatch(setOrganizers(newOrganizers))

    toastMethods.success({
      message: MESSAGES_CONST.UNFOLLOWED.replace('[USER_NAME]', organizerName),
    })
  }

  const chunkArray = (array: string[], size: number) => {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  const getOrganizersData = React.useCallback(async () => {
    try {
      const users: UserModel[] = []
      const usersSnaps = await FirestoreService.filterItems(USERS.NAME, [
        where(USERS.FIELDS.USER_TYPE.KEY, '==', USERS.FIELDS.USER_TYPE.VALUES.ORGANIZER),
      ])
      usersSnaps.forEach((currentUser) => {
        users.push(UserModel.fromFirestoreDoc(currentUser).toObject() as UserModel)
      })

      const updatedUsers: IOrganizer[] = []

      const userIds = users.map((user) => user.id)

      const chunks = chunkArray(userIds, 30)

      const followedOrganizers: FollowingModel[] = []

      const followedOrganizersSnaps = await FirestoreService.filterItems(FOLLOWING.NAME, [
        where(FOLLOWING.FIELDS.FOLLOWER_USER_ID.KEY, '==', loggedInUserId || ''),
      ])

      followedOrganizersSnaps.forEach((current) => {
        followedOrganizers.push(
          FollowingModel.fromFirestoreDoc(current).toObject() as FollowingModel
        )
      })

      const events: EventModel[] = []

      for (const chunk of chunks) {
        const eventsSnaps = await FirestoreService.filterItems(EVENTS.NAME, [
          where(EVENTS.FIELDS.OWNER.KEY, 'in', chunk),
        ])
        eventsSnaps.forEach((snap) => {
          events.push(EventModel.fromFirestoreDoc(snap).toObject() as EventModel)
        })
      }

      for (const user of users) {
        const pastEvents: EventModel[] = []
        const currentEvents: EventModel[] = []

        events.forEach((event) => {
          if (event.status === 'past' && event.owner === user.id) {
            pastEvents.push(event)
          }
          if (event.status === 'current' && event.owner === user.id) {
            currentEvents.push(event)
          }
        })

        const updatedUser: IOrganizer = {
          ...user,
          followingDocId: followedOrganizers.find((itm) => itm.followingUserId === user.id)?.id,
          events: currentEvents,
          pastEvents,
        } as IOrganizer

        updatedUsers.push(updatedUser)
      }

      console.log('=>(useOrganizers.ts:227) updatedUsers', updatedUsers)
      dispatch(setOrganizers(updatedUsers))
    } catch (e) {
      console.log('=>(useOrganizers.ts:22) e', e)
    } finally {
      setLoading(false)
    }
  }, [loggedInUserId])

  const isWithinRadius = (
    point1: Coordinates,
    point2: Coordinates,
    radiusMiles: number
  ): boolean => {
    const toRadians = (degrees: number) => (degrees * Math.PI) / 180

    const earthRadiusMiles = 3958.8 // Средний радиус Земли в милях

    const dLat = toRadians(point2.lat - point1.lat)
    const dLong = toRadians(point2.long - point1.long)

    const lat1 = toRadians(point1.lat)
    const lat2 = toRadians(point2.lat)

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLong / 2) * Math.sin(dLong / 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const distance = earthRadiusMiles * c

    return distance <= radiusMiles
  }
  const filterHandler = React.useCallback(() => {
    let newOrganizers: IOrganizer[] = [...originalOrganizers]

    if (filters.search.searchValue)
      newOrganizers = originalOrganizers.filter(
        (itm) =>
          itm.userName?.toLowerCase().includes(filters.search.searchValue.toLowerCase()) ||
          itm.userLastName?.toLowerCase().includes(filters.search.searchValue.toLowerCase()) ||
          itm.userFirstName?.toLowerCase().includes(filters.search.searchValue.toLowerCase()) ||
          getUserFullName(itm)?.toLowerCase().includes(filters.search.searchValue.toLowerCase())
      )

    if (filters.groupFilters.disciplineFilter[0]) {
      newOrganizers = newOrganizers.filter((itm) => {
        const disciplineLabel = disciplineData.find((d) => d.value === itm.userDiscipline)?.label
        if (disciplineLabel) return filters.groupFilters.disciplineFilter.includes(disciplineLabel)
        return disciplineLabel
      })
    }

    if (filters.location.lat && filters.location.lng) {
      newOrganizers = newOrganizers.filter((itm) => {
        return isWithinRadius(
          {
            lat: filters.location.lat,
            long: filters.location.lng,
          },
          {
            lat: +itm.userLat,
            long: +itm.userLong,
          },
          +filters.location.radius || 400
        )
      })
    }

    if (filters.groupFilters.categoriesFilter[0]) {
      newOrganizers = originalOrganizers.filter((itm) => {
        if (filters.groupFilters.categoriesFilter.includes('following')) {
          return itm.followingDocId
        } else {
          return true
        }
      })
    }

    dispatch(updateOrganizers(newOrganizers))
  }, [filters, originalOrganizers])

  React.useEffect(() => {
    getOrganizersData().then(() => null)
  }, [getOrganizersData])

  React.useEffect(() => {
    filterHandler()
  }, [filterHandler])

  const searchHandler = (data: { value: string; filters: IFilters; tags: (string | null)[] }) => {
    dispatch(setOrganizerFilters({ ...filters, search: { status: true, searchValue: data.value } }))
  }

  return {
    searchHandler,
    organizers,
    filterHandler,
    followHandler,
    loggedInUserId,
    unfollowHandler,
    loading,
    onMapButtonClick,
    mapIsOpened,
  }
}
