import { CONST } from '../../const/const'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'

import { IUserHorseMappingInterface } from './userHorseMapping.interface'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @info The doc specifies that which horse is linked with which persons
 */
export class UserHorseMappingModel
  extends ModelBaseModel<IUserHorseMappingInterface>
  implements IModelBaseModel
{
  // Properties
  public v: IUserHorseMappingInterface['v']
  public id: IUserHorseMappingInterface['id']
  public userId: IUserHorseMappingInterface['userId']
  public horseId: IUserHorseMappingInterface['horseId']
  public isMyHorse: IUserHorseMappingInterface['isMyHorse']
  public horseName: IUserHorseMappingInterface['horseName']
  public horseZone: IUserHorseMappingInterface['horseZone']
  public horseOwnerId: IUserHorseMappingInterface['horseOwnerId']
  public horseNameNGram: IUserHorseMappingInterface['horseNameNGram']
  public horseDiscipline: IUserHorseMappingInterface['horseDiscipline']
  public horseProfilePicture: IUserHorseMappingInterface['horseProfilePicture']
  public horseSelectedForCompeletion: IUserHorseMappingInterface['horseSelectedForCompeletion']

  public created: IUserHorseMappingInterface['created']
  public modified: IUserHorseMappingInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IUserHorseMappingInterface`
   */
  constructor(obj?: IUserHorseMappingInterface) {
    super()
    this.v = obj?.v ?? COLLECTIONS.USER_HORSE_MAPPING.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? COLLECTIONS.USER_HORSE_MAPPING.FIELDS.ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? COLLECTIONS.USER_HORSE_MAPPING.FIELDS.USER_ID.VALUES.DEFAULT
    this.horseId = obj?.horseId ?? COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_ID.VALUES.DEFAULT
    this.isMyHorse =
      obj?.isMyHorse ?? COLLECTIONS.USER_HORSE_MAPPING.FIELDS.IS_MY_HORSE.VALUES.DEFAULT
    this.horseName = obj?.horseName ?? COLLECTIONS.HORSES.FIELDS.HORSE_NAME.VALUES.DEFAULT
    this.horseZone = obj?.horseZone ?? COLLECTIONS.HORSES.FIELDS.HORSE_ZONE.VALUES.DEFAULT
    this.horseZone = obj?.horseZone ?? COLLECTIONS.HORSES.FIELDS.HORSE_ZONE.VALUES.DEFAULT
    this.horseDiscipline =
      obj?.horseDiscipline ?? COLLECTIONS.HORSES.FIELDS.HORSE_DISCIPLINE.VALUES.DEFAULT
    this.horseNameNGram =
      obj?.horseNameNGram ?? COLLECTIONS.HORSES.FIELDS.HORSE_NAME_N_GRAM.VALUES.DEFAULT
    this.horseOwnerId =
      obj?.horseOwnerId ?? COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_OWNER_ID.VALUES.DEFAULT
    this.horseProfilePicture =
      obj?.horseProfilePicture ?? COLLECTIONS.HORSES.FIELDS.HORSE_PROFILE_PICTURE.VALUES.DEFAULT
    this.horseSelectedForCompeletion =
      obj?.horseSelectedForCompeletion ??
      COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_SELECTED_FOR_COMPETETION.VALUES.DEFAULT

    this.created = obj?.created
      ? this.utcTimestamp({
          key: 'created',
          isTimestamp: true,
          value: obj?.created,
        })
      : new Date().toString()

    this.modified = obj?.modified
      ? this.utcTimestamp({
          key: 'modified',
          isTimestamp: true,
          changeOnUpdate: true,
          value: obj?.modified,
        })
      : new Date().toString()
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new UserHorseMappingModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
