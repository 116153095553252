import React, { useEffect, useContext } from 'react'
import { IonRouterContext } from '@ionic/react'
import { useAppDispatch } from '../../../store/hooks'
import { setIsLoggedIn, storeUserId } from '../../../store/user/userSlice'
import { CONST } from '../../../const/const'
import { signOut } from 'firebase/auth'
import FirebaseApp from '../../../services/firebaseApp'
import { storeDisplayName } from '../../../store/user/userThunk'
import { useHistory } from 'react-router'

const RedirectToLogin: React.FC = () => {
  const dispatch = useAppDispatch()

  const ionRouterContext = useContext(IonRouterContext)
  const history = useHistory()

  useEffect(() => {
    dispatch(setIsLoggedIn(false))
    dispatch(storeUserId(''))
    dispatch(storeDisplayName(''))
    signOut(FirebaseApp.auth)
    ionRouterContext.push(CONST.ROUTES.LOGIN.URL)
    history.push(CONST.ROUTES.LOGIN.URL)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoggedIn, storeUserId])
  return null
}

export default RedirectToLogin
