// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Types
import { sumBy, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { FEES_CATEGORY_CONST } from '../../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { CONST } from '../../../../../../const/const'
import { IFeesTab } from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { useAppSelector } from '../../../../../../store/hooks'
import {
  selectFeesTabDataR,
  selectRegisterTabData,
  selectRegistrationsByDayInDb,
} from '../../../../../../store/registration/registrationSlice'
import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'
import { getRegistrations } from '../../../../../../helpers/getRegistrations'
import { convertPrice, getFloatPrice } from '../../../../../../helpers/price'

type SelectedEventGovernanceFeeListProps = {
  setEventTab?: any
  manageInfo: IManageInfo
  isManage?: boolean
}

const FILE_NAME = 'SelectedEventGovernanceFeeList'

const SelectedEventGovernanceFeeList = (props: SelectedEventGovernanceFeeListProps) => {
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const registrationsByDayInDb = useAppSelector(selectRegistrationsByDayInDb)

  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
  }>({ totalItems: 0, totalPrice: 0 })

  let heading = [
    { title: 'Line item', tooltipText: 'Fee line items you added during registration' },
    { title: 'Number of units', tooltipText: 'Number of units per line item' },
    { title: 'Price per unit', tooltipText: 'Price per individual line item' },
  ]

  useEffect(() => {
    const horsesIds: string[] = []
    let horsesIdsInDb: string[] = []
    let governanceFeesItems_: IFeesTab[] = []
    let uniqueHorseIds: string[] = []

    let registers = props.isManage ? props.manageInfo.register : registerTabData

    const registrationsByDay = getRegistrations(registers).allRegistrations

    registrationsByDay.forEach((registrationByDay) => {
      if (registrationByDay.horseIds) horsesIds.push(...registrationByDay.horseIds)
    })

    uniqueHorseIds = uniq(horsesIds)

    if (props.isManage) {
      registrationsByDayInDb.forEach((registrationByDay) => {
        if (registrationByDay.horseIds) horsesIdsInDb.push(...registrationByDay.horseIds)
      })
      uniqueHorseIds = uniq(horsesIds).filter((item) => !horsesIdsInDb.includes(item))
    }

    const fees = props.isManage ? props.manageInfo.fees : feesTabData

    fees.forEach((currFeesTabRow) => {
      if (
        currFeesTabRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE &&
        currFeesTabRow.registrationFees
      )
        governanceFeesItems_.push(currFeesTabRow)
    })

    const totalPrice = convertPrice(
      sumBy(governanceFeesItems_, (fees) => uniqueHorseIds.length * (fees?.feesPrice ?? 0))
    )

    setSelectedUnits({
      totalItems: uniqueHorseIds.length * governanceFeesItems_.length,
      totalPrice,
    })
  }, [props.isManage, props.manageInfo.register, registerTabData])

  return (
    <ConfirmationCompsWrapper
      title="Mandatory Fees"
      id={FILE_NAME}
      setEventTab={props.setEventTab}
      redirectTab={CONST.UI.REGISTER.TABS.FEES}
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Fees' : 'Fee'}, ${getFloatPrice(`${selectedUnits.totalPrice}`)}`}
      accordion={{ open, setOpen }}
      showList={
        !![...feesTabData, ...props.manageInfo.fees].filter((fee) => fee.registrationFees).length
      }
      colsClassName={{
        0: '!w-1/3 flex items-center',
        1: '!w-1/3 flex items-center',
        2: '!w-1/3 flex items-center',
      }}
      cols={heading}
    >
      <>
        {[...feesTabData, ...props.manageInfo.fees]?.map((currRow, index) => {
          if (currRow.feesCategory !== FEES_CATEGORY_CONST.GOVERNANCE || !currRow.registrationFees)
            return null

          return (
            <div
              key={`${JSON.stringify(currRow)}${index}FeesList`}
              className={`flex items-start justify-start w-full mb-2 flex-col lg:flex-row ${index !== 0 ? 'mt-10' : ''} lg:mt-0`}
            >
              <div className="lg:hidden mb-1 font-medium text-[12px]">Line Item</div>
              <p className="w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize lg:w-1/3">
                {currRow.feesTitle}
              </p>
              <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Number Of Units</div>
              <p className="w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize text-center lg:w-1/3">
                {currRow.registrationFees?.selectedUnitsCount ?? 0}
              </p>
              <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Price Per Unit</div>
              <p className="w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize text-center lg:w-1/3">
                {currRow.registrationFees?.paymentType ?? getFloatPrice(`${currRow.feesPrice}`)}
              </p>
            </div>
          )
        })}

        {/* Total */}
        <div className="flex items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor text-center">
          <p className="flex-grow p-4 text-left mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-1/3">
            Total
          </p>

          <p className="flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-1/3">
            {selectedUnits.totalItems}
          </p>

          <p className="flex-grow p-4 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-1/3">
            {getFloatPrice(`${selectedUnits.totalPrice}`)}
          </p>
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventGovernanceFeeList
