import React, { useEffect, useState } from 'react'

// Libs
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Components
import { HelpRootPageDataPassOn } from '../../../pages/help/tabs/HelpRootPagePoliciesTab'

// Helpers
import useToasterHelper from '../../../helpers/ToasterHelper'
import { authHelpers, CustomError } from '../../../helpers/helpers'

// Store
import {
  riderProfileRegistrationActions,
  selectRiderProfileRegistration,
  selectRiderProfileType,
} from '../../../store/riderProfileRegistration/riderProfileRegistrationSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  setProfileDetails,
  setIsLoggedIn,
  setUserId,
  storeUserId,
} from '../../../store/user/userSlice'
import { selectAllUsers, setAllUsers } from '../../../store/users/usersSlice'

// Types
import { ECompetitorTypes, IRiderSignupForm } from '../../../types/competitor_types'

// Constants
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'

// Data
import { PoliciesData } from '../../../pages/help/data/PoliciesData'

// Schema
import { riderSignupSchema } from '../../../validations'
import { useCreateUser } from '../../../hooks/useCreateUser'
import { storeUseMode } from '../../../store/system/systemThunk'
import { CONST } from '../../../const/const'

export interface useCreateRiderProfileStep2Props {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  isTeam: boolean
}
const userIcon = '/assets/img/User2.png'

export const useCreateRiderProfileStep2 = ({ handleModal }: useCreateRiderProfileStep2Props) => {
  const dispatch = useAppDispatch()
  const profileType = useAppSelector(selectRiderProfileType)
  const toastFunctions = useToasterHelper()
  const riderProfileRegistration = useAppSelector(selectRiderProfileRegistration)
  const riderProfileType = useAppSelector(selectRiderProfileType)
  const [imgLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [picture, setPicture] = useState('')
  const [imageFile, setImageFile] = React.useState<File | null>(null)
  const users = useAppSelector(selectAllUsers)
  const { createUser } = useCreateUser()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<IRiderSignupForm>({
    mode: 'onChange',
    resolver: yupResolver(riderSignupSchema),
  })

  useEffect(() => {
    if (riderProfileRegistration.rider && riderProfileRegistration.rider.signupForm) {
      Object.keys(riderProfileRegistration.rider.signupForm).forEach((key) => {
        setValue(
          key as keyof IRiderSignupForm,
          riderProfileRegistration.rider!.signupForm[key as keyof IRiderSignupForm]
        )
      })
    }
    if (riderProfileRegistration.rider && riderProfileRegistration.rider.picture) {
      const imageUrl = URL.createObjectURL(riderProfileRegistration.rider.picture)
      setPicture(imageUrl)
    }
  }, [riderProfileRegistration.rider])

  const handlerImageChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || files.length === 0) {
      return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })
    } else {
      const imageUrl = URL.createObjectURL(files[0])
      setPicture(imageUrl)

      setImageFile(files[0])
    }
  }

  const nextHandler = async (formData: IRiderSignupForm) => {
    try {
      setLoading(true)
      if (!formData.fullName.split(' ')[1]) {
        toastFunctions.error({
          message: 'The full name should start with the first name, followed by the last name.',
        })
        return
      }
      const resp = await authHelpers.isUsernameUnique(formData.userName)
      if (resp instanceof CustomError) {
        setLoading(false)
        toastFunctions.error({ message: resp.message })
        return null
      }

      if (riderProfileType === ECompetitorTypes.spectator) {
        const createdUserData = await createUser(formData, imageFile)
        if (!createdUserData) {
          console.error('createdUserData not found')
          return null
        }

        const { competitor } = createdUserData

        dispatch(setProfileDetails(competitor))
        dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
        dispatch(storeUserId(competitor.id))
        dispatch(setIsLoggedIn(true))
        dispatch(setUserId(competitor.id))
        dispatch(setAllUsers([...users, competitor]))
        dispatch(riderProfileRegistrationActions.clear())
        handleModal(false, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_2)
        dispatch(riderProfileRegistrationActions.clear)
        setTimeout(() => {
          handleModal(true, MODAL_CONSTS.USER_CARD)
        }, 500)
        return null
      }

      dispatch(
        riderProfileRegistrationActions.setRiderForm({
          picture: imageFile || riderProfileRegistration.rider?.picture,
          signupForm: formData,
        })
      )


      handleModal(false, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_2)
      handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
        onlyHorse: true,
        backToCreateProfile: true,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const cancelHandler = () => {
    dispatch(riderProfileRegistrationActions.clear())
    handleModal(false, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_2)
  }

  const openModalPoliciesHandler = (isPrivacyPolicy: boolean) => {
    handleModal(true, MODAL_CONSTS.HELP_VIDEO, {
      component: (
        <HelpRootPageDataPassOn
          handleModal={handleModal}
          policy={PoliciesData[isPrivacyPolicy ? 1 : 0]}
        />
      ),
      small: true,
    })
  }

  return {
    imgLoading,
    picture,
    userIcon,
    cancelHandler,
    openModalPoliciesHandler,
    register,
    errors,
    control,
    handleSubmit,
    nextHandler,
    loading,
    isRegistered: !!riderProfileRegistration.rider,
    handlerImageChanges,
    profileType,
  }
}
