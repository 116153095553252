import React, { useEffect, useState } from 'react'

// Third party
import clsx from 'clsx'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Tooltip } from '@mui/material'

// Custom imports
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import TicketsHeader from '../../event-registration-tabs/components/tickets/TicketHeader'
import AmountInput from '../../../../components/common/inputs/AmountInput'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import TooltipIcon from '../../../../helpers/TooltipIcon'

import { useAppSelector } from '../../../../store/hooks'
import { selectTicketTabData } from '../../../../store/registration/registrationSlice'

import { capitalize } from '../../../../helpers/helpers'

// Types
import { IEventTickets } from '../../../../models/event-details/event-details.interface'
import {
  IRegistrationTabs,
  ITicketTab,
} from '../../../../models/event-registered-users/event-registered-users.interface'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { ISaveTicketTabData } from '../../event-registration-tabs/hooks/useEventRegistrationTabs/useEventRegistrationTabs'

import { MODAL_CONSTS } from '../../../../const/modal-const'
import { getFloatPrice } from '../../../../helpers/price'

type Props = {
  title?: string
  ticketItemsForm: UseFormReturn
  description?: string
  ticketsHandler: ({ updatedObjOfTicketGroup }: { updatedObjOfTicketGroup: IEventTickets }) => void
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  onInputChangeTicket: ({
    setManageInfo,
    manageInfo,
  }: ISaveTicketTabData) => Promise<null | undefined>
}

type ITicketsColumnComp = (props: {
  id: string
  index: number
  ticketItemsForm: UseFormReturn
  ticket: IRegistrationTabs['ITicketTab']
  keyProp: number | string
  handleChange: IChangeHandler
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  listing: { [key: string]: boolean }
  setListing: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean
    }>
  >
  onInputChangeTicket: ({
    setManageInfo,
    manageInfo,
    isManage,
  }: ISaveTicketTabData) => Promise<null | undefined>
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}) => ReactJSXElement

type IChangeHandler = (data: any, e: any) => void

// Constants
const widths = [
  'shrink-0 w-[170px]',
  'shrink-0 w-[100px]',
  'shrink-0 w-[150px]',
  'shrink-0 w-[130px]',
  'grow min-w-[230px]',
  'shrink-0 w-[130px] mr-3',
]

const CompetitorEventRegisterTicketsTab = (props: Props) => {
  const ticketTabData = useAppSelector(selectTicketTabData)

  const [listing, setListing] = useState<{ [key: string]: boolean }>({})
  const [currentTickets, setCurrentTickets] = useState<ITicketTab[] | null>(null)

  const handleTicketChange: IChangeHandler = (data, e) => {
    let updatedObjOfTicketGroup = {
      ...data,
      sold: Number(data.sold),
      units: Number(e.value),
    }
    props.ticketsHandler({ updatedObjOfTicketGroup })
  }

  useEffect(() => {
    setCurrentTickets(props.isManage ? props.manageInfo.tickets : ticketTabData)
  }, [ticketTabData, props.isManage, props.manageInfo])

  useEffect(() => {
    let selectedUnitsCount = 0
    let ticketsStateInHookForm: Record<string, number> = {}

    currentTickets?.forEach((currTicketRow) => {
      if (currTicketRow.registrationTicket) {
        selectedUnitsCount = currTicketRow?.registrationTicket?.selectedUnitsCount ?? 0
        ticketsStateInHookForm = {
          ...ticketsStateInHookForm,
          [currTicketRow.ticketItemId]: selectedUnitsCount,
        }
      }
    })

    props.ticketItemsForm.reset(ticketsStateInHookForm)
  }, [props.ticketItemsForm, currentTickets])

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <div id="rtc">
        {currentTickets?.length ? (
          <>
            <TicketsHeader />
            {currentTickets.map((ticket, index) => (
              <TicketsColumn
                ticket={ticket}
                index={index}
                listing={listing}
                id={`tickets${index}`}
                setListing={setListing}
                handleModal={props?.handleModal}
                handleChange={handleTicketChange}
                ticketItemsForm={props.ticketItemsForm}
                onInputChangeTicket={props.onInputChangeTicket}
                isManage={props.isManage}
                manageInfo={props.manageInfo}
                setManageInfo={props.setManageInfo}
                key={`${JSON.stringify(ticket)}${index}`}
                keyProp={`${JSON.stringify(ticket)}${index}`}
              />
            ))}
          </>
        ) : (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text="No ticket items have been added to this event"
          />
        )}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}
let classList: string = 'border border-solid border-SeabiscuitLightThemeColor !rounded-[8px]'
let commonClasses: string = 'text-ellipsis overflow-hidden whitespace-nowrap'
const TicketsColumn: ITicketsColumnComp = ({
  ticket,
  keyProp,
  handleModal,
  handleChange,
  ...props
}) => {
  // Vars
  const [inputValue, setInputValue] = useState<string | null>(null)

  const openSeeMoreModal = (content: string) => {
    handleModal(true, MODAL_CONSTS.ADD_NOTE, { noteInputRef: content })
  }

  return (
    <div
      className={clsx(
        'border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-5 lg:p-0  flex flex-col lg:flex-row w-full text-nr gap-[10px]',
        props.index && 'mt-2'
      )}
      key={keyProp}
    >
      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[0])}>
        Ticket Item
        <Tooltip
          title={<h1 className="tooltip_title">{`Ticket items added by event organizer`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          `w-full lg:w-[170px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg h-[56px] `,
          classList,
          widths[0],
          commonClasses,
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {capitalize(ticket?.ticketTitle, {
          capitalizeAll: false,
        })}
      </div>

      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[1])}>
        Price
        <Tooltip
          title={<h1 className="tooltip_title">{`Cost per ticket`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'lg:text-center w-full lg:w-[100px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg h-[56px]',
          classList,
          commonClasses,
          widths[1],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {getFloatPrice(`${ticket?.ticketPrice || '$0'}`)}
      </div>

      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[3])}>
        Availability
        <Tooltip
          title={<h1 className="tooltip_title">{`Number of tickets remaining`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'lg:text-center  w-full lg:w-[130px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg h-[56px]',
          classList,
          commonClasses,
          widths[3],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {ticket?.avaliability && !isNaN(Number(ticket?.avaliability))
          ? `${ticket?.avaliability} available`
          : 'N/A'}
      </div>

      <span
        className={clsx(
          'lg:hidden mb-[-10px] registration_tabs_column_title !justify-start',
          widths[4]
        )}
      >
        Details
        <Tooltip
          title={<h1 className="tooltip_title">{`Click for more details about the ticket`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'text-SeabiscuitDark200ThemeColor p-4 rounded-lg w-full lg:w-[unset] flex flex-nowrap',
          classList,
          widths[4],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {ticket?.ticketDescription ? (
          <>
            <div className="w-full line-clamp-1">
              {capitalize(ticket?.ticketDescription, {
                capitalizeAll: false,
              })}
            </div>
            <img
              onClick={() =>
                openSeeMoreModal(
                  capitalize(ticket?.ticketDescription, {
                    capitalizeAll: false,
                  }) as string
                )
              }
              src={'assets/og_icons/FullScreen-1.svg'}
              alt="fullScreenIcon"
              className="w-6 h-6 ml-2 cursor-pointer"
            />
          </>
        ) : (
          'N/A'
        )}
      </div>

      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[5])}>
        Select units
        <Tooltip
          title={
            <h1 className="tooltip_title">{`Select the number of tickets you wish to purchase`}</h1>
          }
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'w-full lg:w-[130px] text-SeabiscuitDark200ThemeColor rounded-lg h-[56px] flex items-center',
          classList,
          widths[5],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {ticket?.avaliability !== 0 ? (
          <Controller
            name={ticket.ticketItemId}
            control={props.ticketItemsForm.control}
            render={({ field: { value, name, onChange } }) => {
              return (
                <AmountInput
                  name={name}
                  onChange={async (value: string) => {
                    setInputValue(value)
                    if (Number(value) > Number(ticket?.avaliability))
                      onChange(Number(ticket?.avaliability))
                    else onChange(Number(value))
                  }}
                  inputClassName="w-full rounded-lg searchableComponent focus:ring-0 lg:p-0 focus:ring-transparent lg:text-center border-none bg-[transparent] h-full"
                  placeholder="Enter units"
                  value={!value ? 0 : value}
                  onBlur={async () => {
                    await props.onInputChangeTicket({
                      currentRow: ticket,
                      setManageInfo: props.setManageInfo,
                      manageInfo: props.manageInfo,
                      isManage: props.isManage,
                    })
                  }}
                />
              )
            }}
          />
        ) : (
          <div className="text-center w-[100%]">N/A</div>
        )}
      </div>
    </div>
  )
}

export default CompetitorEventRegisterTicketsTab
