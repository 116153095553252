/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react'
import { css, keyframes } from '@emotion/react'

//Store
import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'

// Common
import customImageComponent from '../common/CustomImageComponent'

//Helpers
import { getUserFullName } from '../../helpers/helpers'
import { MODAL_CONSTS } from '../../const/modal-const'

interface UserCardModalProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}
export const UserCardModal: FC<UserCardModalProps> = (props) => {
  const profileData = useAppSelector(selectProfileData)
  const [isVisible, setIsVisible] = useState(true)

  const zoomIn = keyframes`
      0% {
        transform: scale(0);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    `
  const zoomOut = keyframes`
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(0);
            opacity: 0;
        }
    `
  const boxStyle = css`
    animation: ${!isVisible ? zoomOut : zoomIn} 0.2s ease-out forwards;
  `

  return (
    <div
      className={'fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/30'}
      onClick={() => {
        setIsVisible(false)
        setTimeout(() => {
          props.handleModal(false, MODAL_CONSTS.USER_CARD)
        }, 200)
      }}
    >
      <div
        css={boxStyle}
        className={
          'group flex flex-col gap-4 justify-center items-center userCompleteProfileCard py-10 relative w-[90%] h-[50vh] max-h-[400px] lg:max-h-[642px] max-w-[639px] overflow-hidden rounded-2xl bg-white cursor-pointer'
        }
      >
        <div className="userCompleteProfileCardOverlay absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-1/2 bg-black lg:rounded-full z-0 w-full h-full lg:w-[118px] lg:h-[118px]"></div>
        <div
          className={
            'w-[100px] h-[100px] lg:w-[206px] lg:h-[206px] userCardImage rounded-full bg-white z-10 shadow-black'
          }
        >
          {customImageComponent(
            profileData?.userProfilePicture,
            getUserFullName(profileData),
            'event-post-horizontal-card-component__image object-cover cursor-pointer swiper-lazy w-full !h-full sliderImage',
            false
          )}
        </div>
        <h4
          className={
            'text-white lg:text-SeabiscuitLightParagraphTextColor text-[14px] z-10 group-hover:text-white'
          }
        >
          {getUserFullName(profileData)}
        </h4>
        <h2
          className={
            'text-SeabiscuitDark200ThemeColor  text-[16px] lg:text-[25px] z-10 font-normal'
          }
        >
          <span className={'group-hover:hidden hidden lg:inline-flex'}>Profile Complete</span>
          <span className={'text-white lg:hidden group-hover:inline-flex underline '}>
            Start Search
          </span>
        </h2>
      </div>
    </div>
  )
}
