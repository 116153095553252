import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { CONST } from '../../../../../const/const'
import useGetEventData from '../../../../../hooks/users/common/useGetEventData'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'
import ManageEventRevenueHeader from '../ManageEventRevenueHeader'
import ManageEventRevenueTabs from '../ManageEventRevenueTabs'
import ManageEventRevenueWrapper from '../ManageEventRevenueWrapper'
import SpectatorRevenueDisplayTabs from './tabs/SpectatorRevenueDisplayTabs'

const SpectatorRevenue = () => {
  const { getAllData } = useGetEventData()
  const { EventDetails } = useAppSelector(selectedEvent)
  const { eventId } = useParams<{ eventId: string }>()

  const [searchValue, setSearchValue] = useState('')
  const [eventTab, setEventTab] = useState(CONST.UI.EVENTS.REVENUE.TABS.ALL.VALUE)

  useEffect(() => {
    if (eventId) {
      getAllData(eventId, [
        'v01_event_fees',
        'v01_event_details',
        'v01_event_registered_users',
        'v01_event_ticketing',
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  return (
    <ManageEventRevenueWrapper
      eventId={eventId}
      setEventTab={setEventTab}
      eventTab={eventTab}
      title={EventDetails?.competitionName ?? 'Unknown'}
    >
      <>
        <div className="w-full flex mb-4 flex-wrap">
          <ManageEventRevenueHeader revenueType={'Spectator Ticket'} />

          <ManageEventRevenueTabs
            eventTab={eventTab}
            setEventTab={setEventTab}
            setSearchValue={setSearchValue}
          />
          <SpectatorRevenueDisplayTabs
            eventId={eventId}
            eventTab={eventTab}
            searchValue={searchValue}
          />
        </div>
      </>
    </ManageEventRevenueWrapper>
  )
}

export default SpectatorRevenue
