import React, { useState, useRef, forwardRef } from 'react'

// Third party
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'

// Helpers
import { getNumbersArrBetween, getReactPickerDate } from '../../../models/interface.helper'
import { IBasicInputProps } from './Input'
import clsx from 'clsx'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

interface ICustomDatePicker {
  /** default is 80, if some number is passed then the height of the target element will be incremented by `incrementBy%` of the select menu */
  incrementBy?: number
  /** default is "true", "false" specifies that the height should not be incremented */
  incrementTargetHeight?: false
  /** Classes for target element */
  targetAttributes?: React.HTMLAttributes<HTMLElement>
  /** target id that you want to add classes passed */
  targetId?: string
}

// Constants
const YEARS = getNumbersArrBetween(1940, new Date().getFullYear())

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const CustomInput = forwardRef<
  HTMLButtonElement,
  {
    placeholderText?: string
    value?: string
    onClick?: () => void
    className?: string
  }
>(({ placeholderText, value, onClick, className }, ref) => {
  return (
    <button
      type="button"
      className={clsx(className, !value && 'opacity-50')}
      onClick={onClick}
      ref={ref}
    >
      {value ? value : (placeholderText ?? 'Select Date')}
    </button>
  )
})
const CustomDatePicker = React.forwardRef<
  any,
  ICustomDatePicker & DatePickerProps & IBasicInputProps
>((props, ref) => {
  // Hooks and vars
  let [yearDropdown, setYearDropdown] = useState(false)
  const yearDropdownRef = useRef<HTMLDivElement>(null)
  let [monthDropdown, setMonthDropdown] = useState(false)

  let localProps = cloneDeep(props)
  let className = `text-left text-[14px] custom-date-picker ${props?.className ?? ''}`

  const scrollYearDown = () => {
    if (yearDropdownRef.current) {
      yearDropdownRef.current.scrollBy({
        top: 100,
        behavior: 'smooth',
      })
    }
  }

  const scrollYearUp = () => {
    if (yearDropdownRef.current) {
      yearDropdownRef.current.scrollBy({
        top: -100,
        behavior: 'smooth',
      })
    }
  }

  localProps.className = clsx(
    'custom-date-picker outline-0 ring-0 rounded-md focus:outline-0 focus:ring-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate w-full',
    className,
    props.textColorOnDisabled,
    props.valid ? props.validValueClassName : props.invalidValueClassName
  )
  localProps.selected = getReactPickerDate(localProps?.selected)

  // Functions
  const handleDatePickerStateChange = async (isOpen: boolean) => {
    await new Promise((resolve) => setTimeout(() => resolve(true), 100))

    if (!props?.targetId) return

    let height = 0
    let valueOfCurrAttrubute: any = null
    let incrementBy = props?.incrementBy ?? 80
    let datePicker = document.querySelector('.react-datepicker')
    let attributes: React.HTMLAttributes<HTMLElement> | null
    let targetElement = document.querySelector(`#${props?.targetId}`) ?? null

    attributes = props?.targetAttributes ?? null
    incrementBy = incrementBy / 100

    if (targetElement) {
      if (attributes)
        Object(attributes).keys((currAttributeKey: keyof typeof attributes) => {
          valueOfCurrAttrubute = attributes?.[currAttributeKey]
          targetElement?.setAttribute?.(currAttributeKey, valueOfCurrAttrubute)
        })

      if (props?.incrementTargetHeight === false) return

      if (isOpen && datePicker) height = datePicker?.clientHeight * incrementBy

      targetElement.setAttribute('style', `margin-bottom: ${height}px`)
    }
  }

  const formatWeekday = (weekdayShort: any) => {
    const customLabels: any = {
      Sunday: 'S',
      Monday: 'M',
      Tuesday: 'T',
      Wednesday: 'W',
      Thursday: 'T',
      Friday: 'F',
      Saturday: 'S',
    }

    return customLabels[weekdayShort]
  }

  return (
    <ReactDatePicker
      {...localProps}
      ref={ref}
      onCalendarOpen={() => handleDatePickerStateChange(true).then()}
      onCalendarClose={() => handleDatePickerStateChange(false).then()}
      onKeyDown={(e) => e.preventDefault()}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth, changeMonth, changeYear }) => {
        return (
          <div className="px-4 flex justify-between items-center">
            <div className="flex py-4">
              <div className="underline hover:no-underline font-semibold text-SeabiscuitDark200ThemeColor relative inline-block">
                <span
                  onClick={() => {
                    setMonthDropdown(!monthDropdown)
                    setYearDropdown(false)
                  }}
                  className="cursor-pointer"
                >
                  {moment(date).format('MMMM')}
                </span>
                {monthDropdown ? (
                  <div className="max-h-[150px] w-fit flex-col overflow-auto absolute bg-white border border-solid border-SeabiscuitDark200ThemeColor rounded-md text-left p-[5px_10px_0px_10px] cursor-pointer">
                    {MONTHS.map((curr, index) => {
                      return (
                        <div
                          onClick={() => {
                            changeMonth(index)
                            setMonthDropdown(false)
                          }}
                          className="mb-1"
                        >
                          {curr}
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </div>
              <div className="underline hover:no-underline font-semibold text-SeabiscuitDark200ThemeColor relative pl-1.5 inline-block">
                <span
                  onClick={() => {
                    setYearDropdown(!yearDropdown)
                    setMonthDropdown(false)
                  }}
                  className="cursor-pointer"
                >
                  {moment(date).format('YYYY')}
                </span>
                {yearDropdown ? (
                  <div className="relative">
                    <div className="absolute border border-solid bg-white border-SeabiscuitDark200ThemeColor h-[182px] w-[52px] rounded-md">
                      <button
                        type="button"
                        onClick={scrollYearUp}
                        className="absolute z-10 top-[-6px] left-1/2 transform -translate-x-1/2 text-SeabiscuitDark200ThemeColor p-1 rounded-full"
                      >
                        <ChevronUpIcon className="w-8 h-8" />
                      </button>
                      <div
                        className="max-h-[130px] top-[23px] w-fit flex-col overflow-auto absolute bg-white border-SeabiscuitDark200ThemeColor rounded-md text-left p-[5px_10px_0px_10px] cursor-pointer scrollbar-thin scrollbar-thumb-SeabiscuitDark200ThemeColor scrollbar-track-SeabiscuitGray500ThemeColor"
                        ref={yearDropdownRef}
                      >
                        {YEARS.slice()
                          .reverse()
                          .map((curr, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  changeYear(curr)
                                  setYearDropdown(false)
                                }}
                                className="mb-1"
                              >
                                {curr}
                              </div>
                            )
                          })}
                      </div>
                      <button
                        type="button"
                        onClick={scrollYearDown}
                        className="absolute z-10 top-[146px] left-1/2 transform -translate-x-1/2 text-SeabiscuitDark200ThemeColor p-1 rounded-full"
                      >
                        <ChevronDownIcon className="w-8 h-8" />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <button
                type="button"
                onClick={decreaseMonth}
                className="w-fit h-fit px-[1px] rounded-full border border-solid border-SeabiscuitGray500ThemeColor"
              >
                <KeyboardArrowLeft fontSize="small" className="text-SeabiscuitDark200ThemeColor" />
              </button>

              <button
                type="button"
                onClick={increaseMonth}
                className="w-fit h-fit px-[1px] rounded-full border border-solid border-SeabiscuitGray500ThemeColor"
              >
                <KeyboardArrowRight fontSize="small" className="text-SeabiscuitDark200ThemeColor" />
              </button>
            </div>
          </div>
        )
      }}
      formatWeekDay={formatWeekday}
      popperPlacement={props.popperPlacement ?? 'bottom'}
      wrapperClassName={clsx(props.wrapperClassName, 'hover:opacity-70 transition-all flex-1')}
      customInput={
        <CustomInput className={props.className} placeholderText={props.placeholderText} />
      }
    />
  )
})

export default CustomDatePicker
