import React, { FC, useEffect } from 'react'

import { IonItem } from '@ionic/react'
import { useParams } from 'react-router'
import clsx from 'clsx'

import FirestoreService from '../../../services/firestoreService'
import { ispublished, setisPublished } from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import { EventModel } from '../../../models/events/event.model'
import { IEventInterface } from '../../../models/events/event.interface'

import { CONFIG } from '../../../config/config'
import { CONST } from '../../../const/const'

import './ShowsDetailPage.css'

interface ShowsDetailPageHeaderSectionProps {
  event: any
  eventTab: string
  onSetEventTab: (tab: string) => void
}

interface TabButtonProps {
  icon: string
  activeIcon: string
  label: string
  event: IEventInterface
  tab: { SEQUENCE: number; VALUE: string; DISABLED: boolean }
  eventTab: string
  onSetEventTab: (value: string) => void
}

const TabButton: FC<TabButtonProps> = ({
  icon,
  activeIcon,
  label,
  event,
  onSetEventTab,
  eventTab,
  tab,
}) => {
  const eventFormIndex = (function () {
    let return_value = tab.SEQUENCE
    for (const [key, value] of Object.entries(CONST.UI.EVENTS.CREATE_EDIT.TABS)) {
      if (0) console.log({ key })
      if (value.VALUE === event?.eventFormState) {
        return_value = value.SEQUENCE
        break
      }
    }
    return return_value
  })()

  const setTabStyle = (value: string, sequence: number, event_index: number) => {
    let style = `px-4 text-center h-10 relative min-w-fit text-sm transition ease-in duration-200 text-md focus:outline-none rounded-full`

    if (eventTab === value || sequence < event_index) {
      if (eventTab === value) {
        return `${style} bg-white text-SeabiscuitMainThemeColor`
      } else if (sequence < event_index) {
        return `${style} text-SeabiscuitMainThemeColor`
      }
    }

    return `${style} bg-SeabiscuitGrayThemeColor`
  }

  return (
    <button
      type="button"
      disabled={tab.DISABLED}
      onClick={() => {
        if (tab.SEQUENCE <= eventFormIndex || CONFIG.EVENT.BYPASS_FORM_SEQUENCING)
          onSetEventTab(tab.VALUE)
      }}
      className={setTabStyle(tab.VALUE, tab.SEQUENCE, eventFormIndex)}
    >
      <div className="flex ion-align-items-center">
        <div className="inline-block shrink-0">
          <img alt="icons" className="mr-2 w-5" src={eventTab === tab.VALUE ? activeIcon : icon} />
        </div>
        <div
          className={clsx(
            'pl whitespace-nowrap',
            eventTab !== tab.VALUE && 'text-SeabiscuitDark200ThemeColor'
          )}
        >
          {label}
        </div>
      </div>
    </button>
  )
}
const ShowsDetailPageTabControlSection: React.FC<ShowsDetailPageHeaderSectionProps> = ({
  event,
  eventTab,
  onSetEventTab,
}) => {
  const isPublished = useAppSelector(ispublished)
  const dispatch = useAppDispatch()

  const eventId = useParams<{ eventId: string }>()?.eventId

  const handleCheckPublished = async (eventId: string) => {
    if (eventId) {
      const eventSnapshot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
        eventId
      )

      if (eventSnapshot.exists()) {
        const event = EventModel.fromFirestoreDoc(eventSnapshot).toObject()
        dispatch(setisPublished(event.isPublished && event.status !== 'draft'))
      }
    }
  }

  useEffect(() => {
    if (eventId) handleCheckPublished(eventId).then()
  }, [eventId])

  return (
    <IonItem lines="none" className="bg-red-400 editEventTabs w-full md:w-[initial]">
      <div className="Innrclass bg-SeabiscuitGrayThemeColor w-full">
        <div className="flex overflow-x-auto gap-x-2 pb-3 tabScroll bg-SeabiscuitGrayThemeColor w-full manage_event_tabs_container">
          <TabButton
            label="Event Details"
            icon="/assets/cp_icons/RegularDocument-1.svg"
            activeIcon="/assets/cp_icons/RegularDocument-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Payments"
            icon="/assets/cp_icons/MagneticCard-1.svg"
            activeIcon="/assets/og_icons/MagneticCard-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Entries"
            icon="/assets/cp_icons/Prize-1.svg"
            activeIcon="/assets/cp_icons/Prize-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Fees"
            icon="/assets/cp_icons/UsDollarCircled1.svg"
            activeIcon="/assets/cp_icons/UsDollarCircled.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Tickets"
            icon="/assets/cp_icons/TwoTickets-1.svg"
            activeIcon="/assets/cp_icons/Two Tickets-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Staff"
            icon="/assets/cp_icons/People-1.svg"
            activeIcon="/assets/cp_icons/People-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Scheduling"
            icon="/assets/cp_icons/Clock-1.svg"
            activeIcon="/assets/cp_icons/Clock-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Policies"
            icon="/assets/cp_icons/Scroll-1.svg"
            activeIcon="/assets/cp_icons/Scroll-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Paperwork"
            icon="/assets/cp_icons/Pen-1.svg"
            activeIcon="/assets/cp_icons/Pen-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Required fields"
            icon="/assets/cp_icons/Error-1.svg"
            activeIcon="/assets/cp_icons/Error-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUIRED_FIELDS}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          <TabButton
            label="Questions"
            icon="/assets/cp_icons/Question-1.svg"
            activeIcon="/assets/cp_icons/Question-2.svg"
            tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS}
            event={event}
            eventTab={eventTab}
            onSetEventTab={onSetEventTab}
          />

          {!isPublished && (
            <TabButton
              label="Publish"
              icon="/assets/cp_icons/publish-2.svg"
              activeIcon="/assets/cp_icons/publish-4.svg"
              tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH}
              event={event}
              eventTab={eventTab}
              onSetEventTab={onSetEventTab}
            />
          )}

          {!isPublished && (
            <TabButton
              label="Send Notifications"
              icon="/assets/cp_icons/Map-2.svg"
              activeIcon="/assets/cp_icons/Map-4.svg"
              tab={CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS}
              event={event}
              eventTab={eventTab}
              onSetEventTab={onSetEventTab}
            />
          )}
        </div>
      </div>
    </IonItem>
  )
}

export default ShowsDetailPageTabControlSection
