import { createSlice, current } from '@reduxjs/toolkit'
import { IEventTickets } from '../../models/event-details/event-details.interface'
import {
  IEventDetailData,
  IFees,
  IPaperworkTab,
} from '../../models/event-drafts/event-draft.interface'
import { TEventRegisteredUsers } from '../../models/event-registered-users/event-registered-users.interface'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import { IEventInterface } from '../../models/events/event.interface'
import { EventModel } from '../../models/events/event.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { ITeamMember } from '../../models/users/user.interface'
import { IReduxStatus, RootState } from '../store'
import { ISpectatorTickets } from '../../models/spectator-tickets/spectator-tickets.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { getEventsThunk } from './thunks/getEventsThunk'
import { toastFunctionsObj } from '../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../const/messages-const'
import { IRecipientInterface } from '../../models/recipients/recipients.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'

export interface IScratch {
  eventId: string | null
  scratchViewVisibility: boolean
  registrationId: string | null
  scratchType: 'fees' | 'event' | 'ticket' | null
}

export interface IEventsRegisterData {
  fees: IFees[]
  sign: IPaperworkTab
  horses: IHorseData[]
  tickets: IEventTickets[]
  paperwork: IPaperworkTab
  events: IEventDetailData[]
  teamMembers: ITeamMember[]
  signatories: ITeamMember[]
}

type EventSteps = {
  step: number
  detailPage: boolean
}

export interface ISelectedEvent {
  Event: IEventInterface | null
  EventPriceList: any | null
  EventRegisteredUsers: TEventRegisteredUsers[]
  basicEventDetails: {
    id: string | null
    owner: string | null
    status: string | null
    created: Date | string | null
    modified: Date | string | null
    registrationPrice: number | null
    registrationPriceAlias: string | null
    description: IEventReviewPublish['briefDescription'] | null
  }
  EventSpectators: ISpectatorTickets[]
  EventFees: IEventReviewPublish['EventFees'] | null
  EventStaff: IEventReviewPublish['EventStaff'] | null
  EventTickets: IEventReviewPublish['EventTickets'] | null
  EventRegistrationTickets: IRegistrationTicketInterface[]
  EventDetails: IEventReviewPublish['EventDetails'] | null
  EventSchedule: IEventReviewPublish['EventSchedule'] | null
  EventPolicies: IEventReviewPublish['EventPolicies'] | null
  EventPaperwork: IEventReviewPublish['EventPaperwork'] | null
  EventPaymentSettings: IEventReviewPublish['EventPaymentSettings'] | null
  EventRequiredFields: IEventReviewPublish['EventRequiredFields'] | null
  EventQuestions: IEventReviewPublish['EventQuestions'] | null
  revenueCustomers?: IRecipientInterface[]
}
interface IEventState {
  events: {
    hasMore: boolean
    status: IReduxStatus
    message: string | null
    cursorId: string | null
    data: IEventInterface[]
    followingCursorId: string | null
    fetchMoreStatus: IReduxStatus
  }
  organizer: {
    events: EventModel[]
    filteredEvents: EventModel[]
  }
  scratch: IScratch
  tabs: Array<string>
  filterEvents: Object
  eventSteps: EventSteps
  selectedEvent: ISelectedEvent
  editedEvent: IEventInterface | null
  filteredEvents: IEventInterface[]
  registerEvents: IEventsRegisterData
  publishedEvent: IEventReviewPublish | null
  isPublished: boolean
  registeredUsersByDay: IRegistrationByDayInterface[]
  editEvent: {
    filesToUpload: Array<string>
    eventId: string
    type: string
  }
  paymentDetails: {
    registrations: number
    totalRevenue: number
    completeRevenue: number
  }
  exhibitor_team: {
    loading: boolean
    document_data: any[]
    member_data: any[]
    resend_email: any[]
  }
  loading: boolean
}

export const initialSystemEventsState: IEventState = {
  events: {
    data: [],
    message: null,
    hasMore: true,
    cursorId: null,
    status: 'loading',
    fetchMoreStatus: 'idle',
    followingCursorId: null,
  },
  editedEvent: null,
  publishedEvent: null,
  isPublished: false,
  scratch: {
    eventId: null,
    scratchType: null,
    scratchViewVisibility: false,
    registrationId: null,
  },
  filteredEvents: [],
  registeredUsersByDay: [],
  organizer: { events: [], filteredEvents: [] },
  selectedEvent: {
    revenueCustomers: [],
    basicEventDetails: {
      id: null,
      owner: null,
      status: null,
      created: null,
      modified: null,
      description: null,
      registrationPrice: null,
      registrationPriceAlias: null,
    },
    Event: null,
    EventFees: null,
    EventStaff: null,
    EventTickets: null,
    EventDetails: null,
    EventSchedule: null,
    EventPolicies: null,
    EventSpectators: [],
    EventPaperwork: null,
    EventPriceList: null,
    EventRegisteredUsers: [],
    EventPaymentSettings: null,
    EventRequiredFields: {
      requiredFields: [],
      requiredHorseFields: [],
    },
    EventQuestions: [],
    EventRegistrationTickets: [],
  },
  tabs: [],
  filterEvents: {},
  registerEvents: {
    fees: [],
    sign: {},
    events: [],
    horses: [],
    tickets: [],
    paperwork: {},
    teamMembers: [],
    signatories: [],
  },
  eventSteps: {
    detailPage: false,
    step: 0,
  },
  editEvent: {
    filesToUpload: [],
    eventId: '',
    type: '',
  },
  paymentDetails: {
    registrations: 0,
    totalRevenue: 0,
    completeRevenue: 0,
  },
  exhibitor_team: {
    loading: false,
    member_data: [],
    document_data: [],
    resend_email: [],
  },
  loading: false,
}

const eventSlice = createSlice({
  name: 'events',
  initialState: initialSystemEventsState,
  reducers: {
    setRegisteredUsersByDay(state, action) {
      state.registeredUsersByDay = action.payload
    },
    setRevenueCustomers: (state, action) => ({
      ...state,
      selectedEvent: { ...state.selectedEvent, revenueCustomers: action.payload || [] },
    }),
    setPublishedEventData(state, { payload }: { payload: any }) {
      state.publishedEvent = payload
    },
    setisPublished(state, { payload }: { payload: any }) {
      state.isPublished = payload
    },
    setOrganizerFilteredEvents(state, { payload }: { payload: EventModel[] }) {
      state.organizer.filteredEvents = payload
    },
    setEventMailSend(state, { payload }: { payload: IEventReviewPublish['EventStaff'] }) {
      state.selectedEvent.EventStaff = payload
    },
    resetSelectedEventAc(state) {
      state.selectedEvent = initialSystemEventsState.selectedEvent
    },
    setSelectedEvent(state, { payload }: { payload: ISelectedEvent }) {
      state.selectedEvent = payload
    },

    setEditedEvent(state, { payload }: { payload: IEventInterface }) {
      state.editedEvent = payload
    },
    setRegisterEventData(state, { payload }) {
      if (payload?.reset === true)
        state.registerEvents = { ...initialSystemEventsState.registerEvents }
      else state.registerEvents = payload
    },
    setSelectedEventKey(
      state,
      { payload }: { payload: { key: keyof ISelectedEvent; value: any } }
    ) {
      if (!payload.key) return
      state.selectedEvent[payload.key] = payload.value
    },
    setRegisterTeamMemberData(state, { payload }) {
      state.registerEvents.teamMembers = payload
    },
    setRegisterPaperworkData(state, { payload }) {
      state.registerEvents.paperwork = payload
    },
    setRegisterHorseData(state, { payload }) {
      if (payload && Array.isArray(payload)) state.registerEvents.horses = payload
      else state.registerEvents.horses = [...state.registerEvents.horses, payload]
    },
    setBasicEventDetails(state, { payload }: { payload: ISelectedEvent['basicEventDetails'] }) {
      state.selectedEvent.basicEventDetails = payload
    },
    setEventSteps(state, { payload }: { payload: { detailPage: boolean; step: number } }) {
      state.eventSteps.detailPage = payload.detailPage
      state.eventSteps.step = payload.step
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    setScratchEventId(state, { payload }: { payload: IScratch['eventId'] }) {
      state.scratch.eventId = payload
    },
    setScratchRegistrationId(state, { payload }: { payload: IScratch['registrationId'] }) {
      state.scratch.registrationId = payload
    },
    setScratchType(state, { payload }: { payload: IScratch['scratchType'] }) {
      state.scratch.scratchType = payload
    },
    resetScratchItems(state) {
      state.scratch = {
        ...initialSystemEventsState['scratch'],
        eventId: state.scratch.eventId,
        registrationId: state.scratch.registrationId,
      }
    },
    setScratchViewVisibility(state, { payload }: { payload: boolean }) {
      state.scratch.scratchViewVisibility = payload
    },
    setGetEventFetchMoreStatus(state, { payload }: { payload: IReduxStatus }) {
      state.events.fetchMoreStatus = payload
    },
    resetEvents(state) {
      state.events = initialSystemEventsState.events
    },
  },
  extraReducers(builder) {
    builder.addCase(getEventsThunk.pending, (state, action) => {
      if (action.meta.arg.fetchMore) {
        state.events.fetchMoreStatus = 'loading'
      } else {
        state.events.status = 'loading'
      }
    })
    builder.addCase(getEventsThunk.fulfilled, (state, { payload }) => {
      const events = current(state).events.data

      state.events.message = payload.message
      state.events.status = 'fulfilled'
      state.events.fetchMoreStatus = 'fulfilled'
      state.events.data = payload.events

      if (payload.fetchMore) {
        state.events.data = [...events, ...payload.events]
      } else {
        state.events.data = payload.events
      }

      state.events.hasMore = payload.hasMore
      state.events.cursorId = payload.cursorId
      state.events.followingCursorId = payload.followingCursorId
    })
    builder.addCase(getEventsThunk.rejected, (state, action) => {
      if ((action.payload as any).code === 'ERR_CANCELED') {
        state.events.message = action.error.message ?? 'Recieved other request'

        if (action.meta.arg.fetchMore) {
          state.events.fetchMoreStatus = 'loading'
        } else {
          state.events.status = 'loading'
        }
      } else {
        state.events.message = action.error.message ?? null

        if (action.meta.arg.fetchMore) {
          state.events.fetchMoreStatus = 'failed'
        } else {
          state.events.status = 'failed'
        }

        toastFunctionsObj.error({
          message: state.events.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      }

      state.events.hasMore = false
    })
  },
})

export const {
  setRevenueCustomers,
  setLoading,
  setEventSteps,
  resetEvents,
  setScratchType,
  setEditedEvent,
  setSelectedEvent,
  resetSelectedEventAc,
  setScratchEventId,
  resetScratchItems,
  setSelectedEventKey,
  setRegisterEventData,
  setBasicEventDetails,
  setRegisterHorseData,
  setPublishedEventData,
  setisPublished,
  setRegisterPaperworkData,
  setEventMailSend,
  setScratchRegistrationId,
  setRegisterTeamMemberData,
  setScratchViewVisibility,
  setOrganizerFilteredEvents,
  setRegisteredUsersByDay,
} = eventSlice.actions

export const selectRegisteredUsersByDay = (state: RootState) => state?.events.registeredUsersByDay

export const selectScratch = (state: RootState) => state?.events.scratch
export const selectEditedEvent = (state: RootState) => state?.events.editedEvent
export const selectEventLoading = (state: any): string[] => state?.events.loading
export const selectOrganizerEvents = (state: RootState) => state?.events?.organizer
export const selectedEvent = (state: RootState) => state?.events?.selectedEvent ?? null
export const selectEvents = (state: RootState) => state?.events.events
export const selectPublishedEvent = (state: RootState) => state?.events.publishedEvent ?? null
export const ispublished = (state: RootState) => state?.events.isPublished ?? false
export const selectEventDetails = (state: RootState): any =>
  state?.events?.selectedEvent?.EventDetails
export const selectRegisterEventData = (state: RootState): IEventsRegisterData =>
  state?.events?.registerEvents ?? {}
export const selectEventPaymentSettings = (state: RootState) =>
  state?.events?.selectedEvent?.EventPaymentSettings
export const selectRegisterEventTeamMembers = (state: RootState): ITeamMember[] =>
  state?.events?.registerEvents.teamMembers ?? []
export const selectRegisterEventPaperworksData = (state: RootState): IPaperworkTab =>
  state?.events?.registerEvents.paperwork ?? {}
export const selectBasicEventDetails = (state: RootState): ISelectedEvent['basicEventDetails'] =>
  state?.events.selectedEvent.basicEventDetails

export default eventSlice.reducer
