import TimeLib from '../../../../lib/Time'
import { IRecipientInterface } from '../../../../models/recipients/recipients.interface'
import { PAYMENT_STATUS_VALUES} from "../../../../types/payment";

export const DATA_FIRESTORE_V01_RECEPIENTS = {
  NAME: 'v01_recipients',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    HAS_REFUND_DOCS: {
      KEY: 'hasRefundDocs',
      VALUES: {
        DEFAULT: false,
        NO: false,
        YES: true,
      },
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      VALUES: {
        SEEN: 'seen' as IRecipientInterface['refundStatus'],
        DEFAULT: null as IRecipientInterface['refundStatus'],
        PENDING: 'pending' as IRecipientInterface['refundStatus'],
      },
    },

    CARD_NUMBER: {
      KEY: 'cardNo',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DATE: {
      KEY: 'registrationDate',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_PROFILE_PICTURE: {
      KEY: 'recipientProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME: {
      KEY: 'recipientName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_ID: {
      KEY: 'recipientId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME_N_GRAM: {
      KEY: 'recipientNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    COMMENTS: {
      KEY: 'comments',
      VALUES: {
        DEFAULT: [],
      },
    },

    USER_NAME: {
      KEY: 'userName',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_NAME_N_GRAM: {
      KEY: 'userNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    AMOUNT_REFUNDED: {
      KEY: 'amountRefunded',
      VALUES: {
        DEFAULT: 0,
      },
    },

    AMOUNT_SCRATCHED: {
      KEY: 'amountScratched',
      VALUES: {
        DEFAULT: 0,
      },
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: PAYMENT_STATUS_VALUES,
    },

    AMOUNT_PAID: {
      KEY: 'amountPaid',
      VALUES: {
        DEFAULT: 0,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    REFUNDEDAT: {
      KEY: 'refundedAt',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
