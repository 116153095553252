import React from 'react'

import { IconUser } from '../../../icons/IconUser'
import { IconUserName } from '../../../icons/IconUserName'
import { IconEmailImg } from '../../../icons/IconEmailImg'
import { IconDate } from '../../../icons/IconDate'
import { NationalityIconImg } from '../../../icons/NationalityIconImg'
import { IconHorseImg } from '../../../icons/IconHorseImg'
import { IconBreadImg } from '../../../icons/IconBreadImg'
import { IconGenderImg } from '../../../icons/IconGenderImg'
import { IconColoringImg } from '../../../icons/IconColoringImg'

import { CountryList } from '../../../../fakeData/countryList'
import { disciplineData } from '../../../../fakeData/disciplineList'
import { horseBreedList } from '../../../../fakeData/horseBreedList'
import { SexRestOptions } from '../../../customStyles/ReactSelectCustomStyle'
import { horseColorList } from '../../../../fakeData/horseColorList'

export const initialUserInputs = {
  userFullName: {
    name: 'userFullName',
    value: '',
    required: true,
    placeholder: 'Enter full name...',
    type: 'text',
    label: 'Full Name',
  },
  userName: {
    name: 'userName',
    value: '',
    required: true,
    placeholder: 'Enter user name...',
    type: 'text',
    label: 'User Name',
  },
  email: {
    name: 'email',
    value: '',
    required: true,
    placeholder: 'Enter address...',
    type: 'email',
    label: 'Email address',
  },
  date: {
    name: 'date',
    value: '',
    required: true,
    placeholder: 'Select date...',
    type: 'date',
    label: 'Date of birth',
  },
  nationality: {
    name: 'nationality',
    value: '',
    required: false,
    placeholder: 'Select nationality...',
    type: 'select',
    label: 'Nationality',
    selectData: CountryList,
  },
  discipline: {
    name: 'discipline',
    value: '',
    required: false,
    placeholder: 'Select discipline....',
    type: 'select',
    label: 'Discipline',
    selectData: disciplineData,
  },
}

export const inputUserIcons = {
  userFullName: <IconUser />,
  userName: <IconUserName />,
  email: <IconEmailImg />,
  date: <IconDate />,
  nationality: <NationalityIconImg />,
  discipline: <IconHorseImg />,
}

export const initialHorseInputs = {
  name: {
    name: 'name',
    value: '',
    required: true,
    placeholder: 'Enter name...',
    type: 'text',
    label: 'Name',
  },
  breed: {
    name: 'breed',
    value: '',
    required: true,
    placeholder: 'Select breed...',
    type: 'select',
    label: 'Breed',
    selectData: horseBreedList,
  },
  gender: {
    name: 'gender',
    value: '',
    required: true,
    placeholder: 'Select gender...',
    type: 'select',
    label: 'Gender',
    selectData: SexRestOptions,
  },
  coloring: {
    name: 'coloring',
    value: '',
    required: true,
    placeholder: 'Select coloring...',
    type: 'select',
    label: 'Coloring',
    selectData: horseColorList,
  },
}

export const inputHorseIcons = {
  name: <IconHorseImg />,
  breed: <IconBreadImg />,
  gender: <IconGenderImg />,
  coloring: <IconColoringImg />,
}
