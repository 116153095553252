import React, { useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Autorenew, AutorenewRounded, CameraAlt, HelpOutline } from '@mui/icons-material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep, sortBy } from 'lodash'
import Select, { GroupBase, OptionProps, components } from 'react-select'

import useToasterHelper from '../../helpers/ToasterHelper'

import FirestoreService from '../../services/firestoreService'

import { horseSchema } from '../../validations'
import {
  HeightRestOptions,
  SexRestOptions,
  customStyles,
} from '../../components/customStyles/ReactSelectCustomStyle'
import formFunctions from '../../components/ui/form/form-functions/formFunctions'

import CustomDatePicker from '../../components/common/inputs/CustomDatePicker'
import FormHeader from '../../components/ui/form/form-header/FormHeader'
import { HorseNumberItem } from './components/HorseNumberItem'

import { getConvertedData, getReactPickerDate } from '../../models/interface.helper'
import FirebaseStorageService from '../../services/storageService'

import { selectUserId } from '../../store/user/userSlice'
import { setRegisterHorseData } from '../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  setIsMyHorse,
  setSelectedHorse,
  setSelectedHorseMapping,
  setSelectedHorseStatus,
  updateHorseProfileImage,
} from '../../store/horses/horseSlice'

import { IHandleModal } from '../../layout/mainlayout/MainLayout'
import { HorseModel } from '../../models/horse/horse.model'
import { IUserHorseMappingInterface } from '../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../models/user-horse-mapping/userHorseMapping.model'
import { IOption } from '../../components/inputs/InputNewMemberRow'
import { IHorseData } from '../../models/horse/horse.interface'
import { ISelect } from '../../models/users/user.interface'

import { zoneList } from '../../fakeData/ZoneList'
import { CountryList } from '../../fakeData/countryList'
import { disciplineData } from '../../fakeData/disciplineList'
import { horseBreedList } from '../../fakeData/horseBreedList'
import { horseColorList } from '../../fakeData/horseColorList'
import { horseMarkingList } from '../../fakeData/horseMarkingList'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { IMAGE_CONSTS } from '../../const/image-const'
import { LOCALSTORAGE_CONST } from '../../const/local-storage-const'
import { ROUTES_CONST } from '../../const/routes-const'

interface IProps {
  show?: boolean
  isView?: boolean
  horseId: string | null
  selectedHorseIndex: number | null
  handleModal?: IHandleModal['handleModal']
}

type MyOptionType = {
  dark_icon: string
  label: string
  light_icon: string
  value: string
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const DEFAULT_HORSE_PIC = IMAGE_CONSTS.PLACEHOLDERS.HORSE_PROFILE_PIC

const AgeVerifiedOption = [
  { label: 'Age Verified', value: 'Yes' },
  { label: 'Age Not Verified', value: 'No' },
]

const horseFormDefaultValues = new HorseModel().toObject()

const ManageHorseForm = (props: IProps) => {
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const { id } = useParams<{ id: string }>()

  const userId = useAppSelector(selectUserId)
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IHorseData>({
    mode: 'onChange',
    resolver: yupResolver(horseSchema),
    defaultValues: { ...horseFormDefaultValues },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'horsesOther',
  })

  const [loading, setLoading] = useState(false)
  const [horsePicLoading, setHorsePicLoading] = useState(false)
  const [saved, isSaved] = useState(true)
  const [isAddHorse, setIsAddHorse] = useState(false)

  const formStyles = formFunctions.getFormStyles()

  useEffect(() => {
    const gerCurrentHorse = async () => {
      let horse_

      if (id) {
        const horseSnapshot = await FirestoreService.getItem(COLLECTIONS.HORSES.NAME, id)
        horse_ = HorseModel.fromFirestoreDoc(horseSnapshot).toObject()
        setIsAddHorse(false)
      } else {
        horse_ = new HorseModel().toObject()
        setIsAddHorse(true)
      }
      reset(horse_)
    }
    gerCurrentHorse().then()
  }, [id])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Updates the horse in db
   */
  const onSubmit = async (data: IHorseData) => {
    let errorOccured = false
    let mapping: IUserHorseMappingInterface | null = null
    setLoading(true)
    try {
      let userFilterData = new HorseModel(data).toObject()

      if (isAddHorse && !data.id) {
        let horseToCreate: IHorseData = {
          ...userFilterData,
          horseStatus: '1',
          horseOwnerId: userId,
          horseId: data.horseId ?? uuidv4(),
        }
        const ref = await FirestoreService.createItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          horseToCreate
        )
        horseToCreate.id = ref.id
        horseToCreate = getConvertedData(horseToCreate)

        let docToSave = new UserHorseMappingModel({
          userId,
          horseId: (horseToCreate ? horseToCreate.id : null) ?? null,
          isMyHorse: true,
          horseOwnerId: userId,
          horseName: horseToCreate.horseName,
          horseZone: horseToCreate.horseZone,
          horseSelectedForCompeletion: true,
          horseNameNGram: horseToCreate?.horseNameNGram,
          horseDiscipline: horseToCreate.horseDiscipline,
          horseProfilePicture: horseToCreate.horseProfilePicture,
          created: new Date(),
        })

        mapping = docToSave.cloneDeep().toFirestore()

        mapping!.id = (
          await FirestoreService.createItem(
            COLLECTIONS.USER_HORSE_MAPPING.NAME,
            docToSave.toFirestore()
          )
        )?.id

        mapping = getConvertedData(mapping!)

        dispatch(setSelectedHorseMapping(mapping!))
        dispatch(setRegisterHorseData(horseToCreate))
        dispatch(
          setSelectedHorse({
            ...horseToCreate,
          })
        )
        dispatch(setIsMyHorse(true))
        dispatch(setSelectedHorseStatus('fulfilled'))
        toastFunctions.success({ message: MESSAGES_CONST.HORSE_CREATED })
      } else {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          userFilterData.id,
          userFilterData
        )
        props?.handleModal?.(false, 'horse')
        toastFunctions.success({ message: MESSAGES_CONST.HORSE_UPDATE_SUCCESSFULLY })
      }
    } catch (error: any) {
      errorOccured = true
      console.error(error?.message ?? error, 'error')

      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      isSaved(true)
      setLoading(false)

      if (!errorOccured) {
        /* Redirecting the user to, from where he have came */
        let cameFrom = '/add-horse'

        if (cameFrom && !cameFrom.includes(ROUTES_CONST.REGISTER_EVENT.URL)) {
          cameFrom = ROUTES_CONST.ACCOUNT_SETTINGS.URL
          localStorage.setItem('tab_type', '2')
        }

        if (cameFrom) {
          localStorage.removeItem(LOCALSTORAGE_CONST.ADD_HORSE_FROM)
          localStorage.setItem('tab_type', '2')
        }
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Uploads the file in firebase storage
   */
  async function fileChangedHandler(files: FileList) {
    let downloadUrl: null | string = null
    const file = files[0]
    let selectedHorse_ = cloneDeep(watch())

    setHorsePicLoading(true)

    if (!file) {
      toastFunctions.info({ message: 'No file selected.' })
      return null
    }

    if (!id) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        setHorsePicLoading(false)
        watch('horseProfilePicture', e.target.result)
      }
      reader.readAsDataURL(file)
      return null
    }

    try {
      downloadUrl =
        ((await FirebaseStorageService.uploadFile(
          file,
          `${CONST.DATA.STORAGE.HORSE.HORSE_PROFILE_IMAGE_URL.PREFIX}/${id}`
        )) as string) ?? null

      if (selectedHorse_) {
        selectedHorse_.horseProfilePicture = downloadUrl as any

        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          id,
          selectedHorse_
        )

        dispatch(setSelectedHorse(getConvertedData(selectedHorse_)))

        if (!isAddHorse)
          dispatch(
            updateHorseProfileImage({
              image: downloadUrl,
              index: props.selectedHorseIndex,
            })
          )

        setValue('horseProfilePicture', downloadUrl)
        toastFunctions.success({ message: MESSAGES_CONST.HORSE_PIC_UPDATED })
      }
      return downloadUrl
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      console.error({ error })
      return downloadUrl
    } finally {
      setHorsePicLoading(false)
    }
  }

  const CustomOption: React.ComponentType<
    OptionProps<MyOptionType, false, GroupBase<MyOptionType>>
  > = (props) => {
    const { label, isDisabled } = props
    return isDisabled ? (
      <div className="border border-b-[1px] border-dashed px-2"></div>
    ) : (
      <components.Option {...props}>{label}</components.Option>
    )
  }

  return (
    <>
      <FormHeader
        title="Horse data"
        description={props.isView ? '' : `${isAddHorse ? 'Add' : 'Update'} your horses data`}
        headerButtonsContainer={
          props.isView ? (
            <></>
          ) : (
            <div className="flex items-center gap-2">
              {saved ? (
                <button
                  type="button"
                  onClick={() => isSaved(false)}
                  className="items-center w-[150px] h-[45px] bg-white border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
                >
                  Edit
                </button>
              ) : (
                <button
                  type="button"
                  disabled={loading}
                  onClick={handleSubmit(onSubmit)}
                  className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-white saveBtn"
                >
                  {loading ? (
                    <AutorenewRounded fontSize="small" className="animate-spin" />
                  ) : (
                    'Save Changes'
                  )}
                </button>
              )}
            </div>
          )
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="horseEditForm">
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <div className="md:w-[38%] flex-1 flex items-center">
              <img src="/assets/img/dark/Image.png" alt="imgIcon" />
              <p className="ml-2">Profile picture</p>
            </div>
            <div className="w-full md:w-[60%]">
              <div className="relative h-[90px] w-[90px] cursor-pointer rounded-full flex items-center justify-center bg-[#d3daee54] p-1">
                <input
                  disabled={horsePicLoading || saved}
                  type="file"
                  accept=".jpeg, .png, .jpg"
                  ref={fileInputRef}
                  onChange={(event) => event.target.files && fileChangedHandler(event.target.files)}
                  className="UserProfilePicture absolute h-full w-full opacity-0 left-0 right-0 top-0 bottom-0 z-40 cursor-pointer"
                />

                {horsePicLoading ? (
                  <Autorenew className="animate-spin" />
                ) : (
                  <img
                    src={
                      watch('horseProfilePicture') === '' || !watch('horseProfilePicture')
                        ? DEFAULT_HORSE_PIC
                        : watch('horseProfilePicture')
                    }
                    className="object-cover rounded-full h-full w-full"
                    alt="profile_picture"
                  />
                )}

                {!saved && (
                  <div className="border border-SeabiscuitGray500ThemeColor text-[#8C95AC]  bg-[#8e95aa]  p-1.5 pl-2 pr-2 rounded-full absolute right-0 bottom-0  w-[45%]">
                    <CameraAlt className="text-white text-xs" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr className="w-full my-4" />

          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img src="/assets/img/dark/NameTag.png" className="mr-4 w-6" alt="horse_name" />
              Name
            </label>
            <div className="w-full md:w-[60%]">
              <div className="w-full flex items-center relative">
                <input
                  type="text"
                  {...register('horseName')}
                  disabled={saved}
                  placeholder="Enter horses name..."
                  className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 px-3 text-sm placeholder:text-[#122B4680] placeholder:text-sm customborder h-[51px]  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
              </div>
              {errors.horseName && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseName.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img
                src="/assets/cp_icons/Biotech-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Breed
            </label>
            <div className="w-full md:w-[60%] text-sm">
              <Controller
                control={control}
                name="horseBreed"
                render={({ field: { onBlur, value } }) => {
                  return (
                    <Select
                      isMulti={false}
                      onChange={(newValue) => setValue('horseBreed', (newValue as ISelect).value)}
                      isDisabled={saved}
                      value={sortBy(horseBreedList, 'label').find(
                        (c: IOption) => c.value === ((value as ISelect)?.value ?? value)
                      )}
                      onBlur={onBlur}
                      placeholder="Select Breed..."
                      options={horseBreedList}
                      components={{
                        Option: CustomOption as any,
                      }}
                      className={`border-[#D3DAEE] border py-[1px] !text-sm w-full rounded-xl  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      isClearable={false}
                      styles={customStyles}
                      isSearchable={true}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img
                src="/assets/og_icons/User-1.svg"
                width="24px"
                className="mr-4"
                alt="breeder_name"
              />
              Breeder
            </label>
            <div className="w-full md:w-[60%]">
              <input
                type="text"
                {...register('horseBreeder')}
                disabled={saved}
                placeholder="Enter Breeder"
                className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 px-3 text-sm placeholder:text-[#122B4680] placeholder:text-sm h-[51px]  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
              />
            </div>
          </div>

          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img src="/assets/img/dark/Ruler.png" className="mr-4 w-6" alt="horse_name" />
              Height
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseHeight"
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Select
                    onChange={(newValue) => setValue('horseHeight', (newValue as ISelect).value)}
                    isDisabled={saved}
                    value={HeightRestOptions.find(
                      (c) => c.value === ((value as ISelect)?.value ?? value)
                    )}
                    onBlur={onBlur}
                    placeholder="Select height..."
                    options={HeightRestOptions}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img
                src="/assets/og_icons/Clock-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Date of birth
            </label>
            <div
              className={`w-full md:w-[60%] gap-2 flex items-start flex-wrap justify-between ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
            >
              <div className="min-w-[200px] md:w-[48%] flex-1">
                <Controller
                  name="horseDob"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        peekNextMonth
                        disabled={saved}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        maxDate={new Date()}
                        placeholderText="Select date of birth..."
                        className={`w-full border rounded-xl !ring-0 p-2.5 mr-2 border-solid h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      />
                    )
                  }}
                />
                {errors.horseDob && (
                  <p className="text-sm text-SeabiscuitMainThemeColor">
                    {errors.horseDob.message?.toString()}
                  </p>
                )}
              </div>
              <div className="min-w-[200px] md:w-[48%] flex-1 flex items-center">
                <Controller
                  name="horseAgeVerified"
                  control={control}
                  render={({ field: { onBlur, value } }) => (
                    <Select
                      isMulti={false}
                      onChange={(newValue) =>
                        setValue('horseAgeVerified', (newValue as ISelect).value)
                      }
                      value={AgeVerifiedOption.find(
                        (c) => c.value === ((value as ISelect)?.value ?? value)
                      )}
                      onBlur={onBlur}
                      placeholder="Age verified?"
                      isDisabled={saved}
                      options={AgeVerifiedOption}
                      className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      isClearable={false}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {/* Gender */}
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img
                src="/assets/og_icons/Gender-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Gender
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseGender"
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Select
                    isMulti={false}
                    onChange={(newValue) => setValue('horseGender', (newValue as ISelect).value)}
                    value={SexRestOptions.find(
                      (c) => c.value === ((value as ISelect)?.value ?? value)
                    )}
                    onBlur={onBlur}
                    isDisabled={saved}
                    placeholder="Select Gender..."
                    options={SexRestOptions}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>

          {/* Discipline */}
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img
                src="/assets/og_icons/YearofHorse-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Disciplines
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseDiscipline"
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Select
                    onChange={(newValue) =>
                      setValue('horseDiscipline', (newValue as ISelect).value)
                    }
                    value={disciplineData.find(
                      (c) => c.value === ((value as ISelect)?.value ?? value)
                    )}
                    onBlur={onBlur}
                    isDisabled={saved}
                    isMulti={false}
                    placeholder="Select Discipline..."
                    options={disciplineData}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>

          {/* Zone */}
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img src="/assets/img/dark/MindMap.png" className="mr-4 w-6" alt="horse_name" />
              Zone
              <span
                onClick={() => props?.handleModal?.(true, 'zone')}
                className="cursor-pointer flex items-center"
              >
                <HelpOutline
                  className="ml-2 text-SeabiscuitDark200ThemeColor relative -bottom-0.5"
                  viewBox="0 0 30 30"
                  fontSize="small"
                />
              </span>
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseZone"
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Select
                    onChange={(newValue) => setValue('horseZone', (newValue as ISelect).value)}
                    value={zoneList.find((c) => c.value === ((value as ISelect)?.value ?? value))}
                    onBlur={onBlur}
                    isMulti={false}
                    isDisabled={saved}
                    placeholder="Select Zone..."
                    options={zoneList}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>

          {/* Color */}
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img src="/assets/img/dark/PaintPalette.png" className="mr-4 w-6" alt="horse_name" />
              Coloring
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseColor"
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Select
                    onChange={(newValue) => setValue('horseColor', (newValue as ISelect).value)}
                    value={horseColorList.find(
                      (c) => c.value === ((value as ISelect)?.value ?? value)
                    )}
                    onBlur={onBlur}
                    isMulti={false}
                    isDisabled={saved}
                    placeholder="Select Color..."
                    options={horseColorList}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>

          {/*  Markings*/}
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img src="/assets/img/dark/PaintBrush.png" className="mr-4 w-6" alt="horse_name" />
              Markings
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseMarking"
                control={control}
                render={({ field: { onBlur, value } }) => {
                  return (
                    <Select
                      onChange={(newValue) => {
                        setValue(
                          'horseMarking',
                          (newValue as { label: string; value: string }[])?.map((c) => c.value)
                        )
                      }}
                      value={horseMarkingList
                        .filter((c) => value?.includes(c.value))
                        .map((c) => ({ ...c, isClearable: false }))}
                      onBlur={onBlur}
                      placeholder="Select Marking..."
                      isDisabled={saved}
                      isMulti
                      options={horseMarkingList}
                      className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      isClearable={false}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  )
                }}
              />
            </div>
          </div>

          {/* Country */}
          <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
            <label className="md:w-[38%] flex-1 flex items-center text-[#122B46]">
              <img src="/assets/img/dark/Globe.png" className="mr-4 w-6" alt="" />
              Country of Origin
            </label>
            <div className="w-full md:w-[60%]">
              <Controller
                name="horseCountry"
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Select
                    onChange={(newValue) => setValue('horseCountry', (newValue as ISelect).value)}
                    value={CountryList.find(
                      (c) => c.value === ((value as ISelect)?.value ?? value)
                    )}
                    onBlur={onBlur}
                    isMulti={false}
                    isDisabled={saved}
                    options={CountryList}
                    placeholder="Select Country..."
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={true}
                  />
                )}
              />
            </div>
          </div>

          <hr className="my-8"></hr>

          <HorseNumberItem
            label="Microchip number"
            numberKey="horseMicrochipNumber"
            expiresKey="horseMicrochipExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="DNA Case Number"
            numberKey="horseDNACaseNumber"
            expiresKey="horseDNACaseExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="Passport number"
            numberKey="horsePassportNumber"
            expiresKey="horsePassportExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <hr className="my-8"></hr>

          <HorseNumberItem
            icon="/assets/cp_icons/Parliament-1.svg"
            label="USEF number"
            numberKey="horseUsefNumber"
            expiresKey="horseUsefExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            icon="/assets/cp_icons/Parliament-1.svg"
            label="FEI number"
            numberKey="horseFeiNumber"
            expiresKey="horseFeiExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="USDF number"
            numberKey="horseUsdfNumber"
            expiresKey="horseUsdfExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="USEA number"
            numberKey="horseUseaNumber"
            expiresKey="horseUseaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="USHJA number"
            numberKey="horseUshjaNumber"
            expiresKey="horseUshjaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="AHHS number"
            numberKey="horseAhhsNumber"
            expiresKey="horseAhhsExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="AMHA number"
            numberKey="horseAmhaNumber"
            expiresKey="horseAmhaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="ARHPA number"
            numberKey="horseArhpaNumber"
            expiresKey="horseArhpaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="ASHA number"
            numberKey="horseAshaNumber"
            expiresKey="horseAshaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="UPHA number"
            numberKey="horseUphaNumber"
            expiresKey="horseUphaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          <HorseNumberItem
            label="WDAA number"
            numberKey="horseWdaaNumber"
            expiresKey="horseWdaaExpiration"
            saved={saved}
            register={register}
            control={control}
          />

          {fields.map((field, index) => (
            <HorseNumberItem
              key={field.id}
              titleKey={`horsesOther.${index}.name`}
              numberKey={`horsesOther.${index}.number`}
              expiresKey={`horsesOther.${index}.date`}
              saved={saved}
              register={register}
              control={control}
              onRemove={() => remove(index)}
            />
          ))}

          {!saved && (
            <button
              type="button"
              className="flex items-center justify-start mt-4 cursor-pointer hover:opacity-70 transition-all"
              onClick={() => {
                append({
                  name: '',
                  number: '',
                  date: '',
                  uuid: uuidv4(),
                })
              }}
            >
              <img src="/assets/cp_icons/Cancel-2.svg" className="w-4 rotate-45" alt="cancelIcon" />
              <p className="ml-2 text-sm text-SeabiscuitMainThemeColor">Add number</p>
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default ManageHorseForm
