import React, { FC, useEffect, useState } from 'react'

// Libraries
import Select from 'react-select'
import { where } from 'firebase/firestore'

// Components
import MainModal from './common/MainModal'
import { AddTeamMemberToPaperworkHeader } from './components/AddTeamMemberToPaperworkHeader'

import FirestoreService from '../../services/firestoreService'

// Helpers
import { getUserFullName } from '../../helpers/helpers'
import { calculateAge, getConvertedData } from '../../models/interface.helper'
import helpers from '../../commonHelpers/helpers'
import { generatePaperworkDocument } from '../../helpers/sign'
import useToasterHelper from '../../helpers/ToasterHelper'
import customImageComponent from '../common/CustomImageComponent'
import { getFilteredPaperwork } from '../../helpers/documents'

// Store
import { setRidersTeamMembersUpdates } from '../../store/paperworks/paperworkSlice'
import { riderActions } from '../../store/rider/riderSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAllUsers } from '../../store/users/usersSlice'

// Models
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { EventDetailsModel } from '../../models/event-details/event-details.model'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { EventReviewPublishModel } from '../../models/event-review-publish/event-review-publish.model'

// Interfaces
import { IPaperworkTeam, IUserDocument } from '../../models/user-documents/user-documents.interface'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IUserInterface } from '../../models/users/user.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { IDocumentList } from '../../fakeData/fakeDocumentList'

// Styles
import { customStyles } from '../customStyles/ReactSelectCustomStyle'

// Constants
import { CONST } from '../../const/const'
import { MODAL_CONSTS } from '../../const/modal-const'
import { MESSAGES_CONST } from '../../const/messages-const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    teamMembers: IUserInterface[]
    registrationByDay: IRegistrationByDayInterface
    rider: IPaperworkTeam
  }
}
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

interface ISelectedTeamMember extends IUserInterface {
  role: {
    label: string
    value: string
  } | null
}
export const AddTeamMemberToPaperworkStep2: FC<IProps> = (props) => {
  const dispatch = useAppDispatch()

  const users = useAppSelector(selectAllUsers)

  const teamMembers = props.dataToPassOn.teamMembers
  const rider = props.dataToPassOn.rider
  const registrationByDay = props.dataToPassOn.registrationByDay

  const ROLES = Object.values(TEAM_MEMBER_ROLES)
    .filter((role) => role !== TEAM_MEMBER_ROLES.RIDER)
    .map((value) => ({
      value,
      label: value,
    }))
  const [loading, setLoading] = useState(false)
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<ISelectedTeamMember[]>([])

  const exhibitorR = useAppSelector((store) => store.exhibitor.registration)

  const toastFunction = useToasterHelper()

  useEffect(() => {
    if (teamMembers) {
      const updatedTeamMembers: ISelectedTeamMember[] = teamMembers.map((member) => ({
        ...member,
        role: null,
      }))
      setSelectedTeamMembers(updatedTeamMembers)
    }
  }, [props.dataToPassOn, teamMembers])

  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const createUserDocuments = async (userDocuments: IUserDocument) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, userDocuments))?.id ??
      null
    )
  }

  const savePaperworkTabData = async ({
    filteredPaperworkDocuments,
    ridersTeamMembersToAdd,
  }: {
    ridersTeamMembersToAdd: IRiderTeamMemberInterface[]
    filteredPaperworkDocuments: IDocumentList
  }) => {
    let id: string | null = null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        console.error(`${emptyVarName} is [${emptyVarValue}]`)
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      const userDocumentsToAdd: IUserDocument[] = []

      for (const document of filteredPaperworkDocuments) {
        if (ridersTeamMembersToAdd) {
          for (const ridersTeamMember of ridersTeamMembersToAdd) {
            const eventsSnapshot = await FirestoreService.filterItems(
              COLLECTIONS.USERS_DOCUMENTS.NAME,
              [
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY,
                  '==',
                  ridersTeamMember.teamMemberId
                ),
                where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', exhibitorR!.eventId),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.RIDER_ID.KEY,
                  '==',
                  ridersTeamMember.riderId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_NAME.KEY,
                  '==',
                  document.document_name
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY,
                  '==',
                  ridersTeamMember.teamMemberRole
                ),
              ]
            )

            if (eventsSnapshot.size <= 0) {
              userDocumentsToAdd.push(
                getConvertedData({
                  eventId: exhibitorR!.eventId!,
                  status: 'Not Signed',
                  activityUser: exhibitorR?.userName ?? '',
                  competitorId: exhibitorR?.id ?? '',
                  eventLogo: exhibitorR!.eventLogo!,
                  eventName: exhibitorR!.eventName!,
                  documentName: document.key,
                  documentUrl: document.document_image[0],
                  documentOriginalName: document.document_name,
                  riderId: ridersTeamMember.riderId,
                  riderName: ridersTeamMember.riderName,
                  documentOwner: ridersTeamMember.teamMemberId,
                  documentNameAsPerPdf: '',
                  signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
                  signatoryDefaultRole: ridersTeamMember.teamMemberRole,
                  signatoryEmail: ridersTeamMember.teamMemberEmail,
                  signatoryName: ridersTeamMember.teamMemberName,
                  signatoryId: ridersTeamMember.teamMemberId,
                  reminder: false,
                  registrationDocId: rider.registrationDocId,
                  registrationByDayUuid: ridersTeamMember.registrationByDayUuid || '',
                  registrationByDayUniqId: ridersTeamMember.registrationByDayUniqId || '',
                  riderTeamMemberDocId: ridersTeamMember.id ?? null,
                  pageNumberToSignOn: null,
                  coordinatesToSignOn: { x: null, y: null },
                  created: new Date().toString(),
                  modified: new Date().toString(),
                })
              )
            }
          }
        }
      }
      if (userDocumentsToAdd.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToAdd?.length ?? 0,
          functionToFire: async (currIndex) => {
            id = await createUserDocuments(
              new UserDocumentModel(userDocumentsToAdd![currIndex]).toFirestore()
            )
          },
        })
      }

      return true
    } catch (error: any) {
      helpers.logger({ message: `${error} in savePaperworkTabData` })
    }
  }

  const saveHandler = async () => {
    if (!exhibitorR) return null

    const doesNotHaveRole = selectedTeamMembers.some((user) => !user.role)

    if (doesNotHaveRole) {
      return toastFunction.error({ message: 'All selected team members should have a role' })
    }

    setLoading(true)

    try {
      for (let currentUser of selectedTeamMembers) {
        let memberAge = calculateAge(currentUser?.userDOB)

        if (memberAge < 18) {
          return toastFunction.error({ message: 'Member is minor' })
        }

        const teamMember: IRiderTeamMemberInterface = new RiderTeamMemberModel({
          mailLog: [],
          userId: exhibitorR.userId,
          eventId: exhibitorR.eventId ?? '',
          eventName: rider.eventName,
          riderDob: rider.riderDob,
          riderId: rider.riderId,
          riderName: rider.riderName,
          registrationDocId: rider.registrationDocId,
          registrationByDayUuid: rider.registrationByDayUuid,
          registrationByDayUniqId: rider.registrationByDayUniqId,
          teamMemberId: currentUser.id,
          teamMemberDob: currentUser.userDOB,
          teamMemberName: getUserFullName(currentUser),
          teamMemberRole: currentUser.role?.value,
          teamMemberEmail: currentUser.userEmail,
          teamMemberProfilePicture: currentUser.userProfilePicture,
        }).toObject()

        const publishedEventInDbSnapShot = await FirestoreService.getItem(
          COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
          registrationByDay.eventId!
        )

        const publishedEvent = getConvertedData(
          EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
        )

        const filteredPaperworkDocuments = getFilteredPaperwork(
          publishedEvent?.EventPaperwork ?? null
        )

        await savePaperworkTabData({
          filteredPaperworkDocuments: filteredPaperworkDocuments,
          ridersTeamMembersToAdd: [teamMember],
        })

        const eventDetailsSnaps = await FirestoreService.getItem(
          COLLECTIONS.EVENT_DETAILS.NAME,
          registrationByDay.eventId!
        )

        const eventDetails = EventDetailsModel.fromFirestoreDoc(eventDetailsSnaps).toObject()

        const user = users.find((user) => user.id === exhibitorR.userId)

        for (const document of filteredPaperworkDocuments) {
          await generatePaperworkDocument({
            paperwork: document,
            currRidersTeamMember: teamMember,
            registrationByDay,
            userData: user!,
            eventId: registrationByDay.eventId!,
            EventDetails: eventDetails,
            dateOfSignature: new Date(),
          })
        }
      }

      dispatch(setRidersTeamMembersUpdates())
      dispatch(riderActions.setIsAppPaperworkSign(false))
      props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2)
    } catch (error) {
      console.error('error', error)
      toastFunction.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      title="Add team member to entry"
      show={true}
      type={loading ? 'ADD_TEAM_MEMBER_TO_PAPERWORK' : 'ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2'}
      size="md"
      titleClassName="!font-normal"
      buttons={[
        {
          disabled: loading,
          label: loading ? 'Loading ....' : 'Assign & Send Paperwork',
          bgClass: 'bg-SeabiscuitMainThemeColor !w-full',
          textClass: 'text-white',
          onClick: saveHandler,
        },
        {
          disabled: loading,
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor !w-full',
          className: 'outline-none',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor',
          onClick: () => props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2),
        },
      ]}
    >
      <>
        <AddTeamMemberToPaperworkHeader
          rider={rider}
          horseName={registrationByDay?.horseName || ''}
          horseProfilePicture={registrationByDay?.horseProfilePicture || ''}
        />
        <h2>Assign roles</h2>
        <p>Assign roles to new team members to send paperwork to sign</p>
        <ul className={'flex flex-col gap-5'}>
          {selectedTeamMembers.map((user, index) => (
            <li key={user.id + '' + index} className={'flex gap-3 items-center'}>
              <div className={'flex flex-1 gap-2 items-center'}>
                {customImageComponent(
                  user.userProfilePicture,
                  getUserFullName(user),
                  'w-[45px] h-[45px] avatarImg rounded object-cover'
                )}
                <div>
                  <h4 className={'text-[#122B46] font-normal text-[16px]'}>
                    {getUserFullName(user)}
                  </h4>
                  <p className={'text-[#122B4680] text-[14px]'}>{user.userEmail}</p>
                </div>
              </div>
              <div>
                <Select
                  isMulti={false}
                  onChange={(select) => {
                    const newUsers = [...selectedTeamMembers]
                    if (newUsers[index]) newUsers[index].role = select as (typeof ROLES)[0]
                    setSelectedTeamMembers(newUsers)
                  }}
                  value={user.role}
                  // onBlur={onBlur}
                  placeholder={<div className="flex text-[13px]">Role</div>}
                  options={ROLES}
                  className={`border rounded-md w-40 !border-[#D3DAEE]`}
                  isClearable={false}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </li>
          ))}
        </ul>
      </>
    </MainModal>
  )
}
