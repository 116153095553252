import { FC, useEffect, useState } from 'react'

// Common
import MainModal from './common/MainModal'

// Components
import { SelectOption } from '../selects/SelectOption'

// Const
import { MODAL_CONSTS } from '../../const/modal-const'

// Store
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectRiderProfileRegistration,
  riderProfileRegistrationActions,
} from '../../store/riderProfileRegistration/riderProfileRegistrationSlice'

// Types
import { ECompetitorTypes } from '../../types/competitor_types'

interface CreateRiderProfileModalProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

const OPTIONS = [
  {
    option: ECompetitorTypes.alone,
    title: 'Lone rider',
    description: 'I register for events alone, just me and my horse.',
  },
  {
    option: ECompetitorTypes.team,
    title: 'Team rider',
    description:
      'I register for events with team members such as my trainer, owner, guardian, etc.',
  },
  {
    option: ECompetitorTypes.guardian,
    title: 'Guardian',
    description: 'I am a guardian setting up an account for a minor',
    learnMoreLink: 'https://intercom.help/pegasus-app/en/articles/10736119-guardians',
  },
  {
    option: ECompetitorTypes.spectator,
    title: 'Auditor, Spectator',
    description: 'I’m not riding, just auditing or spectating an event',
  },
]

export const CreateRiderProfileStepOneModal: FC<CreateRiderProfileModalProps> = ({
  handleModal,
}) => {
  const [selectedOption, setSelectedOption] = useState<ECompetitorTypes>(ECompetitorTypes.none)
  const riderProfileRegistrationData = useAppSelector(selectRiderProfileRegistration)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (riderProfileRegistrationData.profileType) {
      setSelectedOption(riderProfileRegistrationData.profileType)
    }
  }, [riderProfileRegistrationData])

  const nextHandler = () => {
    dispatch(riderProfileRegistrationActions.setProfileType(selectedOption))
    dispatch(riderProfileRegistrationActions.setIsTeam(selectedOption === ECompetitorTypes.team))
    handleModal(false, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_1)
    handleModal(true, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_2)
  }

  return (
    <MainModal
      title="Create rider profile"
      description={'Which best describes you?'}
      show={true}
      type="CREATE_RIDER_PROFILE_STEP_1"
      size={'lg'}
      buttons={[
        {
          label: 'NEXT >',
          className: '!w-full',
          disabled: selectedOption === ECompetitorTypes.none,
          onClick: nextHandler,
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          className: 'outline-none',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor !w-full',
          onClick: () => {
            dispatch(riderProfileRegistrationActions.clear())
            handleModal(false, MODAL_CONSTS.CREATE_RIDER_PROFILE_STEP_1)
          },
        },
      ]}
    >
      <div className={'-ml-2 -mr-2 text-SeabiscuitDark200ThemeColor'}>
        <div className={'flex flex-col gap-2.5 mt-5'}>
          {OPTIONS.map(({ option, title, description, learnMoreLink }) => (
            <SelectOption
              key={option}
              isActive={selectedOption === option}
              title={title}
              learnMoreLink={learnMoreLink}
              description={description}
              onClick={() => setSelectedOption(option)}
            />
          ))}
        </div>
      </div>
    </MainModal>
  )
}
