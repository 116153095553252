// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import User from './assets/PointOfContact/user.svg'
import { IconMessaging } from '../../../../components/icons/IconMessaging'
import { IEventDetails } from '../../../../models/event-details/event-details.interface'
import { useChatClient } from '../../../../pages/messages/components/withStreamClient'
import { useHistory } from 'react-router'
import { CONST } from '../../../../const/const'
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const EventDetailViewComponentPointOfContactElement: React.FC<{
  icon?: string
  iconComponent?: React.ReactNode
  title: string
  type?: string
  details: string
  onClick?: () => void
}> = ({ icon, iconComponent, title, type, details, onClick }) => {
  return (
    <div className="items-center gap-10 border border-[#D3DAEE] p-5 rounded-lg">
      <div className="flex mb-4 items-center">
        {icon ? (
          <img className="mr-2 w-6 h-6" src={icon} alt="icon" />
        ) : (
          <div className="mr-2 w-6 h-6">{iconComponent}</div>
        )}
        <p className="text-SeabiscuitDark200ThemeColor font-semibold">{title}</p>
      </div>
      {type === 'message' ? (
        <button
          onClick={onClick}
          className="whitespace-nowrap text-SeabiscuitDark200ThemeColor capitalize hover:underline cursor-pointer"
        >
          {details}
        </button>
      ) : (
        <p className="whitespace-nowrap text-SeabiscuitDark200ThemeColor capitalize">{details}</p>
      )}
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const EventDetailViewComponentPointOfContact: React.FC<{ eventData?: IEventDetails }> = (props) => {
  const { createChat } = useChatClient()
  const history = useHistory()

  const handleMessageOrganizer = async () => {
    if (!props.eventData?.owner || !props.eventData?.competitionPOCName) return

    await createChat(
      [{ id: props.eventData.owner, name: props.eventData.competitionPOCName }],
      'new_chat'
    ).then(() => {
      history.push(`${CONST.ROUTES.MESSAGES.URL}`)
    })
  }

  return (
    <div className="grid sm:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 gap-x-2 gap-y-4">
      <EventDetailViewComponentPointOfContactElement
        title="Point of Contact"
        icon={User}
        details={props.eventData?.competitionPOCName ?? 'N/A'}
      />
      <EventDetailViewComponentPointOfContactElement
        title="Contact"
        iconComponent={<IconMessaging fill="#8c95ac" />}
        type="message"
        details="Message Organizer"
        onClick={handleMessageOrganizer}
      />
    </div>
  )
}

export default EventDetailViewComponentPointOfContact
