import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useAppSelector } from '../../../../store/hooks'
import { selectedEvent, selectPublishedEvent } from '../../../../store/events/eventsSlice'

import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import { RequiredFieldsHeader } from '../../../../pages/shows/detail/components/RequiredFieldsHeader'
import { RequiredFieldRow } from '../../../../pages/shows/detail/components/RequiredFieldRow'
import { IconUser } from '../../../icons/IconUser'
import { IconHorseImg } from '../../../icons/IconHorseImg'

import useGetEventData from '../../../../hooks/users/common/useGetEventData'

import { IEventInterface } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'

import { REQUIERED_FILEDS } from '../../../../const/requeredFields'

interface IProps {
  data: any
  validFormHandler: any
  invalidFormHandler: any
  onInvalidAndExit: any
  nextEventTab?: string
  eventTab?: string
}

const eventRequiredFieldDefaultValues = new EventModel().toObject()

export const EventRequiredFieldFormComponent: React.FC<IProps> = (props) => {
  const { getAllData } = useGetEventData()

  const publishedEvent = useAppSelector(selectPublishedEvent)
  const event = useAppSelector(selectedEvent)

  const { handleSubmit, reset, watch, setValue } = useForm<IEventInterface>({
    defaultValues: { ...eventRequiredFieldDefaultValues },
    mode: 'onChange',
  })

  useEffect(() => {
    if (event.Event) {
      reset({
        ...event.Event,
        requiredFields: event.Event.requiredFields,
        requiredHorseFields: event.Event.requiredHorseFields,
      })
    }
  }, [event])

  const clickUserHandler = (value: string) => {
    if (watch('requiredFields').includes(value)) {
      setValue(
        'requiredFields',
        watch('requiredFields').filter((field) => field !== value)
      )
    } else {
      setValue('requiredFields', [...watch('requiredFields'), value])
    }
  }
  const clickHorseHandler = (value: string) => {
    if (watch('requiredHorseFields').includes(value)) {
      setValue(
        'requiredHorseFields',
        watch('requiredHorseFields').filter((field) => field !== value)
      )
    } else {
      setValue('requiredHorseFields', [...watch('requiredHorseFields'), value])
    }
  }

  useEffect(() => {
    if (event.Event?.id) getAllData(event.Event.id, ['v01_events']).then()
  }, [event.Event?.id])

  return (
    <EventFormContainerComponent>
      <div className="mr-4 mb-4 w-full mx-auto">
        <EventFormHeaderComponent
          title="Required fields"
          description="Select the profile fields that are required to register for this event"
        >
          <EventFormFooterCommonComponent
            publishedEvent={publishedEvent}
            publishButton={false}
            loading={false}
            onSaveAndExit={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    onSaveAndExit: true,
                    tabName: 'EventRequiredFields',
                    validFormHandler: props.validFormHandler,
                  }),
                props.onInvalidAndExit
              )(e)
            }}
            onNext={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    tabName: 'EventRequiredFields',
                    validFormHandler: props.validFormHandler,
                  }),
                props.invalidFormHandler
              )(e)
            }}
          />
        </EventFormHeaderComponent>

        <div className="flex flex-col lg:flex-row flex-1 gap-5">
          <div className={'flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-3'}>
            <RequiredFieldsHeader
              title={'People'}
              desc={'Select required fields'}
              count={watch('requiredFields').length}
              icon={<IconUser />}
            />
            <div className={'flex flex-col gap-3 mt-3'}>
              {REQUIERED_FILEDS.people.map((itm) => (
                <RequiredFieldRow
                  onClick={() => clickUserHandler(itm.value)}
                  key={`p-${itm.value}`}
                  title={itm.title}
                  isActive={watch('requiredFields').includes(itm.value)}
                />
              ))}
            </div>

            <div className={'flex flex-col gap-3 mt-3 border-t pt-5'}>
              {REQUIERED_FILEDS.peopleNumbers.map((itm) => (
                <RequiredFieldRow
                  onClick={() => clickUserHandler(itm.value)}
                  key={`p-${itm.value}`}
                  src={itm.img}
                  title={itm.title}
                  isActive={watch('requiredFields').includes(itm.value)}
                />
              ))}
            </div>
          </div>
          <div className={'flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-3'}>
            <RequiredFieldsHeader
              title={'Horses'}
              desc={'Select required fields'}
              count={watch('requiredHorseFields').length}
              icon={<IconHorseImg />}
            />

            <div className={'flex flex-col gap-3 mt-3'}>
              {REQUIERED_FILEDS.horseSecondary.map((itm) => (
                <RequiredFieldRow
                  key={`p-${itm.value}`}
                  onClick={() => clickHorseHandler(itm.value)}
                  src={itm.img}
                  title={itm.title}
                  isActive={watch('requiredHorseFields').includes(itm.value)}
                />
              ))}
            </div>

            <div className={'flex flex-col gap-3 mt-3 border-t pt-5'}>
              {REQUIERED_FILEDS.horseNumbers.map((itm) => (
                <RequiredFieldRow
                  key={`p-${itm.value}`}
                  onClick={() => clickHorseHandler(itm.value)}
                  src={itm.img}
                  title={itm.title}
                  isActive={watch('requiredHorseFields').includes(itm.value)}
                />
              ))}
            </div>

            <div className={'flex flex-col gap-3 border-t pt-5 mt-3'}>
              {REQUIERED_FILEDS.horseMain.map((itm) => (
                <RequiredFieldRow
                  onClick={() => clickHorseHandler(itm.value)}
                  key={`p-${itm.value}`}
                  title={itm.title}
                  isActive={watch('requiredHorseFields').includes(itm.value)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </EventFormContainerComponent>
  )
}
