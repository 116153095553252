import { createSlice } from '@reduxjs/toolkit'

export interface IDocumentFromDB {
  id: string
  signatoryId: string
  riderId: string
  signatoryName: string
  signatoryProfilePicture: string
  status: string
  eventId: string
  documentName: string
  documentUrl: string
  eventName: string
  eventLogo: string
  documentOriginalName: string
  documentNameAsPerPdf: string
  pageNumberToSignOn: number
  coordinatesToSignOn: CoordinatesToSignOn
  documentShareViaEmail: any[]
  documentOwner: string
  competitorId: string
  signatoryDefaultRole: string
  registrationDocId: string
  riderTeamMemberDocId: string
  signatoryEmail: string
  reminder: boolean
  created: string
  modified: string
  activityUser: string
  riderName: string
}

export interface CoordinatesToSignOn {
  x: number
  y: number
}

interface State {
  isAppPaperworkSign: boolean
  data: any
  documents: IDocumentFromDB[]
}

const initialState: State = {
  isAppPaperworkSign: false,
  data: null,
  documents: [],
}

const riderSlice = createSlice({
  name: 'rider',
  initialState,
  reducers: {
    setIsAppPaperworkSign: (state, action) => ({
      ...state,
      isAppPaperworkSign: action.payload,
    }),
    setDocuments: (state, action) => ({ ...state, documents: action.payload }),
  },
})

export const riderActions = riderSlice.actions

export default riderSlice.reducer
