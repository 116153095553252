import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FEES_CATEGORY_CONST } from '../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { useAppSelector } from '../../../../../store/hooks'
import { selectFeesTabDataR } from '../../../../../store/registration/registrationSlice'
import FeesRow from './FeesRow'
import DataNotAvailable from '../../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IManageInfo } from '../../EventRegistrationTabs'
import { IOnChangeFees } from '../../hooks/useEventRegistrationTabs/useEventRegistrationTabs'
import { IFeesTab } from '../../../../../models/event-registered-users/event-registered-users.interface'

// Types
type FeesRootProps = {
  feesItemsForm: UseFormReturn
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  onChangeFees: ({
    setManageInfo,
    manageInfo,
    isManage,
  }: IOnChangeFees) => Promise<null | undefined>
}

const FeesRoot: FC<FeesRootProps> = ({
  feesItemsForm,
  handleModal,
  manageInfo,
  setManageInfo,
  isManage,
  onChangeFees,
}) => {
  // Hooks and vars
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const [currentFees, setCurrentFees] = useState<IFeesTab[] | null>(null)

  useEffect(() => {
    setCurrentFees(isManage ? manageInfo.fees : feesTabData)
  }, [feesTabData, isManage, manageInfo])

  useEffect(() => {
    let selectedUnitsCount = 0
    let feesStateInHookForm: Record<string, number> = {}

    currentFees?.forEach((currFeesRow) => {
      if (currFeesRow.registrationFees) {
        selectedUnitsCount = currFeesRow?.registrationFees?.selectedUnitsCount ?? 0
        feesStateInHookForm = {
          ...feesStateInHookForm,
          [currFeesRow.feesItemId]: selectedUnitsCount,
        }
      }
    })
    feesItemsForm.reset(feesStateInHookForm)
  }, [currentFees, feesItemsForm])

  if (!currentFees?.length) {
    return (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
        text="No Fees items have been added to this event"
      />
    )
  }

  return (
    currentFees && (
      <>
        <FeesRow
          feesTabData={currentFees}
          onChangeFees={onChangeFees}
          feesCategory={FEES_CATEGORY_CONST.STALLS}
          feesItemsForm={feesItemsForm}
          handleModal={handleModal}
          showHeader
          isManage={isManage}
          manageInfo={manageInfo}
          setManageInfo={setManageInfo}
        />

        <FeesRow
          feesTabData={currentFees}
          onChangeFees={onChangeFees}
          feesCategory={FEES_CATEGORY_CONST.BEDDING}
          feesItemsForm={feesItemsForm}
          handleModal={handleModal}
          isManage={isManage}
          manageInfo={manageInfo}
          setManageInfo={setManageInfo}
        />

        <FeesRow
          feesTabData={currentFees}
          onChangeFees={onChangeFees}
          feesCategory={FEES_CATEGORY_CONST.FEED}
          feesItemsForm={feesItemsForm}
          isManage={isManage}
          manageInfo={manageInfo}
          setManageInfo={setManageInfo}
          handleModal={handleModal}
        />

        <FeesRow
          feesTabData={currentFees}
          onChangeFees={onChangeFees}
          feesCategory={FEES_CATEGORY_CONST.GOVERNANCE}
          feesItemsForm={feesItemsForm}
          handleModal={handleModal}
          isManage={isManage}
          manageInfo={manageInfo}
          setManageInfo={setManageInfo}
        />

        <FeesRow
          feesTabData={currentFees}
          onChangeFees={onChangeFees}
          feesCategory={FEES_CATEGORY_CONST.OTHER}
          feesItemsForm={feesItemsForm}
          handleModal={handleModal}
          isManage={isManage}
          manageInfo={manageInfo}
          setManageInfo={setManageInfo}
        />
      </>
    )
  )
}

export default FeesRoot
