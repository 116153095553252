import { CarouselSettings } from 'yet-another-react-lightbox/*'
import { IEventDetailData } from '../models/event-drafts/event-draft.interface'
import { ITeamMember } from '../models/users/user.interface'

export interface IUserSignupForm {
  email: string
  username: string
  fullname: string
  newPassword: string
  c_password: string
  nationality: string
}

export enum ECompetitorTypes {
  none,
  alone,
  team,
  guardian,
  spectator,
}

export interface IRiderSignupForm {
  email: string
  userName: string
  fullName: string
  password: string
  confirmPassword: string
  userPhoneNumber: string
  userNationality: string
  userDiscipline: string
  userDOB: string
}

export interface IVendorSignupForm {
  email: string
  userName: string
  vendorname: string
  newPassword: string
  c_password: string
  userAddress: string
  website: string
  userPhoneNumber: string
  userCategory: string
  profilePic: string
}

export type ITeamMateType = {
  teamMateImage?: string
  teamMateUsername: string
  teamMateRole: string
  step?: number
  competitorUsefProfileSynced: boolean
  competitorUsefMemberShipActive: boolean
  competitorSafeSupportTraining: boolean
  status: boolean
  id: string
}

export type IRegisterTabsSelectData = {
  id: string
  name?: string
  label?: string
  value?: string
  horseProfilePicture?: string
}

export interface IEventDetailsUi extends IEventDetailData {}

export type IEventDetailsForRevenve = {
  name: string
  event_date: string | Date
  event_id: string
  cost: number
  costAlias: string
  description: string
  uniqueId?: string
  members: ITeamMember[]
  payer: null | ITeamMember
  registrationAvailableCoun: null | number
}

export type IHorseDetail = {
  label?: string
  value?: string
  icon?: string
  name?: string
  id?: string
  horseName?: string
}

export type IClinicTabData = {
  horses: IHorseDetail[]
}

export type ITeamMemberRoles = string

export type IUserIntialState = {
  userId: string
  displayName: string
  username: string
  competitionProfileImageUrl: string
  organizerProfileImageUrl: string
  vendorProfileImageUrl: string
  profileCompleted: boolean
  isLoggedIn: boolean
  isFirstTime: boolean
  showRegistration: boolean
}

export type ICompetitorNotificationSetting = {
  label: string
  value: boolean
}

export type ICompetitorCard = {
  cardHolderName: string
  cardHolderEmail: string
  cardNumber: number
  cardType: string
  pmId: string
  expiryMonth: string
  expiryYear: string
  zipCode: string
  default: boolean
}

export type INotificationSettings = {
  newMessages: boolean
  weatherUpdates: boolean
  resultUpdates: boolean
  courseUpdates: boolean
  paymentUpdates: boolean
}

export interface ICompetitorProfileAccountForm {
  password: string
  userEmail: string
  c_password: string
  newPassword: string
  old_password: string
  cards: ICompetitorCard[]
  userAccountPrivacy: any
  userAccountNumber: string
  userNotificationsSettings: INotificationSettings
}
export interface IUserEmailUpdateAccountForm {
  password: string
  userEmail: string
  updateUserEmail: string
}
export type ILightboxProps = {
  inline?: boolean
  carouselSettings?: { [K in keyof CarouselSettings]?: CarouselSettings[K] }
  inlineStyles?: React.HTMLAttributes<HTMLDivElement>
  slides: { src: string; title?: any }[]
  visible: boolean
  onClose?: () => void
}

export type IMessageComp = {
  isError?: boolean
  className?: string
  focusOnError?: boolean
  message: string | undefined | any
}

export type Props = {
  show?: boolean
  handleModal?: any
}

export type ISelect = {
  label?: any
  value?: string
}

export type IImage = {
  lastModified?: number
  lastModifiedDate?: Date
  name?: string
  size?: number
  type?: string
  webkitRelativePath?: string
}

// export interface IHorseData {
//     horseId?: string,
//     status?: string,
//     horseName?: string,
//     horseBreed?: ISelect | string,
//     horseHeight?: ISelect | string,
//     horseDob?: Date | string,
//     horseGender?: ISelect | string,
//     horseDiscipline?: ISelect | string,
//     horseColor?: ISelect | string,
//     horseMarking?: ISelect | string,
//     horseCountry?: ISelect | string,
//     horseStatus?: string,
//     horseMicrochipNumber?: number,
//     horsePassportNumber?: number,
//     horseFeiNumber?: number,
//     horseUsefNumber?: number,
//     horseUsdfNumber?: number,
//     horseUseaNumber?: number,
//     horseUshjaNumber?: number,
//     horseMeasurementCardType?: ISelect | string,
//     horseMeasurementDate?: Date | string,
//     horseLeftHeelMeasurement?: number,
//     horseRightHeelMeasurement?: number,
//     cogginsInspectionDate?: Date | string,
//     horseCogginsCertificate?: string
// }

export interface ICountryList {
  label: string
  code: string
  value: string
  light_icon?: string
  ccode?: string
}

export type IJudgingLicence = {
  label: string
  value: string
}

export interface ICompetitorProfileDetailForm {
  userFirstName?: string | null
  userLastName?: string | null
  userEmail?: string | null
  userNationality?: ICountryList[] | ICountryList | string | null
  userDOB?: Date | null | undefined
  userAddress?: string | null
  userPhoneNumber?: string | null
  userDiscipline?: ICountryList[] | ICountryList | string | null
  userZone?: ICountryList[] | ICountryList | string | null
  competitorJudgingLicense?: ICountryList[] | ICountryList | string | null
  competitorFEINumber?: string | null
  competitorFEIStatus?: string | null
  competitorUSEFNumber?: string | null
  competitorUSDFNumber?: string | null
  competitorUSEANumber?: string | null
  competitorUSHJANumber?: string | null
  competitorOtherNumber?: string | null
  competitorEndorsmentNumberLetter?: IImage[] | null
  competitorUSDFDate?: Date | null | undefined
  competitorUSEADate?: Date | null | undefined
  competitorUSHJADate?: Date | null | undefined
  competitorOtherDate?: Date | null | undefined
  competitorEndorsementDate?: Date | null | undefined
  userSafeSupportStatus?: string | null
  userSafeSupportExpiry?: Date | null
  userBackgroundCheckStatus?: string | null
  userBackgroundCheckExpiry?: Date | null
  userState?: string
  userLong?: string
  userLat?: string
}

export interface EventHorizontalCardComponentProps {
  id?: string
  cardCoverImageUrl?: string
  isLicensed?: boolean
  city: string
  state: string
  country: string
  tags: string[]
  title?: string
  briefDescription?: string
  eventEndDate: string | Date
  eventStartDate: string | Date
  registered?: number
  available?: number
  registrationCloseDays?: number
  sale?: string
}
