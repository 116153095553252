import React, { useContext } from 'react'

import clsx from 'clsx'
import { AutorenewRounded } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { useIonRouter } from '@ionic/react'

import { useAppSelector } from '../../../../store/hooks'
import { selectIsLoggedIn } from '../../../../store/user/userSlice'

import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { IUserInterfaceExtended } from '../../../../models/users/user.interface'

interface ProfileDetailsButtonsProps {
  isMyProfile: boolean
  loading: boolean
  following: boolean
  alreadyFollowed: boolean
  messageHandler: () => void
  onFollowHandler: () => void
  profileDetails: IUserInterfaceExtended | null
}

export const ProfileDetailsButtons: React.FC<ProfileDetailsButtonsProps> = ({
  isMyProfile,
  loading,
  following,
  alreadyFollowed,
  messageHandler,
  profileDetails,
  onFollowHandler,
}) => {
  const router = useIonRouter()
  const history = useHistory()

  const handleModalContext = useContext(HandleModalContext)

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  if (!isLoggedIn) return <></>

  if (isMyProfile) {
    return (
      <button
        type="button"
        className=" mt-3 mb-3 items-center  h-[45px] border-solid border !border-[#122B46] rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitDark200ThemeColor w-[80%] min-w-[150px] bg-white hover:bg-[#122B46] focus:bg-[#122B46] active:bg-[#122B46] hover:text-white focus:text-white active:text-white "
        onClick={() => {
          router.push(CONST.ROUTES.PROFILE_SETTINGS.URL)
          history.push(CONST.ROUTES.PROFILE_SETTINGS.URL)
        }}
      >
        Edit Profile
      </button>
    )
  }

  return (
    <div className="flex flex-wrap justify-center gap-1.5 w-full mb-2">
      {profileDetails?.userMemberships && profileDetails.userMemberships.length > 0 && (
        <button
          type="button"
          disabled={loading}
          onClick={() => {
            handleModalContext?.handleModal(true, MODAL_CONSTS.JOIN, {
              organiserProfile: profileDetails,
              memberships: profileDetails?.userMemberships,
            })
          }}
          className={clsx(
            'h-[35px] border border-solid rounded-full flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[48%] sm:w-[32%] justify-center gap-1 hover:opacity-70',
            loading && 'pointer-events-none'
          )}
        >
          <img
            src="/assets/og_icons/Add User Male-1.svg"
            alt="add user"
            className="h-[20px] w-[20px]"
          />
          Join
        </button>
      )}
      <button
        type="button"
        disabled={following || loading}
        onClick={onFollowHandler}
        className={clsx(
          'h-[35px] border border-solid rounded-full flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[48%] sm:w-[32%] justify-center gap-1 hover:opacity-70',
          loading && 'pointer-events-none'
        )}
      >
        {following ? (
          <AutorenewRounded fontSize="small" className="animate-spin mx-auto w-[30px]" />
        ) : (
          <>
            <img
              src="/assets/og_icons/Bookmark-1.svg"
              alt="add user"
              className="h-[20px] w-[20px]"
            />
            {alreadyFollowed ? 'Unfollow' : 'Follow'}
          </>
        )}
      </button>

      <button
        type="button"
        disabled={loading}
        onClick={messageHandler}
        className="h-[35px] border border-solid rounded-full text-[12px] flex items-center px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[48%] sm:w-[32%] justify-center gap-1 hover:opacity-70"
      >
        <img
          src="/assets/og_icons/Speech Bubble-1.svg"
          alt="message"
          className="h-[20px] w-[20px]"
        />
        <span className="flex flex-col justify-end">
          <span>Message</span>
        </span>
      </button>
    </div>
  )
}
