import { createSlice } from '@reduxjs/toolkit'
import { ECompetitorTypes, IRiderSignupForm } from '../../types/competitor_types'
import { RootState } from '../store'
import { IInputHorseData, IInputUserData } from '../../components/home/types/inputData'
import { IUserInterface } from '../../models/users/user.interface'

interface IState {
  isTeam: boolean | null
  profileType: ECompetitorTypes
  eventId: string | null
  userId: string | null
  rider: {
    signupForm: IRiderSignupForm
    picture: File
  } | null
  horse: {
    horsePicture: File
    inputData: IInputHorseData
  } | null
  team: IUserInterface[]
  competitor: IUserInterface | null
  newTeamRider: {
    inputData: IInputUserData
    picture: File
  } | null
}

const initialState: IState = {
  isTeam: null,
  rider: null,
  horse: null,
  eventId: null,
  userId: null,
  team: [],
  competitor: null,
  newTeamRider: null,
  profileType: ECompetitorTypes.none,
}

export const riderProfileRegistrationSlice = createSlice({
  name: 'riderProfileRegistration',
  initialState,
  reducers: {
    setProfileType(state, action) {
      state.profileType = action.payload
    },
    setEventId: (state, action) => {
      state.eventId = action.payload
    },
    setIsTeam: (state, action) => {
      state.isTeam = action.payload
    },
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setRiderForm: (state, action) => {
      state.rider = action.payload
    },
    setHorse: (state, action) => {
      state.horse = action.payload
    },
    setTeam: (state, action) => {
      state.team = action.payload
    },
    setCompetitor: (state, action) => {
      state.competitor = action.payload
    },
    setNewTeamRider: (state, action) => {
      state.newTeamRider = action.payload
    },
    clear: (state) => {
      state.rider = null
      state.horse = null
      state.eventId = null
      state.isTeam = null
      state.userId = null
      state.profileType = ECompetitorTypes.none
      state.competitor = null
      state.newTeamRider = null
      state.team = []
    },
  },
})

export const riderProfileRegistrationActions = riderProfileRegistrationSlice.actions

export const selectRiderProfileRegistration = (state: RootState) => state.riderProfileRegistration
export const selectRiderProfileHorse = (state: RootState) => state.riderProfileRegistration.horse
export const selectIsTeamRegistration = (state: RootState) => state.riderProfileRegistration.isTeam
export const selectRiderProfileType = (state: RootState) =>
  state.riderProfileRegistration.profileType

export const selectNewTeamRiderRegistration = (state: RootState) =>
  state.riderProfileRegistration.newTeamRider

export default riderProfileRegistrationSlice.reducer
