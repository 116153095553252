import React, { useEffect, useState } from 'react'

import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { DaysTabs } from '../../../../components/events/common/DaysTabs/DaysTabs'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import {
  selectRegisterTabData,
  selectUserTeamMembersR,
} from '../../../../store/registration/registrationSlice'

import { IUserInterface } from '../../../../models/users/user.interface'

import { IRegisterTab } from '../../../../models/event-registered-users/event-registered-users.interface'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

// Styles
import './clinicNOtherReviewAndPayTab.css'

import { Tooltip } from '@mui/material'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getRegisteredRiders } from '../../../../helpers/riders'
import { daysOfWeek } from '../../../../helpers/time'
import { ISaveRegisterTabDataFnArgs } from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { RegisterItem } from './components/RegisterItem'
import useRegisterTab from './helpers/useRegisterTab'

type IClinicNOtherRegisterTabProps = {
  title?: string
  description?: string
  usersHolder: IUserInterface[]
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  saveRegisterTabData: (args: ISaveRegisterTabDataFnArgs) => Promise<ISaveRegisterTabDataFnArgs>
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  eventId: string
}

export interface IHandleTeamMateChange {
  id: string
  currentRow: IRegisterTab
  isRemove: boolean
  removedIndex?: number
  isChildren?: boolean
}

const ClinicNOtherRegisterTab = (props: IClinicNOtherRegisterTabProps) => {
  // Hooks and vars
  const registerTabData = useAppSelector(selectRegisterTabData)
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const selectedEventR = useAppSelector(selectedEvent)

  const toastFunction = useToasterHelper()
  const [days, setDays] = useState<string[]>([])
  const [activeDay, setActiveDay] = useState<string>('All')
  const [currentRegisterTabData, setCurrentRegisterTabData] = useState<IRegisterTab[] | null>(null)
  const [registrationsCount, setRegistrationsCount] = useState<number>(0)
  const { onRegistrationMemberChange } = useRegisterTab({
    saveRegisterTabData: props.saveRegisterTabData,
  })

  useEffect(() => {
    const getEventRiders = async () => {
      const registeredUsers = await getRegisteredRiders([props.eventId])
      setRegistrationsCount(registeredUsers.length)
    }
    getEventRiders().then()
  }, [props.eventId])

  const handleTeamMateChange = async ({
    id,
    currentRow,
    isRemove,
    isChildren,
    removedIndex,
  }: IHandleTeamMateChange) => {
    const availableSpots = selectedEventR.EventFees?.registrationLimit?.spots
      ? +selectedEventR.EventFees.registrationLimit.spots
      : 0
    let currentRegNum = 1 + registrationsCount

    currentRegisterTabData?.forEach((element) => {
      currentRegNum += element.registrationsByDay.length
    })

    if (availableSpots < currentRegNum && !isRemove) {
      toastFunction.error({
        message: 'Event full. This event has reached its registration limit.',
      })

      return null
    }

    const member = teamMembersWithUser.find((member) => member.memberId === id) || null

    await onRegistrationMemberChange({
      currentRow,
      member,
      isRemove,
      isChildren,
      removedIndex,
      isManage: props.isManage,
      manageInfo: props.manageInfo,
      setManageInfo: props.setManageInfo,
    })
  }

  useEffect(() => {
    const days_: string[] = []
    currentRegisterTabData?.forEach((fee) => {
      const day = daysOfWeek[new Date(fee.startDate)?.getDay()]
      if (!days_.includes(day)) days_.push(day)
    })

    setDays(
      days_.sort((a, b) => {
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
      })
    )
  }, [currentRegisterTabData])

  useEffect(() => {
    const registers = props.isManage ? props.manageInfo.register : registerTabData
    setCurrentRegisterTabData(registers)
  }, [props.isManage, props.manageInfo.register, registerTabData])

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <div className={'flex justify-between gap-5 items-center'}>
        <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} />
        <Tooltip
          title={
            <h4 className="text-[12px] text-left max-w-[180px] leading-4">
              Extra fees you can select to pay when entering this class
            </h4>
          }
          placement="top-end"
          arrow
        >
          <p className="mb-6 text-[#122B46] text-[14px] underline hover:no-underline cursor-pointer">
            Extra fee?
          </p>
        </Tooltip>
      </div>

      <div className="flex flex-col gap-2">
        {currentRegisterTabData && currentRegisterTabData.length > 0 ? (
          currentRegisterTabData.map((row, index) => {
            return (
              <RegisterItem
                key={index}
                row={row}
                teamMembersWithUser={teamMembersWithUser}
                handleTeamMateChange={handleTeamMateChange}
                isManage={props.isManage}
                manageInfo={props.manageInfo}
                handleModal={props.handleModal}
                eventId={props.eventId}
                activeDay={activeDay}
                EventFees={selectedEventR?.EventFees}
              />
            )
          })
        ) : (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text="No Classes have been added to this event"
          />
        )}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default ClinicNOtherRegisterTab
