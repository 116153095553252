import clsx from 'clsx'
import { where } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Select from 'react-select'
import helpers from '../../../commonHelpers/helpers'
import { customStyles } from '../../../components/customStyles/ReactSelectCustomStyle'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'
import useGuest from '../../../hooks/useGuest'
import useGetEventData from '../../../hooks/users/common/useGetEventData'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { TicketType } from '../../../models/event-ticketing/event-ticketing.interface'
import { getConvertedData } from '../../../models/interface.helper'
import { SpectatorTicketModel } from '../../../models/spectator-tickets/spectator-tickets-model'
import { ISpectatorTickets } from '../../../models/spectator-tickets/spectator-tickets.interface'
import FirestoreService from '../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectEventTicketsR,
  selectSpectatorTickets,
  setEventTickets,
  setSpectatorEventData,
} from '../../../store/tickets/ticketslice'
import { selectIsLoggedIn, selectUserId, selectUserReducer } from '../../../store/user/userSlice'
import SpectatorTickesPurchaseWrapper from '../SpectatorTickesPurchaseWrapper'
import { getFloatPrice } from '../../../helpers/price'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const SpeactatorTicketsPurchaseTicketsTab = ({
  saveRef,
  nextRef,
  onSetEventTab,
  props,
  ...rest
}: {
  EventDetails: IEventDetails
  eventTickets: TicketType[]
  saveRef: any
  nextRef: any
  props: any
  onSetEventTab: any
  setLoading: any
}) => {
  const dispatch = useAppDispatch()
  const guestHook = useGuest()
  const guest_id = guestHook.guestId
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const user = useAppSelector(selectUserReducer)

  const eventId = useParams<{ id: string }>()?.id
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const [loading, setLoading] = useState(true)
  const eventTickets = useAppSelector(selectEventTicketsR)
  const spectatorTickets = useAppSelector(selectSpectatorTickets)

  const { getAllData } = useGetEventData()

  let classToAdd: string = ''

  const { control } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    if (eventId) {
      setLoading(true)
      getAllData(eventId, ['v01_event_review-publish'])
        .then((data) => {
          if (data['v01_event_review-publish']?.EventTickets?.sellSpectatorTickets) {
            dispatch(
              setEventTickets(
                (data['v01_event_review-publish']?.EventTickets.tickets ?? []).map((currTicket) => {
                  return getConvertedData(currTicket)
                })
              )
            )
          }
        })
        .catch((error) => {
          toastFunctions.error({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })
          console.log(error)
        })
    }
  }, [dispatch, eventId, getAllData, setLoading, toastFunctions])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @returns Add and update tickets
   */
  const handleSave = useCallback(async () => {
    console.log('handleSave')
  }, [])

  /**
   * @returns get tickets firebase function
   */
  useEffect(() => {
    const getSpectatorTickets = async () => {
      let eventTickets_ = [...eventTickets]
      let spectatorTicket: ISpectatorTickets | null = null
      let spectatorTicketsMap: Map<string, ISpectatorTickets> = new Map()

      const spectatorTicketSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.SPECTATOR_TICKETS.NAME,
        [
          where(COLLECTIONS.SPECTATOR_TICKETS.FIELDS.EVENT_ID, '==', eventId),
          where(COLLECTIONS.SPECTATOR_TICKETS.FIELDS.USER_ID, '==', isLoggedIn ? userId : guest_id),
          where(
            COLLECTIONS.SPECTATOR_TICKETS.FIELDS.PAYMENT_STATUS.KEY,
            '!=',
            COLLECTIONS.SPECTATOR_TICKETS.FIELDS.PAYMENT_STATUS.VALUES.PAID
          ),
        ]
      )

      if (spectatorTicketSnapshots.size === 0) {
        return null
      } else {
        const spectatorTickets = spectatorTicketSnapshots.docs.map((currDoc) => {
          spectatorTicket = getConvertedData(
            SpectatorTicketModel.fromFirestoreDoc(currDoc).toObject()
          )
          if (spectatorTicket.ticketItemId)
            spectatorTicketsMap.set(spectatorTicket.ticketItemId, spectatorTicket)
          return spectatorTicket
        })

        dispatch(
          setSpectatorEventData(
            getConvertedData({
              spectator_tickets: spectatorTickets,
            })
          )
        )

        eventTickets_ = eventTickets_.map((currEventTicket) => {
          return {
            ...currEventTicket,
            spectatorTicket: spectatorTicketsMap.get(currEventTicket?.uuid) ?? null,
          }
        })

        dispatch(setEventTickets(getConvertedData(eventTickets_)))
      }
    }

    try {
      if ((userId || guest_id) && eventTickets.length) {
        setLoading(true)
        getSpectatorTickets()
      }
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.log(error)
    } finally {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isLoggedIn, guest_id, eventTickets.length])

  /**
   * @returns save data and open next tab
   */
  const handleNext = useCallback(async () => {
    let updatedSpectatorTickets: ISpectatorTickets[] = []
    let spectatorTicketToUpdate: ISpectatorTickets[] = []
    let spectatorTicketToCreate: ISpectatorTickets[] = []

    rest.setLoading(true)

    try {
      const ticketUnitsSelected = spectatorTickets.find((currTicket) => {
        return !!currTicket.selectedUnitsCount
      })

      if (!ticketUnitsSelected) {
        return toastFunctions.info({ message: MESSAGES_CONST.NO_SPECTATOR_TICKET_SELECTED })
      }

      spectatorTickets.forEach((currSpectatorTicket) => {
        if (!!currSpectatorTicket.id && currSpectatorTicket.update) {
          spectatorTicketToUpdate.push({
            ...new SpectatorTicketModel({
              ...currSpectatorTicket,
              recipientProfilePicture: user.profileDetails?.userProfilePicture ?? null,
              userName: user.profileDetails ? getUserFullName(user.profileDetails) : '',
            }).toFirestore(),
            id: currSpectatorTicket.id,
          })
        } else if (!currSpectatorTicket.id) {
          spectatorTicketToCreate.push(
            new SpectatorTicketModel({
              ...currSpectatorTicket,
              recipientProfilePicture: user.profileDetails?.userProfilePicture ?? null,
              userName: user.profileDetails ? getUserFullName(user.profileDetails) : '',
            }).toFirestore()
          )
        } else {
          updatedSpectatorTickets.push(currSpectatorTicket)
        }
      })

      // Update - SpectatorTickets

      await helpers.asyncWhileLoop({
        loopCount: spectatorTicketToUpdate.length,
        functionToFire: async (index) => {
          await FirestoreService.updateItem(
            COLLECTIONS.SPECTATOR_TICKETS.NAME,
            spectatorTicketToUpdate[index].id!,
            spectatorTicketToUpdate[index]
          )
        },
      })

      // Create - SpectatorTickets

      await helpers.asyncWhileLoop({
        loopCount: spectatorTicketToCreate.length,
        functionToFire: async (index) => {
          spectatorTicketToCreate[index].id = (
            await FirestoreService.createItem(
              COLLECTIONS.SPECTATOR_TICKETS.NAME,
              spectatorTicketToCreate[index]
            )
          ).id
        },
      })

      updatedSpectatorTickets = [
        ...updatedSpectatorTickets,
        ...spectatorTicketToCreate,
        ...spectatorTicketToUpdate,
      ]

      updatedSpectatorTickets = updatedSpectatorTickets.map((currSpectatorTicket) => {
        return getConvertedData(currSpectatorTicket)
      })

      dispatch(
        setSpectatorEventData({
          spectator_tickets: updatedSpectatorTickets,
        })
      )

      onSetEventTab?.(CONST.UI.SPECTATOR_TABS.TABS.EMAIL)
    } catch (error) {
      console.log(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      rest.setLoading(false)
    }
  }, [dispatch, onSetEventTab, rest, spectatorTickets, toastFunctions, user.profileDetails])

  const getOptions = (optionNumber: number) => {
    let allOptions: any[] = []
    for (let i = 0; i < optionNumber; i++) {
      allOptions.push({ label: `${i + 1}`, value: `${i + 1}` })
    }
    return allOptions
  }

  // Functions

  useEffect(() => {
    if (eventId && (!!guest_id || !!userId)) {
      saveRef.current = handleSave
      nextRef.current = handleNext
    }
  }, [eventId, guest_id, handleNext, handleSave, nextRef, saveRef, userId])

  const hadleTicketUpdate = (newValue: any, ticketIndex: number) => {
    let spectatorTicketIndex = -1
    let eventTickets_ = [...eventTickets]
    let spectatorTickets_ = [...spectatorTickets]
    let eventTicket = cloneDeep(eventTickets_[ticketIndex])

    let valueToReturn: ISpectatorTickets = new SpectatorTicketModel().toObject()

    if (!newValue?.value) {
      return console.log(`newValue is ${newValue}`)
    }

    spectatorTicketIndex = spectatorTickets.findIndex((currSpectatorTicket) => {
      return currSpectatorTicket.ticketItemId === eventTicket.uuid
    })

    if (spectatorTicketIndex === -1) {
      eventTicket.spectatorTicket = getConvertedData({
        ...valueToReturn,
        create: true,
        eventDocId: eventId,
        isPlatformUser: !!isLoggedIn,
        ticketPrice: eventTicket.cost,
        ticketTitle: eventTicket.name,
        ticketItemId: eventTicket.uuid,
        userId: isLoggedIn ? userId : guest_id,
        selectedUnitsCount: Number(newValue.value),
        userEmailId: user.profileDetails?.userEmail ?? null,
      })
      spectatorTickets_.push(eventTicket.spectatorTicket)
    } else {
      eventTicket.spectatorTicket = {
        ...spectatorTickets_[spectatorTicketIndex],
        selectedUnitsCount: Number(newValue.value),
        update: !!spectatorTickets_[spectatorTicketIndex].id,
      }

      spectatorTickets_[spectatorTicketIndex] = eventTicket.spectatorTicket
    }

    eventTickets_[ticketIndex] = eventTicket
    dispatch(
      setSpectatorEventData({
        spectator_tickets: spectatorTickets_,
      })
    )
    dispatch(setEventTickets(eventTickets_))
  }

  const openSeeMoreModal = (content: string) => {
    props.handleModal(true, MODAL_CONSTS.ADD_NOTE, { noteInputRef: content })
  }

  return (
    <SpectatorTickesPurchaseWrapper title="Tickets" description="Select tickets to purchase">
      <div>
        <header className="grid grid-cols-[25%_8%_8%_45%_12%] shrink-0 gap-[10px] flex-wrap">
          <div className="text-SeabiscuitDark200ThemeColor text-[12px] font-semibold flex items-center">
            Ticket item
            {/* <Tooltip
              title={<h1 className='tooltip_title'>Events you registered for</h1>}
              placement="top"
              arrow
            >
              <button
                className=""
              >
                <TooltipIcon color='#122B46' />
              </button>
            </Tooltip> */}
          </div>

          <div className="text-SeabiscuitDark200ThemeColor  text-[12px] font-semibold flex items-center ">
            Price
            {/* <Tooltip
              title={<h1 className='tooltip_title'>Cost to register for this event</h1>}
              placement="top"
              arrow
            >
              <button
                className=""
              >
                <TooltipIcon color='#122B46' />
              </button>
            </Tooltip> */}
          </div>

          <div className=" text-[12px] font-semibold flex items-center text-SeabiscuitDark200ThemeColor ">
            Remaining
            {/* <Tooltip
              title={<h1 className='tooltip_title'>
                Team member registered for this event
              </h1>}
              placement="top"
              arrow
            >
              <button
                className=""
              >
                <TooltipIcon color='#122B46' />
              </button>
            </Tooltip> */}
          </div>

          <div className=" text-[12px] font-semibold flex items-center text-SeabiscuitDark200ThemeColor ">
            Details
            {/* <Tooltip
              title={<h1 className='tooltip_title'>Select horse this exhibitor will ride in this event. You can only select horses saved in your settings</h1>}
              placement="top"
              arrow
            >
              <button
                className=""
              >
                <TooltipIcon color='#122B46' />
              </button>
            </Tooltip> */}
          </div>

          <div className=" text-[12px] font-semibold flex items-center  text-SeabiscuitDark200ThemeColor ">
            Amount
            {/* <Tooltip
              title={<h1 className='tooltip_title'>Select horse this exhibitor will ride in this event. You can only select horses saved in your settings</h1>}
              placement="top"
              arrow
            >
              <button
                className=""
              >
                <TooltipIcon color='#122B46' />
              </button>
            </Tooltip> */}
          </div>
        </header>
      </div>
      {loading ? (
        <div className="flex justify-center pt-[40px]">
          <ViewsLoader color="#ff2d55" size="lg" />
        </div>
      ) : eventTickets.length ? (
        eventTickets.map((ticket, index) => {
          classToAdd =
            'border border-solid ' +
            (ticket?.spectatorTicket?.selectedUnitsCount
              ? 'bg-SeabiscuitGrayThemeColor border-SeabiscuitGrayThemeColor'
              : 'border-SeabiscuitLightThemeColor')

          return (
            <div
              key={`${index}`}
              className={`grid grid-cols-[25%_8%_8%_45%_12%] gap-[10px] grid-flow-col-dense text-sm text-SeabiscuitDark200ThemeColor `}
            >
              <div
                className={clsx(
                  'p-2 font-normal flex flex-wrap items-center mb-2 rounded-lg',
                  classToAdd
                )}
              >
                {ticket?.name}
              </div>
              <div
                title="Click to view the document"
                className={clsx(
                  ' p-2 font-normal flex flex-wrap items-center mb-2 rounded-lg cursor-pointer justify-center',
                  classToAdd
                )}
              >
                {getFloatPrice(ticket?.costAlias || '$0')}
              </div>

              <input
                className={clsx(
                  ' p-2 font-normal grid items-center mb-2 justify-center text-center relative rounded-lg',
                  classToAdd
                )}
                value={ticket?.available - ticket?.sold}
                disabled={true}
              />

              <div
                className={clsx(
                  ' p-2 font-normal items-center mb-2 flex justify-start text-left relative rounded-lg',
                  classToAdd
                )}
              >
                <span className="block whitespace-nowrap overflow-hidden text-ellipsis">
                  {ticket?.note}
                </span>
                <img
                  src={'assets/og_icons/FullScreen-1.svg'}
                  alt="fullScreenIcon"
                  className="w-6 h-6 ml-3 cursor-pointer"
                  onClick={() => openSeeMoreModal(ticket?.note ?? '')}
                />
              </div>
              <div
                className={`text-SeabiscuitDark200ThemeColor text-sm mb-2 h-[50px] flex justify-between items-center  relative !rounded-lg ${ticket?.spectatorTicket?.selectedUnitsCount ? 'bg-SeabiscuitGrayThemeColor border-SeabiscuitGrayThemeColor' : 'border-SeabiscuitLightThemeColor border'}`}
              >
                <Controller
                  control={control}
                  name={`ticket.${index}.duration`}
                  render={({ field: { onChange, onBlur } }) => {
                    return (
                      <Select
                        options={getOptions(ticket?.available - ticket?.sold) as any}
                        isClearable={false}
                        isSearchable={true}
                        onBlur={onBlur}
                        isDisabled={ticket?.available === 0}
                        placeholder={`${ticket?.available === 0 ? 'N/a' : 'Select...'}`}
                        onChange={(value) => {
                          hadleTicketUpdate({ value: value?.value ?? 1 }, index)
                          onChange(value)
                        }}
                        value={
                          ticket?.spectatorTicket?.selectedUnitsCount
                            ? {
                                label: ticket?.spectatorTicket?.selectedUnitsCount ?? '',
                                value: ticket?.spectatorTicket?.selectedUnitsCount ?? '',
                              }
                            : undefined
                        }
                        styles={
                          {
                            ...customStyles,
                            valueContainer: (provided: any) => ({
                              ...provided,

                              justifyContent: 'center',
                            }),
                            menu: (provided: any, state: any) =>
                              ({
                                ...(typeof customStyles['menu'] === 'function' && {
                                  ...customStyles['menu'](provided, state),
                                }),
                              }) as any,
                          } as any
                        }
                        getOptionLabel={(props: any) => {
                          const { label } = props
                          return (
                            <span className="inline-flex justify-center w-full">{label}</span>
                          ) as unknown as string
                        }}
                        components={{
                          DropdownIndicator: () => {
                            if (ticket?.spectatorTicket?.selectedUnitsCount) {
                              return (
                                <img
                                  src="/assets/cp_icons/Ok-3.svg"
                                  alt="icon"
                                  width="20"
                                  height="20"
                                  className="mr-2 mb-[3px]"
                                />
                              )
                            } else {
                              return <></>
                            }
                          },
                          IndicatorSeparator: () => null,
                        }}
                        className="w-full rounded-lg searchableComponent focus:ring-0 p-0 focus:ring-transparent h-full flex items-center"
                      />
                    )
                  }}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div className="text-[14px] text-SeabiscuitLightParagraphTextColor mt-2">
          No tickets has been added to this event.
        </div>
      )}
    </SpectatorTickesPurchaseWrapper>
  )
}

export default SpeactatorTicketsPurchaseTicketsTab
