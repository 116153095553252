import { FIREBASE_CONFIG } from './firebase-config'

export const CONFIG = {
  APP_NAME: 'Pegasus',
  RUN_MODE: process.env.NODE_ENV,
  FIREBASE: FIREBASE_CONFIG,
  EVENT: {
    BYPASS_FORM_SEQUENCING: true,
  },
}
