import React, { FC } from 'react'
import customImageComponent from '../../common/CustomImageComponent'

interface ConfirmProfileDetailsCardProps {
  title: string
  src: string
  subTitle: string
}
export const ConfirmProfileDetailsCard: FC<ConfirmProfileDetailsCardProps> = (props) => {
  const { title, src, subTitle } = props

  return (
    <div
      className={
        'border max-w-[310px] rounded-2xl border-SeabiscuitGray500ThemeColor h-[244px] flex-1 flex justify-center flex-col items-center gap-4'
      }
    >
      {customImageComponent(
        src,
        title,
        '!w-[100px] !h-[100px] rounded-full mr-2 avatarImg object-cover border border-SeabiscuitGray500ThemeColor'
      )}
      <div className={'text-center'}>
        <h3>{title}</h3>
        <p className={'mt-1  text-xs text-SeabiscuitDark200ThemeColor/50'}>{subTitle}</p>
      </div>
    </div>
  )
}
