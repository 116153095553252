import { PDFDocument, PDFFont, PDFImage, PDFPage } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'

import helpers from '../commonHelpers/helpers'

import { CustomError } from '../helpers/helpers'

import { IAddNewPdfPageArgs, IPdfServiceArgs } from '../types/pdf'

import { MESSAGES_CONST } from '../const/messages-const'

const CUSTOM_ERROR_PROPS = {
  fileName: 'pdfService',
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

class PdfService {
  private currentY: number = 720
  private pdfPages: PDFPage[] = []
  readonly pageWidth: number = 550
  private pdfDoc: PDFDocument | null = null
  readonly pageHeight: number = 760
  private currPageNumber: number = 1
  readonly distanceBetweenLines: number = 20
  private currPdfPage: PDFPage | null = null
  readonly pageVerticalEndPoint: number = 80
  readonly pageVerticalStartPoint: number = 720
  readonly pageHorizontalEndPoint: number = 715
  readonly pageHorizontalStartPoint: number = 45

  constructor(args: IPdfServiceArgs) {
    this.pageWidth = args.pageWidth
    this.pageHeight = args.pageHeight
    this.distanceBetweenLines = args.distanceBetweenLines
    this.pageVerticalStartPoint = args.pageVerticalStartPoint
    this.pageHorizontalStartPoint = args.pageHorizontalStartPoint
    this.pageVerticalEndPoint =
      args.pageVerticalEndPoint ?? this.pageHeight - this.pageVerticalStartPoint
    this.pageHorizontalEndPoint =
      args.pageHorizontalEndPoint ?? this.pageWidth - this.pageHorizontalStartPoint
  }

  get getPdfInstance(): Promise<PDFDocument> {
    return new Promise(async (resolve) => {
      let created = false
      let pdfDoc: typeof this.pdfDoc

      if (this.pdfDoc) pdfDoc = this.pdfDoc
      else {
        pdfDoc = await PDFDocument.create()
        created = true
      }

      if (!pdfDoc) {
        let error = CustomError.somethingWentWrong({
          ...CUSTOM_ERROR_PROPS,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `this.pdfDoc is null`,
          moduleName: 'addNewPdfPage',
        })

        helpers.logger({
          message: error,
        })

        throw error
      }

      this.pdfDoc = pdfDoc

      if (created) this.registerFontkit()

      resolve(pdfDoc)
    })
  }

  get getPdfPagesCount(): number {
    return this.pdfPages.length
  }

  addNewPdfPage(args?: IAddNewPdfPageArgs): Promise<PDFPage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance

      let { width = this.pageWidth, height = this.pageHeight } = args ?? {}

      this.pdfDoc = pdfDoc
      this.currPdfPage = this.pdfDoc.addPage([width, height])
      this.pdfPages.push(this.currPdfPage)
      this.currPageNumber = this.getPdfPagesCount
      this.currentY = this.pageVerticalStartPoint - 40
      resolve(this.currPdfPage)
    })
  }

  get generatedPdf(): Promise<Uint8Array> {
    return new Promise(async (resolve) => {
      this.pdfDoc = await this.getPdfInstance
      let pdfBytes = await this.pdfDoc.save()
      resolve(pdfBytes)
    })
  }

  embedFont(font_: ArrayBuffer | string): Promise<PDFFont> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const font = await pdfDoc.embedFont(font_)
      resolve(font)
    })
  }

  embedJpg(jpg: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedJpg(jpg)
      resolve(image)
    })
  }

  embedPng(png: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedPng(png)
      resolve(image)
    })
  }

  embedJpeg(jpg: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedJpg(jpg)
      resolve(image)
    })
  }

  registerFontkit(): void {
    this.pdfDoc?.registerFontkit(fontkit)
  }
}

export default PdfService
