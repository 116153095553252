// Libs
import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { Control, Controller, FieldError, UseFormRegisterReturn } from 'react-hook-form'
import Select from 'react-select'

// Components
import CustomDatePicker from '../../common/inputs/CustomDatePicker'

// Styles
import { customStyles } from '../../customStyles/ReactSelectCustomStyle'
import { IRiderSignupForm } from '../../../types/competitor_types'
import { getReactPickerDate } from '../../../models/interface.helper'

export interface IInputField {
  name: keyof IRiderSignupForm
  label: string
  type: string
  control?: Control<IRiderSignupForm>
  selectData?: any
  isUnique?: boolean
  icon: JSX.Element
  required?: boolean
}

interface InputRowProps {
  register: UseFormRegisterReturn
  placeholder: string
  errors?: FieldError
  control?: Control<IRiderSignupForm>
  field: IInputField
}

export const InputRow: FC<InputRowProps> = (props) => {
  const { errors, field, register, control, placeholder } = props

  const renderInput = useMemo(() => {
    switch (field.type) {
      case 'select':
        return (
          <Controller
            control={control}
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <Select
                onChange={(data: any) => {
                  onChange(data.ccode || data.value)
                }}
                placeholder={value}
                options={field.selectData}
                className={clsx(
                  `border py-[1px] !text-sm w-full rounded-xl text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor`,
                  errors
                    ? 'border-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor'
                    : 'border-SeabiscuitLightThemeColorD3 focus:border-SeabiscuitGray300ThemeColor'
                )}
                styles={customStyles}
                value={{
                  label: value
                    ? field.selectData.find(
                        (itm: any) => itm.ccode === value || itm.value === value
                      )?.label || ''
                    : '',
                  value: value,
                }}
                isSearchable
                menuPlacement="auto"
                menuPortalTarget={document.body}
              />
            )}
          />
        )
      case 'date':
        return (
          <Controller
            control={control}
            name={field.name}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                popperPlacement="bottom-start"
                className={clsx(
                  `w-full border rounded-xl !ring-0 p-2.5 mr-2 border-solid h-[51px]`,
                  errors
                    ? 'border-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor'
                    : 'border-SeabiscuitLightThemeColorD3 focus:border-SeabiscuitGray300ThemeColor'
                )}
                selected={getReactPickerDate(value)}
                maxDate={new Date()}
                onChange={onChange}
              />
            )}
          />
        )
      default:
        return (
          <input
            type={field.type}
            {...register}
            className={clsx(
              `w-[100%] text-[#122B46] !outline-0 border  rounded-xl placeholder:text-SeabiscuitDark200ThemeColor p-3 h-[51px] !ring-0 `,
              errors
                ? 'border-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor'
                : 'border-SeabiscuitLightThemeColorD3 focus:border-SeabiscuitGray300ThemeColor'
            )}
          />
        )
    }
  }, [register, errors, placeholder, control, field])

  return (
    <div className={'flex flex-col gap-2 sm:gap-0 sm:flex-row sm:items-center'}>
      <div className={'flex items-center gap-[16px] min-w-[180px]'}>
        {field.icon}
        <span className={'text-[#122B46] text-[14px]'}>
          {field.label}
          {field.required && <span className={'text-[#122B4680] text-[10px] block'}>Required</span>}
        </span>
      </div>

      <div className="flex-1 relative">
        {renderInput}
        {errors && (
          <span
            className={'absolute left-0 top-full text-[10px] text-SeabiscuitMainThemeColor  pl-1'}
          >
            {errors.message}
          </span>
        )}
      </div>
    </div>
  )
}
