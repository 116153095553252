import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router'
import { IonContent, IonPage } from '@ionic/react'

import UserLoginViewComponentProps from '../../../components/user/views/login/UserLoginViewComponentProps'

import FirebaseApp from '../../../services/firebaseApp'

import { setIsLoggedIn } from '../../../store/user/userSlice'

import { CONST } from '../../../const/const'

import PegasusLogo from '../../../icons/logo/Logo.svg'

import './UserLoginPage.css'

// Inside AuthProvider
const provider = new GoogleAuthProvider()

export const UserLoginPage = ({ handleModal }: { handleModal: any }) => {
  const history = useHistory()

  const handleLoginInvalid = async () => {}

  const handleSignInWithGoogle = async () => {
    signInWithPopup(FirebaseApp.auth, provider)
      .then(() => {
        setIsLoggedIn(true)
        history.replace('/', { direction: 'none' })
      })
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.error(error.message, error.email, error.code, credential, 'err')
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonPage>
      <IonContent>
        <motion.div
          className="w-full mx-auto h-full !bg-white "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 100,
            damping: 20,
            delay: 0.7,
          }}
        >
          {/*<UserSignupViewComponent />*/}

          <UserLoginViewComponentProps
            onLoginInvalid={handleLoginInvalid}
            onSignInWithGoogleButtonPressed={() => handleSignInWithGoogle()}
            onSignUpButtonPressed={() => {
              history.push(CONST.ROUTES.SIGNUP.URL)
            }}
            logo={PegasusLogo}
            handleModal={handleModal}
            logoAlt="Pegasus"
          />
        </motion.div>
      </IonContent>
    </IonPage>
  )
}

export default UserLoginPage
