import React, { useContext, useEffect } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import SwitchElementUpdated from '../paperwork/SwitchElement'
import { EventQuestionInput } from '../../../../pages/shows/detail/components/EventQuestionInput'
import { IconPlusCircle } from '../../../icons/IconPlusCircle'

import { useAppSelector } from '../../../../store/hooks'
import { selectedEvent, selectPublishedEvent } from '../../../../store/events/eventsSlice'

import { IEventInterface, IQuestion } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'

interface EventQuestionsFormComponentProps {
  data: any
  validFormHandler: any
  invalidFormHandler: any
  onInvalidAndExit: any
  nextEventTab?: string
  eventTab?: string
}

const eventQuestionsValidationSchema = EventModel.validationQuestionSchema()
const eventQuestionsDefaultValues = new EventModel().toObject()

export const EventQuestionsFormComponent: React.FC<EventQuestionsFormComponentProps> = (props) => {
  const { getAllData } = useGetEventData()

  const event = useAppSelector(selectedEvent)
  const publishedEvent = useAppSelector(selectPublishedEvent)

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IEventInterface>({
    defaultValues: { ...eventQuestionsDefaultValues },
    resolver: yupResolver(eventQuestionsValidationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (event.Event) {
      reset({
        ...event.Event,
        questions: event.Event.questions,
        isEnableQuestions: event.Event.isEnableQuestions
          ? event.Event.isEnableQuestions
          : !!event.Event.questions,
      })
    }
  }, [event])

  useEffect(() => {
    if (event.Event?.id) getAllData(event.Event.id, ['v01_events']).then()
  }, [event.Event?.id])

  return (
    <EventFormContainerComponent>
      <div className="mr-4 mb-4 w-full mx-auto">
        <EventFormHeaderComponent
          title="Custom Questions"
          description="Add questions for exhibitors to answer during registration, including uploading files."
        >
          <EventFormFooterCommonComponent
            publishedEvent={publishedEvent}
            publishButton={false}
            loading={false}
            onSaveAndExit={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    onSaveAndExit: true,
                    tabName: 'EventQuestions',
                    validFormHandler: props.validFormHandler,
                  }),
                props.onInvalidAndExit
              )(e)
            }}
            onNext={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    tabName: 'EventQuestions',
                    validFormHandler: props.validFormHandler,
                  }),
                props.invalidFormHandler
              )(e)
            }}
          />
        </EventFormHeaderComponent>

        <div className={'flex flex-wrap item-center justify-between gap-3 mt-7'}>
          <h2 className={'m-0 p-0 text-[#122B46] font-bold text-[20px] h-auto flex items-center '}>
            Do you want to add custom questions for this event?
          </h2>
          <SwitchElementUpdated
            onSwitch={() => setValue('isEnableQuestions', !watch('isEnableQuestions'))}
            on={watch('isEnableQuestions') || false}
          />
        </div>

        <div
          className={clsx(
            'mt-7 flex items-start flex-col gap-8',
            !watch('isEnableQuestions') && 'opacity-50 pointer-events-none'
          )}
        >
          {watch('questions')?.map((question, index) => (
            <EventQuestionInput
              key={index}
              index={index}
              question={question}
              register={register}
              errors={errors}
              onRemove={() => {
                handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                  removeItem: () => {
                    setValue(
                      'questions',
                      watch('questions')?.filter((field) => field.id !== question.id)
                    )
                  },
                })
              }}
              onUpdate={(value: IQuestion) => {
                const questions = cloneDeep(watch('questions') ?? [])
                questions[index] = value
                setValue('questions', questions)
              }}
            />
          ))}

          {watch('isEnableQuestions') && (
            <button
              onClick={() => {
                setValue('questions', [
                  ...(watch('questions') ?? []),
                  { id: uuidv4(), value: '', isRequired: false },
                ])
              }}
              className="inline-flex gap-2 underline items-center group text-[#122B4680] text-[14px] hover:text-SeabiscuitMainThemeColor hover:no-underline"
            >
              <IconPlusCircle />
              <span>New question</span>
            </button>
          )}
        </div>
      </div>
    </EventFormContainerComponent>
  )
}
