import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router'

// Common
import MainModal from './common/MainModal'
import { createHorseData } from './common/createHorseData'

// Const
import { CONST } from '../../const/const'
import { MODAL_CONSTS } from '../../const/modal-const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { ITEAMMEMBER_MODAL_TYPES } from '../home/modals/UserProfileTeamTabAddTeamMember'

// Services
import FirestoreService from '../../services/firestoreService'

// Store
import { selectAllUsers, setAllUsers } from '../../store/users/usersSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectProfileData,
  setProfileDetails,
  setUserId,
  storeUserId,
} from '../../store/user/userSlice'
import { setHorses, setIsMyHorse, setSelectedHorseStatus } from '../../store/horses/horseSlice'
import {
  riderProfileRegistrationActions,
  selectRiderProfileRegistration,
} from '../../store/riderProfileRegistration/riderProfileRegistrationSlice'
import { storeUseMode } from '../../store/system/systemThunk'
import { setTeamTabData } from '../../store/registration/registrationSlice'

// Models
import { IRiderTeamMember, IUserInterface } from '../../models/users/user.interface'
import { IRegistrationTeamInterface } from '../../models/registeration-teams/registration-teams.interface'
import { getConvertedData, getSelectedUserAsTeamMember } from '../../models/interface.helper'

// Helpers
import useToasterHelper from '../../helpers/ToasterHelper'
import { getUserFullName } from '../../helpers/helpers'
import { uploadPicture } from '../../helpers/uploadPicture'

// Components
import { ModalBackButton } from './components/ModalBackButton'
import { ConfirmProfileDetailsCard } from './components/ConfirmProfileDetailsCard'
import { IOption } from '../inputs/InputNewMemberRow'

// Hooks
import { useCreateUser } from '../../hooks/useCreateUser'
import { IHorseData } from '../../models/horse/horse.interface'

interface ConfirmProfileDetailsProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    createCb: (value: IHorseData) => void
    submitOnlyMemberHandler: ({
      isSetLoading,
      newCompetitor,
      newPicture,
      newTeamMembers,
    }: {
      isSetLoading: boolean
      newCompetitor: IUserInterface
      newPicture: string
      newTeamMembers: IRegistrationTeamInterface[]
    }) => Promise<void>
  }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const ConfirmProfileDetails: FC<ConfirmProfileDetailsProps> = (props) => {
  const { handleModal } = props
  const riderProfileRegistrationData = useAppSelector(selectRiderProfileRegistration)
  const { horse, rider, isTeam, eventId, team, newTeamRider } = riderProfileRegistrationData
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectAllUsers)
  const [loading, setLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const [horsePicture, setHorsePicture] = useState('')
  const [newRiderPicture, setNewRiderPicture] = useState('')
  const { createUser } = useCreateUser()
  const toastFunctions = useToasterHelper()
  const history = useHistory()
  const profileDetails = useAppSelector(selectProfileData)

  useEffect(() => {
    let imageUrl = ''
    if (rider?.picture) {
      imageUrl = URL.createObjectURL(rider.picture)
    }
    setProfilePicture(imageUrl)
  }, [rider?.picture])

  useEffect(() => {
    let imageUrl = ''
    if (horse?.horsePicture) {
      imageUrl = URL.createObjectURL(horse.horsePicture)
    }
    setHorsePicture(imageUrl)
  }, [horse?.horsePicture])

  useEffect(() => {
    let imageUrl = ''
    if (newTeamRider?.picture) {
      imageUrl = URL.createObjectURL(newTeamRider.picture)
    }
    setNewRiderPicture(imageUrl)
  }, [newTeamRider?.picture])

  const closeHandler = () => {
    handleModal(false, MODAL_CONSTS.CONFIRM_PROFILE_DETAILS)
    dispatch(riderProfileRegistrationActions.clear)
    setTimeout(() => {
      handleModal(true, MODAL_CONSTS.USER_CARD)
    }, 500)
  }

  const updateUserTeam = async (competitor: IUserInterface) => {
    if (!competitor) return
    let updatedTeamMembersList: IRiderTeamMember[] = team.map((user) => ({
      selected: true,
      memberId: user.id,
      memberDob: user.userDOB,
      memberName: getUserFullName(user),
      memberPhoneNumber: user.userPhoneNumber,
      memberEmail: user.userEmail,
      memberProfilePicture: user.userProfilePicture,
      connectedDate: new Date().toString(),
      memberRole: CONST.UI.TEAM_MEMBERS_ROLES.RIDER,
      defaultRole: CONST.UI.TEAM_MEMBERS_ROLES.RIDER,
    }))

    try {
      await FirestoreService.updateItem(COLLECTIONS.USERS.NAME, competitor.id, {
        userTeamMembers: updatedTeamMembersList,
      })
      dispatch(setProfileDetails({ ...profileDetails, userTeamMembers: updatedTeamMembersList }))
    } catch (e) {
      console.error(e)
    }
  }
  const createHorse = async (userId: string, email: string) => {
    try {
      const { horsePicture, inputData } = horse!
      const downloadUrl = await uploadPicture(horsePicture)
      const { horseToCreate } = await createHorseData({
        userData: {
          id: userId,
          email,
        },
        horsePicture: `${downloadUrl || ''}`,
        inputData,
      })

      dispatch(setHorses([horseToCreate]))
      dispatch(setIsMyHorse(true))
      dispatch(setSelectedHorseStatus('fulfilled'))

      if (props.dataToPassOn.createCb) props.dataToPassOn.createCb(horseToCreate)
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({ message: 'Horse not created' })
    }
  }

  const finishHandler = async () => {
    if (!rider) return
    if (loading) return
    setLoading(true)
    try {
      const createdUserData = await createUser(rider.signupForm)
      if (!createdUserData) return
      const { competitor } = createdUserData

      if (!competitor) {
        console.error('error competitor', competitor)
        return toastFunctions.error({ message: 'Competitor does not exist' })
      }

      if (!competitor.id) {
        return toastFunctions.error({ message: `${competitor.userEmail} is not registered` })
      }
      if (!horse) {
        return toastFunctions.error({ message: `Horse is not registered` })
      }

      await createHorse(competitor.id, competitor.userEmail)

      const teamData: IRegistrationTeamInterface[] = []

      if (team[0]) {
        team.forEach((user) => {
          teamData.push(getConvertedData(getSelectedUserAsTeamMember(user, competitor)))
        })

        dispatch(setTeamTabData([...teamData]))
        await updateUserTeam(competitor)
      }

      const redirectUrl =
        eventId && competitor
          ? `${CONST.ROUTES.REGISTER_EVENT.URL}/${eventId}/${competitor.id}`
          : `${CONST.ROUTES.HOME.URL}`

      history.replace(redirectUrl, {
        direction: 'none',
      })

      dispatch(setProfileDetails(competitor))
      dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
      dispatch(storeUserId(competitor.id))
      dispatch(setUserId(competitor.id))
      dispatch(setAllUsers([...users, competitor]))
      if (props.dataToPassOn.submitOnlyMemberHandler) {
        const newTeamRiderPictureFile = newTeamRider?.picture
        let downloadUrl = ''
        if (newTeamRiderPictureFile) {
          downloadUrl = (await uploadPicture(newTeamRiderPictureFile)) || ''
        }
        await props.dataToPassOn.submitOnlyMemberHandler({
          isSetLoading: true,
          newCompetitor: competitor,
          newPicture: downloadUrl || '',
          newTeamMembers: teamData,
        })
      }
      closeHandler()
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      localStorage.clear()
      sessionStorage.clear()
    } finally {
      setLoading(false)
      dispatch(riderProfileRegistrationActions.clear())
    }
  }

  const backHandler = () => {
    handleModal(false, MODAL_CONSTS.CONFIRM_PROFILE_DETAILS)
    if (newTeamRider) {
      handleModal(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER, { isLogin: true })
    } else if (isTeam && !newTeamRider) {
      handleModal(true, MODAL_CONSTS.REGISTER_TEAM, {
        modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
        userTeamMembers: team,
        users,
        authorizeUserHorses: [],
        createCb: () => {},
      })
    } else {
      handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
        onlyHorse: true,
        backToCreateProfile: true,
      })
    }
  }

  return (
    <MainModal
      title="Confirm Profile Details"
      description={'Confirm team is complete to start registering for events'}
      show={true}
      type="CONFIRM_PROFILE_DETAILS"
      size={'lg'}
      goBackBtn={<ModalBackButton onClick={backHandler} />}
      buttons={[
        {
          label: 'FINISH',
          className: '!w-full',
          loading,
          onClick: finishHandler,
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          className: 'outline-none',
          loading,
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor !w-full',
          onClick: closeHandler,
        },
      ]}
    >
      <div className={'-ml-2 -mr-2 text-SeabiscuitDark200ThemeColor pt-5'}>
        <h3 className={'font-medium'}>You and your horse</h3>
        <div
          className={clsx(
            'grid grid-cols-1 md:grid-cols-2 gap-6 mt-2.5 pb-6  border-SeabiscuitGray500ThemeColor ',
            {
              'border-b': riderProfileRegistrationData.isTeam,
            }
          )}
        >
          {rider && (
            <ConfirmProfileDetailsCard
              title={rider.signupForm.fullName}
              subTitle={'@' + rider.signupForm.userName}
              src={profilePicture}
            />
          )}
          {horse && (
            <ConfirmProfileDetailsCard
              title={horse.inputData.name.value.toString()}
              subTitle={(horse.inputData.breed.value as IOption).label.toString()}
              src={horsePicture}
            />
          )}
        </div>
        {isTeam && (
          <>
            <h3 className={'font-medium mt-6'}>Team members</h3>
            <div className={'grid grid-cols-1 md:grid-cols-2 gap-6 pt-6'}>
              {newTeamRider && (
                <ConfirmProfileDetailsCard
                  title={newTeamRider.inputData.userFullName.value.toString()}
                  subTitle={'@' + newTeamRider.inputData.userName.value.toString()}
                  src={newRiderPicture || ''}
                />
              )}
              {team.map((user) => (
                <ConfirmProfileDetailsCard
                  key={user.id}
                  title={getUserFullName(user)}
                  subTitle={'@' + user.userName}
                  src={user.userProfilePicture || ''}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </MainModal>
  )
}
