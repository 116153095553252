import { DocumentData, QueryDocumentSnapshot, where } from 'firebase/firestore'
import { cloneDeep, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import helpers from '../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../../../helpers/helpers'
import {
  IFeesTab,
  IRegistrationTabs,
  ITicketTab,
  TEventRegisteredUsers,
} from '../../../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../../../models/event-registered-users/event-registered-users.model'
import { IEventReviewPublish } from '../../../../../models/event-review-publish/event-review-publish.interface'
import { EventReviewPublishModel } from '../../../../../models/event-review-publish/event-review-publish.model'
import {
  getConvertedData,
  getSelectedUserAsTeamMember,
} from '../../../../../models/interface.helper'
import { IRegistrationFeesInterface } from '../../../../../models/registration-fees/registrationFees.interface'
import { RegistrationFeesModel } from '../../../../../models/registration-fees/registrationFees.model'
import { IRegistrationTicketInterface } from '../../../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../../../models/registration-tickets/registrationTicket.model'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../models/registrations-by-day/registrationByDay.model'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'

import ITypes from '../useEventRegistrationTabs.types'
import IUseEventRegistrationTabsTypes, {
  ISaveRegisterTabDataFnArgs,
} from '../useEventRegistrationTabs.types'

import { useForm } from 'react-hook-form'
import { FEES_CATEGORY_CONST } from '../../../../../components/events/views/details/EventDetailsViewComponentFees'
import fakeDocumentList from '../../../../../fakeData/fakeDocumentList'
import {
  IEventFees,
  RegistrationFeesType,
} from '../../../../../models/event-fees/event-fees.interface'
import { RecipientModel } from '../../../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../../../models/recipients/recipients.interface'
import IRegistrationTeamsTypes, {
  IRegistrationTeamInterface,
} from '../../../../../models/registeration-teams/registration-teams.interface'
import { RegistrationTeamModel } from '../../../../../models/registeration-teams/registration-teams.models'
import { IRiderTeamMemberInterface } from '../../../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../../../models/rider-team-member/riderTeamMember.model'
import { updateUsersHolderAc } from '../../../../../store/holders/holdersSlice'
import {
  selectFeesTabDataR,
  selectPaperworkTabData,
  selectPublishedEventInDb,
  selectRegisterTabData,
  selectRegistration,
  selectRegistrationByDay,
  selectRegistrationFeesR,
  selectRegistrationsByDayInDb,
  selectRegistrationTicketsR,
  selectTeamTabData,
  selectTeamTabDataInDb,
  selectTicketTabData,
  selectUserTeamMembersR,
  setFeesTabDataAc,
  setFilteredPaperworkDocuments,
  setPaperworkTabData,
  setPublishedEventInDb,
  setRecipientsInDb,
  setRegisterTabData,
  setRegistrationAc,
  setRegistrationLoading,
  setRegistrationsByDay,
  setRegistrationsByDayInDb,
  setRegistrationsFeesAc,
  setRegistrationsTicketsAc,
  setRidersTeamMembers,
  setRidersTeamMembersInDb,
  setTeamTabData,
  setTeamTabDataInDb,
  setTicketTabData,
  setUserTeamMembers,
} from '../../../../../store/registration/registrationSlice'
import {
  competitorEventRegisterHelper as cerh,
  ICompetitorEventRegisterTypes as ICerhTypes,
} from '../../../competitorEventRegister/competitorEventRegisterHelper'
import { IManageInfo } from '../../EventRegistrationTabs'
import { getFilteredPaperwork } from '../../../../../helpers/documents'
import UserService from '../../../../../services/userService'
import { mergeRidersTeamMembersWithPaperworkTabData } from '../../../../../helpers/teamMembers'
import { selectHorses } from '../../../../../store/horses/horseSlice'

// Constants
const FILE_NAME = 'useEventRegistrationTabs'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}
const feesItemsDefaultValue: Record<string, number> = {}
const ticketItemsDefaultValue: Record<string, number> = {}

export interface ISaveTicketTabData {
  currentRow: ITicketTab
  isManage?: boolean
  setManageInfo: (value: IManageInfo) => void
  manageInfo: IManageInfo
}

export interface IOnChangeFees {
  currentRow: IFeesTab
  isManage?: boolean
  setManageInfo: (value: IManageInfo) => void
  manageInfo: IManageInfo
}

const useEventRegistrationTabs = () => {
  const dispatch = useAppDispatch()
  const ticketItemsForm = useForm({
    mode: 'onChange',
    defaultValues: ticketItemsDefaultValue,
  })
  const myHorses = useAppSelector(selectHorses)
  const feesItemsForm = useForm({
    mode: 'onChange',
    defaultValues: feesItemsDefaultValue,
  })

  const toastFunctions = useToasterHelper()
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registration = useAppSelector(selectRegistration)
  const ticketTabData = useAppSelector(selectTicketTabData)
  const publishedEventInDb = useAppSelector(selectPublishedEventInDb)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const registrationFees = useAppSelector(selectRegistrationFeesR)
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const teamMembers = useAppSelector(selectTeamTabData)
  const teamMembersInDb = useAppSelector(selectTeamTabDataInDb)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const registrationTickets = useAppSelector(selectRegistrationTicketsR)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const [manageInfo, setManageInfo] = useState<IManageInfo>({
    register: [],
    fees: [],
    tickets: [],
    paperwork: [],
  })
  const registrationsByDayInDb = useAppSelector(selectRegistrationsByDayInDb)

  const { eventId, userId } = useParams<ITypes['IParams']>()

  const [isManage, setIsManage] = useState<boolean>(false)
  const [registeredUser, setRegisteredUser] = useState<IUserInterface | null>(null)

  useEffect(() => {
    const getCurrentUser = async () => {
      const userSnaps = await UserService.getUserInfoById(userId)
      const user = UserModel.fromFirestoreDoc(userSnaps).toObject()
      setRegisteredUser(user)
    }

    if (userId) getCurrentUser().then()
  }, [userId])

  useEffect(() => {
    const getUniqueTeamMembers = async ({
      currentTeamTabData,
      registeredUser,
    }: {
      currentTeamTabData: IRegistrationTeamInterface[]
      registeredUser: IUserInterface
    }) => {
      let teamMembers_: IRegistrationTeamInterface[] = []

      const owner = getConvertedData(getSelectedUserAsTeamMember(registeredUser, registeredUser))
      if (registration?.id) {
        getTeamMembersFromDb(registration.id, registeredUser).then((currTeamMembers) => {
          teamMembers_ = [...currTeamMembers]
        })
      }

      const teamMembersData = [owner, ...teamMembers_, ...currentTeamTabData]

      dispatch(setUserTeamMembers(teamMembersData))
    }

    if (registeredUser)
      getUniqueTeamMembers({ currentTeamTabData: teamMembers, registeredUser }).then()
  }, [registration?.id, teamMembers, registeredUser])

  useEffect(() => {
    let userIdsToFetch: string[] = []

    teamMembersWithUser.forEach((currTeamMember) => {
      if (!!currTeamMember.memberId) userIdsToFetch.push(currTeamMember.memberId)
    })

    dispatch(updateUsersHolderAc(userIdsToFetch))
  }, [teamMembersWithUser])

  useEffect(() => {
    if (!eventId || !userId || !registeredUser) return

    async function loadAll() {
      dispatch(setRegistrationLoading(true))

      let registrationId: string | null = null
      let recipientsInDb: IRecipientInterface[] = []
      let registionFees_: IRegistrationFeesInterface[] = []
      let registration_: null | TEventRegisteredUsers = null
      let publishedEventInDb_: IEventReviewPublish | null = null
      let registrationsByDayFromDb_: IRegistrationByDayInterface[] = []
      let registerTabData_: IRegistrationTabs['IRegisterTab'][] = []
      let registrationTickets: IRegistrationTicketInterface[] = []
      let ticketTabData_: IRegistrationTabs['ITicketTab'][] = []
      let feesTabData_: IRegistrationTabs['IFeesTab'][] = []
      let filteredPaperworkDocuments: typeof fakeDocumentList = []
      let ridersTeamMembersInDb_: IRiderTeamMemberInterface[] = []
      let riderTeamMembersInDb_: IRegistrationTeamInterface[] = []

      try {
        publishedEventInDb_ = await getPublishedEventFromDb()

        registration_ = await getRegistrationFromDb()
        registrationId = registration_?.id ?? null

        if (registrationId) {
          registionFees_ = await getFeesFromDb()
          registrationsByDayFromDb_ = await getRegistrationsByDayFromDb(registrationId)
          registrationTickets = await getTicketTabDataFromDb(registrationId)
          ridersTeamMembersInDb_ = await getRiderTeamMembersFromDb(registrationId)
          riderTeamMembersInDb_ = await getRegistrationTeamsInDb(registrationId)
          recipientsInDb = await getRecipientsInDb(registrationId)
        }

        feesTabData_ = getFeesTabData(publishedEventInDb_)
        ticketTabData_ = getTicketTabData(publishedEventInDb_)
        registerTabData_ = getRegisterTabData(publishedEventInDb_)
        filteredPaperworkDocuments = getFilteredPaperwork(
          publishedEventInDb_?.EventPaperwork ?? null
        )
        registerTabData_ = mergeRegistrationWithTabData(
          registerTabData_,
          isManage ? [] : registrationsByDayFromDb_
        )
        ticketTabData_ = mergeTicketsWithTabData(ticketTabData_, registrationTickets)
        feesTabData_ = mergeFeesWithTabData(
          feesTabData_,
          registionFees_,
          isManage ? registrationsByDayInDb : registrationsByDay
        )
      } catch (error) {
        console.error(error, 'error')
      } finally {
        dispatch(setPublishedEventInDb(publishedEventInDb_))
        dispatch(setRegistrationAc(registration_))
        dispatch(setRecipientsInDb(recipientsInDb))
        dispatch(setRidersTeamMembersInDb(ridersTeamMembersInDb_))
        dispatch(setTeamTabDataInDb(riderTeamMembersInDb_))
        dispatch(setRegistrationsFeesAc(registionFees_))
        dispatch(setRegistrationsTicketsAc(registrationTickets))
        dispatch(setRegistrationsByDayInDb(registrationsByDayFromDb_))
        if (isManage) {
          setManageInfo({
            ...manageInfo,
            register: registerTabData_,
            fees: feesTabData_.map((feeData) => ({ ...feeData, registrationFees: null })),
            tickets: ticketTabData_.map((ticketData) => ({
              ...ticketData,
              registrationTicket: null,
            })),
          })
        } else {
          dispatch(setRegistrationsByDay(registrationsByDayFromDb_))
          dispatch(setRegisterTabData(registerTabData_))
          dispatch(setTicketTabData(ticketTabData_))
        }
        dispatch(setFeesTabDataAc(feesTabData_))
        dispatch(setFilteredPaperworkDocuments(filteredPaperworkDocuments))
        dispatch(setRegistrationLoading(false))
      }
    }
    loadAll().then()
  }, [eventId, isManage, userId, registeredUser, registration?.id])

  function getBasicQueries() {
    const { EVENT_ID, USER_ID } = COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS
    return [where(USER_ID.KEY, '==', userId), where(EVENT_ID.KEY, '==', eventId)]
  }

  const createTeamTabData = async (team: IRegistrationTeamInterface) => {
    return (
      (
        await FirestoreService.createItem(
          COLLECTIONS.REGISTRATION_TEAMS.NAME,
          new RegistrationTeamModel(team).toFirestore()
        )
      )?.id ?? null
    )
  }

  const removeTeamTabData = async (id: string) => {
    return await FirestoreService.deleteItem(COLLECTIONS.REGISTRATION_TEAMS.NAME, id)
  }

  const saveTeamTabData = async (registrationId: string) => {
    let id: string | null = null
    const teamsToAdd: IRegistrationTeamInterface[] = []
    const teamsToDelete: IRegistrationTeamInterface[] = []
    const teamsIds = teamMembers.map((ridersTeamMember) => ridersTeamMember.id)

    teamMembers.forEach((team) => {
      if (!team.id) {
        teamsToAdd.push({
          ...team,
          registrationDocId: registrationId,
          eventName: publishedEventInDb?.EventDetails.competitionName ?? '',
          eventId,
        })
      }
    })

    teamMembersInDb.forEach((team) => {
      if (team.id && !teamsIds.includes(team.id)) {
        teamsToDelete.push(team)
      }
    })

    try {
      await helpers.asyncWhileLoop({
        loopCount: teamsToAdd.length,
        functionToFire: async (currIndex) => {
          id = await createTeamTabData({
            ...new RegistrationTeamModel(teamsToAdd[currIndex]).toFirestore(),
          })
          teamsToAdd![currIndex] = getConvertedData({
            ...teamsToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      await helpers.asyncWhileLoop({
        loopCount: teamsToDelete.length,
        functionToFire: async (currIndex) => {
          if (!teamsToDelete![currIndex].id) return
          await removeTeamTabData(teamsToDelete[currIndex].id!)
        },
      })

      const riderTeamMembers__ = await getRegistrationTeamsInDb(registrationId)

      dispatch(setTeamTabData(riderTeamMembers__))
      dispatch(setTeamTabDataInDb(riderTeamMembers__))
    } catch (error: any) {
      helpers.logger({ message: `${error} in saveTeamTabData` })
    }
  }

  const updateRidersTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
      ridersTeamMember.id,
      new RiderTeamMemberModel(ridersTeamMember).toFirestore()
    )
  }

  const deleteRiderTeamMember = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, idOfDocToRemove)
  }

  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const saveRegisterTabData = async ({
    registrationsByDayToAdd,
    registrationsByDayToUpdate,
    registrationsByDayToDelete,
  }: ISaveRegisterTabDataFnArgs) => {
    let id: string | null = null
    let dontHaveRegistrationDocId
    let registrationsByDayUpdated: IRegistrationByDayInterface[] = []
    const registrationsByDay_ = cloneDeep(registrationsByDay)

    dontHaveRegistrationDocId = !!registrationsByDayToAdd?.find(
      (currRegistrationByDay) => !currRegistrationByDay.registrationDocId
    )

    if (dontHaveRegistrationDocId)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        message: "one of the registrationsByDay don't have registrationDocId in it",
        moduleName: 'saveRegisterTabData',
      })

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: registrationsByDayToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await saveRegistrationByDay(
            new RegistrationByDayModel(registrationsByDayToAdd![currIndex]).toFirestore()
          )
          registrationsByDayToAdd![currIndex] = getConvertedData({
            ...registrationsByDayToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: registrationsByDayToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!registrationsByDayToDelete![currIndex].id) return
          await deleteRegistrationByDay(registrationsByDayToDelete![currIndex].id as any)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: registrationsByDayToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRegistrationByDay({
            ...new RegistrationByDayModel(registrationsByDayToUpdate![currIndex]).toFirestore(),
            id: registrationsByDayToUpdate![currIndex].id,
          })
        },
      })

      if (registrationsByDayToDelete) {
        registrationsByDayUpdated = registrationsByDay.filter(
          (registration) => registration.id !== registrationsByDayToDelete[0].id
        )
      } else if (registrationsByDayToUpdate) {
        registrationsByDayUpdated = registrationsByDay_.map((registrationByDay) => {
          const filtered = registrationsByDayToUpdate.filter(
            (registrationByDayToUpdate) => registrationByDayToUpdate.id === registrationByDay.id
          )
          if (filtered.length > 0) {
            return filtered[0]
          } else {
            return registrationByDay
          }
        })
      } else {
        registrationsByDayUpdated = getConvertedData([
          ...registrationsByDay,
          ...(registrationsByDayToAdd ?? []),
        ])
      }

      const registerTabData_ = mergeRegistrationWithTabData(
        registerTabData,
        registrationsByDayUpdated
      )

      dispatch(setRegistrationsByDay(registrationsByDayUpdated))
      dispatch(setRegisterTabData(registerTabData_))
      return { registrationsByDayToAdd: registrationsByDayToAdd ?? null }
    } catch (error) {
      console.error(error, 'error')
      return { registrationsByDayToAdd: null }
    }
  }

  const saveRegistrationByDay = async (registrationsByDay: IRegistrationByDayInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.REGISTRATION_BY_DAY.NAME, registrationsByDay))
        ?.id ?? null
    )
  }

  const deleteRegistrationByDay = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.REGISTRATION_BY_DAY.NAME, idOfDocToRemove)
  }

  const updateRegistrationByDay = async (registrationsByDay: IRegistrationByDayInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      registrationsByDay.id,
      new RegistrationByDayModel(registrationsByDay).toFirestore()
    )
  }

  const saveRidersTeamMembers = async ({
    ridersTeamMembersToAdd,
    ridersTeamMembersToDelete,
    ridersTeamMembersToUpdate,
  }: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => {
    let id: string | null = null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'saveRidersTeamMembers',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
          message: 'Please change empty Rider or Role.',
        })
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!ridersTeamMembersToDelete![currIndex].id) return
          await deleteRiderTeamMember(ridersTeamMembersToDelete![currIndex].id!)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRidersTeamMember({
            ...new RiderTeamMemberModel(ridersTeamMembersToUpdate![currIndex]).toFirestore(),
            id: ridersTeamMembersToUpdate![currIndex].id,
          })
        },
      })

      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(registration?.id ?? '')

      const paperworkTabData__ = mergeRidersTeamMembersWithPaperworkTabData(
        paperworkTabData,
        ridersTeamMembersInDb__
      )

      dispatch(setRidersTeamMembers(ridersTeamMembersInDb__))
      dispatch(setRidersTeamMembersInDb(ridersTeamMembersInDb__))
      dispatch(setPaperworkTabData(paperworkTabData__))
    } catch (error) {
      console.error(error, 'error')
    }
  }

  const onInputChangeTicket = async ({
    currentRow,
    setManageInfo,
    manageInfo,
    isManage,
  }: ISaveTicketTabData) => {
    let data = ticketItemsForm.watch() ?? {}
    const currentTicket = cloneDeep(currentRow)
    if (!publishedEventInDb) return null

    const value = isNaN(data[currentTicket.ticketItemId])
      ? '0'
      : `${data[currentTicket.ticketItemId]}`

    try {
      const { EventDetails } = publishedEventInDb
      currentTicket.registrationTicket = getConvertedData({
        ...new RegistrationTicketModel({
          userId,
          ticketItemId: currentTicket.ticketItemId,
          refundId: null,
          recipientId: null,
          refundDocId: null,
          paymentStatus: null,
          paymentType: null,
          amountRefunded: 0,
          eventId,
          recipientProfilePicture: null,
          userName: registeredUser ? getUserFullName(registeredUser) : null,
          selectedUnitsCount: Number(value),
          remainingTicketsCount: Number(value),
          registrationDocId: registration?.id ?? null,
          eventName: EventDetails?.competitionName ?? null,
          amountScratched: 0,
          ticketTitle: currentTicket.ticketTitle,
          ticketPrice: currentTicket.ticketPrice ?? 0,
          id: currentTicket.registrationTicket?.id ?? null,
        }).toObject(),
      })

      let ticketTabData_

      if (isManage) {
        ticketTabData_ = cloneDeep(manageInfo.tickets)
      } else {
        ticketTabData_ = cloneDeep(ticketTabData)
      }

      const ticketTabDataIndex = ticketTabData_.findIndex(
        (ticket) => ticket.ticketItemId === currentTicket.ticketItemId
      )

      if (ticketTabDataIndex === -1) {
        ticketTabData_.push(currentTicket)
      } else {
        ticketTabData_[ticketTabDataIndex].registrationTicket = currentTicket.registrationTicket
      }

      if (isManage) {
        setManageInfo({ ...manageInfo, tickets: ticketTabData_ })
      } else {
        await saveTicketTabData({
          ...(currentTicket.registrationTicket?.id && {
            registrationTicketsToUpdate: [currentTicket.registrationTicket],
          }),
          ...(!currentTicket.registrationTicket?.id && {
            registrationTicketsToAdd: [currentTicket.registrationTicket],
          }),
        })
      }
    } catch (error) {
      console.error(error, 'error')
    }
  }

  const saveTicketTabData = async ({
    registrationTicketsToAdd,
    registrationTicketsToDelete,
    registrationTicketsToUpdate,
  }: {
    registrationTicketsToAdd?: IRegistrationTicketInterface[]
    registrationTicketsToDelete?: IRegistrationTicketInterface[]
    registrationTicketsToUpdate?: IRegistrationTicketInterface[]
  }) => {
    let id: string | null = null
    let registrationTicketsUpdated: IRegistrationTicketInterface[] = []

    const dontHaveRegistrationDocId = !!registrationTicketsToAdd?.find(
      (currRegistrationTicket) => !currRegistrationTicket.registrationDocId
    )

    try {
      if (dontHaveRegistrationDocId)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          message: "one of the registrationsByDay don't have registrationDocId in it",
          moduleName: 'saveTicketTabData',
        })

      // Add
      await helpers.asyncWhileLoop({
        loopCount: registrationTicketsToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await saveRegistrationTicket(
            new RegistrationTicketModel(registrationTicketsToAdd![currIndex]).toFirestore()
          )
          registrationTicketsToAdd![currIndex] = getConvertedData({
            ...registrationTicketsToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: registrationTicketsToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!registrationTicketsToDelete![currIndex].id) return
          await deleteRegistrationTicket(registrationTicketsToDelete![currIndex].id as any)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: registrationTicketsToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRegistrationTicket({
            ...new RegistrationTicketModel(registrationTicketsToUpdate![currIndex]).toFirestore(),
            id: registrationTicketsToUpdate![currIndex].id,
          })
        },
      })

      if (registrationTicketsToUpdate?.[0]?.id) {
        const newTickets = cloneDeep(registrationTickets)
        const index = newTickets.findIndex(
          (ticket) => ticket.id === registrationTicketsToUpdate[0].id
        )
        if (index !== -1) {
          newTickets[index] = registrationTicketsToUpdate[0]
        }
        registrationTicketsUpdated = newTickets
      } else {
        registrationTicketsUpdated = getConvertedData([
          ...registrationTickets,
          ...(registrationTicketsToAdd ?? []),
        ])
      }

      const ticketTabData_ = mergeTicketsWithTabData(ticketTabData, registrationTicketsUpdated)
      dispatch(setTicketTabData(ticketTabData_))
      dispatch(setRegistrationsTicketsAc(registrationTicketsUpdated))
    } catch (error: any) {
      helpers.logger({ message: `${error} in saveTicketTabData` })
    }
  }

  const saveRegistrationTicket = async (registrationTicket: IRegistrationTicketInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.REGISTRATION_TICKET.NAME, registrationTicket))
        ?.id ?? null
    )
  }

  const deleteRegistrationTicket = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.REGISTRATION_TICKET.NAME, idOfDocToRemove)
  }

  const updateRegistrationTicket = async (registrationTicket: IRegistrationTicketInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      registrationTicket.id,
      new RegistrationTicketModel(registrationTicket).toFirestore()
    )
  }

  const onChangeFees = async ({
    currentRow,
    setManageInfo,
    manageInfo,
    isManage,
  }: IOnChangeFees) => {
    const currentFees = cloneDeep(currentRow)
    const registrationsByDay_ = cloneDeep(registrationsByDay)
    const horsesIds: string[] = []

    if (!publishedEventInDb) return null

    registrationsByDay_.forEach((registrationsByDay) => {
      if (registrationsByDay.horseId && !horsesIds.includes(registrationsByDay.horseId))
        horsesIds.push(registrationsByDay.horseId)
    })

    try {
      const { EventDetails } = publishedEventInDb

      currentFees.registrationFees = getConvertedData({
        ...new RegistrationFeesModel({
          userId,
          feesItemId: currentFees.feesItemId,
          refundId: null,
          refundDocId: null,
          amountRefunded: 0,
          amountScratched: 0,
          paymentStatus: null,
          paymentType: null,
          eventId,
          selectedUnitsCount: currentFees.registrationFees?.selectedUnitsCount ?? 0,
          userName: registeredUser ? getUserFullName(registeredUser) : null,
          registrationDocId: registration?.id ?? null,
          eventName: EventDetails?.competitionName ?? null,
          recipientId: currentFees.registrationFees?.recipientId ?? null,
          feesTitle: currentFees.feesTitle,
          recipientName: currentFees.registrationFees?.recipientName ?? null,
          feesPrice: currentFees.feesPrice ?? 0,
          recipientProfilePicture: currentFees.registrationFees?.recipientProfilePicture ?? null,
          feesItemCategory: currentFees.feesCategory ?? null,
          id: currentFees.registrationFees?.id ?? null,
        }).toObject(),
      })

      let feesTabData_

      if (isManage) {
        feesTabData_ = cloneDeep(manageInfo.fees)
      } else {
        feesTabData_ = cloneDeep(feesTabData)
      }

      const feesTabDataIndex = feesTabData_.findIndex(
        (fees) => fees.feesItemId === currentFees.feesItemId
      )

      if (feesTabDataIndex === -1) {
        feesTabData_.push(currentFees)
      } else {
        feesTabData_[feesTabDataIndex].registrationFees = currentFees.registrationFees
      }
      if (isManage) {
        setManageInfo({ ...manageInfo, fees: feesTabData_ })
      } else {
        await saveFeesTabData({
          ...(currentFees.registrationFees?.id && {
            registrationFeesToUpdate: [currentFees.registrationFees],
          }),
          ...(!currentFees.registrationFees?.id && {
            registrationFeesToAdd: [currentFees.registrationFees],
          }),
        })
      }
    } catch (error: any) {
      helpers.logger({ message: `${error} in onChangeFees` })
    }
  }

  const saveFeesTabData = async ({
    registrationFeesToAdd,
    registrationFeesToUpdate,
  }: {
    registrationFeesToAdd?: IRegistrationFeesInterface[]
    registrationFeesToUpdate?: IRegistrationFeesInterface[]
  }) => {
    let id: string | null = null
    let dontHaveRegistrationDocId
    let registrationFeesUpdated: IRegistrationFeesInterface[] = []

    dontHaveRegistrationDocId = !!registrationFeesToAdd?.find((fees) => !fees.registrationDocId)

    try {
      if (dontHaveRegistrationDocId) {
        console.error("one of the registrationsByDay don't have registrationDocId in it")
      }

      // Add
      await helpers.asyncWhileLoop({
        loopCount: registrationFeesToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await saveRegistrationFees(
            new RegistrationFeesModel(registrationFeesToAdd![currIndex]).toFirestore()
          )
          registrationFeesToAdd![currIndex] = getConvertedData({
            ...registrationFeesToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: registrationFeesToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRegistrationFees({
            ...new RegistrationFeesModel(registrationFeesToUpdate![currIndex]).toFirestore(),
            id: registrationFeesToUpdate![currIndex].id,
          })
        },
      })
      if (registrationFeesToUpdate && registrationFeesToUpdate[0]) {
        const newRegistrationFees = cloneDeep(registrationFees)
        const index = registrationFees.findIndex(
          (fees) => fees.id === registrationFeesToUpdate[0].id
        )
        if (index !== -1) {
          newRegistrationFees[index] = registrationFeesToUpdate[0]
        }
        registrationFeesUpdated = newRegistrationFees
      } else {
        registrationFeesUpdated = getConvertedData([
          ...registrationFees,
          ...(registrationFeesToAdd ?? []),
        ])
      }
      const feesTabData_ = mergeFeesWithTabData(
        feesTabData,
        registrationFeesUpdated,
        registrationsByDay
      )
      dispatch(setFeesTabDataAc(feesTabData_))
      dispatch(setRegistrationsFeesAc(registrationFeesUpdated))
    } catch (error) {
      console.error(error)
    }
  }

  const saveRegistrationFees = async (registrationFees: IRegistrationFeesInterface) => {
    let id =
      (await FirestoreService.createItem(COLLECTIONS.REGISTRATION_FEES.NAME, registrationFees))
        ?.id ?? null
    return id
  }

  const updateRegistrationFees = async (registratonFees: IRegistrationFeesInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_FEES.NAME,
      registratonFees.id,
      new RegistrationFeesModel(registratonFees).toFirestore()
    )
  }

  const createRegistration = async () => {
    let EventDetails = publishedEventInDb?.EventDetails ?? null

    try {
      const horsesIds = myHorses.map((horse) => `${horse.id}`)
      const membersIds = teamMembers.map((member) => `${member.memberId}`)

      if (registration?.id) {
        await FirestoreService.updateItem(
          COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
          registration.id,
          {
            horsesIds,
            membersIds,
            modified: new Date().toString(),
          }
        )
      } else if (registeredUser?.id) {
        await FirestoreService.createItem(
          COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
          new EventRegisteredUsersModel({
            ...registeredUser,
            horsesIds,
            membersIds,
            eventId,
            eventName: EventDetails?.competitionName,
            eventLogo: EventDetails?.eventLogo,
            userId,
          }).toFirestore()
        )
      } else {
        console.error('Current registration not found')
        return null
      }

      const registration_ = await getRegistrationFromDb()
      dispatch(setRegistrationAc(registration_))
      return registration_
    } catch (error) {
      console.error(error, 'error')
      return null
    }
  }

  function getRegisterTabData(publishedEventInDb_: IEventReviewPublish | null) {
    let registerTabData_: IRegistrationTabs['IRegisterTab'][] = []
    let registrationFees_: RegistrationFeesType[] = []
    try {
      if (publishedEventInDb_) {
        const { EventFees } = publishedEventInDb_
        if ('registrationFees' in EventFees) {
          const { registrationFees } = EventFees
          if (registrationFees && registrationFees.length) {
            registrationFees_ = registrationFees
          }
        }
      }

      registrationFees_.forEach((fees) => {
        registerTabData_.push({
          feesNote: fees.note,
          registrationsByDay: [],
          price: fees.cost,
          startDate: fees.startDate,
          startTimeHours: fees.startTimeHours,
          startTimeMinutes: fees.startTimeMinutes,
          startTimeFormat: fees.startTimeFormat,
          priceAlias: fees.costAlias,
          registrationByDayName: fees.name ? (fees.name === '' ? 'Custom' : fees.name) : 'Custom',
          uuid: fees.uuid,
          order: fees.order,
          qualifyFee: fees.qualifyingClass?.price ?? null,
          qualifyFeeName: fees.qualifyingClass?.name || null,
          children:
            fees.children?.map((children) => ({
              feesNote: fees.note,
              registrationsByDay: [],
              price: children.cost,
              startDate: fees.startDate,
              startTimeHours: fees.startTimeHours,
              startTimeMinutes: fees.startTimeMinutes,
              startTimeFormat: fees.startTimeFormat,
              priceAlias: children.costAlias,
              registrationByDayName: children.name
                ? children.name === ''
                  ? 'Custom'
                  : children.name
                : 'Custom',
              uuid: children.uuid,
              order: children.order,
              qualifyFee: children.qualifyingClass?.price ?? null,
              qualifyFeeName: children.qualifyingClass?.name || null,
            })) ?? [],
        })
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return registerTabData_
    }
  }

  function getTicketTabData(publishedEventInDb_: IEventReviewPublish | null) {
    let ticketTabData_: IRegistrationTabs['ITicketTab'][] = []

    try {
      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        publishedEventInDb_,
        EventTickets: publishedEventInDb_?.EventTickets ?? null,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getTicketTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      const { EventTickets } = publishedEventInDb_ as IEventReviewPublish
      const { tickets, sellSpectatorTickets } = EventTickets

      if (!sellSpectatorTickets || !tickets?.length) return ticketTabData_

      tickets.forEach((ticketItemId) => {
        ticketTabData_.push({
          ticketItemId: ticketItemId.uuid,
          avaliability: ticketItemId.available,
          ticketPrice: ticketItemId.cost,
          ticketTitle: ticketItemId.name,
          ticketDescription: ticketItemId.note,
          registrationTicket: null,
        })
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return ticketTabData_
    }
  }

  function getFeesTabData(publishedEventInDb_: IEventReviewPublish | null) {
    let availablity = 0
    let isExtraFees = false
    let feesItem: any = null
    let availabilityExists: boolean = false
    let feesTabData_: IRegistrationTabs['IFeesTab'][] = []
    let keyofeesItemKeyName_: keyof IEventFees | null = null
    let feesItem_: ICerhTypes['IEvaluatedFeeItem'] | null = null

    try {
      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        publishedEventInDb_,
        EventFees: publishedEventInDb_?.EventFees ?? null,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getFeesTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      const { EventFees } = publishedEventInDb_ as IEventReviewPublish

      feesTabData_ = Object.keys(EventFees).reduce(
        (acc, feesItemKeyName) => {
          feesItem_ = null
          isExtraFees = false
          keyofeesItemKeyName_ = feesItemKeyName as any

          if (!keyofeesItemKeyName_) return acc

          feesItem = EventFees[keyofeesItemKeyName_]

          if (!feesItem) return acc
          if (feesItem && typeof feesItem === 'object' && !Array.isArray(feesItem))
            feesItem_ = cerh.getEvaluatedFeeItem(feesItem)
          else if (feesItem && Array.isArray(feesItem)) isExtraFees = true

          if (!feesItem) return acc

          if (isExtraFees) {
            feesItem.forEach((currentExtraFees: any) => {
              feesItem_ = null
              availabilityExists = false
              feesItem_ = cerh.getEvaluatedFeeItem(currentExtraFees)

              if (!feesItem_) return acc

              availabilityExists = (feesItem_?.available ?? 0) > 0
              availablity = availabilityExists ? feesItem_.available : 0

              if (availablity < 0) availablity = 0

              acc.push({
                registrationFees: null,
                feesCategory: feesItem_.category,
                feesDescription: feesItem_.note,
                feesPrice: feesItem_.cost,
                feesItemId: feesItem_.uuid,
                avaliability: availablity,
                feesTitle: feesItem_.name,
                feesDuration: feesItem_.duration,
              })
            })
          } else if (feesItem_ && feesItem.category !== 'refund') {
            if (Object.values(FEES_CATEGORY_CONST).includes(feesItem_.category)) {
              availabilityExists = (feesItem_?.available ?? 0) > 0
              availablity = availabilityExists ? feesItem_.available : 0

              if (availablity < 0) availablity = 0

              if (FEES_CATEGORY_CONST.GOVERNANCE === feesItem_?.category) {
                feesItem_.duration =
                  FEES_CATEGORY_CONST.GOVERNANCE === feesItem_.category
                    ? 'Per horse'
                    : feesItem_.duration
              }

              acc.push({
                registrationFees: null,
                feesCategory: feesItem_.category,
                feesDescription: feesItem_.note,
                feesPrice: feesItem_.cost,
                feesItemId: feesItem_.uuid,
                avaliability: availablity,
                feesTitle: feesItem_.name,
                feesDuration: feesItem_.duration,
              })
            }
          }

          return acc
        },
        [] as IRegistrationTabs['IFeesTab'][]
      )
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return feesTabData_
    }
  }

  async function getPublishedEventFromDb() {
    let publishedEventInDb_: IEventReviewPublish | null = null

    try {
      const publishedEventInDbSnapShot = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      if (publishedEventInDbSnapShot.exists())
        publishedEventInDb_ = getConvertedData(
          EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
        )
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return publishedEventInDb_
    }
  }

  async function getTeamMembersFromDb(
    registrationId: string | null,
    registeredUser: IUserInterface
  ) {
    let memberName: string = ''
    let userIdsList: string[] = []
    let usersList: IUserInterface[] = []
    let currUser: IUserInterface | null = null
    let usersSnaps: QueryDocumentSnapshot<DocumentData>[] = []
    let owner: IRegistrationTeamsTypes['IRegistrationTeamInterface'] | null = null
    let registrationTeams: IRegistrationTeamsTypes['IRegistrationTeamInterface'][] = []

    try {
      owner = getConvertedData(getSelectedUserAsTeamMember(registeredUser, registeredUser))
      owner.eventId = eventId
      registrationTeams.push(owner)

      if (!registrationId) return registrationTeams

      const registrationTeamMemberSnapshot = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_TEAMS.NAME,
        [where(COLLECTIONS.REGISTRATION_TEAMS.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId)]
      )

      registrationTeamMemberSnapshot.docs.forEach((currDoc) => {
        registrationTeams.push(
          getConvertedData(RegistrationTeamModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      registrationTeams.forEach((currMember) => {
        if (currMember?.memberId) userIdsList.push(currMember.memberId)
      })

      userIdsList = [...new Set(userIdsList)]

      usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsList)

      usersSnaps.forEach((curr) => {
        usersList.push(getConvertedData(UserModel.fromFirestoreDoc(curr).toObject()))
      })

      registrationTeams = registrationTeams.map((currMember) => {
        currUser = usersList.find((currUser_) => currUser_?.id === currMember.memberId) ?? null

        if (currUser) {
          memberName = getUserFullName(currUser)
        } else {
          memberName = currMember.memberName ?? 'Unknown'
        }

        if (!currUser) return getConvertedData(currMember)
        else
          return getConvertedData({
            ...currMember,
            memberName,
            memberEmail: currUser?.userEmail,
            memberProfilePicture: currUser?.userProfilePicture,
          })
      })
    } catch (e) {
      helpers.logger({
        isError: true,
        message: e,
      })
    } finally {
      return registrationTeams
    }
  }

  async function getRegistrationsByDayFromDb(registrationId: string) {
    let registrationsByDay_: IRegistrationByDayInterface[] = []

    try {
      const registrationByDaySnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      registrationByDaySnapShots.forEach((currDoc) => {
        registrationsByDay_.push(
          getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return registrationsByDay_
    }
  }

  async function getRegistrationFromDb() {
    let queries = getBasicQueries()
    let registration: null | TEventRegisteredUsers = null

    try {
      const registrationSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        queries,
        1
      )

      if (registrationSnapShots.size)
        registration = getConvertedData(
          EventRegisteredUsersModel.fromFirestoreDoc(registrationSnapShots.docs[0]).toObject()
        )
    } catch (error) {
      console.error(error, 'error')
    } finally {
      return registration
    }
  }

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  async function getRegistrationTeamsInDb(registrationId: string) {
    let riderTeamMembers: IRegistrationTeamInterface[] = []

    try {
      const registrationTeamsSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_TEAMS.NAME,
        [where(COLLECTIONS.REGISTRATION_TEAMS.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId)]
      )

      if (registrationTeamsSnapShots.size) {
        registrationTeamsSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RegistrationTeamModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  async function getRecipientsInDb(registrationId: string) {
    let recipients: IRecipientInterface[] = []

    try {
      const recipientSnapShots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId),
      ])

      if (recipientSnapShots.size) {
        recipientSnapShots.forEach((currDoc) => {
          recipients.push(getConvertedData(RecipientModel.fromFirestoreDoc(currDoc).toObject()))
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return recipients
    }
  }

  async function getTicketTabDataFromDb(registrationId: string) {
    let tickets_: IRegistrationTicketInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_TICKET.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          tickets_.push(
            getConvertedData(RegistrationTicketModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return tickets_
    }
  }

  async function getFeesFromDb() {
    let queries = getBasicQueries()
    let registionFees: IRegistrationFeesInterface[] = []

    try {
      const registrationFeesSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_FEES.NAME,
        queries
      )

      registrationFeesSnapShots.forEach((currDoc) => {
        registionFees.push(
          getConvertedData(RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    } catch (error: any) {
      helpers.logger(
        CustomError.somethingWentWrong({
          message: error?.message,
          devMessage: error?.message,
          fileName: FILE_NAME,
        })
      )
    } finally {
      return registionFees
    }
  }

  function mergeRegistrationWithTabData(
    registerTabData_: IRegistrationTabs['IRegisterTab'][],
    registrationsByDay_: IRegistrationByDayInterface[]
  ) {
    let registerTabData__ = cloneDeep(registerTabData_)
    let registrationsByDay__ = cloneDeep(registrationsByDay_)
    registerTabData__ = registerTabData__.map((currRow) => {
      return {
        ...currRow,
        registrationsByDay: [],
      }
    })
    return registerTabData__.map((item) => {
      const registrationsByDay = registrationsByDay__.filter(
        (obj) => obj.uuid === item.uuid && obj.registrationByDayName === item.registrationByDayName
      )

      const newChildren = cloneDeep(item?.children)?.map((child) => {
        const registrationsByDayChildren = registrationsByDay__.filter(
          (obj) => obj.uuid === child.uuid
        )
        return { ...child, registrationsByDay: registrationsByDayChildren }
      })

      return { ...item, registrationsByDay, children: newChildren }
    })
  }

  function mergeTicketsWithTabData(
    ticketTabData_: IRegistrationTabs['ITicketTab'][],
    registrationTickets_: IRegistrationTicketInterface[]
  ) {
    let foundRegistrationTicket: null | IRegistrationTicketInterface = null
    let ticketTabData__ = cloneDeep(ticketTabData_)
    let registrationTickets__ = cloneDeep(registrationTickets_)

    // Remove all registrationTickets
    ticketTabData__ = ticketTabData__.map((currRow) => {
      return {
        ...currRow,
        registrationTicket: null,
      }
    })

    ticketTabData__ = ticketTabData__.map((currRow) => {
      foundRegistrationTicket =
        registrationTickets__.find((currRegistrationTicket) => {
          return currRegistrationTicket.ticketItemId === currRow.ticketItemId
        }) ?? null

      return {
        ...currRow,
        registrationTicket: foundRegistrationTicket,
      }
    })

    return ticketTabData__
  }

  const onAddRiderButtonClick: IUseEventRegistrationTabsTypes['IOnAddRiderButtonClickFn'] = ({
    paperworkIndex,
    row,
    currentPaperworkTabData,
    manageInfo,
    setManageInfo,
    isManage,
  }) => {
    let paperworkTabData_ = cloneDeep(currentPaperworkTabData)
    let registrationByDay_

    if (isManage) {
      const currentRegistrationsByDay: IRegistrationByDayInterface[] = []

      manageInfo.register.forEach((register) => {
        register.registrationsByDay.forEach((registrationByDay) =>
          currentRegistrationsByDay.push(registrationByDay)
        )

        register.children?.forEach((child) => {
          child.registrationsByDay.forEach((registrationByDay) => {
            currentRegistrationsByDay.push(registrationByDay)
          })
        })
      })

      registrationByDay_ = currentRegistrationsByDay.find((currRegistrationByDayInDb) => {
        return (
          currRegistrationByDayInDb.uniqId === row?.registrationByDayUniqId &&
          currRegistrationByDayInDb.riderId === row.riderId
        )
      })
    } else {
      registrationByDay_ = registrationsByDay.find((currRegistrationByDayInDb) => {
        return (
          currRegistrationByDayInDb.uniqId === row?.registrationByDayUniqId &&
          currRegistrationByDayInDb.riderId === row.riderId
        )
      })
    }

    paperworkTabData_[paperworkIndex].ridersTeamMembers.push(
      getConvertedData({
        ...new RiderTeamMemberModel({
          riderDob: row?.riderDob ?? null,
          riderName: row?.riderName ?? null,
          riderId: row?.riderId ?? null,
          mailLog: [],
          teamMemberId: null,
          teamMemberName: null,
          teamMemberProfilePicture: null,
          teamMemberRole: null,
          teamMemberDob: null,
          teamMemberEmail: null,
          userId: registrationByDay_?.userId ?? null,
          eventId: registrationByDay_?.eventId ?? null,
          eventName: registrationByDay_?.eventName ?? null,
          registrationDocId: registrationByDay_?.registrationDocId ?? null,
          registrationByDayUuid: registrationByDay_?.uuid ?? null,
          registrationByDayUniqId: registrationByDay_?.uniqId || '',
        }).toObject(),
      })
    )

    let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
    paperworkTabData_.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0) {
        ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
      }
    })

    dispatch(setRidersTeamMembers(ridersTeamMembers_))

    if (isManage) {
      setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
    } else {
      dispatch(setPaperworkTabData(paperworkTabData_))
    }
  }

  const onRiderTeamMemberChange: ITypes['IOnRiderTeamMemberChangeFn'] = ({
    paperworkIndex,
    currentPaperworkTabData,
    currentTeamMember,
    ridersTeamMemberIndex,
    isManage,
    manageInfo,
    setManageInfo,
  }) => {
    let paperworkTabData_ = cloneDeep(currentPaperworkTabData)
    const ridersTeamMember =
      paperworkTabData_[paperworkIndex].ridersTeamMembers[ridersTeamMemberIndex]

    paperworkTabData_[paperworkIndex].ridersTeamMembers[ridersTeamMemberIndex] = {
      ...ridersTeamMember,
      teamMemberEmail: currentTeamMember.memberEmail,
      teamMemberRole: currentTeamMember.memberRole,
      teamMemberId: currentTeamMember.memberId,
      teamMemberName: currentTeamMember.memberName,
      teamMemberProfilePicture: currentTeamMember.memberProfilePicture,
      teamMemberDob: currentTeamMember.memberDob,
    }

    let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
    paperworkTabData_.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0) {
        ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
      }
    })

    dispatch(setRidersTeamMembers(ridersTeamMembers_))

    if (isManage) {
      setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
    } else {
      dispatch(setPaperworkTabData(paperworkTabData_))
    }
  }

  const onRiderTeamMemberRoleChange: ITypes['IOnRiderTeamMemberRoleChangeFn'] = async ({
    teamMemberId,
    teamMemberRole,
    paperworkIndex,
    currentPaperworkTabData,
    ridersTeamMemberIndex,
    manageInfo,
    setManageInfo,
    isManage,
  }) => {
    try {
      let paperworkTabData_ = cloneDeep(currentPaperworkTabData)

      if (!teamMemberId) {
        toastFunctions.info({
          message: `Please select team member first`,
        })
        return
      }

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        teamMemberId,
      })

      if (emptyVarName) {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            ...customErrorProps,
            moduleName: 'onRiderTeamMemberRoleChange',
            devMessage: `${emptyVarName} is ${emptyVarValue}`,
          }),
        })
        return
      }

      paperworkTabData_[paperworkIndex].ridersTeamMembers[ridersTeamMemberIndex].teamMemberRole =
        teamMemberRole

      let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
      paperworkTabData_.forEach((paperwork) => {
        if (paperwork.ridersTeamMembers.length > 0) {
          ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
        }
      })

      dispatch(setRidersTeamMembers(ridersTeamMembers_))

      if (isManage) {
        setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
      } else {
        dispatch(setPaperworkTabData(paperworkTabData_))
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    }
  }

  const onRemoveRiderTeamMemberClick: ITypes['IOnRemoveRiderTeamMemberClickFn'] = async ({
    paperworkIndex,
    currentPaperworkTabData,
    teamMemberIndex,
    manageInfo,
    setManageInfo,
    isManage,
  }) => {
    let paperworkTabData_ = cloneDeep(currentPaperworkTabData)

    try {
      paperworkTabData_[paperworkIndex].ridersTeamMembers.splice(teamMemberIndex, 1)

      let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
      paperworkTabData_.forEach((paperwork) => {
        if (paperwork.ridersTeamMembers.length > 0) {
          ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
        }
      })

      dispatch(setRidersTeamMembers(ridersTeamMembers_))

      if (isManage) {
        setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
      } else {
        dispatch(setPaperworkTabData(paperworkTabData_))
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    }
  }

  function mergeFeesWithTabData(
    feesTabData_: IRegistrationTabs['IFeesTab'][],
    registrationFees_: IRegistrationFeesInterface[],
    registrationsByDay_: IRegistrationByDayInterface[],
    checkIfWehaveInDb?: boolean
  ) {
    let foundRegistrationFees: null | IRegistrationFeesInterface = null
    const horsesIds: string[] = []
    let horsesIdsInDb: string[] = []
    let uniqueHorseIds: string[] = []

    let feesTabData__ = cloneDeep(feesTabData_)
    let registrationFees__ = cloneDeep(registrationFees_)
    let registrationsByDay__ = cloneDeep(registrationsByDay_)

    // Remove all registrationFees
    feesTabData__ = feesTabData__.map((currRow) => {
      return {
        ...currRow,
        registrationFees: null,
      }
    })

    feesTabData__ = feesTabData__.map((currRow) => {
      foundRegistrationFees =
        registrationFees__.find((currRegistrationFees) => {
          return currRegistrationFees.feesItemId === currRow.feesItemId
        }) ?? null

      if (!foundRegistrationFees && currRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
        foundRegistrationFees = new RegistrationFeesModel({
          userId,
          create: true,
          refundId: null,
          eventName: registration?.eventName ?? '',
          recipientId: null,
          amountRefunded: 0,
          paymentStatus: null,
          paymentType: null,
          refundDocId: null,
          amountScratched: 0,
          eventId,
          recipientProfilePicture: null,
          feesItemId: currRow.feesItemId,
          selectedUnitsCount: currRow.registrationFees?.selectedUnitsCount ?? 0,
          registrationDocId: registration?.id ?? null,
          feesTitle: currRow.feesTitle,
          feesPrice: currRow.feesPrice ?? 0,
          userName: registeredUser ? getUserFullName(registeredUser) : null,
          feesItemCategory: currRow.feesCategory ?? null,
        }).toObject()
      }

      if (foundRegistrationFees && currRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
        registrationsByDay__.forEach((registrationByDay) => {
          if (registrationByDay.horseIds) horsesIds.push(...registrationByDay.horseIds)
        })
        uniqueHorseIds = uniq(horsesIds)

        if (checkIfWehaveInDb) {
          registrationsByDayInDb.forEach((registrationByDay) => {
            if (registrationByDay.horseIds) horsesIdsInDb.push(...registrationByDay.horseIds)
          })
          uniqueHorseIds = uniq(horsesIds).filter((item) => !horsesIdsInDb.includes(item))
        }

        foundRegistrationFees.selectedUnitsCount = uniqueHorseIds.length
      }

      return {
        ...currRow,
        registrationFees:
          foundRegistrationFees && foundRegistrationFees.selectedUnitsCount > 0
            ? getConvertedData(foundRegistrationFees)
            : null,
      }
    })

    return feesTabData__
  }

  return {
    createRegistration,
    saveTeamTabData,
    saveRidersTeamMembers,
    onAddRiderButtonClick,
    onRiderTeamMemberChange,
    onRemoveRiderTeamMemberClick,
    onRiderTeamMemberRoleChange,
    feesItemsForm: feesItemsForm,
    ticketItemsForm: ticketItemsForm,
    onInputChangeTicket,
    onChangeFees,
    saveRegisterTabData,
    saveTicketTabData,
    setIsManage,
    registeredUser,
    manageInfo,
    setManageInfo,
    mergeFeesWithTabData,
  }
}

export default useEventRegistrationTabs
