import React from 'react'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CommentsQuestion } from '../../manage/clinic/components/CommentsQuestion'
import { IQuestion } from '../../../../models/events/event.interface'

interface IProps {
  comment: string
  onChange: (value: string) => void
  question: IQuestion
  isExhibitor?: boolean
  files: string[]
  editMode: boolean
  cancelHandler: () => void
  saveHandler: (value: string, files: string[]) => Promise<null | undefined>
  // removeHandler: () => void
}
export const ExhibitorAnswerInput: React.FC<IProps> = ({
  cancelHandler,
  comment,
  files,
  question,
  editMode,
  saveHandler,
  isExhibitor,
}) => {
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [newFiles, setNewFiles] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const toastFunctions = useToasterHelper()

  React.useEffect(() => {
    setIsEditMode(editMode)
  }, [editMode])

  React.useEffect(() => {
    setNewFiles(files)
  }, [files])
  React.useEffect(() => {
    setValue(comment)
  }, [comment])

  if (!isEditMode) {
    return (
      <div className={'w-full flex gap-5'}>
        <div className={'w-full bg-[#F6F7FB] rounded-lg'}>
          <div className={'p-4 text-[#122B46]'}>
            <p>{value}</p>
          </div>
          {newFiles && newFiles.length > 0 && (
            <div className="p-4 border-t-[1px] border-solid border-[#D3DAEE] flex gap-3 flex-wrap">
              {newFiles.map((f, index) => (
                <a
                  key={index}
                  href={f}
                  rel="noreferrer"
                  target={'_blank'}
                  className={'w-[45px] h-[45px] bg-[#D3DAEE] rounded-lg'}
                >
                  <img className={'w-[45px] h-[45px] rounded-lg'} src={f} alt={`img-${index}`} />
                </a>
              ))}
            </div>
          )}
        </div>

        {isExhibitor && (
          <button onClick={() => setIsEditMode(true)} className={'underline hover:no-underline'}>
            Edit
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      <CommentsQuestion
        isRequired={false}
        addFile={(v) => setNewFiles([...newFiles, v])}
        files={newFiles}
        questionId={question.id}
        defaultValue={value}
        handleChange={(value) => setValue(value)}
        title={''}
        removeFile={(_v, i) => {
          const updatedFiles = [...newFiles]
          updatedFiles.splice(i, 1)
          setNewFiles(updatedFiles)
        }}
      />
      <div className={'flex justify-end mt-3 gap-8'}>
        <button
          disabled={loading}
          className="text-[14px] text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline"
          onClick={() => {
            cancelHandler()
            setIsEditMode(false)
            setValue(comment)
            setNewFiles(files)
          }}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          onClick={async () => {
            if (question.isRequired && !value) {
              return toastFunctions.error({ message: 'Answer is required' })
            }
            try {
              setLoading(true)
              await saveHandler(value, newFiles)
              setIsEditMode(false)
            } catch (e) {
              console.error(e)
            } finally {
              setLoading(false)
            }
          }}
          className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor border-solid saveBtn"
        >
          {loading ? 'Loading...' : 'Save'}
        </button>
      </div>
    </>
  )
}
