import { useState } from 'react'
import helpers from '../../../../../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../../helpers/ToasterHelper'
import useProfileHook from '../../../../../../../hooks/users/competitor/profile/useProfileHook'
import { httpService } from '../../../../../../../services/httpService'
import { useAppSelector } from '../../../../../../../store/hooks'
import { selectProfileData } from '../../../../../../../store/user/userSlice'
import IStripeInterface from '../../../../../../../types/stripe.types'
import MainModal from '../../../../../../modals/common/MainModal'
import { MODAL_CONSTS } from '../../../../../../../const/modal-const'

// Types
type IUserEmailUpdateProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const ManageStripeConnectAccountModal = ({ show, handleModal }: IUserEmailUpdateProps) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()
  const profileData = useAppSelector(selectProfileData)

  const [openTab, setOpenTab] = useState<'change' | 'remove' | null>(null)
  const [loading, setLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const { updateUserDetails, updateStripeConnectDetails } = useProfileHook({ dontFetch: true })

  const updateProfile = async (accountId: null) => {
    try {
      const res = await updateUserDetails({
        userStripeAccountId: accountId,
        userStripeAccountStatus: null,
      })
      if (res.status) {
        toastFunctions.success({
          message: 'Stripe account removed successfully',
        })
      }

      setRemoveLoading(false)
      handleModal(false, MODAL_CONSTS.MANAGE_STRIPE_CONNECT_ACCOUNT)
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const getAvailableStripeBalance = async (): Promise<
    IStripeInterface['IStripeBalance'] | null
  > => {
    if (!profileData?.userStripeAccountId) return null

    let balanceResponse: IStripeInterface['IStripeBalance'] | null = null

    try {
      const response: any = await httpService({
        method: 'GET',
        url: `get_stripe_connect_balance/${profileData?.userStripeAccountId}`,
      })

      balanceResponse = response?.data?.balance ?? null
    } catch (error: any) {
      let message = error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG

      toastFunctions.error({
        message,
      })

      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return balanceResponse
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info checks if the account can be removed from stripe
   * @param remove true means remove the account, else its called for updating the email
   */
  const checkAccountCanBeRemoved = async (): Promise<boolean> => {
    let canBeRemoved = false
    let availableFunds_ = 0
    let foundFoundAtIndex = -1

    let message = MESSAGES_CONST.SOMETHING_WENT_WRONG

    try {
      const availableBalance = await getAvailableStripeBalance()

      if (!availableBalance) return canBeRemoved

      const { available, instant_available, pending } = availableBalance

      const pendingFunds = pending?.[0]?.amount ?? 0
      const availableFunds = available?.[0]?.amount ?? 0
      const instantAvailableFunds = instant_available?.[0]?.amount ?? 0

      const fundsKeysArr = ['pending funds', 'available funds', 'instant funds']
      const fundsArr = [pendingFunds, availableFunds, instantAvailableFunds].map((cf) =>
        Number((cf / 100).toFixed(2))
      )

      const areFundsAvailable = !!fundsArr.find((currentFund, index) => {
        if (currentFund > 0) {
          foundFoundAtIndex = index
          availableFunds_ = currentFund
          return true
        }
        return false
      })

      if (areFundsAvailable) {
        canBeRemoved = false

        message = MESSAGES_CONST.UNABLE_TO_REMOVE_ACCOUNT
        message = message
          .replace('[AVAILABLE_FUNDS]', `$${availableFunds_}`)
          .replace('[BALANCE_TYPE]', fundsKeysArr[foundFoundAtIndex])

        toastFunctions.error({
          message,
        })
      } else canBeRemoved = true
    } catch (error) {
      canBeRemoved = false
    } finally {
      return canBeRemoved
    }
  }

  const removeStripeAccountFromDb = async (showMessage?: boolean) => {
    const response = await updateStripeConnectDetails(null, null)
    if (response?.status) {
      if (showMessage)
        toastFunctions.success({
          message: MESSAGES_CONST.STRIPE_ACCOUNT_DELETE,
        })
    }
  }

  const handleRemoveConnectAccount = async (userStripeAccountId: string | null) => {
    setRemoveLoading(true)

    try {
      const canBeRemoved = await checkAccountCanBeRemoved()

      if (!canBeRemoved) return

      if (userStripeAccountId) {
        await httpService({
          url: `disconnect_stripe_connect_account/${userStripeAccountId}`,
          method: 'POST',
        })

        updateProfile(null)
      }
    } catch (error: any) {
      let responseFromServer = error?.response?.data
      let message = responseFromServer?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      if (responseFromServer?.data?.removeAccount) await removeStripeAccountFromDb()

      toastFunctions.error({ message })

      helpers.logger({
        isError: true,
        message,
      })
    } finally {
      setRemoveLoading(false)
    }
  }

  const goToLink = (link: string) => {
    const AnchorTag = document.createElement('a')
    AnchorTag.href = link
    AnchorTag.target = '_blank'
    document.body.appendChild(AnchorTag)
    AnchorTag.click()
    AnchorTag.remove()
  }

  const onChangeLinkedAccountClick = async () => {
    setLoading(true)

    try {
      const result: any = await httpService({
        url: `create_account_link/${profileData?.userStripeAccountId}`,
        method: 'POST',
        data: {
          email: profileData?.userEmail,
          country: profileData?.userNationality,
        },
      })

      let url = result?.data.accountLink.url

      if (!url) throw new Error(`url is ${url}`)

      goToLink(url)
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  const onNextButtonClick = () => {
    if (openTab === 'change') {
      onChangeLinkedAccountClick()
    } else {
      handleRemoveConnectAccount(profileData?.userStripeAccountId ?? null)
    }
  }

  return (
    <MainModal
      type="MANAGE_STRIPE_CONNECT_ACCOUNT"
      size="md"
      show={show}
      className="!px-0"
      title={'Managed bank account'}
      setHeightAsPerContent={true}
      buttons={[
        {
          label: 'NEXT',
          onClick: onNextButtonClick,
          loading: removeLoading || loading,
          disabled: removeLoading || loading || !openTab || !profileData?.userStripeAccountStatus,
        },
      ]}
    >
      <div className="overflow-y-auto">
        <div className="flex flex-wrap items-start pb-3">
          <div className="w-full flex flex-col pr-2">
            <div className="mt-3 mb-8 text-SeabiscuitDark200ThemeColor text-[16px]">
              Change your linked bank account at any time.
              <br />
              <br />
              You cannot remove your bank account until you close all your events and transfer all
              your funds out of your Pegasus account.
            </div>
          </div>

          {/* Tabs */}
          <div className="w-full min-h-[300px] h-full">
            <div className="w-full grid grid-cols-2 gap-3 ">
              <div
                role="button"
                onClick={() => setOpenTab('change')}
                className={`w-full mx-auto  flex flex-col py-2 px-4 border rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor
                                        ${openTab === 'change' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
              >
                {openTab === 'change' ? (
                  <img src="/assets/og_icons/Synchronize-4.svg" className="w-[30px]" alt="change" />
                ) : (
                  <img
                    src="/assets/og_icons/Synchronize.svg"
                    className="w-[30px]"
                    alt="synchronize"
                  />
                )}
                <span
                  className={`text-sm font-normal leading-[25px] italic ${openTab === 'change' ? 'text-[#fff]' : ''}`}
                >
                  Change account
                </span>
                <span
                  className={`text-base font-normal leading-[24px] ${openTab === 'change' ? 'text-[#fff]' : ''}`}
                >
                  Change linked bank account
                </span>
              </div>

              <div
                role="button"
                onClick={() => setOpenTab('remove')}
                className={`w-full mx-auto flex flex-col py-2 px-4 border  rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE]
                                        ${openTab === 'remove' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
              >
                {openTab === 'remove' ? (
                  <img src="/assets/cp_icons/Cancel-4.svg" className="w-[30px]" alt="cancel" />
                ) : (
                  <img src="/assets/cp_icons/Cancel.svg" className="w-[30px]" alt="cancel" />
                )}

                <span
                  className={`text-sm font-normal leading-[25px] italic ${openTab === 'remove' ? 'text-[#fff]' : ''}`}
                >
                  Remove account
                </span>
                <span
                  className={`text-base font-normal leading-[24px] ${openTab === 'remove' ? 'text-[#fff]' : ''}`}
                >
                  Remove this bank account
                </span>
              </div>
            </div>
          </div>
          {/* Tabs */}
        </div>
      </div>
    </MainModal>
  )
}

export default ManageStripeConnectAccountModal
