import React, { FC, useEffect, useState } from 'react'

import ViewsLoader from '../../loader/ViewsLoader'

import { ISelectedEvent } from '../../../store/events/eventsSlice'

import {
  IUserCards,
  IUserInterface,
  IUserInterfaceExtended,
} from '../../../models/users/user.interface'

import { setPaymentMethods } from '../../../helpers/payment'

interface SelectPaymentMethodProps {
  selectedCard: IUserCards | null
  setSelectedCard: (card: IUserCards | null) => void
  openAddNewCardModal: () => void
  selectedEvent?: ISelectedEvent
  setIsPayByCash?: (value: boolean) => void
  isPayByCash?: boolean
  userProfile: IUserInterfaceExtended | null
  organiserProfile: IUserInterfaceExtended | null
}

export const SelectPaymentMethod: FC<SelectPaymentMethodProps> = ({
  selectedCard,
  setSelectedCard,
  openAddNewCardModal,
  setIsPayByCash,
  isPayByCash,
  selectedEvent,
  userProfile,
  organiserProfile,
}) => {
  const [loading, setLoading] = useState(false)
  const [userCards, setUserCards] = useState<IUserCards[]>([])
  const getPaymentMethods = async (user: IUserInterface, organiser: IUserInterface) => {
    setLoading(true)

    const payments = await setPaymentMethods(user, organiser)
    setUserCards(payments)
    setLoading(false)
  }

  useEffect(() => {
    if (userProfile?.id && organiserProfile?.id)
      getPaymentMethods(userProfile, organiserProfile).then()
  }, [userProfile, organiserProfile])

  if (loading) {
    return (
      <div className="relative py-2">
        <ViewsLoader />
      </div>
    )
  }

  return (
    <div className="mt-3 w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
      <div className="allCards">
        <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">Select payment method</p>
        {userCards && userCards.length > 0
          ? userCards.map((currCard, cardIndex) => {
              return currCard.pmId ? (
                <div
                  className="mb-2 relative"
                  onClick={() => {
                    setSelectedCard(currCard)
                    if (setIsPayByCash) setIsPayByCash(false)
                  }}
                  key={`${JSON.stringify(currCard)}${cardIndex}`}
                >
                  <span className="absolute left-2 top-2 cursor-pointer">
                    <img src={'assets/img/dark/credit-card.svg'} alt="icons" />
                  </span>
                  <input
                    type="text"
                    readOnly
                    placeholder={`****-****-****-${currCard?.cardNumber}`}
                    className="w-full pl-10 bg-gray-50 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer "
                    value={`****-****-****-${currCard.cardNumber}`}
                  />
                  {selectedCard?.pmId === currCard.pmId && !isPayByCash ? (
                    <span className="px-2 absolute right-2 top-3">
                      <img alt="icons" src={'assets/og_icons/Ok-1.svg'} />
                    </span>
                  ) : null}
                </div>
              ) : null
            })
          : null}
        <div className="mb-2 relative cursor-pointer flex items-center">
          <span className="absolute left-2">
            <img src={'assets/img/dark/credit-card.svg'} alt="icons" />
          </span>
          <input
            type="text"
            readOnly
            placeholder="Add a new card"
            className="w-full pl-10 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer"
            value="Add a new card >"
            onClick={openAddNewCardModal}
          />
        </div>
        {selectedEvent?.EventPaymentSettings?.allowRegistrationWithoutPayment && (
          <div className="mb-2 relative cursor-pointer flex items-center">
            <span className="absolute left-2">
              <img src={'assets/img/dark/cash.svg'} alt="icons" />
            </span>
            <input
              type="text"
              readOnly
              placeholder=""
              className="w-full pl-10 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer"
              value="Pay by cash or check at the show"
              onClick={() => setIsPayByCash && setIsPayByCash(true)}
            />
            {isPayByCash ? (
              <span className="px-2 absolute right-2 top-3">
                <img alt="icons" src={'assets/og_icons/Ok-1.svg'} />
              </span>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}
