import MainModal from '../../../components/modals/common/MainModal'

// Types
type Props = {
  show: boolean
  handleModal?: any
  dataToPassOn?: any
  positionModel?: string
}

const HelpPageModal = (props: Props) => {
  return (
    <MainModal
      size="3xl"
      hideCloseButton={true}
      show={props.show}
      modalClassName={'!z-[90]'}
      type="HELP_VIDEO"
      titleClassName="text-SeabiscuitDark200ThemeColor"
    >
      <div className="min-h-[calc(100vh-200px)]">{props.dataToPassOn?.component}</div>
    </MainModal>
  )
}

export default HelpPageModal
