import { CONST } from '../../const/const'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IRecipientInterface } from './recipients.interface'

// Constants
const RECIPIENT_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT

export class RecipientModel extends ModelBaseModel<IRecipientInterface> {
  // Properties
  public v: IRecipientInterface['v']
  public id: IRecipientInterface['id']
  public userId: IRecipientInterface['userId']
  public registrationDate: IRecipientInterface['registrationDate']
  public recipientProfilePicture: IRecipientInterface['recipientProfilePicture']
  public recipientId: IRecipientInterface['recipientId']
  public recipientName: IRecipientInterface['recipientName']
  public registrationDocId: IRecipientInterface['registrationDocId']
  public userName: IRecipientInterface['userName']
  public eventId: IRecipientInterface['eventId']
  public refundStatus: IRecipientInterface['refundStatus']
  public eventName: IRecipientInterface['eventName']
  public amountRefunded: IRecipientInterface['amountRefunded']
  public hasRefundDocs: IRecipientInterface['hasRefundDocs']
  public amountScratched: IRecipientInterface['amountScratched']
  public paymentStatus: IRecipientInterface['paymentStatus']
  public amountPaid: IRecipientInterface['amountPaid']
  public comments: IRecipientInterface['comments']
  public created: IRecipientInterface['created']
  public modified: IRecipientInterface['modified']
  public refundedAt: IRecipientInterface['refundedAt']
  public cardNo: IRecipientInterface['cardNo']

  public constructor(obj?: IRecipientInterface) {
    super()

    this.id = obj?.id ?? RECIPIENT_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.v = this.v ?? RECIPIENT_COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.userId = obj?.userId ?? RECIPIENT_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? RECIPIENT_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.recipientId = obj?.recipientId ?? RECIPIENT_COLLECTION.FIELDS.RECIPIENT_ID.VALUES.DEFAULT
    this.comments = obj?.comments ?? RECIPIENT_COLLECTION.FIELDS.COMMENTS.VALUES.DEFAULT
    this.hasRefundDocs =
      obj?.hasRefundDocs ?? RECIPIENT_COLLECTION.FIELDS.HAS_REFUND_DOCS.VALUES.DEFAULT
    this.registrationDate =
      obj?.registrationDate ?? RECIPIENT_COLLECTION.FIELDS.REGISTRATION_DATE.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ?? RECIPIENT_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.recipientProfilePicture =
      obj?.recipientProfilePicture ??
      RECIPIENT_COLLECTION.FIELDS.RECIPIENT_PROFILE_PICTURE.VALUES.DEFAULT
    this.recipientName =
      obj?.recipientName ?? RECIPIENT_COLLECTION.FIELDS.RECIPIENT_NAME.VALUES.DEFAULT
    this.refundStatus =
      obj?.refundStatus ?? RECIPIENT_COLLECTION.FIELDS.REFUND_STATUS.VALUES.DEFAULT
    this.userName = obj?.userName ?? RECIPIENT_COLLECTION.FIELDS.USER_NAME.VALUES.DEFAULT
    this.eventName = obj?.eventName ?? RECIPIENT_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.amountRefunded =
      obj?.amountRefunded ?? RECIPIENT_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.amountScratched =
      obj?.amountScratched ?? RECIPIENT_COLLECTION.FIELDS.AMOUNT_SCRATCHED.VALUES.DEFAULT
    this.amountPaid = obj?.amountPaid ?? RECIPIENT_COLLECTION.FIELDS.AMOUNT_PAID.VALUES.DEFAULT
    this.paymentStatus =
      obj?.paymentStatus ?? RECIPIENT_COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.cardNo = obj?.cardNo ?? RECIPIENT_COLLECTION.FIELDS.CARD_NUMBER.VALUES.DEFAULT
    this.refundedAt = obj?.refundedAt ?? RECIPIENT_COLLECTION.FIELDS.REFUNDEDAT.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }
  static fromFirestoreDoc(doc: any) {
    return new RecipientModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
