export const IconPassword = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#122B46"
        d="M12 2C9.8 2 8 3.8 8 6v2H6.25A2.26 2.26 0 0 0 4 10.25v9.5A2.26 2.26 0 0 0 6.25 22h11.5A2.26 2.26 0 0 0 20 19.75v-9.5A2.26 2.26 0 0 0 17.75 8H16V6c0-2.2-1.8-4-4-4m0 1.5c1.39 0 2.5 1.11 2.5 2.5v2h-5V6c0-1.39 1.11-2.5 2.5-2.5m-5.75 6h11.5c.423 0 .75.327.75.75v9.5c0 .423-.327.75-.75.75H6.25a.74.74 0 0 1-.75-.75v-9.5c0-.423.327-.75.75-.75m5.75 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"
      ></path>
    </svg>
  )
}
