import React, { Fragment, useEffect, useState } from 'react'

import { ChevronRight } from '@mui/icons-material'
import { useParams } from 'react-router'

import WrapperWithHeader from '../../components/common/wrappers/WrapperWithHeader'
import ViewsLoader from '../../components/loader/ViewsLoader'
import { ProfileItem } from '../../components/user/views/profile/components/ProfileItem'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getConvertedData } from '../../models/interface.helper'
import { getUserFullName } from '../../helpers/helpers'
import useToasterHelper from '../../helpers/ToasterHelper'

import helpers from '../../commonHelpers/helpers'

import { IHorseTeamInterface } from '../../models/horse-team/horseTeam.interface'
import { HorseTeamModel } from '../../models/horse-team/horseTeam.model'
import { IHorseData } from '../../models/horse/horse.interface'

import FirestoreService from '../../services/firestoreService'
import {
  horseSliceThunks,
  selectHorseReducer,
  setSelectedHorseTeam,
  setSelectedHorseTeamInDb,
  setTeamMemberInMappings,
} from '../../store/horses/horseSlice'
import { selectAllUsers } from '../../store/users/usersSlice'

import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import { ITEAMMEMBER_MODAL_TYPES } from '../../components/home/modals/UserProfileTeamTabAddTeamMember'
import { IUserInterfaceExtended } from '../../models/users/user.interface'

// Types
type Props = {
  addHorse: boolean
  setActiveTab?: any
  isView?: any
  horseId: string | null
  selectedHorse: IHorseData | null
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// Constants
const OWNER = 'Owner'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const getOwner = (
  teamMembers: IHorseTeamInterface[],
  profileDetails: IUserInterfaceExtended
) => {
  let [horseOwner] = teamMembers.filter((item) => {
    return item.memberRole === OWNER
  })

  if (!horseOwner?.id) {
    horseOwner = {
      ...new HorseTeamModel(),
      memberId: profileDetails.id,
      memberRole: 'Owner',
      memberName: getUserFullName(profileDetails),
      memberProfilePicture: profileDetails.userProfilePicture,
    }
  }

  return horseOwner
}

const createHorseTeamMember = async (horse: IHorseTeamInterface) => {
  return (
    (
      await FirestoreService.createItem(
        COLLECTIONS.HORSE_TEAM.NAME,
        new HorseTeamModel(horse).toFirestore()
      )
    )?.id ?? null
  )
}

const updateHorseTeamMember = async (horse: IHorseTeamInterface) => {
  await FirestoreService.updateItem(
    COLLECTIONS.HORSE_TEAM.NAME,
    horse.id,
    new HorseTeamModel(horse).toFirestore()
  )
}

const deleteHorseTeamMember = async (idOfDocToRemove: string) => {
  await FirestoreService.deleteItem(COLLECTIONS.HORSE_TEAM.NAME, idOfDocToRemove)
}

const HorseRootEditTeamMemberTab = (props: Props) => {
  const { userHorseMappingId } = useParams<{ userHorseMappingId: string }>()

  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const profileDetails = useAppSelector((state) => state.user.profileDetails)
  const horseReducer = useAppSelector(selectHorseReducer)
  const allUsers = useAppSelector(selectAllUsers)

  const selectedHorse = horseReducer.selected.horse.data
  const selectedHorseTeam = horseReducer.selected.team.data
  const selectedHorseMapping = horseReducer.selected.mapping.data
  const horseTeamMembersInDb = horseReducer.selected.team.dataInDb

  const [horseTeamMembers, setHorseTeamMembers] = useState<IHorseTeamInterface[]>([])

  useEffect(() => {
    if (!horseReducer.selected.mapping.data?.id || horseReducer.selected.team.dataInDb.length)
      return

    dispatch(
      horseSliceThunks.getHorseTeamMembersThunk({
        userHorseMappingDocId: horseReducer.selected.mapping.data?.id,
      })
    )
  }, [horseReducer.selected.mapping.data?.id])

  // Functions
  const openAddTeamMemberModal = () => {
    if (!validateAction()) return

    props.handleModal(true, MODAL_CONSTS.TEAM, {
      modal_type: ITEAMMEMBER_MODAL_TYPES.HORSE,
      userHorseMappingId: userHorseMappingId,
      saveHandler: onSubmit,
    })
  }

  useEffect(() => {
    setHorseTeamMembers(
      props.isView
        ? selectedHorseTeam
        : [getOwner(selectedHorseTeam, profileDetails), ...selectedHorseTeam]
    )
  }, [selectedHorseTeam, profileDetails])

  const validateAction = (): boolean => {
    if (!selectedHorse) {
      toastFunctions.error({
        message: MESSAGES_CONST.PLEASE_CREATE_HORSE_FIRST,
      })
      return false
    }

    if (!selectedHorse?.id || !selectedHorseMapping?.id) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return false
    }

    return true
  }

  const onSubmit = async (updatedSelectedHorseTeam: IHorseTeamInterface[]) => {
    let existsInDb = false
    let teamMembersToCreate: IHorseTeamInterface[] = []
    let teamMembersToUpdate: IHorseTeamInterface[] = []
    let teamMembersToDelete: IHorseTeamInterface[] = []
    let updatedTeamMembersInDb: IHorseTeamInterface[] = []

    if (!validateAction()) return

    try {
      updatedSelectedHorseTeam.forEach((currMember) => {
        existsInDb = !!horseTeamMembersInDb.find(
          (currMemberInDb) => currMemberInDb.memberId === currMember.memberId
        )
        if (existsInDb) teamMembersToUpdate.push(currMember)
        else teamMembersToCreate.push(currMember)

        updatedTeamMembersInDb.push(currMember)
      })

      teamMembersToDelete = horseTeamMembersInDb.filter((currMemberInDb) => {
        return updatedSelectedHorseTeam.every(
          (currMember) => currMemberInDb.memberId !== currMember.memberId
        )
      })

      teamMembersToUpdate = teamMembersToUpdate.filter((currMember) => currMember.mutated)

      let id: string | null = null

      // Create horses
      await helpers.asyncWhileLoop({
        loopCount: teamMembersToCreate.length,
        functionToFire: async (currIndex) => {
          id = await createHorseTeamMember({
            ...new HorseTeamModel(teamMembersToCreate[currIndex]).toFirestore(),
          })
          teamMembersToCreate![currIndex] = getConvertedData({
            ...teamMembersToCreate![currIndex],
            id: id ?? null,
          })
        },
      })

      // Update horses
      await helpers.asyncWhileLoop({
        loopCount: teamMembersToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateHorseTeamMember({
            ...new HorseTeamModel(teamMembersToUpdate![currIndex]).toFirestore(),
            id: teamMembersToUpdate![currIndex].id,
          })
        },
      })

      // Delete members
      await helpers.asyncWhileLoop({
        loopCount: teamMembersToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!teamMembersToDelete![currIndex].id) return
          await deleteHorseTeamMember(teamMembersToDelete![currIndex].id as any)
        },
      })

      updatedTeamMembersInDb = updatedTeamMembersInDb.filter((currMember) => {
        return !teamMembersToDelete.find(
          (currMemberToDel) => currMemberToDel.memberId === currMember.memberId
        )
      })

      updatedTeamMembersInDb = updatedTeamMembersInDb.map((currentMember) => {
        return getConvertedData(new HorseTeamModel(currentMember).toObject())
      })

      dispatch(setSelectedHorseTeamInDb(updatedTeamMembersInDb))
      dispatch(setSelectedHorseTeam(updatedTeamMembersInDb))
      dispatch(
        setTeamMemberInMappings({
          mappingDocId: selectedHorseMapping?.id!,
          teamMembers: updatedTeamMembersInDb,
        })
      )

      toastFunctions.success({ message: MESSAGES_CONST.TEAM_UPDATED_SUCCESSFULLY })
    } catch (err) {
      console.log(err, 'err')
      helpers.logger({
        isError: true,
        message: err,
      })
    }
  }

  return (
    <WrapperWithHeader
      title="Team members"
      description={props.isView ? '' : 'Add, edit or remove horses team members'}
    >
      {horseReducer.selected.team.status === 'loading' ? (
        <ViewsLoader
          className="flex items-center w-full justify-center gap-4"
          size="xl"
          color="red"
        />
      ) : (
        <>
          <div className={'flex flex-col gap-2.5 pb-2.5'}>
            {horseTeamMembers.map((item) => {
              const user = allUsers.find((u) => u.id === item.memberId)
              const isOwner = item.memberRole === CONST.UI.TEAM_MEMBERS_ROLES.OWNER

              return (
                <Fragment key={item.id + '_' + user?.id}>
                  <ProfileItem
                    member={{
                      id: item.memberId || '',
                      name: item.memberName || '',
                      profilePicture: item.memberProfilePicture || '',
                      userDesc: isOwner ? 'You' : '@' + user?.userName?.replace(/\s+/g, ''),
                    }}
                    removeItem={() => {
                      props.handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, {
                        removingHorse: item.id,
                        memberId: item.memberId,
                        mappingId: item.userHorseMappingId,
                        modal_type: 'forHorses',
                      })
                    }}
                    hideRemoveBtn={isOwner || props.isView}
                    isConnections={false}
                  />
                  {isOwner && <hr className="my-2.5" />}
                </Fragment>
              )
            })}
          </div>
          {!props.isView ? (
            selectedHorseTeam.length > 0 ? (
              <div className="horseAdd flex items-center justify-center">
                <p
                  onClick={openAddTeamMemberModal}
                  className="ml-2 transition-all underline cursor-pointer text-SeabiscuitDark200ThemeColor/50 hover:no-underline text-[14px]"
                >
                  Add Team Member
                </p>
              </div>
            ) : (
              <div className="mt-4 min-h-[400px] flex justify-center items-center flex-col">
                <p className="text-[#000] cursor-pointer mb-6">You have not add any team members</p>
                <img src="/assets/placeholders/teamMemberPlaceholder.svg" alt="placeholderImage" />
                <p
                  onClick={openAddTeamMemberModal}
                  className="text-SeabiscuitMainThemeColor inline-flex cursor-pointer mt-4"
                >
                  Add your first team member <ChevronRight />
                </p>
              </div>
            )
          ) : null}
        </>
      )}
    </WrapperWithHeader>
  )
}

export default HorseRootEditTeamMemberTab
