import React, { FC } from 'react'
import clsx from 'clsx'

// Components
import { IconCheckAccent } from '../icons/IconCheckAccent'

interface SelectOptionProps {
  title: string
  description?: string
  isActive: boolean
  onClick: () => void
  learnMoreLink?: string
}
export const SelectOption: FC<SelectOptionProps> = ({
  onClick,
  title,
  isActive,
  description,
  learnMoreLink,
}) => {
  return (
    <div
      onClick={!learnMoreLink ? onClick : () => null}
      className={clsx(
        `transition-all border text-[14px] p-6 flex gap-4 md:ap-8 md:gap-20 justify-between items-center rounded-lg border-[#D3DAEE]`,
        {
          'cursor-pointer hover:bg-[#F6F7FB] hover:border-[#F6F7FB]': !learnMoreLink,
        }
      )}
    >
      <div className={'flex flex-col gap-2'}>
        <p className={'font-black text-[16px]'}>{title}</p>
        {description && <p>{description}</p>}
      </div>

      {learnMoreLink ? (
        <a
          href={learnMoreLink}
          className={
            'whitespace-nowrap bg-SeabiscuitGrayThemeColor py-2.5 px-4 flex items-center justify-center rounded-lg transition-all text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor'
          }
          target={'_blank'}
          rel={'noreferrer'}
        >
          Learn more
        </a>
      ) : (
        <IconCheckAccent isActive={isActive} />
      )}
    </div>
  )
}
