import React, { createContext, useEffect, useMemo, useState } from 'react'

import { IonList } from '@ionic/react'
import { motion } from 'framer-motion'
import { CONST } from '../../../const/const'

// Tabs
import ClinicNOtherEventRegisterConfirmationTab from '../clinicAndOther/Tabs/ClinicNOtherEventRegisterConfirmationTab'
import ClinicNOtherRegisterTab from '../clinicAndOther/Tabs/ClinicNOtherRegisterTab'
import ClinicNOtherStatusTab from '../clinicAndOther/Tabs/ClinicNOtherStatusTab'
import CompetitorEventRegisterFeesTab from '../clinicAndOther/Tabs/CompetitorEventRegisterFeesTab'
import CompetitorEventRegisterClassesTab from '../competitorEventRegister/Tabs/CompetitorEventRegisterClassesTab'
import CompetitorEventRegisterPayTab from '../competitorEventRegister/Tabs/CompetitorEventRegisterPayTab'
import CompetitorEventRegisterSignTab from '../competitorEventRegister/Tabs/CompetitorEventRegisterSignTab'
import CompetitorEventsRegisterPaperworkTab from '../competitorEventRegister/Tabs/CompetitorEventsRegisterPaperworkTab'

// Component
import LightboxCommonComponent from '../../../components/common/dialogs/full-screen/LightboxCommonComponent'

// Fake data
import { EVENT_REG_CLINIC_TABS_CONST, UI_REGISTER_CONST } from '../../../const/ui/ui-register.const'

// Third party
import { cloneDeep } from 'lodash'
import { useParams } from 'react-router'

// Constants
import { MESSAGES_CONST } from '../../../const/messages-const'

// Redux
import { useDispatch } from 'react-redux'
import { IEventsRegisterData, selectedEvent } from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'

// Types
import {
  IEventDetailsUi,
  ILightboxProps,
  IRegisterTabsSelectData,
} from '../../../types/competitor_types'

// Helpers
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CountryList } from '../../../fakeData/countryList'
import { getUserFullName } from '../../../helpers/helpers'
import {
  IFeesTab,
  IPaperworkTab_,
  IRegisterTab,
  ISignatoryForMail,
  ITicketTab,
} from '../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { IHorseData } from '../../../models/horse/horse.interface'
import { calculateAge, getConvertedData } from '../../../models/interface.helper'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { ITeamMember, IUserInterface } from '../../../models/users/user.interface'
import FirestoreService from '../../../services/firestoreService'
import { selectUsersHolder } from '../../../store/holders/holdersSlice'
import { selectHorses } from '../../../store/horses/horseSlice'
import {
  selectFeesTabDataR,
  selectFilteredPaperworkDocuments,
  selectRegistration,
  selectRegistrationAnswers,
  selectRegistrationByDay,
  selectRegistrationErrors,
  selectRegistrationFeesR,
  selectRegistrationLoading,
  selectRegistrationsByDayInDb,
  selectRidersTeamMembersInDb,
  selectUserTeamMembersR,
  setFeesTabDataAc,
  setPaperworkTabData,
  setRegistrationAnswers,
  setRidersTeamMembers,
} from '../../../store/registration/registrationSlice'
import { selectIsLoggedIn } from '../../../store/user/userSlice'
import ClinicNOtherRegisterHorseTab from '../clinicAndOther/Tabs/ClinicNOtherHorseTab'
import { ICompetitorEventRegisterTypes as ICerhTypes } from '../competitorEventRegister/competitorEventRegisterHelper'
import useEventRegistrationTabs from './hooks/useEventRegistrationTabs/useEventRegistrationTabs'
import { CompetitorEventRegisterComments } from '../competitorEventRegister/Tabs/CompetitorEventRegisterComments'
import { RegistrationButtons } from './components/RegistrationButtons'
import ITypes from './hooks/useEventRegistrationTabs.types'
import CompetitorEventRegisterTicketsTab from '../clinicAndOther/Tabs/CompetitorEventRegisterTicketsTab'
import { getRegistrations } from '../../../helpers/getRegistrations'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

type EventRegistrationTabsProps = {
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
}

type EventT = { tab: string; step: number }

type IMaturityDetail = {
  teamMemberIsMature: boolean
  allRidersAreMature: boolean
  registererIsInRiderOrMembers: boolean
  oneOfRiderTeamMemberIsGuardian: boolean
}

export type IManageInfo = {
  register: IRegisterTab[]
  tickets: ITicketTab[]
  fees: IFeesTab[]
  paperwork: IPaperworkTab_[]
}

const FILE_NAME = 'EventRegistrationTabs'
export const EventRegistrationContext = createContext<ICerhTypes['IEventRegistrationContext']>(null)

/**
 *
 * @param props EventRegistrationTabsProps
 * @returns ReactJsxElement
 */
const EventRegistrationTabs = (props: EventRegistrationTabsProps) => {
  const {
    feesItemsForm,
    ticketItemsForm,
    createRegistration,
    saveTeamTabData,
    saveRidersTeamMembers,
    onAddRiderButtonClick,
    onRiderTeamMemberChange,
    onRemoveRiderTeamMemberClick,
    onRiderTeamMemberRoleChange,
    onInputChangeTicket,
    onChangeFees,
    saveRegisterTabData,
    setIsManage,
    registeredUser,
    manageInfo,
    setManageInfo,
    mergeFeesWithTabData,
  } = useEventRegistrationTabs()

  const { eventId, userId } = useParams<ITypes['IParams']>()
  const dispatch = useDispatch()

  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const answers = useAppSelector(selectRegistrationAnswers)
  const usersHolder = useAppSelector(selectUsersHolder)
  const rootState = useAppSelector((store) => store)
  const registration = useAppSelector(selectRegistration)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const ridersTeamMembersInDb = useAppSelector(selectRidersTeamMembersInDb)
  const filteredPaperworkDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const myHorses = useAppSelector(selectHorses)
  const registrationLoading = useAppSelector(selectRegistrationLoading)
  const event = useAppSelector(selectedEvent)
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registrationFees = useAppSelector(selectRegistrationFeesR)
  const registrationsByDayInDb = useAppSelector(selectRegistrationsByDayInDb)

  const [membersCount, setMembersCount] = useState(0)
  const [message, setMessage] = useState<null | string>(null)
  const [membersWithoutHorse, setMembersWithoutHorse] = useState(0)
  const [maturityDetails, setMaturityDetails] = useState<IMaturityDetail[]>([])
  const [allRiderTeamMembers, setAllRiderTeamMembers] = useState<ISignatoryForMail[]>([])
  const [lightBox, setLightBox] = useState<ILightboxProps>({
    visible: false,
    slides: [],
  })
  const [eventTab, setEventTab] = useState<EventT>({
    tab: CONST.UI.REGISTER.TABS.TEAM,
    step: 0,
  })
  const [isStepLoading, setIsStepLoading] = useState(false)

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  let registerFormData = useMemo(() => {
    let horses: IRegisterTabsSelectData[] = []
    let members: IEventDetailsUi['members'] = []

    let foundHorse: IHorseData | null = null
    let foundUser: IUserInterface | null = null
    let foundTeamMember: ITeamMember | null = null

    let registerTabData = cloneDeep(rootState?.events?.registerEvents)

    registerTabData.events = registerTabData.events.map((currOneDayRegistration) => {
      members = []
      currOneDayRegistration.members.forEach((curr) => {
        foundUser = usersHolder.find((c) => c.id === curr.memberId) ?? null
        foundTeamMember = teamMembersWithUser.find((c) => c.memberId === curr.memberId) ?? null
        horses = curr?.horses ?? []

        horses = horses.reduce((acc: IRegisterTabsSelectData[], currHorse) => {
          if (!currHorse.id) return acc

          foundHorse = myHorses.find((c) => c.id === currHorse.id) ?? null

          if (!foundHorse) return acc

          acc.push({
            ...currHorse,
            name: foundHorse.horseName ?? '',
            label: foundHorse.horseName ?? '',
            horseProfilePicture: foundHorse?.horseProfilePicture,
          })

          return acc
        }, [])

        if (curr?.memberId && foundTeamMember)
          members.push({
            ...curr,
            horses,
            memberId: curr?.memberId,
            refundAmount: curr?.refundAmount ?? 0,
            memberName: foundUser ? getUserFullName(foundUser) : 'N/A',
            isScratched: curr?.isScratched ?? false,
            memberRole: foundTeamMember?.defaultRole,
            defaultRole: foundUser?.userDefaultRole ?? '',
            memberProfilePicture: foundUser?.userProfilePicture,
            riderTeamMembers: (curr?.riderTeamMembers ?? []).map((currRider) => {
              foundUser = usersHolder.find((c) => c.id === currRider.memberId) ?? null
              return {
                ...currRider,
                memberDob: foundUser?.userDOB ?? '',
                memberName: foundUser ? getUserFullName(foundUser) : 'N/A',
                memberEmail: foundUser?.userEmail ?? '',
                defaultRole: foundUser?.userDefaultRole ?? '',
                memberProfilePicture: foundUser?.userProfilePicture ?? '',
              }
            }),
          })
      })

      currOneDayRegistration.members = members
      return currOneDayRegistration
    }, [])

    return registerTabData
  }, [rootState?.events?.registerEvents, usersHolder, teamMembersWithUser])
  const registrationHasErrors = useAppSelector(selectRegistrationErrors)

  useEffect(() => {
    let membersCount = 0
    let membersWithoutHorseCount = 0
    let localRegisterFormData: IEventsRegisterData | null = null

    if (registerFormData?.events) localRegisterFormData = { ...registerFormData }

    if (!localRegisterFormData) return

    localRegisterFormData.events?.forEach((currOneDayRegistration) => {
      currOneDayRegistration.members.forEach((currMember) => {
        membersCount++
        if (!currMember?.horses?.length) membersWithoutHorseCount++
      })
    }, [])

    setMembersCount(membersCount)
    setMembersWithoutHorse(membersWithoutHorseCount)
  }, [registerFormData])

  useEffect(() => {
    ;(async () => {
      try {
        const registeredEventUser = await getEventRegisteredUser()

        dispatch(setRegistrationAnswers(registeredEventUser?.answers || []))
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  useEffect(() => {
    setIsManage(props.isManage ?? false)
  }, [props.isManage])

  useEffect(() => {
    if (!registeredUser) return
    const GUARDIAN_ROLE = 'Guardian'

    const events = registerFormData?.events ?? []

    if (events && events.length) {
      let riderIsMature = true
      let teamMemberIsMature = true
      let allRidersAreMature = true
      let registererIsInRiderOrMembers = false
      let oneOfRiderTeamMemberIsGuardian = false

      let maturityDetails: IMaturityDetail[] = []

      let horseId: string | null = null
      let riderUser: IUserInterface | null = null
      let memberUser: IUserInterface | null = null

      let allRiderMembers = events.reduce((acc: ISignatoryForMail[], currentOneDayRegistration) => {
        currentOneDayRegistration.members.forEach((currRegisteredTeamMember) => {
          horseId = currRegisteredTeamMember?.horses?.[0]?.id ?? null

          if (
            !currRegisteredTeamMember.memberId ||
            !currRegisteredTeamMember?.horses?.[0]?.label ||
            !horseId
          )
            return

          teamMemberIsMature = false
          allRidersAreMature = true
          registererIsInRiderOrMembers = false
          oneOfRiderTeamMemberIsGuardian = false

          memberUser = usersHolder.find((cu) => cu.id === currRegisteredTeamMember.memberId) ?? null
          teamMemberIsMature = !!(
            memberUser &&
            memberUser.userDOB &&
            calculateAge(memberUser.userDOB) > 18
          )

          if (currRegisteredTeamMember.memberId === registeredUser.id)
            registererIsInRiderOrMembers = true

          currRegisteredTeamMember?.riderTeamMembers?.forEach((currRiderTeamMember) => {
            if (currRiderTeamMember.memberId === registeredUser.id)
              registererIsInRiderOrMembers = true

            if (!currRiderTeamMember.memberId || !memberUser || !memberUser.userDOB) return

            riderUser = usersHolder.find((cu) => cu.id === currRiderTeamMember.memberId) ?? null
            riderIsMature = !!(
              riderUser &&
              riderUser.userDOB &&
              calculateAge(riderUser.userDOB) > 18
            )

            if (
              riderIsMature &&
              currRiderTeamMember.memberRole === GUARDIAN_ROLE &&
              currRiderTeamMember.memberId !== registeredUser.id
            )
              oneOfRiderTeamMemberIsGuardian = true

            if (!riderIsMature) allRidersAreMature = false

            if (!riderUser || !horseId) return

            acc.push({
              horseId: horseId,
              memberId: memberUser.id ?? '',
              riderName: getUserFullName(riderUser),
              memberDob: memberUser.userDOB,
              memberName: getUserFullName(memberUser),
              memberEmail: memberUser.userEmail,
              riderId: currRiderTeamMember.memberId,
              memberAddress: memberUser.userAddress,
              memberPhoneNumber: memberUser.userPhoneNumber,
              memberProfilePicture: memberUser.userProfilePicture,
              defaultRole: currRegisteredTeamMember?.memberRole ?? '',
              horseName: currRegisteredTeamMember?.horses?.[0]?.label ?? '',
              memberCountry:
                CountryList.find((currCountry) => currCountry.value === memberUser?.userNationality)
                  ?.label ?? '',
            })
          })

          maturityDetails.push({
            teamMemberIsMature,
            allRidersAreMature,
            registererIsInRiderOrMembers,
            oneOfRiderTeamMemberIsGuardian,
          })
        })

        return acc
      }, [])

      setMaturityDetails(maturityDetails)
      setAllRiderTeamMembers(allRiderMembers)
    }
  }, [registerFormData?.events, registeredUser, usersHolder])

  useEffect(() => {
    if (registeredUser) setMessage(getMessageToShow())
  }, [registerFormData, registeredUser, maturityDetails, membersWithoutHorse])

  function getMessageToShow() {
    let message: string | null = null
    let isMature = (() => {
      if (registeredUser && Object.keys(registeredUser).length) {
        return calculateAge(registeredUser.userDOB) >= 18
      } else return false
    })()

    const immatureRiderWithoutGuardian = !!maturityDetails.find((cmd) => {
      return !cmd.teamMemberIsMature && !cmd.oneOfRiderTeamMemberIsGuardian
    })

    const imInAllRiders = maturityDetails.every((cmd) => cmd.registererIsInRiderOrMembers)
    const allHaveGuardian = maturityDetails.every((cmd) => cmd.oneOfRiderTeamMemberIsGuardian)
    const noEventsWereSelected = !(
      registerFormData &&
      registerFormData.events &&
      registerFormData.events.length
    )

    const imImatureWithoutGuardian = !isMature && !allHaveGuardian

    switch (true) {
      case noEventsWereSelected && !membersWithoutHorse:
        message = 'Please register a user in this event'
        break

      case !!membersWithoutHorse && membersWithoutHorse === membersCount:
        message = MESSAGES_CONST.MEMBERS_WITHOUT_HORSE.replace(
          '[COUNT]',
          `${membersWithoutHorse}`
        ).replace('[MEMBER]', membersWithoutHorse > 1 ? 'members' : 'member')
        break

      case !imInAllRiders:
        message =
          'You have no paperwork to sign because you have not listed yourself as a rider or team member. If you are attending this event, please list yourself as a team member to receive paperwork to sign.'
        break

      case imImatureWithoutGuardian:
        message =
          'Your date of birth identifies you as a minor. Your paperwork will be sent to the person you identified as your guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case immatureRiderWithoutGuardian:
        message =
          'Your one rider date of birth identifies you as a minor. so paperwork will be sent to the person you identified as minor rider guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case !allRiderTeamMembers.length:
        message = `Something went wrong ${FILE_NAME}`
    }

    return message
  }

  // Handles lightbox
  const handleLightBox = (props: ILightboxProps) => {
    setLightBox({
      ...props,
    })
  }

  // Closes lightbox
  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  // Handlers Tickets step of fees tab
  const ticketsHandler = () => {}

  const getEventRegisteredUser = async (): Promise<
    EventRegisteredUsersModel | null | undefined
  > => {
    const registeredUsers: any = []
    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
      ]
    )

    registeredUsersSnaps.forEach((currDoc) => {
      registeredUsers.push(
        getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    return registeredUsers[0]
  }

  const setnavigateTab = (data: any) => {
    setEventTab(data)
  }

  const loadPaperworkData = () => {
    let ridersTeamMembersFiltered: IRiderTeamMemberInterface[] = []
    let paperworkTabData_: IPaperworkTab_[] = []
    let registrationsByDay_: IRegistrationByDayInterface[] = []
    let ridersTeamMembers_: IRiderTeamMemberInterface[] = []

    if (props.isManage) {
      manageInfo.register.forEach((register) => {
        register.registrationsByDay.forEach((registrationByDay) => {
          registrationsByDay_.push(registrationByDay)
        })

        register.children?.forEach((child) => {
          child.registrationsByDay.forEach((registrationByDay) => {
            registrationsByDay_.push(registrationByDay)
          })
        })
      })
    } else {
      registrationsByDay_ = registrationsByDay
    }

    registrationsByDay_.forEach((registrationByDay) => {
      if (!props.isManage) {
        ridersTeamMembersFiltered = ridersTeamMembersInDb.filter(
          (currentRider) => currentRider.registrationByDayUniqId === registrationByDay.uniqId
        )
      }

      paperworkTabData_.push(
        getConvertedData({
          riderDob: registrationByDay.riderDob ?? null,
          riderName: registrationByDay.riderName ?? null,
          riderId: registrationByDay.riderId ?? null,
          horseId: registrationByDay.horseId ?? null,
          horseName: registrationByDay.horseName ?? null,
          registrationByDayUuid: registrationByDay.uuid ?? null,
          registrationByDayUniqId: registrationByDay.uniqId || '',
          horseProfilePicture: registrationByDay.horseProfilePicture ?? null,
          riderProfilePicture: registrationByDay.riderProfilePicture ?? null,
          riderNameNGram: registrationByDay.riderNameNGram ?? [],
          ridersTeamMembers: [
            ...(ridersTeamMembersFiltered.length <= 0
              ? [
                  {
                    ...getConvertedData(
                      new RiderTeamMemberModel({
                        mailLog: [],
                        userId: userId,
                        eventId: eventId,
                        riderDob: registrationByDay.riderDob ?? null,
                        riderId: registrationByDay.riderId ?? null,
                        riderName: registrationByDay.riderName ?? null,
                        eventName: registrationByDay.eventName ?? null,
                        registrationDocId: registration?.id ?? null,
                        registrationByDayUuid: registrationByDay.uuid ?? null,
                        registrationByDayUniqId: registrationByDay.uniqId || '',
                        teamMemberId: registrationByDay.riderId ?? null,
                        teamMemberDob: registrationByDay.riderDob ?? null,
                        teamMemberName: registrationByDay.riderName,
                        teamMemberRole: TEAM_MEMBER_ROLES.RIDER ?? null,
                        teamMemberEmail: registrationByDay.riderEmail ?? null,
                        teamMemberProfilePicture: registrationByDay.riderProfilePicture ?? null,
                      }).toObject()
                    ),
                  },
                ]
              : []),
            ...ridersTeamMembersFiltered,
          ],
          disableAddButton: false,
        })
      )
    })

    const uniquePaperwork = paperworkTabData_.filter((paperwork, index) => {
      return (
        index ===
        paperworkTabData_.findIndex(
          (currentPaperwork) =>
            paperwork.riderName === currentPaperwork.riderName &&
            paperwork.horseName === currentPaperwork.horseName
        )
      )
    })

    uniquePaperwork.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0) {
        ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
      }
    })

    dispatch(setRidersTeamMembers(ridersTeamMembers_))
    if (!props.isManage) {
      dispatch(setPaperworkTabData(uniquePaperwork))
    }

    // we need to update the "selectedUnitsCount" key in "governance" when changing the horse
    const feesTabDataManage = mergeFeesWithTabData(
      manageInfo.fees,
      manageInfo.fees
        ?.filter((item) => item?.registrationFees)
        ?.map((item) => item.registrationFees!) ?? [],
      getRegistrations(manageInfo.register).allRegistrations,
      true
    )
    setManageInfo({ ...manageInfo, paperwork: uniquePaperwork, fees: feesTabDataManage })

    const feesTabData_ = mergeFeesWithTabData(
      feesTabData,
      registrationFees,
      props.isManage ? registrationsByDayInDb : registrationsByDay
    )
    dispatch(setFeesTabDataAc(feesTabData_))
  }

  useEffect(() => {
    if (registration?.id) loadPaperworkData()
  }, [
    registration?.id,
    registrationsByDay,
    manageInfo.register,
    ridersTeamMembersInDb,
    props.isManage,
  ])

  const tabs = isLoggedIn
    ? !Array.isArray(event?.Event?.questions) || !event?.Event?.questions[0]
      ? EVENT_REG_CLINIC_TABS_CONST.filter((tab) => tab.tabName !== UI_REGISTER_CONST.TABS.COMMENTS)
      : EVENT_REG_CLINIC_TABS_CONST
    : [EVENT_REG_CLINIC_TABS_CONST[0]]

  return (
    <>
      <ul className="hidden md:flex flex-wrap">
        {tabs.map((tab, index) => {
          return (
            <button
              key={index}
              className={clsx(
                `text-nr inline-flex items-center text-SeabiscuitDark200ThemeColor mr-2 bg-transparent py-2 px-4 rounded-full mb-2 !border-none !ring-0 cursor-default`,
                eventTab.tab === tab.tabName &&
                  '!bg-SeabiscuitWhiteThemeColor text-SeabiscuitMainThemeColor',
                { 'opacity-50': !registration?.id }
              )}
            >
              {eventTab.tab === tab.tabName ? (
                <img className="mr-2" src={tab.activeIcon} alt={tab.tabName} />
              ) : (
                <img className="mr-2" src={tab.icon} alt={tab.tabName} />
              )}
              {tab.tabName}
            </button>
          )
        })}
      </ul>

      <IonList className="home_root_ion_list flex flex-col-reverse lg:flex-col">
        <RegistrationButtons
          eventTab={eventTab}
          saveRidersTeamMembers={saveRidersTeamMembers}
          createRegistration={createRegistration}
          setEventTab={setEventTab}
          saveTeamTabData={saveTeamTabData}
          isStepLoading={isStepLoading}
          setIsStepLoading={setIsStepLoading}
          tabs={tabs}
          isManage={props.isManage}
          manageInfo={manageInfo}
          filteredPaperworkDocuments={filteredPaperworkDocuments}
          getEventRegisteredUser={getEventRegisteredUser}
          registeredUser={registeredUser}
          registrationHasErrors={registrationHasErrors}
        />
        {isStepLoading || registrationLoading ? (
          <ViewsLoader
            className="flex items-center w-full justify-center flex-col flex-1"
            size="xl"
            color="red"
          />
        ) : (
          <>
            {eventTab.tab === CONST.UI.REGISTER.TABS.TEAM && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherStatusTab
                  title={eventTab.step === 0 ? 'Confirm team details' : 'Status'}
                  description={
                    eventTab.step === 0
                      ? 'Add riders and horses to start your registration'
                      : 'Licensed competitons require complete profiles to register.'
                  }
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.CLASSES && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                {eventTab.step === 0 && (
                  <CompetitorEventRegisterClassesTab
                    title="Classes"
                    description="Select the classes you wish to enter"
                  />
                )}
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.HORSES && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherRegisterHorseTab
                  title="Horses"
                  description="Match horses to riders, per entry"
                  isManage={props.isManage}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  saveRegisterTabData={saveRegisterTabData}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.REGISTER && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherRegisterTab
                  usersHolder={usersHolder}
                  handleModal={props.handleModal}
                  saveRegisterTabData={saveRegisterTabData}
                  isManage={props.isManage}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  title="Register"
                  description="Add riders to classes to enter. You can add more than one rider per class."
                  eventId={eventId}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.FEES && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterFeesTab
                  feesItemsForm={feesItemsForm}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  isManage={props.isManage}
                  onChangeFees={onChangeFees}
                  fees={registerFormData.fees}
                  handleModal={props.handleModal}
                  title="Fees"
                  description={'Add items to your registration'}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.TICKETS && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterTicketsTab
                  ticketItemsForm={ticketItemsForm}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  isManage={props.isManage}
                  onInputChangeTicket={onInputChangeTicket}
                  ticketsHandler={ticketsHandler}
                  handleModal={props.handleModal}
                  title="Tickets"
                  description={'Add spectator tickets your order'}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.PAPERWORK && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventsRegisterPaperworkTab
                  title="Team Roles"
                  onAddRiderButtonClick={onAddRiderButtonClick}
                  onRiderTeamMemberChange={onRiderTeamMemberChange}
                  onRemoveRiderTeamMemberClick={onRemoveRiderTeamMemberClick}
                  onRiderTeamMemberRoleChange={onRiderTeamMemberRoleChange}
                  description="Add team members to each entry"
                  isManage={props.isManage}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.SIGN && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterSignTab
                  message={message}
                  title="Your Paperwork"
                  handleLightBox={handleLightBox}
                  saveRidersTeamMembers={saveRidersTeamMembers}
                  description="Review and sign your paperwork to participate in this event"
                  isManage={props.isManage}
                  setManageInfo={setManageInfo}
                  manageInfo={manageInfo}
                  userId={userId}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.COMMENTS && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterComments event={event?.Event} comments={answers || []} />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.CONFIRMATION && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherEventRegisterConfirmationTab
                  title="Confirmation"
                  step={eventTab.step}
                  activeTab={eventTab}
                  setEventTab={setnavigateTab}
                  description="Review and confirm your registation details"
                  manageInfo={manageInfo}
                  isManage={props.isManage}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.REVIEWANDPAY && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterPayTab
                  title="Pay"
                  activeTab={eventTab}
                  step={eventTab.step}
                  registerFormData={registerFormData}
                  description="Select a payment method and complete registration"
                  manageInfo={manageInfo}
                  isManage={props.isManage}
                />
              </motion.div>
            )}
          </>
        )}
      </IonList>

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </>
  )
}

export default EventRegistrationTabs
