import { useState } from 'react'

// Types
import { IRiderSignupForm } from '../types/competitor_types'

// Helpers
import { authHelpers, CustomError } from '../helpers/helpers'
import { uploadPicture } from '../helpers/uploadPicture'
import useToasterHelper from '../helpers/ToasterHelper'

// Const
import { CONST } from '../const/const'

// Firebase
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'

// Models
import { UserModel } from '../models/users/user.model'
import { CompetitorProfileModel } from '../models/competitor-profile/competitor-profile.model'

// Services
import FirebaseApp from '../services/firebaseApp'
import { httpService } from '../services/httpService'
import UserService from '../services/userService'
import FirestoreService from '../services/firestoreService'

// Store
import { setStripeId } from '../store/user/userSlice'
import {
  riderProfileRegistrationActions,
  selectRiderProfileRegistration,
} from '../store/riderProfileRegistration/riderProfileRegistrationSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'

export const useCreateUser = () => {
  const [createUserLoading, setCreateUserLoading] = useState(true)
  const toastFunctions = useToasterHelper()
  const dispatch = useAppDispatch()
  const riderProfileRegistrationData = useAppSelector(selectRiderProfileRegistration)
  const { rider } = riderProfileRegistrationData

  const createUser = async (signupForm: IRiderSignupForm, pictureFile?: File | null) => {
    try {
      if (!signupForm.fullName.split(' ')[1]) {
        toastFunctions.error({
          message: 'The full name should start with the first name, followed by the last name.',
        })
        return
      }
      const resp = await authHelpers.isUsernameUnique(signupForm.userName)
      if (resp instanceof CustomError) {
        setCreateUserLoading(false)
        console.error(resp)
        toastFunctions.error({ message: resp.message })
        return null
      }

      const createUserResult = await createUserWithEmailAndPassword(
        FirebaseApp.auth,
        signupForm.email,
        signupForm.password
      )


      await updateProfile(createUserResult.user, {
        displayName: signupForm.fullName,
      })

      const respUserInfo = await httpService({
        url: `create_user`,
        method: 'POST',
        data: {
          uuid: createUserResult.user.uid,
          userName: signupForm.userName,
          email: signupForm.email,
          userType: 'competitor',
        },
      })
      const respUser = respUserInfo?.data?.user

      if (!respUser) {
        toastFunctions.error({
          message: `Failed to create user with email ${signupForm.email}`,
        })
        return
      }

      const user_data_doc = await UserService.getUserInfoById(createUserResult.user.uid)

      const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

      if (!userData.userStripeId) {
        const customerData = await httpService({
          url: `create_stripe_customer`,
          method: 'POST',
          data: {
            userEmail: signupForm.email,
            userName: signupForm.fullName,
            userStripeAccountId: userData.userStripeAccountId as any,
          },
        })

        userData.userStripeId = customerData.stripeId

        if (userData.userStripeId) {
          dispatch(setStripeId(userData.userStripeId))
        }
      }

      const userProfile = new CompetitorProfileModel({
        competitorOwnerId: createUserResult.user.uid,
      }).toFirestore()

      await UserService.createCompetitorProfile(createUserResult.user.uid, userProfile)

      try {
        await httpService({
          url: 'create_rewardful_affiliate',
          method: 'POST',
          data: {
            email: signupForm.email,
            first_name: signupForm.fullName.split(' ')[0],
            last_name: signupForm.fullName.split(' ')[1] || '',
            stripe_customer_id: userData.userStripeId,
          },
        })
      } catch (e) {
        console.error(e)
      }

      await httpService({
        url: 'send_emails',
        method: 'POST',
        data: {
          mail_type: 'account_create',
          mail_data: {
            reciever_data: {
              receiverName: signupForm.fullName,
              receiverEmail: signupForm.email,
            },
          },
        },
      })
      let userProfilePicture = undefined

      const pictureToUpload = rider?.picture || pictureFile
      if (pictureToUpload) {
        userProfilePicture = await uploadPicture(pictureToUpload)
      }

      const updatedData = {
        userProfilePicture: userProfilePicture || '',
        userFullName: signupForm.fullName,
        userName: signupForm.userName,
        userDOB: signupForm.userDOB,
        userNationality: signupForm.userNationality || '',
        userDiscipline: signupForm.userDiscipline || '',
        userPhoneNumber: signupForm.userPhoneNumber || '',
      }
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        respUser.id,
        {
          ...updatedData,
        }
      )

      const competitor = {
        ...userData,
        ...updatedData,
        userStripeId: userData.userStripeId,
      }
      dispatch(riderProfileRegistrationActions.setCompetitor(competitor))
      dispatch(riderProfileRegistrationActions.setUserId(respUser.id))

      return {
        uuid: createUserResult.user.uid,
        firebaseId: respUser.id,
        email: signupForm.email,
        competitor,
      }
    } catch (e: any) {
      toastFunctions.error({
        message: e.message,
      })
      console.error(e)
    }
  }

  return {
    createUserLoading,
    createUser,
  }
}
