import TimeLib from '../../../../lib/Time'
import { IRegistrationFeesInterface } from '../../../../models/registration-fees/registrationFees.interface'
import { PAYMENT_STATUS_VALUES, PAYMENT_TYPE } from '../../../../types/payment'

export const DATA_FIRESTORE_V01_REGISTRATION_FEES = {
  NAME: 'v01_registration_fees',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_ID: {
      KEY: 'invoiceId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_URL: {
      KEY: 'invoiceUrl',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME: {
      KEY: 'recipientName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME_N_GRAM: {
      KEY: 'recipientNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    REFUND_AMOUNT_ADDED: {
      KEY: 'refundAmountAdded',
      VALUES: {
        DEFAULT: false,
      },
    },

    SCRATCH_AMOUNT_ADDED: {
      KEY: 'scratchAmountAdded',
      VALUES: {
        DEFAULT: false,
      },
    },

    SELECTED_UNITS_COUNT: {
      KEY: 'selectedUnitsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    FEES_ITEM_ID: {
      KEY: 'feesItemId',
      VALUES: {
        DEFAULT: null,
      },
    },

    FEES_TITLE: {
      KEY: 'feesTitle',
      VALUES: {
        DEFAULT: null,
      },
    },

    FEES_PRICE: {
      KEY: 'feesPrice',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REFUND_DOC_ID: {
      KEY: 'refundDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_ID: {
      KEY: 'refundId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_PROFILE_PICTURE: {
      KEY: 'recipientProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: PAYMENT_STATUS_VALUES,
    },

    PAYMENT_TYPE: {
      KEY: 'paymentType',
      VALUES: PAYMENT_TYPE,
    },

    IS_SCRATCHED: {
      KEY: 'isScratched',
      VALUES: {
        YES: true,
        NO: false,
        DEFAULT: false,
      },
    },

    AMOUNT_REFUNDED: {
      KEY: 'amountRefunded',
      VALUES: {
        DEFAULT: 0,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      VALUES: {
        DEFAULT: null,
        REFUNDED: 'refunded' as IRegistrationFeesInterface['refundStatus'],
      },
    },

    FEES_ITEM_CATEGORY: {
      KEY: 'feesItemCategory',
      VALUES: {
        DEFAULT: null,
        OTHER: 'other',
        GOVERNANCE: 'governance',
        REFUND: 'refund',
        BEDDING: 'bedding',
        STALLS: 'stalls',
        FEED: 'feed',
      },
    },

    RECIPIENT_ID: {
      KEY: 'recipientId',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
