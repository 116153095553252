import { createRef, FC } from 'react'
import HeaderSection from './HeaderSection'
import bgImage from './assets/main-bg.png'
import UserLoginFormComponent from './UserLoginFormComponent'

interface IUserLoginViewComponentProps {
  logo: any
  logoAlt: string
  handleModal: any
  onLoginInvalid: (data: any) => any
  onSignInWithGoogleButtonPressed: () => Promise<void>
  onSignUpButtonPressed: () => void
}

const UserLoginViewComponentProps: FC<IUserLoginViewComponentProps> = (props) => {
  const mainContainer = createRef<HTMLDivElement>()
  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
      className="h-screen flex flex-col bg-cover"
    >
      <HeaderSection />
      <div className="flex-1 removeScroll overflow-auto pb-10 min-h-[calc(100vh_-_118px)] text-white flex items-center">
        <div
          className="md:w-[470px] sm:w-[350px] w-[300px] mx-auto md:ml-[100px] transition-[all_.3s]"
          ref={mainContainer}
        >
          <div>
            <h1 className="text-[22px] font-normal text-white mt-4">Sign in</h1>
          </div>

          <UserLoginFormComponent
            logo={props.logo}
            logoAlt="Pegasus"
            handleModal={props.handleModal}
            onLoginInvalid={props.onLoginInvalid}
            onSignInWithGoogleButtonPressed={props.onSignInWithGoogleButtonPressed}
            onSignUpButtonPressed={props.onSignUpButtonPressed}
          />
        </div>
      </div>
    </div>
  )
}

export default UserLoginViewComponentProps
