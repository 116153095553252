import { IAppState } from '../../../../store/system/systemSlice'

export const filtersCategories = (useMode: IAppState['useMode']) => {
  return [
    {
      name: 'licensed',
      icon: '/assets/og_icons/Laurel Wreath.svg',
      activeIcon: '/assets/og_icons/Laurel Wreath-2.svg',
      isVisible: useMode === 'competitor' || !useMode,
    },
    {
      name: 'unlicensed',
      icon: '/assets/og_icons/Prize.svg',
      activeIcon: '/assets/og_icons/Prize-2.svg',
      isVisible: useMode === 'competitor' || !useMode,
    },
    {
      name: 'clinic',
      icon: '/assets/og_icons/Graduation Cap.svg',
      activeIcon: '/assets/og_icons/Graduation Cap-2.svg',
      isVisible: useMode === 'competitor' || !useMode,
    },
    {
      name: 'other',
      icon: '/assets/og_icons/Two Tickets.svg',
      activeIcon: '/assets/og_icons/Two Tickets-2.svg',
      isVisible: useMode === 'competitor' || !useMode,
    },
    {
      name: 'following',
      icon: '/assets/og_icons/Bookmark.svg',
      activeIcon: '/assets/og_icons/Bookmark-2.svg',
      isVisible: useMode === 'competitor',
    },
  ]
}

export const filters = {
  date: [
    {
      name: 'This week',
      icon: '/assets/og_icons/Tear-Off Calendar.svg',
      activeIcon: '/assets/og_icons/Tear-Off Calendar-2.svg',
    },
    {
      name: 'This month',
      icon: '/assets/og_icons/Calendar.svg',
      activeIcon: '/assets/og_icons/Calendar-2.svg',
    },
    {
      name: 'Next month',
      icon: '/assets/cp_icons/next month1.svg',
      activeIcon: '/assets/cp_icons/next month2.svg',
    },
    {
      name: 'Next year',
      icon: '/assets/og_icons/Calendar.svg',
      activeIcon: '/assets/og_icons/Calendar-2.svg',
    },
  ],
  discipline: [
    { label: 'All', value: 'all' },
    { label: 'American Saddlebred', value: 'americanSaddlebred' },
    { label: 'Andalusian/Lusitano', value: 'andalusian/lusitano' },
    { label: 'Arabian', value: 'arabian' },
    { label: 'Carriage Pleasure Driving', value: 'carriagePleasureDriving' },
    { label: 'CombinedDriving', value: 'combinedDriving' },
    { label: 'Connemara', value: 'connemara' },
    { label: 'Cutting', value: 'cutting' },
    { label: 'Dressage', value: 'dressage' },
    { label: 'Endurance', value: 'endurance' },
    { label: 'EnglishPleasure', value: 'englishPleasure' },
    { label: 'Equitation', value: 'equitation' },
    { label: 'Eventing', value: 'eventing' },
    { label: 'Friesian', value: 'friesian' },
  ],
}
