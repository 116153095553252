import React, { useEffect, useRef, useState } from 'react'

// Const
import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST } from '../../../const/const'

// Modals
import MainModal from '../../modals/common/MainModal'
import { ModalBackButton } from '../../modals/components/ModalBackButton'

// Models
import { getConvertedData, getSelectedUserAsTeamMember } from '../../../models/interface.helper'
import { IRegistrationTeamInterface } from '../../../models/registeration-teams/registration-teams.interface'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { HorseModel } from '../../../models/horse/horse.model'
import { IHorseData } from '../../../models/horse/horse.interface'

// Store
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectHorses, setHorses } from '../../../store/horses/horseSlice'
import { selectTeamTabData, setTeamTabData } from '../../../store/registration/registrationSlice'
import {
  riderProfileRegistrationActions,
  selectIsTeamRegistration,
  selectRiderProfileRegistration,
} from '../../../store/riderProfileRegistration/riderProfileRegistrationSlice'

// Common
import customImageComponent from '../../common/CustomImageComponent'

// Components
import ViewsLoader from '../../loader/ViewsLoader'
import { IconPlus } from '../../icons/IconPlus'

// Helpers
import { getUserFullName } from '../../../helpers/helpers'

// Services
import FirestoreService from '../../../services/firestoreService'

// Hooks
import useHorses from '../../../hooks/users/competitor/profile/useHorses'

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    competitor: IUserInterface
    users: IUserInterface[]
    userTeamMembers: IUserInterface[]
    authorizeUserHorses: IUserHorseMappingInterface[]
  }
}

interface IUserExtendedWithSelected extends IUserInterface {
  selected?: boolean
}

const RegistrationTeamTabModal = ({ dataToPassOn, handleModal }: Props) => {
  const dispatch = useAppDispatch()

  const { userTeamMembers, users, competitor } = dataToPassOn
  const riderProfileRegistration = useAppSelector(selectRiderProfileRegistration)
  const isTeamRegistration = useAppSelector(selectIsTeamRegistration)
  const teamMembers = useAppSelector(selectTeamTabData)
  const myHorses = useAppSelector(selectHorses)

  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [listToShow, setListToShow] = useState<IUserExtendedWithSelected[]>([])
  const [loading, setLoading] = useState(false)

  const { getMapping } = useHorses()

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  const handleUsers = (strToSearch: string) => {
    const selected = listToShow?.filter((item) => item.selected)

    if (strToSearch === '') {
      const defaultList = userTeamMembers?.map((user) => {
        const isSelected = selected.find((item) => item.id === user.id)
        if (isSelected) {
          return { ...user, selected: true }
        } else {
          return user
        }
      })
      return setListToShow([...defaultList])
    }

    const matched = users.filter((user: IUserInterface) => {
      const isExist = userTeamMembers?.find((member) => member.id === user.id)
      const isCompetitor = competitor && competitor.id === user.id

      return (
        !isExist &&
        !isCompetitor &&
        (user.userName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
          user.userFirstName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
          user.userLastName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
          getUserFullName(user)?.toLowerCase()?.includes(strToSearch?.toLowerCase()))
      )
    })
    setListToShow([...selected, ...matched])
  }

  async function addTeamMembersToViewOnly() {
    const teamData: IRegistrationTeamInterface[] = []

    setLoading(true)
    try {
      if (isTeamRegistration) {
        const selectedUsers: IUserExtendedWithSelected[] = []

        listToShow?.forEach((user) => {
          user.selected && selectedUsers.push(user)
        })

        dispatch(riderProfileRegistrationActions.setTeam(selectedUsers))
        handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
        handleModal(true, MODAL_CONSTS.CONFIRM_PROFILE_DETAILS)

        return
      }

      listToShow?.forEach((user) => {
        if (user.selected) {
          teamData.push(getConvertedData(getSelectedUserAsTeamMember(user, competitor)))
        }
      })

      const filteredTeamData = teamData.filter(
        (member) => !teamMembers.find((m) => m.memberId === member.memberId)
      )

      const newIds: string[] = []

      filteredTeamData.forEach((m) => {
        if (m.memberId) {
          newIds.push(m.memberId)
        }
      })

      if (newIds[0]) {
        const promises: IUserHorseMappingInterface[][] = []

        for (const id of newIds) {
          const { horsesTeamsMappings } = await getMapping(id)
          promises.push(horsesTeamsMappings)
        }

        const horses: IUserHorseMappingInterface[] = []
        const snaps = await Promise.all(promises)
        snaps.forEach((sn) => horses.push(...sn))
        const horsesIds: string[] = []

        horses.forEach((h) => {
          h.horseId &&
            h.horseOwnerId &&
            newIds?.includes(h.horseOwnerId) &&
            horsesIds.push(h.horseId)
        })

        if (horsesIds[0]) {
          const membersHorses: IHorseData[] = []
          const horsesSnaps = await FirestoreService.getItemsUsingIds(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
            horsesIds
          )

          horsesSnaps.forEach((currDoc) => {
            membersHorses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
          })

          dispatch(setHorses([...myHorses, ...membersHorses]))
        }
      }

      dispatch(setTeamTabData([...teamData]))
      handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
    } catch (error) {
      console.error(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (team_member: IUserExtendedWithSelected) => {
    if (listToShow) {
      const sameTypeUsersArrCopy = listToShow.map((currUser) => {
        if (currUser.id === team_member?.id) {
          return { ...currUser, selected: !currUser.selected }
        } else {
          return currUser
        }
      })
      setListToShow([...sameTypeUsersArrCopy])
    }
  }

  useEffect(() => {
    const list: IUserExtendedWithSelected[] = []
    userTeamMembers?.forEach((user) => {
      const isSelected = teamMembers.find((member) => member.memberId === user.id)

      if (isSelected) {
        list.push({ ...user, selected: true })
      } else {
        list.push(user)
      }
    })
    setListToShow(list)
  }, [teamMembers, userTeamMembers])

  const backHandler = () => {
    handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
    handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
      onlyHorse: true,
    })
  }

  return (
    <>
      <MainModal
        title="Add team member"
        description={'Add team members so you can include them in your registration'}
        show={true}
        type="REGISTER_TEAM"
        goBackBtn={riderProfileRegistration.isTeam && <ModalBackButton onClick={backHandler} />}
        size="md"
        onTransitionEnd={focusInput}
        titleClassName="!font-normal"
        buttons={[
          {
            label: riderProfileRegistration.isTeam ? 'NEXT >' : 'SAVE',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: addTeamMembersToViewOnly,
            className: 'outline-none',
            textClass: 'text-white',
            disabled: !listToShow?.length,
            loading: loading,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => {
              dispatch(riderProfileRegistrationActions.clear())
              handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
            },
            loading: loading,
          },
        ]}
      >
        <div className="inputSearch mt-4 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={(e) => handleUsers(e.target.value)}
            placeholder="Search..."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />
          {/*<Search className="absolute text-SeabiscuitMainThemeColor right-4" />*/}
          <div className="absolute right-0 top-0 h-full w-[135px] flex justify-center items-center">
            <span className="absolute left-0 h-[60%] top-[50%] translate-y-[-50%] w-[1px] bg-[#122B4680]" />
            <button
              onClick={() => {
                handleModal(false, MODAL_CONSTS.REGISTER_TEAM)
                handleModal(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
              }}
              className={
                'group text-[#122B4680] text-[14px] flex items-center gap-1 hover:text-SeabiscuitMainThemeColor'
              }
            >
              <IconPlus /> New Person
            </button>
          </div>
        </div>
        {listToShow?.length ? (
          <>
            <div className="uselListWarp mt-4 flex flex-col">
              {listToShow?.length ? (
                listToShow?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleChange(item)}
                      className="form-check mb-3 cursor-pointer"
                    >
                      <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                        <div className="min-w-fit flex items-center">
                          {customImageComponent(
                            item.userProfilePicture,
                            getUserFullName(item),
                            'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                          )}
                          <div className="hourseDetails w-10/12">
                            <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                              <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                                {getUserFullName(item)}
                              </p>
                              <p className="text-xs">{item?.userEmail}</p>
                            </div>
                          </div>
                        </div>

                        {item.selected && (
                          <img
                            src={CHECKED_ICON}
                            className="relative right-[0px] top-[0px]"
                            width={20}
                            height={20}
                            alt="checked"
                          />
                        )}

                        <input
                          className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor hidden`}
                          type="checkbox"
                        />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor"></div>
              )}
            </div>
          </>
        ) : (
          <>
            {!!searchBoxRef?.current?.value?.length && !listToShow ? (
              <>No data found</>
            ) : (
              <>
                {!!searchBoxRef?.current?.value?.length && !listToShow ? (
                  <ViewsLoader size="md" color="red" className="mt-1 pt-1" />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </MainModal>
    </>
  )
}

export default RegistrationTeamTabModal
