import React, { useEffect, useState } from 'react'

import MainModal from './common/MainModal'
import ViewsLoader from '../loader/ViewsLoader'
import { IconChevron } from '../icons/IconChevron'

import { getUserFullName } from '../../helpers/helpers'

import { useAppSelector } from '../../store/hooks'
import { selectAllUsers } from '../../store/users/usersSlice'
import { selectedEvent } from '../../store/events/eventsSlice'

import { getAllRidersByUser } from '../../helpers/riders'

import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IRider } from '../../types/entryRider'
import { IUserInterface } from '../../models/users/user.interface'

import { MODAL_CONSTS } from '../../const/modal-const'

interface IProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    rider: IRider
  }
}

export const AnswersModal: React.FC<IProps> = ({ handleModal, dataToPassOn }) => {
  const event = useAppSelector(selectedEvent)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const currentEvent = event?.Event
  const [loading, setLoading] = useState<boolean>(true)
  const [riders, setRiders] = useState<IRegistrationByDayInterface[]>([])
  const [horses, setHorses] = useState<
    {
      horseName: IRegistrationByDayInterface['horseName']
      horseProfilePicture: IRegistrationByDayInterface['horseProfilePicture']
      horseId: IRegistrationByDayInterface['horseId']
    }[]
  >([])
  const [registeredUser, setRegisteredUser] = useState<IUserInterface | null>(null)
  const users = useAppSelector(selectAllUsers)

  useEffect(() => {
    const getRidersAndHorses = async () => {
      try {
        const registrationByDay = await getAllRidersByUser(
          event?.Event?.id || '',
          dataToPassOn.rider.userId ?? ''
        )

        const amRegisteredUser = users.find((r) => r.id === dataToPassOn.rider.userId)

        const amHorses: typeof horses = []
        const amRiders: typeof registrationByDay = []

        registrationByDay.forEach((user) => {
          const hasHorse = amHorses.find((h) => user.horseId === h.horseId)
          const hasRider = amRiders.find((h) => user.riderId === h.riderId)
          if (!hasHorse && user.horseId) {
            amHorses.push({
              horseName: user.horseName,
              horseProfilePicture: user.horseProfilePicture,
              horseId: user.horseId,
            })
          }
          if (!hasRider && user.riderId) {
            amRiders.push(user)
          }
        })
        setRiders(amRiders)
        setHorses(amHorses)
        if (amRegisteredUser) setRegisteredUser(amRegisteredUser)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    getRidersAndHorses().then()
  }, [dataToPassOn.rider.userId, event?.Event?.id])

  const renderBody = () => {
    const comments = dataToPassOn.rider?.answers || []
    if (!currentEvent) {
      return <h2>No questions</h2>
    }
    if (!Array.isArray(currentEvent.questions) || !currentEvent.questions[0]) {
      return <h2>No questions</h2>
    }
    return currentEvent.questions.map((question) => {
      const qc = comments.filter((c) => c.questionId === question.id)

      return (
        question.value && (
          <div key={question.id} className="text-[#122B46]">
            <h3 className="text-[16px] mb-4">{question.value}</h3>
            {qc.length > 0 ? (
              qc.map((cc, index) => (
                <div key={index} className="bg-[#F6F7FB] rounded-lg text-[#122B46] ">
                  <div className="p-4">
                    <p>{cc.answer}</p>
                  </div>
                  {cc.files && cc.files.length > 0 && (
                    <div className="p-4 border-t-[1px] border-solid border-[#D3DAEE] flex gap-3 flex-wrap">
                      {cc.files.map((f, index) => (
                        <a
                          key={index + f + question.id}
                          href={f}
                          target={'_blank'}
                          rel="noreferrer"
                          className={'w-[45px] h-[45px] bg-[#D3DAEE] rounded-lg'}
                        >
                          <img
                            className={'w-[45px] h-[45px] rounded-lg'}
                            src={f}
                            alt={`img-${index}`}
                          />
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className="opacity-50">No answer</p>
            )}
          </div>
        )
      )
    })
  }

  return (
    <>
      <MainModal
        title="Answers"
        show={true}
        type="ANSWERS"
        size="2xl"
        titleClassName="!font-normal"
        buttons={[
          {
            label: 'CLOSE',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor !w-full',
            onClick: () => handleModal(false, MODAL_CONSTS.ANSWERS),
          },
        ]}
      >
        {loading ? (
          <div className={'w-full h-full relative'}>
            <ViewsLoader
              isPageLoader={true}
              className="absolute !w-[100%] !h-[100%]"
              size="sm"
              color="red"
            />
          </div>
        ) : (
          <div>
            <div className={'border-b-[1px] border-solid border-[#D3DAEE] pb-4'}>
              <div className={'flex justify-between items-center gap-3'}>
                <div>
                  <div className={'flex gap-2 items-center'}>
                    <span className="p-[2px] bg-[white] my-2 flex items-center rounded-lg justify-center shrink-0">
                      <img
                        src={registeredUser?.userProfilePicture || ''}
                        alt="icons"
                        className="object-cover w-[45px] h-[45px] rounded-full"
                        onError={(e) =>
                          ((e.target as any).src =
                            `https://ui-avatars.com/api/?name=${registeredUser ? getUserFullName(registeredUser) : 'N/A'}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                        }
                      />
                    </span>
                    <div>
                      <span className={'text-[#122B4680] text-[12px]'}>Registered by</span>
                      <p className={'text-[#122B46] text-[16px]'}>
                        {registeredUser ? getUserFullName(registeredUser) : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={'flex items-center gap-2'}>
                  <div className={'text-right'}>
                    <span className={'text-[#122B4680] text-[12px]'}>Entries</span>
                    <p
                      className={'text-[#122B46] text-[16px]'}
                    >{`${riders.length} riders • ${horses.length} horses`}</p>
                  </div>
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={`w-[45px] h-[45px] bg-[#F6F7FB] rounded-lg flex items-center justify-center ${isOpen ? `rotate-180 bg-SeabiscuitMainThemeColor/5` : ''}`}
                  >
                    <IconChevron color={isOpen ? 'red' : '#122B46'} />
                  </button>
                </div>
              </div>
              {isOpen && (
                <div className={'flex flex-col gap-4 mt-4'}>
                  {riders.map((rider) => (
                    <div className={'flex justify-between gap-5'}>
                      <div className={'flex'}>
                        <span className="p-[2px] border-[2px] border-solid border-white bg-[#F6F7FB] my-2 flex items-center rounded-full justify-center shrink-0 z-[2]">
                          <img
                            src={rider.riderProfilePicture || ''}
                            alt="icons"
                            className="object-cover w-[45px] h-[45px] rounded-full"
                            onError={(e) =>
                              ((e.target as any).src =
                                `https://ui-avatars.com/api/?name=${rider.riderName}&background=F6F7FB&format=svg&bold=true&color=BCC6D5&rounded=true`)
                            }
                          />
                        </span>
                        {rider.horseName && (
                          <span className="p-[2px] border-[2px] border-solid border-white bg-[#F6F7FB] my-2 flex items-center rounded-full justify-center shrink-0 translate-x-[-50%]">
                            <img
                              src={rider.horseProfilePicture || ''}
                              alt="icons"
                              className="object-cover w-[45px] h-[45px] rounded-full"
                              onError={(e) =>
                                ((e.target as any).src =
                                  `https://ui-avatars.com/api/?name=${rider.horseName}&background=F6F7FB&format=svg&bold=true&color=BCC6D5&rounded=true`)
                              }
                            />
                          </span>
                        )}
                      </div>

                      <div className={'text-right'}>
                        <span className={'text-[#122B4680] text-[12px]'}>Horse</span>
                        <p className={'text-[#122B46] text-[16px]'}>{rider.horseName || 'None'}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={'flex flex-col gap-6 mt-4'}>{renderBody()}</div>
          </div>
        )}
      </MainModal>
    </>
  )
}
