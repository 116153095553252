// Services
import FirebaseStorageService from '../services/storageService'

// Const
import { CONST } from '../const/const'

const PATH = CONST.DATA.STORAGE.HORSE.HORSE_PROFILE_IMAGE_URL.PREFIX

export const uploadPicture = async (file: File) => {
  try {
    const downloadUrl = await FirebaseStorageService.uploadFile(
      file,
      `${PATH}/${new Date().getTime()}`
    )

    return `${downloadUrl || ''}`
  } catch (e) {
    console.error(e)
  }
}
