import React, { FC, useContext, useEffect, useState } from 'react'

import { useParams } from 'react-router'
import { Tooltip } from '@mui/material'

import TooltipIcon from '../../../../../helpers/TooltipIcon'
import { IconHorseImg } from '../../../../../components/icons/IconHorseImg'
import { NoProfileData } from './NoProfileData'
import { ProfileFields } from './ProfileFields'

import { selectHorses, setHorses } from '../../../../../store/horses/horseSlice'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'

import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import FirestoreService from '../../../../../services/firestoreService'

import { IHorseCompetitorDocument } from '../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { HorseCompetitonPaperworkModel } from '../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import ITypes from '../../../event-registration-tabs/hooks/useEventRegistrationTabs.types'

import { CONST } from '../../../../../const/const'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { getUserFullName } from '../../../../../helpers/helpers'
import { selectAllUsers } from '../../../../../store/users/usersSlice'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import { selectRegistrationByDay } from '../../../../../store/registration/registrationSlice'
import { IUserHorseMappingInterface } from '../../../../../models/user-horse-mapping/userHorseMapping.interface'

interface HorsesProps {
  horsesPaperwork: IHorseCompetitorDocument[] | null
  setHorsesPaperwork: (value: IHorseCompetitorDocument[] | null) => void
  mainLoading: boolean
  mapping: IUserHorseMappingInterface[] | null
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const Horses: FC<HorsesProps> = ({
  horsesPaperwork,
  setHorsesPaperwork,
  mainLoading,
  mapping,
}) => {
  const { userId } = useParams<ITypes['IParams']>()

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal
  const registrationByDay = useAppSelector(selectRegistrationByDay)
  const users = useAppSelector(selectAllUsers)
  const myHorses = useAppSelector(selectHorses)

  const dispatch = useAppDispatch()
  const { Event } = useAppSelector(selectedEvent)

  const [loading, setLoading] = useState(false)

  const getHorsesPaperwork = async () => {
    setLoading(true)

    const horsesPaperworkSnap = await FirestoreService.filterItems(
      COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME
    )

    let horsesPaperwork_ = horsesPaperworkSnap?.docs.map((doc) => {
      return HorseCompetitonPaperworkModel.fromFirestoreDoc(doc).toObject()
    })
    setHorsesPaperwork([...horsesPaperwork_])

    setLoading(false)
  }

  useEffect(() => {
    if (Event?.id && userId) {
      getHorsesPaperwork().then()
    }
  }, [Event?.id, userId])

  const getUserNameById = (id: string | null) => {
    if (!id) return ''
    const user = users.find((user) => user.id === id)

    if (!user) return ''

    return getUserFullName(user)
  }

  const removeHandler = (id: string) => {
    let horseIsRegistered = false

    if (registrationByDay) {
      horseIsRegistered = !!registrationByDay.find((rider) => rider.horseId === id)
    }

    handleModalContext?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: () => dispatch(setHorses(myHorses.filter((h) => h.id !== id))),
      isBtnOk: horseIsRegistered,
      alertText: horseIsRegistered ? (
        <>
          <h5 className="font-semibold text-xl text-center mb-10 leading-relaxed">
            The horse is already registered. <br /> You can remove them in the next steps if needed.
          </h5>
        </>
      ) : (
        <div>
          <h5 className="font-semibold text-xl mb-10">
            Are you sure you want to remove this horse?
          </h5>
        </div>
      ),
    })
  }

  if (mainLoading || loading) {
    return (
      <div className="w-full lg:w-[48%] flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-5 pb-[300px]">
        <ViewsLoader
          className="flex items-center md:w-80 w-full justify-center my-3"
          size="md"
          color="red"
        />
      </div>
    )
  }

  if (myHorses.length <= 0) {
    return (
      <NoProfileData
        title={'You have not added any horses'}
        createHandler={() => {
          handleModal?.(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
            userPicture: '',
            userId,
            createCb: () => {
              handleModal?.(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
            },
          })
        }}
        btnCreateTitle={'Add your first horse now >'}
      />
    )
  }

  return (
    <div className="w-full flex-1 flex border flex-col rounded-md border-[#D3DAEE] p-4 gap-2.5 min-h-[300px]">
      <div className="flex w-full justify-between">
        <div>
          <div className="flex items-center">
            <h3 className="text-[#122B46] text-[22px] font-bold mr-1">Horses</h3>
            <Tooltip title={''} placement="top" arrow>
              <button
                type="button"
                className=""
                onClick={() => handleModal?.(true, MODAL_CONSTS.REGISTRATION_HORSES)}
              >
                <TooltipIcon color="#122B46" />
              </button>
            </Tooltip>
          </div>
          <p className={'text-[#122B4680] text-[14px]'}>Add all horses included in registration</p>
        </div>
        <div className={'flex gap-5'}>
          <div className={'flex flex-col text-right'}>
            <span className={'text-[#122B4680] text-[12px]'}>Team</span>
            <button
              onClick={() => handleModal?.(true, MODAL_CONSTS.HORSE)}
              className={'text-[14px] text-[#122B46] underline hover:no-underline'}
            >
              Add horse
            </button>
          </div>

          <div
            className={'flex w-[45px] rounded-md h-[45px] items-center justify-center bg-[#F6F7FB]'}
          >
            <IconHorseImg />
          </div>
        </div>
      </div>

      {myHorses.map((item, index) => {
        const currentMapping = mapping?.find((mapping_) => mapping_.horseId === item?.id)

        const horsePaperwork = horsesPaperwork?.find(
          (paperwork) => paperwork.id && paperwork.id === currentMapping?.id
        )

        return (
          <ProfileFields
            eventRequiredFields={Event?.requiredHorseFields || []}
            key={`${item.horseName} + ${index}`}
            horse={item}
            headerImgSrc={item?.horseProfilePicture || ''}
            headerTitle={item?.horseName || 'Unknown'}
            headerSubTitle={getUserNameById(item.horseOwnerId)}
            editHandler={async (profileFiled, horse) => {
              if (
                profileFiled.value === 'horseMeasurementCard' ||
                profileFiled.value === 'horseCoggins' ||
                profileFiled.value === 'horseHealthCertificate' ||
                profileFiled.value === 'horseRhinopneumonitis' ||
                profileFiled.value === 'horseInfluenza'
              ) {
                const currentMapping = mapping?.find((mapping_) => mapping_.horseId === horse?.id)
                const horsePaperwork = horsesPaperwork?.find(
                  (horsePaperwork_) =>
                    horsePaperwork_.id && horsePaperwork_.id === currentMapping?.id
                )
                handleModal?.(true, MODAL_CONSTS.UPDATE_TEAM_DETAILS, {
                  profileFiled,
                  horsePaperwork: horsePaperwork,
                  selectedHorse: horse,
                  onSave: async () => await getHorsesPaperwork(),
                })
              } else {
                handleModal?.(true, MODAL_CONSTS.UPDATE_USER_NUMBER, {
                  data: profileFiled,
                  isHorse: true,
                  horseData: item,
                })
              }
            }}
            removeHandler={() => removeHandler(item.id || '')}
            horsePaperwork={horsePaperwork}
            myHorses={myHorses}
          />
        )
      })}
    </div>
  )
}
