// Types

import { useAppSelector } from '../../../../store/hooks'
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useEffect, useState } from 'react'

type IProps = {
  revenueType: string
}

const ManageEventRevenueHeader = (props: IProps) => {
  const currentEvent = useAppSelector(selectedEvent)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [paidAmount, setPaidAmount] = useState<number>(0)

  useEffect(() => {
    if (currentEvent?.revenueCustomers && currentEvent?.revenueCustomers[0]) {
      const paidTotal = currentEvent.revenueCustomers
        .filter((payment) => payment.paymentStatus === 'paid')
        .reduce((total, payment) => total + payment.amountPaid, 0)
      const overallTotal = currentEvent.revenueCustomers.reduce(
        (total, payment) => total + payment.amountPaid,
        0
      )
      setTotalAmount(overallTotal)
      setPaidAmount(paidTotal)
    }
  }, [currentEvent?.revenueCustomers])

  return (
    <>
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <div className="bg-[#00B6AA26] rounded-md p-1 w-12 h-12 mr-4">
          <img className="w-full" src="/assets/cp_icons/Two Tickets-3.svg" alt="groupIcon" />
        </div>
        <div className="text-SeabiscuitDark200ThemeColor font-semibold">
          <span className="capitalize text-lg">{props.revenueType} revenue</span>
        </div>
      </div>

      <div className="flex flex-col mt-6 text-nr text-SeabiscuitDark200ThemeColor">
        <div className="flex items-center mb-2">
          <span className="w-40 mr-2">Total amount:</span>
          <span>${totalAmount.toLocaleString()}</span>
        </div>
        <div className="flex items-center mb-2">
          <span className="w-40 mr-2">Paid amount:</span>
          <span>${paidAmount.toLocaleString()}</span>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#D3DAEE] my-4"></div>
    </>
  )
}

export default ManageEventRevenueHeader
